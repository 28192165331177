import { Target } from "@angular/compiler";
import { Component, Input } from "@angular/core";
@Component({
    selector: 'app-targets',
    styleUrls: ['./targets.component.scss'],
    templateUrl: './targets.component.html'
})
export class TargetsComponent {

    @Input() targets: Target[];
}