
import {  Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Contractor, Person } from 'src/app/contractor/models';
import { Store } from '@ngrx/store';
import * as authActions from "../../../../../auth/actions/auth.actions"
import { LayoutActions } from '../../../../../core/actions';

@Component({
    selector: 'app-single-contractor',
    styleUrls: ['./single-contractor.component.scss'],
    templateUrl: './single-contractor.component.html'
})




export class SingleContractorComponent   implements OnInit, OnDestroy { 

  constructor(
    private store : Store) {

  } 
  ngOnDestroy(): void {
      this.store.dispatch(LayoutActions.hiddenLogoutBtn());
      this.store.dispatch(LayoutActions.showBtnsFromTop());
    }
  ngOnInit(): void {
    this.store.dispatch(LayoutActions.showLogoutBtn());
    this.store.dispatch(LayoutActions.hideBtnsFromTop());
    }
    @Input()  role: string;
  @Input() data: Contractor | Person;





  LogOut() {
    this.store.dispatch(authActions.logout());
  }
}
