import {createAction, props} from '@ngrx/store';
import { DialogData, DialogResult, DialogMeta } from '../models/dialog';


export const showDialog = 
    createAction('[Layout] Show Dialog',
    props<{dialog: DialogData, meta?:DialogMeta}>()
  );
export const setDialog =
  createAction('[Layout] Set Dialog',
    props<{ dialog: string}>()
  );

export const RemoveDialog = createAction('[Layout] Remove Dialog msg',
  
);



export const resultDialog = 
    createAction('[Layout] Hide Dialog Result',
    props<{result: DialogResult }>()
);
export const redirect = 
    createAction('[Layout] Redirect',
    props<{path: string }>()
);
export const role = 
    createAction('[Layout] role',
    props<{role: string }>()
  );

export const setError = createAction('[Layout] set Error',
  props<{ error: string }>()
);

export const setIsMediaviev  =  createAction('[Layout] set Media Viev',
  props<{ isMedia: boolean }>()
);



export const RemoveError = createAction('[Layout] Remove Error',
  
);

export const removeError = createAction('[Layout] role',
);



export const showLogoutBtn = createAction('[Layout] Show Logout Btn',
);


export const hiddenLogoutBtn = createAction('[Layout] hide Logout Btn',
);
export const showBtnsFromTop = createAction('[Layout] Show  Btns From Top Menu',
);

export const showFilters = createAction('[Layout] Show  Filters',
);


export const hideFilters = createAction('[Layout] Hide  Filters',
);

export const showMenu = createAction('[Layout] Show  Menu',
);


export const hideMenu = createAction('[Layout] Hide  Menu',
);

export const setLeadsFilterDate = createAction('[Layout]  Set Leads Filter Date',
  props<{start : string, end : string}>()
);

export const removeLeadsFilterDate = createAction('[Layout]  Remove Leads Filter Date');



export const setCurrentStatus = createAction('[Layout] Set Current Status',
  props<{ status: string }>()
);

export const setCurrentCampaing = createAction('[Layout] Set Current Campaign',
  props<{ campaing: string }>()
);


export const removeCurrentCampaing = createAction('[Layout] Remove Current Campaign',
  
);




export const removeCurrentStatus = createAction('[Layout] Remove Current Status',
);

export const setSearchTerm = createAction('[Layout] Set Search Term ',
  props<{ search: string }>()
);


export const removeSearchTerm = createAction('[Layout]  Remove Search Term',
);



export const setSearchCampaingTerm = createAction('[Layout] Set Search Campaign Term ',
  props<{ search: string }>()
);


export const removeSearchCampaignTerm = createAction('[Layout]  Remove Search Campaign Term',
);


export const showDentisFilter = createAction('[Layout]  Show Dentist Filter ',);






export const setCurrentPersonOfLeads = createAction('[Layout] Set Leads Person ',
  props<{ id: string }>()
);



export const hideDentisFilter = createAction('[Layout]  Hide Dentist Filter ',
);

export const showLeadsFilter = createAction('[Layout]  Show Leads Filter ',
);

export const hideLeadsFilter = createAction('[Layout]  Hide Leads Filter ',
);
export const setColumnVisible = createAction('[Layout]  Set Column Visible  ',
  props<{ column : { name: string, hide: boolean } }>()
);

export const LayoutInitialState = createAction('[Layout]  Layout InitialState ',);






export const hideBtnsFromTop = createAction('[Layout] Hide  Btns From Top Menu',
);

export const hideDialog = createAction('[Layout] Hide Dialog');
export const toggleEnlarge = createAction('[Layout] Toggle Enlarge');

