

<div class="container-fluid">
  <div class="row">
    
    <div class="tab-pane" id="pills-person-add" role="tabpanel" aria-labelledby="pills-person-add-tab">
      <form [formGroup]="form" (ngSubmit)="submit(form.value)">
        <div class="action-bar p-3 bg-light d-flex mb-3">
          <div class="row g-3 w-100">
            <div class="col">
              <select (change)="changeContractor($event.target.value)" class="form-select large" id="specificSizeSelect">
                <option>Wybierz</option>
                <option value="brak">Nie dotyczy</option>
                <option [selected]="item.shortName === currentPerson?.distributor && currentId" *ngFor="let item of contractors" [value]="item._id">{{ item.shortName }}</option>

              </select>
            </div>
            <div class="col">
              <select (change)="changeType($event.target.value)" class="form-select large" id="specificSizeSelect">
                <option  [selected]="!currentPerson">Wybierz rolę</option>

                <option [selected]="currentPerson?.type ==='seller'" value="seller">handlowiec</option>
                <option *ngIf="!noDistributor" [selected]="currentPerson?.type ==='director'" value="director">dyrektor</option>
                <option *ngIf="!noDistributor" [selected]="currentPerson?.type ==='admin'" value="admin">admin</option>
             
              </select>
            </div>
          </div>
        </div>
        <div class="row">
          <h5 class="section-title">Dane osoby</h5>

          <div class="col-3">
            <div class="form-floating mb-3">
              <input formControlName="name" type="text" class="form-control" id="name"/>
              <label for="name">Imię</label>
            </div>
          </div>

          <div class="col-3">
            <div class="form-floating mb-3">
              <input formControlName="surname" type="text" class="form-control" id="surname" />
              <label for="surname">Nazwisko</label>
            </div>
          </div>
          <div class="col-3">
            <div class="form-floating mb-3">
              <input   mask="000000000" formControlName="phone" type="text" class="form-control" id="phone" />
              <label for="phone">Numer telefonu</label>
            </div>
          </div>
          <div class="col-3">
            <div class="form-floating mb-3">
              <input formControlName="email" (focusout)="focuseOut($event.target.value)" type="text" class="form-control" id="email" />
              <label for="email">Adres e-mail</label>
            </div>
          </div>

          <div class="col-3">
            <div class="mb-3">
              <select formControlName="state" class="form-select large" id="specificSizeSelect">
                <option  *ngFor="let region of regions">{{ region }}</option>
              </select>
            </div>
          </div>

          <div class="col-3">
            <div class="form-floating mb-3">
              <input formControlName="country" type="text" class="form-control" id="country" />
              <input hidden formControlName="distributorId" type="text" class="form-control" id="apartment_number"  />
              <label for="country">Kraj</label>
            </div>
          </div>
          <div class="col-3">
            <div class="form-floating mb-3">
              <input formControlName="city" type="text" class="form-control" id="city"  />

              <label for="city">Miasto</label>
            </div>
          </div>
          <div class="col-6">
            <div class="mb-3">
              <textarea formControlName="note" class="form-control" id="exampleFormControlTextarea1" rows="4" placeholder="Notatki"></textarea>
            </div>
          </div>
        </div>
        <hr />
        <div class="row">
          <div class="col-6">
            <h5 class="section-title">Dane dostępowe</h5>

            <div class="row">
              <div class="col">
                <div class="form-floating mb-3">
                  <input formControlName="login" type="text" class="form-control" id="login"  />
                  <label for="login">Login</label>
                </div>
              </div>

              <div class="col">
                <div  class="pass_rules form-floating mb-3">
                  <input #password formControlName="password" type="text" class="form-control" id="password"  />
                  <label for="password">Hasło</label>
                  <p class="mb-0" >Hasło powinno zawierać:</p>
                  <ul>
                    <li>- minimum 8 znaków</li>
                    <li>- co najmiej 1 wielką litere</li>
                    <li>- co najmiej 1 małą litere</li>
                    <li>- co najmiej 1  cyfra</li>
                    <li>- co najmiej 1  znak specjalny</li>
                  </ul>
                </div>
              </div>

              <div class="col-sm-4">
                <a [ngClass]="currentId ? 'disabled' : '' " (click)="setPassword()" class="d-none d-sm-inline-block btn btn-secondary w-100 py-2">Wygeneruj hasło</a>
              </div>
            </div>
          </div>
        </div>

        <hr />

        <div class="d-flex justify-content-end mb-3">
          <button [disabled]="form.invalid" type="submit" class="d-none d-sm-inline-block btn btn-primary px-5 ms-3">Zapisz</button>
        </div>
      </form>
    </div>
  </div>
</div>

