import {FormGroup, FormControl} from '@angular/forms';

export class Utils {

    public static validateFormFields(formGroup: FormGroup) {
        Object.keys(formGroup.controls).forEach(field => {
            const control = formGroup.get(field);
            if (control instanceof FormControl && !control.valid) {
                control.markAsTouched({onlySelf: true});
            } else if (control instanceof FormGroup) {
                Utils.validateFormFields(control);
            }
        });
    }
    public static clean(object) {
        Object
            .entries(object)
            .forEach(([k, v]) => {
                if (v && typeof v === 'object') {
                    this.clean(v);
                }
                if (v && typeof v === 'object' && !Object.keys(v).length || v === null || v === undefined) {
                    if (Array.isArray(object)) {
                        object.splice(parseInt(k), 1).filter(()=> { return true });
                    } else {
                        delete object[k];
                    }
                }
            });
        return object;
    }
    public static copyNonEmpty(o) {
        let ignores = [null, undefined, ""],
            that = this,
          isNonEmpty = d => !ignores.includes(d) && (typeof(d) !== "object" || Object.keys(d).length)
        return JSON.parse(JSON.stringify(o), function(k, v) {
          if (isNonEmpty(v))
            return that.clean(v);
        });
      }
      public static isJson(str: string) {
          try {
              var json = JSON.parse(str);
              return json;
          } catch (e) {
              return str;
          }
      }
      public static toTitleCase(str:string) {
        return str.replace(
          /\w\S*/g,
          function(txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
          }
        );
      }
}