
import { Component } from "@angular/core";
import { Store, select } from '@ngrx/store';
import * as fromContractors from 'src/app/contractor/reducers';


@Component({
  selector: 'app-add-contractor-page',
  template: `<app-add-contractor
[list]="contractors$ | async"
[currentContractor]="contractor$ | async"

></app-add-contractor>`
})




export class AddContractorPageComponent   {
  contractors$ = this.store.pipe(select(fromContractors.selectContractorOptions));
  contractor$ = this.store.pipe(select(fromContractors.selectCurrentContractor));
  constructor(
    private store: Store ) {
   
  }

 

}
