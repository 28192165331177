import { Component,  Input, } from '@angular/core';
import { ISingleLeadDetails } from '../models';






@Component({
  selector: 'app-single-lead',
  // styleUrls: ['./add-contractor.component.scss'],
  templateUrl: "./single-lead.component.html",
})

export class SingleLeadComponent {
  @Input() lead: ISingleLeadDetails;




}
