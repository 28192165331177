<div class="tab-pane" id="pills-player" role="tabpanel" aria-labelledby="pills-player-tab">
  <div class="action-bar p-3 bg-light mb-3">

    <div class="row g-3 w-100 mb-3">
      <div class="col-sm-3">
        <div class="form-floating">
          <select (change)="selectProduct($event.target.value)"  class="form-select large" id="specificSizeSelect">
            <option [selected]="true">Wybierz produkt</option>
            <option value="eminto box">eminto box</option>
            <option value="player virtualny">player virtualny</option>
            <option value="aplikacja desktop">aplikacja desktop</option>
          </select>
        </div>
      </div>
      <div *ngIf="selectedProduct === 'aplikacja desktop' " class="col-sm-3 d-grid">
        <button type="submit" class="d-none d-sm-inline-block btn btn-primary btn-block pt-3">Pobierz aplikację</button>
      </div>
      <div *ngIf="selectedProduct === 'aplikacja desktop' " class="col-sm-3 d-grid">
        <a (click)="copy()" type="submit" class="d-none d-sm-inline-block pt-3">Skopiuj link do pobrania</a>
      </div>
      <div   *ngIf="selectedProduct === 'player virtualny'   ||  selectedProduct === 'eminto box'"  class="col-sm-3">
        <div class="form-floating">
          <select (change)="selectQty($event.target.value)"    class="form-select large" id="specificSizeSelect">
            <option selected>ilość</option>
            <option>1</option>
            <option>2</option>
            <option>3</option>
            <option>4</option>
            <option>5</option>
          </select>







      
        </div>
      </div>
    </div>
    <form *ngIf="selectedProduct === 'eminto box' " [formGroup]="form" (ngSubmit)="submit(form.value)">
      <div class="row g-3 w-100 mb-3">
        <h5 class="section-title mb-0">eminto box</h5>
    
        <div class="col-sm-6">
          <div class="form-floating">
            <textarea  rows="4" cols="50" formControlName="mesage" type="number" class="form-control" id="Wiadomość" placeholder="Nazwa gabinetu"></textarea>
            <label for="Wiadomość">Wiadomość</label>
          </div>
        </div>
        <div class="col-sm-3 d-grid">
          <button type="submit"   [disabled]="!selectedQty"  class="d-none d-sm-inline-block btn btn-primary btn-block pt-3">Zamów</button>
        </div>
      </div>


    </form>
    <form *ngIf="selectedProduct === 'player virtualny' " [formGroup]="form2" (ngSubmit)="submit(form2.value)">
      <div class="row g-3 w-100 mb-3">
        <h5 class="section-title mb-0">player virtualny</h5>
        <div class="col-sm-3 d-grid">
          <button  [disabled]="!selectedQty"    type="submit" class="d-none d-sm-inline-block btn btn-primary btn-block pt-3">Zamów</button>
        </div>


      </div>

 
    </form>
  </div>
  <div class="action-bar p-3 bg-light mb-3">
    <form *ngIf="selectedProduct === 'aplikacja desktop' " [formGroup]="form3" (ngSubmit)="submit(form3.value)">
      <div class="row g-3 w-100 mb-3">
        <h5 class="section-title mb-0">aplikacja desktop</h5>
        <div class="col-sm-3">
          <div class="form-floating">
            <input formControlName="computerId" type="number" class="form-control" id="number-nip" placeholder="Nazwa gabinetu" />
            <label for="number-nip">ID komputera klienta</label>
          </div>
        </div>
        <div class="col-sm-3">
          <div class="form-floating">
            <select formControlName="time" class="form-select large" id="specificSizeSelect">
              <option selected>Długość dostepu do konta</option>
              <option>demo</option>
              <option>20 dni</option>
              <option>rok</option>
            </select>
          </div>
        </div>

        <div class="col-sm-3">
    <div class="form-floating">
      <select formControlName="dentist" class="form-select large" id="specificSizeSelect">
        <option selected>Gabinet</option>
        <option>-</option>
      </select>
    </div>
  </div>





      </div>
      <div class="row g-3 w-100">
        <div class="col-sm-3 d-grid">
          <a (click)="generateKey()" class="d-none d-sm-inline-block btn btn-secondary btn-block pt-3">Wygeneruj klucz</a>
        </div>
        <div class="col-sm-3">
    <div class="form-floating">
      <input formControlName="key"  #key  type="text" class="form-control" id="number-nip" placeholder=""  />
      <label       for="number-nip">Wygenerowany klucz</label>
    </div>
  </div>

        <div class="col-sm-3 d-grid">
          <button type="submit" class="d-none d-sm-inline-block btn btn-primary btn-block pt-3">Zakończ</button>
        </div>


      </div>
    </form>
  </div>
</div>
