import { AfterViewInit, Component, EventEmitter, OnDestroy, OnInit, Output } from "@angular/core";
import * as fromLayout from 'src/app/core/reducers';

import { select, Store } from "@ngrx/store";

@Component({
    selector: 'app-learning',
    styleUrls: ['./learning.component.scss'],
    templateUrl: './learning.component.html'
})
export class LearningComponent implements OnInit  {


  isMedia$ = this.store.pipe(select(fromLayout.selectIsMedia));
  isMediaActive: boolean;

    constructor(
   
  
        private store: Store<fromLayout.State>) {
    
      }
  ngOnInit(): void {
    this.isMedia$.subscribe(val => {
      this.isMediaActive = val
    });
    }
     
}
