import { Component, OnInit } from "@angular/core";
import mediaData from "./media.json"
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

import { LayoutActions } from 'src/app/core/actions'
import { select, Store } from "@ngrx/store";


@Component({
  selector: 'app-eminto',
  styleUrls: ['./eminto.component.scss'],
  templateUrl: './eminto.component.html'
})



export class EmintoComponent implements OnInit {

  title = 'appBootstrap';
  imgSrc: string;
  closeResult: string;
  link: string;
  constructor(
    private store : Store,
    private modalService: NgbModal) { }
  open(content, img, link) {

    this.imgSrc = img.getAttribute('src')
    this.link = link.getAttribute('href');

    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {

      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {

      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });

  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  ngOnDestroy(): void {
    this.store.dispatch(LayoutActions.setIsMediaviev({ isMedia: false }));
  };

  media: object[];
  ngOnInit(): void {
    this.media = mediaData;
    this.store.dispatch(LayoutActions.setIsMediaviev({ isMedia: true }));

  }

}
