
import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from "@angular/core";
import {  NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ILeadPopup } from "../../models/ledasSteps.models";



@Component({
    selector: 'app-lead-modal',
    templateUrl: './lead-modal.component.html',
    styleUrls: ['./lead-modal.component.scss'],
})
export class LeadModalComponent implements OnChanges {
    @ViewChild('leadModal ') leadModal: ElementRef;


    @Input() currentLead: string;
    @Input() stepSelected: any;
    @Input() show: ILeadPopup;
    @Output()
    closeModal = new EventEmitter<any>();
    name : string;

    constructor(
        private modalService: NgbModal,
        ) {
    }
   
    ngOnChanges(changes: SimpleChanges): void {
      
        if (!changes.show?.firstChange && changes.show?.currentValue) {
            debugger
            this.name = changes.show.currentValue.name;
            this.modalService.open(this.leadModal, { ariaLabelledBy: "modal-basic-title" }).result.then((result) => {
                this.closeModal.emit('close')
                
            },
            );
        }
    }
}
