<nav *ngIf="role">
  <div
    [ngClass]="menu ? 'show' : 'hideMenu'"
    class="navbar px-3"
    style="transition: 0.5s all; width: 200px"
  >
    <div class="close-ctn d-flex">
      <i
        [ngClass]="menu ? '' : 'rotate'"
        (click)="toggleMenu()"
        class="fa-solid fa-arrow-left"
      ></i>
    </div>
    <a class="navbar-brand text-decoration-none">
      <img src="assets/img/logo.svg" alt="Eminto" />
    </a>

    <!--<hr class="navbar-divider my-0" />-->
    <div *ngIf="role === 'admin' || role === 'director'">
      <a
        [routerLink]="['contractor/list-contractor']"
        class="d-flex align-items-center text-decoration-none mb-4"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <strong>{{ user }} </strong>
      </a>
    </div>
    <div *ngIf="role === 'seller'">
      <a
        [routerLink]="['contractor/list-contractor']"
        class="d-flex align-items-center text-decoration-none mb-4"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <strong>{{ user }} </strong>
      </a>
    </div>
    <div *ngIf="role === 'eminto'">
      <a
        [routerLink]="['contractor/list-contractor/name']"
        class="d-flex align-items-center text-decoration-none mb-4"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <strong>{{ user }} </strong>
      </a>
    </div>
    <!--<hr class="navbar-divider my-0" />-->

    <ul class="nav nav-pills flex-column mb-auto">
      <li class="menu-list-item">
        <span *ngIf="role === 'eminto'" class="d-flex">
          <svg
            version="1.1"
            id="Warstwa_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 48 48"
            style="enable-background: new 0 0 48 48"
            xml:space="preserve"
          >
            <style type="text/css">
              .st0 {
                fill: #ffffff;
              }
            </style>
            <path
              class="st0"
              d="M34.2,28.9c-1.3,0-2.4-0.5-3.3-1.4c-0.9-0.9-1.4-2-1.4-3.3s0.5-2.4,1.4-3.3s2-1.4,3.3-1.4
	c1.3,0,2.4,0.5,3.3,1.4s1.4,2,1.4,3.3s-0.5,2.4-1.4,3.3C36.6,28.5,35.5,28.9,34.2,28.9z M24.4,40v-2.5c0-0.9,0.2-1.6,0.6-2.2
	c0.4-0.6,0.9-1.1,1.5-1.4c1.2-0.6,2.5-1.1,3.8-1.4s2.6-0.4,4-0.5c1.4,0,2.7,0.1,4,0.4s2.5,0.8,3.8,1.4c0.7,0.3,1.2,0.8,1.5,1.4
	c0.4,0.6,0.5,1.4,0.5,2.2V40H24.4z M20,23.9c-2.2,0-4-0.7-5.4-2.1c-1.4-1.4-2.1-3.2-2.1-5.4s0.7-4,2.1-5.4s3.2-2.1,5.4-2.1
	s4,0.7,5.4,2.1c1.4,1.4,2.1,3.2,2.1,5.4s-0.7,4-2.1,5.4C24,23.2,22.2,23.9,20,23.9z M4,40v-4.7c0-1.1,0.3-2.2,0.8-3.1
	c0.6-0.9,1.4-1.7,2.6-2.2c2.2-1,4.4-1.8,6.4-2.2S17.9,27,20,27c1.1,0,2,0.1,2.9,0.1c0.8,0.1,1.7,0.2,2.7,0.5L23,30.1
	c-0.4-0.1-0.9-0.1-1.4-0.1s-1,0-1.6,0c-1.9,0-3.8,0.2-5.6,0.6c-1.8,0.4-3.8,1.1-5.8,2.1c-0.5,0.2-0.9,0.6-1.2,1.1
	c-0.3,0.5-0.4,1-0.4,1.5V37h14.4v3H4z M21.4,37L21.4,37L21.4,37L21.4,37L21.4,37L21.4,37L21.4,37L21.4,37L21.4,37z M20,20.9
	c1.3,0,2.4-0.4,3.2-1.3c0.8-0.9,1.3-1.9,1.3-3.2s-0.4-2.4-1.3-3.2c-0.9-0.9-1.9-1.3-3.2-1.3s-2.4,0.4-3.2,1.3
	c-0.8,0.8-1.3,1.9-1.3,3.2s0.4,2.4,1.3,3.2C17.6,20.5,18.7,20.9,20,20.9z M20,16.4L20,16.4L20,16.4L20,16.4L20,16.4L20,16.4L20,16.4
	L20,16.4L20,16.4z"
            /></svg
          ><a
            [routerLink]="['contractor/list-contractor']"
            [ngClass]="url === 'contractor' ? 'active-url' : ''"
            [routerLinkActive]="['active']"
            class="ms-2 nav-link"
            >Dystrybutorzy</a>
        </span>

        <span class="d-flex" *ngIf="role === 'director' || role === 'admin'">
          <svg
            version="1.1"
            id="Warstwa_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 48 48"
            style="enable-background: new 0 0 48 48"
            xml:space="preserve"
          >
            <style type="text/css">
              .st0 {
                fill: #ffffff;
              }
            </style>
            <path
              class="st0"
              d="M34.2,28.9c-1.3,0-2.4-0.5-3.3-1.4c-0.9-0.9-1.4-2-1.4-3.3s0.5-2.4,1.4-3.3s2-1.4,3.3-1.4
	c1.3,0,2.4,0.5,3.3,1.4s1.4,2,1.4,3.3s-0.5,2.4-1.4,3.3C36.6,28.5,35.5,28.9,34.2,28.9z M24.4,40v-2.5c0-0.9,0.2-1.6,0.6-2.2
	c0.4-0.6,0.9-1.1,1.5-1.4c1.2-0.6,2.5-1.1,3.8-1.4s2.6-0.4,4-0.5c1.4,0,2.7,0.1,4,0.4s2.5,0.8,3.8,1.4c0.7,0.3,1.2,0.8,1.5,1.4
	c0.4,0.6,0.5,1.4,0.5,2.2V40H24.4z M20,23.9c-2.2,0-4-0.7-5.4-2.1c-1.4-1.4-2.1-3.2-2.1-5.4s0.7-4,2.1-5.4s3.2-2.1,5.4-2.1
	s4,0.7,5.4,2.1c1.4,1.4,2.1,3.2,2.1,5.4s-0.7,4-2.1,5.4C24,23.2,22.2,23.9,20,23.9z M4,40v-4.7c0-1.1,0.3-2.2,0.8-3.1
	c0.6-0.9,1.4-1.7,2.6-2.2c2.2-1,4.4-1.8,6.4-2.2S17.9,27,20,27c1.1,0,2,0.1,2.9,0.1c0.8,0.1,1.7,0.2,2.7,0.5L23,30.1
	c-0.4-0.1-0.9-0.1-1.4-0.1s-1,0-1.6,0c-1.9,0-3.8,0.2-5.6,0.6c-1.8,0.4-3.8,1.1-5.8,2.1c-0.5,0.2-0.9,0.6-1.2,1.1
	c-0.3,0.5-0.4,1-0.4,1.5V37h14.4v3H4z M21.4,37L21.4,37L21.4,37L21.4,37L21.4,37L21.4,37L21.4,37L21.4,37L21.4,37z M20,20.9
	c1.3,0,2.4-0.4,3.2-1.3c0.8-0.9,1.3-1.9,1.3-3.2s-0.4-2.4-1.3-3.2c-0.9-0.9-1.9-1.3-3.2-1.3s-2.4,0.4-3.2,1.3
	c-0.8,0.8-1.3,1.9-1.3,3.2s0.4,2.4,1.3,3.2C17.6,20.5,18.7,20.9,20,20.9z M20,16.4L20,16.4L20,16.4L20,16.4L20,16.4L20,16.4L20,16.4
	L20,16.4L20,16.4z"
            />
          </svg>

          <a
            [routerLink]="['contractor/list-preson']"
            [routerLinkActive]="['active']"
            class="ms-2 nav-link"
            >Lista osób</a
          >
        </span>
      </li>
      <li *ngIf="role === 'eminto'" class="menu-list-item">
        <span class="d-flex">
          <svg
            version="1.1"
            id="Warstwa_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 48 48"
            style="enable-background: new 0 0 48 48"
            xml:space="preserve"
          >
            <style type="text/css">
              .st0 {
                fill: #ffffff;
              }
            </style>
            <path
              class="st0"
              d="M11,44c-0.8,0-1.5-0.3-2.1-0.9C8.3,42.5,8,41.8,8,41V15c0-0.8,0.3-1.5,0.9-2.1C9.5,12.3,10.2,12,11,12h5.5v-0.5
	c0-2.1,0.7-3.9,2.2-5.3C20.1,4.7,21.9,4,24,4s3.9,0.7,5.3,2.2c1.4,1.4,2.2,3.2,2.2,5.3V12H37c0.8,0,1.5,0.3,2.1,0.9
	c0.6,0.6,0.9,1.3,0.9,2.1v26c0,0.8-0.3,1.5-0.9,2.1C38.5,43.7,37.8,44,37,44H11z M19.5,11.5V12h9v-0.5c0-1.3-0.4-2.3-1.3-3.2
	C26.3,7.4,25.3,7,24,7s-2.3,0.4-3.2,1.3C19.9,9.2,19.5,10.2,19.5,11.5z M11,41h26l0,0l0,0V15l0,0l0,0h-5.5v4.5
	c0,0.4-0.1,0.8-0.4,1.1C30.8,20.9,30.4,21,30,21s-0.8-0.1-1.1-0.4c-0.3-0.3-0.4-0.6-0.4-1.1V15h-9v4.5c0,0.4-0.1,0.8-0.4,1.1
	C18.8,20.9,18.4,21,18,21s-0.8-0.1-1.1-0.4c-0.3-0.3-0.4-0.6-0.4-1.1V15H11l0,0l0,0V41L11,41L11,41z M11,41L11,41L11,41V15l0,0l0,0
	l0,0l0,0V41L11,41L11,41z"
            />
          </svg>

          <a
            [routerLink]="['products/list']"
            [routerLinkActive]="['active']"
            [ngClass]="url === 'products' ? 'active-url' : ''"
            class="ms-2 nav-link"
            >Produkty</a
          >
        </span>
      </li>
      <li *ngIf="role === 'eminto'" class="menu-list-item">
        <span class="d-flex">
          <svg
            version="1.1"
            id="Warstwa_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 48 48"
            style="enable-background: new 0 0 48 48"
            xml:space="preserve"
          >
            <style type="text/css">
              .st0 {
                fill: #ffffff;
              }
            </style>
            <path
              class="st0"
              d="M18.5,25.5v-9.2l5.5-3.6l5.5,3.6v9.2h-3v-6h-5v6H18.5z M24,40c4.4-4,7.7-7.7,9.8-11s3.2-6.2,3.2-8.7
	c0-3.9-1.3-7.1-3.8-9.6S27.6,7,24,7s-6.7,1.3-9.2,3.8S11,16.5,11,20.4c0,2.5,1.1,5.4,3.2,8.7S19.7,36,24,40z M24,44
	c-5.4-4.6-9.4-8.8-12-12.7s-4-7.5-4-10.9c0-5,1.6-9,4.8-11.9S19.8,4,24,4s8,1.5,11.2,4.4S40,15.4,40,20.4c0,3.3-1.3,7-4,10.9
	C33.4,35.2,29.4,39.4,24,44z"
            />
          </svg>
          <a
            [routerLink]="['dentist/list-dentist']"
            [routerLinkActive]="['active']"
            [ngClass]="url === 'dentist' ? 'active-url' : ''"
            class="ms-2 nav-link"
            >Gabinety</a
          >
        </span>
      </li>
      <li class="menu-list-item">
        <span class="d-flex">
          <svg
            version="1.1"
            id="Warstwa_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 48 48"
            style="enable-background: new 0 0 48 48"
            xml:space="preserve"
          >
            <style type="text/css">
              .st0 {
                fill: #ffffff;
              }
            </style>
            <path
              class="st0"
              d="M24,42L9.4,34V22L2,18L24,6l22,12v15.8h-3V19.7L38.5,22v12L24,42z M24,26.6L39.7,18L24,9.5L8.3,18L24,26.6z
	 M24,38.6l11.5-6.3v-8.4L24,30l-11.5-6.2v8.5L24,38.6z"
            />
          </svg>

          <a
            [routerLink]="['/learning/presentation']"
            [routerLinkActive]="['active']"
            [ngClass]="url === 'learning' ? 'active-url' : ''"
            class="ms-2 nav-link"
            >E-Learning</a
          >
        </span>
      </li>
      <li class="menu-list-item">
        <span *ngIf="role === 'eminto'" class="d-flex align-items-center">
          <svg
            version="1.1"
            id="Warstwa_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 48 48"
            style="enable-background: new 0 0 48 48"
            xml:space="preserve"
          >
            <style type="text/css">
              .st0 {
                fill: #ffffff;
              }
            </style>
            <path
              class="st0"
              d="M14.4,43.9c-1,0-1.9-0.3-2.6-1c-0.7-0.7-1.1-1.6-1.1-2.6s0.3-1.8,1.1-2.5c0.7-0.7,1.6-1,2.6-1
	c1,0,1.8,0.4,2.5,1c0.7,0.7,1.1,1.5,1.1,2.5s-0.4,1.9-1.1,2.6S15.4,43.9,14.4,43.9z M34.3,43.9c-1,0-1.8-0.3-2.5-1
	c-0.7-0.7-1-1.6-1-2.6s0.4-1.8,1-2.5c0.7-0.7,1.5-1,2.5-1c1,0,1.8,0.4,2.5,1c0.7,0.7,1.1,1.5,1.1,2.5s-0.3,1.9-1,2.6
	S35.3,43.9,34.3,43.9z M11.8,10.9l5.5,11.4h14.4l0,0l0,0l6.3-11.4l0,0l0,0H11.8z M10.2,7.9h29.5c1.1,0,1.7,0.3,2,1
	c0.3,0.6,0.2,1.4-0.3,2.2l-6.8,12.1c-0.3,0.6-0.8,1.1-1.4,1.5c-0.6,0.4-1.2,0.6-1.9,0.6H16.2l-2.8,5.2c0,0,0,0,0,0c0,0,0,0,0,0H38v3
	H13.9c-1.4,0-2.4-0.5-3-1.4c-0.6-0.9-0.6-2,0-3.2l3.2-5.9L6.4,7H2.5V4h5.8L10.2,7.9z M17.2,22.3h14.4l0,0l0,0H17.2z"
            /></svg
          ><a
            [routerLink]="['orders/orders-list']"
            [routerLinkActive]="['active']"
            [ngClass]="url === 'orders' ? 'active-url' : ''"
            class="ms-2 nav-link"
            >Zamówienia</a
          ><span *ngIf="orders.length > 0" class="ms-2 point"></span>
        </span>
      </li>
      <li class="menu-list-item">
        <span *ngIf="role === 'eminto'" class="d-flex align-items-center">
          <svg
            version="1.1"
            id="Warstwa_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 48 48"
            style="enable-background: new 0 0 48 48"
            xml:space="preserve"
          >
            <style type="text/css">
              .st0 {
                fill: #ffffff;
              }
            </style>
            <path
              class="st0"
              d="M32.9,8.9c4.4,0,7.8,3.4,7.8,7.8c0,6.1-6.3,11.8-15.8,20.4l-0.1,0.1L24,38l-0.9-0.8
	C13.6,28.6,7.3,22.9,7.3,16.8c0-4.4,3.4-7.8,7.8-7.8c2.5,0,4.9,1.1,6.6,3.1l2.3,2.7l2.3-2.7C27.9,10.1,30.4,8.9,32.9,8.9 M32.9,5.9
	c-3.4,0-6.7,1.6-8.9,4.1c-2.1-2.5-5.4-4.1-8.9-4.1C9,5.9,4.3,10.7,4.3,16.7c0,7.4,6.7,13.5,16.8,22.7L24,42l2.9-2.6
	C37,30.3,43.7,24.2,43.7,16.8C43.7,10.7,38.9,5.9,32.9,5.9L32.9,5.9z"
            />
          </svg>

          <a
            [routerLink]="['leads/lead-list']"
            [routerLinkActive]="['active']"
            [ngClass]="url === 'leads' ? 'active-url' : ''"
            class="ms-2 nav-link"
            >Leads</a
          >
        </span>
      </li>
      <li class="menu-list-item">
        <span *ngIf="role === 'eminto' || role === 'callCenter'" class="d-flex align-items-center">
          <svg
            version="1.1"
            id="Warstwa_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 48 48"
            style="enable-background: new 0 0 48 48"
            xml:space="preserve"
          >
            <style type="text/css">
              .st0 {
                fill: #ffffff;
              }
            </style>
            <path
              class="st0"
              d="M32.9,8.9c4.4,0,7.8,3.4,7.8,7.8c0,6.1-6.3,11.8-15.8,20.4l-0.1,0.1L24,38l-0.9-0.8
	C13.6,28.6,7.3,22.9,7.3,16.8c0-4.4,3.4-7.8,7.8-7.8c2.5,0,4.9,1.1,6.6,3.1l2.3,2.7l2.3-2.7C27.9,10.1,30.4,8.9,32.9,8.9 M32.9,5.9
	c-3.4,0-6.7,1.6-8.9,4.1c-2.1-2.5-5.4-4.1-8.9-4.1C9,5.9,4.3,10.7,4.3,16.7c0,7.4,6.7,13.5,16.8,22.7L24,42l2.9-2.6
	C37,30.3,43.7,24.2,43.7,16.8C43.7,10.7,38.9,5.9,32.9,5.9L32.9,5.9z"
            />
          </svg>

          <a
            [routerLink]="['offer/create']"
            [routerLinkActive]="['active']"
            [ngClass]="url === 'offer' ? 'active-url' : ''"
            class="ms-2 nav-link"
            >Oferta</a
          >
        </span>
      </li>
    </ul>
  </div>
</nav>
