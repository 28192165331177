import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { SharedModule } from "../common/shared.module";
import { AddProductsComponent } from "./components/add-product/add-products.component";
import { OrderComponent } from "./components/order/order.component";
import { ProductsComponent } from "./components/products/products.component";
import * as fromProducts from './reducers'
import { ProductsPageComponent } from "./containers/products-page.component";
import { ProductsRoutingModule } from "./products-routing.module";
import { AddProductPageComponent } from "./containers/add.products.component";
import { ProductsEffects } from "./effects/products.effects";
import { ProductsListPageComponent } from "./containers/product-page.component/product-page.component";

export const COMPONENTS = [
    ProductsComponent,
    ProductsPageComponent,
    AddProductsComponent,
  OrderComponent,
   AddProductPageComponent,
    ProductsListPageComponent
]
    

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        ProductsRoutingModule,
        ReactiveFormsModule,

    StoreModule.forFeature(fromProducts.productFeatureKey, fromProducts.reducers),
    EffectsModule.forFeature([ProductsEffects])
    ],
    providers: [
        //   { provide: ApiService, useClass: FakeService },
    //  { provide: ApiService, useClass: HomeService }
    ],
    declarations: COMPONENTS
})
export class ProductsModule { }
