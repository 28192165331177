
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AddDentistPageComponent } from './containers/add-dentist.page.component';
import { DentistPageComponent } from './containers/dentist-page.component';
import { SingleDentistPageComponent } from "./containers/single-dentist.page.component/single-dentis.page.component";
import { DentistListPageComponent } from "./containers/dentist-list.page.component/dentist-list.page.component";
import { AuthGuard } from "../auth/services/auth-guard.service";


export const routes: Routes = [

  {
    path: 'dentist', component: DentistPageComponent, canActivate: [AuthGuard],
    children: [
      {
        path: 'add-dentist',
        component: AddDentistPageComponent, canActivate: [AuthGuard]
      },
      {
        path: 'add-dentist/:id',
        component: AddDentistPageComponent, canActivate: [AuthGuard]
      },
      {
        path: 'add-dentist/:id/:lead',
        component: AddDentistPageComponent, canActivate: [AuthGuard]
      },
      {
        path: 'add-dentist/:id/:lead/:status',
        component: AddDentistPageComponent, canActivate: [AuthGuard]
      },
      {
        path: 'list-dentist',
        component: DentistListPageComponent, canActivate: [AuthGuard]
      },
      {
        path: 'single-dentist/:id',
        component: SingleDentistPageComponent, canActivate: [AuthGuard]
      },
     
    ]
  },
]
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DentistRoutingModule { }
