
import { AfterViewInit, Component, OnInit } from '@angular/core';

import { ActivatedRoute } from '@angular/router';
import { select, Store } from '@ngrx/store';
import * as fromAuth from 'src/app/auth/reducers'
import * as fromOrders from 'src/app/orders/reducers'


@Component({

  template: `<app-orders
  [role]="role$ | async"
  [d]="orders$ | async"

></app-orders>`
})
export class OrdersListPageComponent {

  constructor(

    private store: Store,
    private route: ActivatedRoute
  ) {


  }

  role$ = this.store.pipe(select(fromAuth.selectUserType));
  orders$ = this.store.pipe(select(fromOrders.selectOrders));
  dataDentist$ = this.store.pipe(select(fromOrders.selectOrders));
}
