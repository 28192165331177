import { createAction, props } from "@ngrx/store";
import { Dentist } from "../models";


export const getDentists = createAction(
  "[Dentists Page] Get Dentists"
);


export const getDentistsBySeller = createAction(
  "[Dentists Page] Get Dentists By Seller",
  props<{ id: string }>()
);





export const getDentistsWithoutContractors = createAction(
  "[Dentists Page] Get Dentists Without Contractors"
);


export const getSingleDentists = createAction(
  "[Dentists Page] Get Dentists",
  props<{ id: string }>()
);


export const getDentistsDetails = createAction(
  "[Dentists Page] Get Dentists Details",
  props<{ id: string }>()
);


export const removeCurrentDentist = createAction(
  "[Dentists Page] Remove Current Details",
 
);






export const postDentists = createAction(
  "[Dentists Page] Post Dentists",
  props<{ dentist: Dentist }>()
);

export const postDentistsWithStatus = createAction(
  "[Dentists Page] Post Dentists With Status",
  props<{ dentist: Dentist, status: string }>()
);





export const upDateViewChange = createAction(
  "[Dentists Page] Change upDate view",
  props<{ view: boolean }>()
);

export const getStatusList = createAction(
  "[Dentists Page] Get Status List"
);

export const getDentistDetails = createAction(
  "[Dentists Page] Get Dentist Details",
  props<{ dentistId: string }>()
);



export const AddProductToDentist = createAction(
  "[Dentists Page] Add Product To Dentist",
  props<{ id: string, dentistId:string, statusId : string | boolean }>()
);

export const SetDentistStatus= createAction(
  "[Dentists Page] Set Dentist Status",
  props<{ dentistId: string, statusId: string | boolean , productId?: any}>()
);


export const RemoveCurrentDentist = createAction(
  "[Dentists Page] Remove Current Dentist",

);


export const RemoveDentisFromGus = createAction(
  "[Dentists Page] Remove Gus Dentist",

);




export const GenerateTestLink = createAction(
  "[Dentists Page] Generate Test Link",
  props<{dentistId: string, productId: string[] }>()

);

export const GeneratePaymentsLink = createAction(
  "[Dentists Page] Generate Payments Link",
  props<{ dentistId: string, productId: string[], licenseDuration:number, price?: string, monthly?: string  }>()

);

export const confirmPayments = createAction(
  "[Product Page] Confirm Payments",
  props<{ id: string }>()
)


export const GetDataFromGus = createAction(
  '[Dentists API] Get Data From Gus',
  props<{ nip: string }>()
);


export const AddContractorToDentist = createAction(
  "[Dentists Page] Add Contractor To Dentist",
  props<{ id: string, accountId: string, distributorId?: string  }>()
);




export const renewLicense = createAction(
  "[Product Api]  renew  License",
  props<{ dentistId: string, laicensDuration: number }>());


export const addLogo = createAction(
  "[Product Api]  Add Logo",
  props<{ logo: File }>());
