import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import * as fromAuth from 'src/app/auth/reducers'
import * as fromLayout from 'src/app/core/reducers'
import { routesData } from '../../common/models/route.model';

@Component({
  selector: 'orders-page',
  template: `<app-top-nav
    [role]="role$ | async"
    [routes]="routesData"
  [selectBtnFromTop]="showBtnFromTop$ | async"
    ></app-top-nav>
    <router-outlet></router-outlet>`
})
export class OrdersPageComponent {

  constructor(
    private router: Router,
    private store2: Store<fromLayout.State>,
    private store: Store<fromAuth.State>) {


  }


  role$ = this.store.pipe(select(fromAuth.selectUserRole));
  showBtnFromTop$ = this.store2.pipe(select(fromLayout.selectBtnsFromTop));
  routesData: routesData[] = [
    {
      path: 'orders-list',
      name: 'Zamówienia',
      forbiden: []
    },
    {
      path: "history",
      name: 'Historia',
      forbiden: []
    },


  ]



}
