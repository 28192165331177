import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { SharedModule } from "../common/shared.module";
import { LearningComponent } from "./components/learning.component";
import { LearningRoutingModule } from "./learning-routing.modules";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SelsCardComponent } from "./components/sels-card/sels-card.component";
import { BrowserModule } from "@angular/platform-browser";
import { FormsModule }      from '@angular/forms';
import { CalculatorComponent } from "./components/calculator/calculator.component";
import { PresentationComponent } from "./components/presentation/presentation.component";
import { MediaComponent } from "./components/media/media.component";
import { SwiperModule } from 'swiper/angular';
import {
    NgxExtendedPdfViewerModule,
    pdfDefaultOptions,
  } from 'ngx-extended-pdf-viewer';
import { OfferComponent } from "./components/offer/offer.component";
import { TestComponent } from "./components/test/components/test.component";
import { PriceComponent } from "./components/price/components/price.component";
import { TestPageComponent } from "./components/test/containers/test.page.component";
import { PricePageComponent } from "./components/price/containers/price.page.component";

import { PdfViewerModule } from 'ng2-pdf-viewer';
import { EmintoComponent } from "./components/media/components/eminto/eminto.component";
import { FaceComponent } from "./components/media/components/facebook/facebook.component";
import { InviteComponent } from "./components/media/components/invite/invite.component";
import { MailingComponent } from "./components/media/components/mailing/mailing.component";
import { PdfComponent } from "./components/media/components/pdf/pdf.component";
import { VideoComponent } from "./components/media/components/video/video.component";
import { WwwComponent } from "./components/media/components/www/www.component";

import  * as  fromElearning  from "./components/test/reducers"
import { TestEffects } from "./components/test/effects";
export const COMPONENTS = [
  
    LearningComponent,
    SelsCardComponent,
    CalculatorComponent,
    PresentationComponent,
    MediaComponent,
    OfferComponent,
    TestComponent,
    PriceComponent,
    TestPageComponent,
  PricePageComponent,
  EmintoComponent,
  FaceComponent,
  InviteComponent,
  MailingComponent,
  PdfComponent,
  VideoComponent,
  WwwComponent,
 
    
    

];

@NgModule({
    imports: [
        PdfViewerModule,
        NgxExtendedPdfViewerModule,
        BrowserModule,
        CommonModule,
        SharedModule,
        LearningRoutingModule,
        NgbModule,
        FormsModule,
        SwiperModule,
        StoreModule.forFeature(fromElearning.testFeatureKey, fromElearning.reducers),
    EffectsModule.forFeature([TestEffects])
      
     
    ],
    exports: [SelsCardComponent],
    providers: [],
    bootstrap: [SelsCardComponent],
    declarations: COMPONENTS
})
export class LearningModule { }
