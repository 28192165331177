import {  Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Person } from '../../../models/contractor.model';
import * as fromContractors from 'src/app/contractor/reducers'
import { Store } from '@ngrx/store';
import { ContractorPageActions } from '../../../actions';
import { Sort } from '@angular/material/sort';


@Component({
  selector: 'app-list-person',
  styleUrls: ['./list-person.component.scss'],
  template: `
  <app-table-filters
          
            [search]="'Wyszukaj po dowolnych parametrach'"
            (filter)="filterData($event)"
        ></app-table-filters>

  <app-data-table
  [tableData]="dataSet"
  [tableColumns]="columns"
  (sort)="sortData($event)"
></app-data-table>
`
})

export class ListPersonComponent implements OnInit , OnChanges  {

  //persons$ = this.stroeContractor.pipe(select(fromContractors.selectPersons));





  @Input()  role: any 
  @Input()  hide: any
  @Input() data: Person[]
  constructor(
    private store : Store,
    private stroeContractor: Store<fromContractors.State>,
  ) {

}
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data.currentValue && !changes.data.firstChange) {
      this.dataSet = changes.data.currentValue;
    }
    }
  ngOnInit(): void {
     this.stroeContractor.dispatch(ContractorPageActions.getPersons());
    }



columns = [
  { name: 'Kraj', dataKey: 'country', position: 'left', isSortable: false },
  { name: 'Województwo', dataKey: 'state', position: 'left', isSortable: true, type: 'region' },
  { name: 'Miejscowość', dataKey: 'city', position: 'streat', isSortable: true, type: 'string' },
  { name: 'Skrócona nazwa', dataKey: 'distributor', position: 'left', isSortable: true, type: 'string' },
  { name: 'Imię', dataKey: 'name', position: 'left', isSortable: true, type: 'number' },
  { name: 'Nazwisko', dataKey: 'surname', position: 'left', isSortable: true, type: 'surname' },
  { name: 'Rola', dataKey: 'type', position: 'left', isSortable: true, type: 'role' },
  { name: 'Telefon', dataKey: 'phone', position: 'left', isSortable: false, type: 'string' },
  { name: 'Adres email', dataKey: 'email', position: 'left', isSortable: true, type: 'email' },
  { name: 'Akcje', dataKey: 'id', position: 'left', isSortable: false, type: 'edit' },
];




  dataSet: Person[];
  allData: Person[];



  sortData(sortParameters: Sort) {

    let keyName = sortParameters.active;
    if (keyName === "contractors" || keyName === "seller") {
      keyName = "account"
    }
    let d = this.dataSet.filter(item => item[keyName]);
    let empty = this.dataSet.filter(item => !item[keyName]);
    if (sortParameters.direction === 'asc') {

      if (empty) {
        this.dataSet = d.slice().sort((a, b) => {
          return a[keyName].toString().toLowerCase()?.localeCompare(b[keyName].toString().toLowerCase())
        })
        this.dataSet = [...this.dataSet, ...empty]
      } else {
        this.dataSet = this.dataSet.slice().sort((a, b) => {
          return a[keyName].toString().toLowerCase()?.localeCompare(b[keyName].toString().toLowerCase())
        });
      }

    } else if (sortParameters.direction === 'desc') {
      
      if (empty) {
        this.dataSet = d.slice().sort((a, b) => b[keyName].toString()?.localeCompare(a[keyName].toString()));
        this.dataSet = [...empty, ...this.dataSet]
      }
    } else {
      return this.dataSet;
    }
  }


  filterData(search) {
    if (search === 'clear') {
      this.dataSet = this.allData;
    } else {
      let filteredData = [];
      this.dataSet.forEach(val => {
        if (Object.values(val).some(value => {
          if (typeof value === 'object' && value !== null) {
            if (Object.values(value).some(item => item.toString().indexOf(search) !== -1)) {
              filteredData.push(val);
            }
          } else
            if (value) {
              
              return value.toString().indexOf(search) !== -1;
            }
        })) {
          filteredData.push(val);
        }
      });
      this.dataSet = filteredData;
    }
  }
}
