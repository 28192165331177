import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Contractor, Person, ContractorOptions } from '../models';
import * as  fromContractor from "../reducers"
import { ContractorApiActions, ContractorPageActions } from '../actions';
import { ApiService } from '../services';
import { LayoutActions } from "../../core/actions/index";


@Injectable()
export class ContractorEffects {


  getContractors$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContractorPageActions.gsetContractors),
      switchMap(() =>
        this.apiService.getContractor().pipe(
          map((contractors: Contractor[]) => {
            return ContractorApiActions.getContractorSuccess({ contractors });
          }),
          catchError((error: string) =>
            of(ContractorApiActions.getContractorError({ error })),

          )
        )
      )
    )
  );



  getContractorError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContractorApiActions.getContractorError),
      map(({ error }) => {
        this.store.dispatch(LayoutActions.setError({ error: error }));
      })
    ),
    { dispatch: false }
  );





  getProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContractorPageActions.getProfile),
      switchMap(() =>
        this.apiService.getProfile().pipe(
          map((profile: any) => {
            return ContractorApiActions.getProfileSuccess({ profile });
          }),
          catchError((error: string) =>
            of(ContractorApiActions.getProfileError({ error }))

          )
        )
      )
    )
  )




  getContractorsOptions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContractorPageActions.getContractorOptions),
      switchMap(() =>
        this.apiService.getContractorOptions().pipe(
          map((contractors: ContractorOptions[]) => {
            return ContractorApiActions.getContractorOptionsSuccess({ contractors });
          }),
          catchError((error: string) =>
            of(ContractorApiActions.getContractorOptionsError({ error })),

          )
        )
      )
    )
  );










  addContractor$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContractorPageActions.addContractor),
      switchMap(({ contractor }) =>
        this.apiService.addContractor(contractor).pipe(
          map(() => {
            return ContractorApiActions.addContractorSuccess();
          }),
          catchError((error: string) =>
            of(ContractorApiActions.addContractorError({ error })),
          )
        )
      )
    )
  )



 



  addContractorError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContractorApiActions.addContractorError),
      map(({ error }) => {
        this.store.dispatch(LayoutActions.setError({ error: error }));
      })
    ),
    { dispatch: false }
  );


  addContractorSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContractorApiActions.addContractorSuccess),
      map(() => {
        this.router.navigate(["/contractor/list-contractor"]);
      })
    ),
    { dispatch: false }
  );





  updateContractor$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContractorPageActions.upDateContractor),
      switchMap(({ contractor, id }) =>
        this.apiService.updateContractor(contractor, id).pipe(
          map(() => {
            return ContractorApiActions.updateContractorSuccess();
          }),
          catchError((error: string) =>
            of(ContractorApiActions.updateContractorError({ error })),
          )
        )
      )
    )
  );


  updateSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContractorApiActions.updateContractorSuccess),
      map(() => {
 
      /*  this.router.navigate(['contractor']);*/
      })
    ),
    { dispatch: false }
  );










  updateContractorError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContractorApiActions.updateContractorError),
      map(({ error }) => {
        this.store.dispatch(LayoutActions.setError({ error: error }));
      })
    ),
    { dispatch: false }
  );











  updatePersons$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContractorPageActions.upDatePerson),
      switchMap(({ person, id }) =>
        this.apiService.updatePerson(person, id).pipe(
          map(() => {
            return ContractorApiActions.updatePersonSuccess();
          }),
          catchError((error: string) =>
            of(ContractorApiActions.updateContractorError({ error })),
          )
        )
      )
    )
  );

  updatePersonError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContractorApiActions.updateContractorError),
      map(({ error }) => {
        this.store.dispatch(LayoutActions.setError({ error: error }));
      })
    ),
    { dispatch: false }
  );


  updatePersonSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContractorApiActions.updatePersonSuccess),
      map(() => {
        this.router.navigate(['contractor/list-preson']);
      })
    ),
    { dispatch: false }
  );











  addPerson$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContractorPageActions.addPerson),
      switchMap(({ person }) =>
        this.apiService.addPerson(person).pipe(
          map(() => {
            return ContractorApiActions.addPersonSuccess();
          }),
          catchError((error: string) => {
            return of(ContractorApiActions.addPersonError({ error }));
          })
        )
      )
    )
  );



  addPersonError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContractorApiActions.addPersonError),
      map(({ error }) => {
        this.store.dispatch(LayoutActions.setError({ error: error }));
      })
    ),
    { dispatch: false }
  );

  addPersonSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContractorApiActions.addPersonSuccess),
      map(() => {
      
        this.router.navigate(['contractor/list-preson']);
      })
    ),
    { dispatch: false }
  );





  getPersons$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContractorPageActions.getPersons),
      switchMap(() =>
        this.apiService.getPersons().pipe(
          map((persons: Person[]) => {
            return ContractorApiActions.getPersonsSuccess({ persons });
          }),
          catchError((error: string) =>
            of(ContractorApiActions.getPersonsError({ error })),

          )
        )
      )
    )
  );


  getSinglePersons$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContractorPageActions.getSinglePerson),
      switchMap(({id}) =>
        this.apiService.getSinglePerson(id).pipe(
          map((person: Person) => {
            return ContractorApiActions.getSinglePersonSuccess({ person });
          }),
          catchError((error: string) =>
            of(ContractorApiActions.getSinglePersonError({ error })),

          )
        )
      )
    )
  );











  getPersonError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContractorApiActions.getPersonsError),
      map(({ error }) => {
        this.store.dispatch(LayoutActions.setError({ error: error }));
      })
    ),
    { dispatch: false }
  );








  getPersonsByContractorEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContractorPageActions.getPersonsByContractors),
      switchMap(({ contractorId } ) => {

          return this.apiService.getPersons(contractorId).pipe(
            map((persons: Person[]) => {
              return ContractorApiActions.getPersonsSuccess({ persons });
            }),
            catchError((error: string) => {
                return   of(ContractorApiActions.getPersonsError({ error }))
              },
            )
          );
        }
      )
    )
  );
  //getPersonsError$ = createEffect(() =>
  //  this.actions$.pipe(
  //    ofType(ContractorApiActions.getPersonsError),
  //    map(({ error }) => {
  //      this.store.dispatch(LayoutActions.setError({ error: error }));
  //    })
  //  ),
  //  { dispatch: false }
  //);



  getPersonsByContractorEffectSingle$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContractorPageActions.getPersonsByContractor),
      switchMap(() => {

          return this.apiService.getPersons().pipe(
            map((persons: Person[]) => {
              return ContractorApiActions.getPersonsSuccess({ persons });
            }),
            catchError((error: string) =>
              of(ContractorApiActions.getPersonsError({ error })),

            )
          )
        }
      )
    )
  )













  constructor(
    private store: Store<fromContractor.State>,
    private actions$: Actions,
    private apiService: ApiService,
    private router: Router
  ) { }
}
