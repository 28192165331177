
import { Component, ElementRef,  Input,  OnChanges,  OnInit,  QueryList, Renderer2, SimpleChanges, ViewChild, ViewChildren } from "@angular/core";
import * as fromTest from "../reducers";
import 'swiper/css';
import { Store, select } from '@ngrx/store';
import { SwiperOptions } from 'swiper';
import { SwiperComponent } from "swiper/angular";
import Swiper, { Pagination } from 'swiper';
import { TestPageActions } from '../actions';
import { IQuestion, IstatusQuiz } from "../models/test.models";
import { IResults } from "../../../../auth/models/auth.model";
import { Router } from '@angular/router';

Swiper.use([Pagination]);

@Component({
    selector: 'app-test',
    styleUrls: ['./test.component.scss'],
    templateUrl: './test.component.html'
})
export class TestComponent implements OnInit, OnChanges  {

  @Input() quiz: IQuestion[];
  @Input() quiz2: IQuestion[];
  @Input() quiz3: IQuestion[];
 


  @Input() s: IstatusQuiz[];


  qr;
  level2:boolean =  false;
  quizPercent: number;


  constructor(private store: Store<fromTest.State>,
    private ElByClassName: ElementRef,
        private router : Router,
         public elementRef: ElementRef, 
        private renderer: Renderer2) { }



  ngOnChanges(changes: SimpleChanges): void {
      if (changes.s.currentValue) {
        this.qr = changes.s.currentValue;
    
      }
    }


  goBack() {
    this.selectedLevel = -1;
    this.store.dispatch(TestPageActions.getQuizStatus());
    this.store.dispatch(TestPageActions.getQuiz());
    this.store.dispatch(TestPageActions.getQuiz2());
    this.store.dispatch(TestPageActions.getQuiz3());
    this.a = {
      level: 0,
      answers: []
    };
  }

 
  selectedLevel = -1;


    @ViewChild('swiper', { static: false }) swiper?: SwiperComponent;
  @ViewChildren('responseBox') responsesBox: QueryList<ElementRef>;

    nextSlide = false;
    percentages = 0;
    questionsData: IQuestion[];
    config: SwiperOptions = {
        spaceBetween: 50,
        allowSlideNext : true,
        allowTouchMove: false,
      
  };


  t = [];
  a: IResults = {
    level: 0,
    answers: []
  };
    googAnswers = 0;


 


    onSwiper(swiper) {
      
    }

    labelAddClass(e) {
      e.preventDefault();
      e.stopPropagation();
        let box = this.renderer.parentNode(e.currentTarget);
        let a = this.renderer.parentNode(box);
        let slide = this.renderer.parentNode(a);
        let questionNr = slide.querySelector('.q-box').getAttribute('id')
        let all = [...a.querySelectorAll('div')];
     
        all.forEach(val => {
          val.classList.add('not-active');

          val.classList.remove('active');
        })

   
        let allInputs = a.querySelectorAll('input');

        const hasClass = box.classList.contains('active');
        this.nextSlide = true;
        if (!hasClass) {
            allInputs.forEach(input => input.checked = false);
            this.renderer.addClass(box, 'active');
          let selected = box.getAttribute('id');
          this.t.push({
            questionId: this.questionsData[questionNr]._id.toString(),
            answer: parseInt(selected)
          });

          if (selected === this.questionsData[questionNr].answer.toString()) {
       
                this.googAnswers++;
                box.classList.add('good-answer');
            } else {

              box.classList.add('invalid');
                let boxs = slide
                    .querySelector('.responses-box')
                    .querySelectorAll('div');
            boxs.forEach(element => {
                    if (this.questionsData[questionNr].answer == element.getAttribute('id')) {
                      this.renderer.addClass(element, 'valid');
                    }
                });

            }

        }

    }


    next(e) {

   if (this.nextSlide) { 
        this.swiper.swiperRef.slideNext(100);
    }
}

    onSlideChange(e) {
            this.nextSlide = false;
    }
    goToNewTest() {
       this.swiper.swiperRef.slideTo(0)
    }


  selectQuizLevel(level) {
  
    this.t = [];
      this.googAnswers = 0;
    this.selectedLevel = level;
    
        if (level === 0) {
          this.questionsData = this.quiz;
      }
        if (level === 1) {
          this.questionsData = this.quiz2;
      }
        if (level === 2) {
          this.questionsData = this.quiz3;
        }
    this.a['level'] = level + 1;
    this.a['answers'] = [];
    
    }




  sendResultsTest() {
 
    
    this.a['answers'] = JSON.stringify(this.t);
 
    this.store.dispatch(TestPageActions.sendQuizResults({ quizResults: this.a }));
    this.swiper.swiperRef.slideNext(100);
  }



  testAgain() {
    this.googAnswers = 0;
   this.t = []
   this.a  = {
      level: 0,
      answers: []
    };
   
   
   
    this.swiper.swiperRef.slideTo(0);
    this.responsesBox.forEach(box => {
    let boxs = box.nativeElement.querySelectorAll('div')
     boxs.forEach(val => {
 
        this.renderer.removeClass(val, 'not-active')
        this.renderer.removeClass(val, 'invalid')
        this.renderer.removeClass(val, 'valid')
        this.renderer.removeClass(val, 'active')
        this.renderer.removeClass(val, 'good-answer')
      })
     
      
    })
 
    
  }

  ngOnInit(): void {

    this.store.dispatch(TestPageActions.getQuiz());
    this.store.dispatch(TestPageActions.getQuiz2());
    this.store.dispatch(TestPageActions.getQuiz3());
    this.store.dispatch(TestPageActions.getQuizStatus());
         


    }
}
