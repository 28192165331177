import { createReducer, on } from "@ngrx/store";
import { OrdersApiActions } from "../actions";
import { IOrders } from "../models/orders.model";


export const ordersPageFeatureKey = "ordersPage";

export interface State {
  error: string | null;
  orders: IOrders[];
  complete: IOrders[] | null;

}

export const initialState: State = {
  error: null,
  orders: [],
  complete:  null
  };

export const reducer = createReducer(
  initialState,
  on(
    OrdersApiActions.getOrdersSuccess,
    (state, action) => ({ ...state, orders: action.orders, loading : false })
  ),
  on(
    OrdersApiActions.getCompleteOrdersSuccess,
    (state, action) => ({ ...state, complete: action.orders, loading: false })
  ),





);


export const selectError = (state: State) => state.error;
export const selectOrders = (state: State) => state.orders;
export const selectComplete = (state: State) => state.complete;
