
import { Component, Input, OnInit } from "@angular/core";
import { Store, select } from '@ngrx/store';
import { ProductPageActions } from "../actions";
import * as fromDentist from '../../dentist/reducers'
import * as fromContractors from '../../contractor/reducers'
import { DentistsPageActions } from "../../dentist/actions";
import { ContractorPageActions } from "../../contractor/actions";
import { AppDesktop } from "../models";
@Component({
  selector: 'app-add-dentist-page',
  template: `<app-add-products
[dentist]="dataDentist$ | async"
[contractors]="contractors$ | async"
(submitProduct)="submit($event)"
(changeContractor)="changeContractorId($event)"
[persons]="persons$ | async"
></app-add-products>`
})




export class AddProductPageComponent implements OnInit {

  contractors$ = this.store.pipe(select(fromContractors.selectContractor));
  persons$ = this.store.pipe(select(fromContractors.selectPersons));
  dataDentist$ = this.store.pipe(select(fromDentist.selectDentistBySeller));






  constructor(
    private store: Store,) {
  }

  submit(form) {
 
    if (form.dentist) {
      let mapAppDesktop: AppDesktop = {
        quantity: form.quantity,
        dentistId: form.dentist
      }

      
    } else {
      this.store.dispatch(ProductPageActions.addProductBox({ emintoBox: form }))
    }
  }
  changeContractorId(event) {
    this.store.dispatch(ContractorPageActions.getPersonsByContractors({ contractorId: event }));
  }


  ngOnInit(): void {
    this.store.dispatch(ContractorPageActions.gsetContractors());
  }
}
