import {Component, EventEmitter, Input, Output, SimpleChanges, OnInit, OnChanges} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ResetPassword} from '../models/reset-password';
import {Utils} from '../../common/utils';

@Component({
    selector: 'app-reset-password-form',
    template: `


    <div class="d-flex text-center justify-content-center w-100 form-container">
      <main class="form-signin align-items-center">
        <form class="w-100" [formGroup]="form" (ngSubmit)="submit()">
          <img class="mb-4" src="assets/img/logo-dark.svg" alt="" height="40" />


          <div class="form-floating">
            <input type="email" class="form-control" id="floatingInput" placeholder="name@example.com" (click)="ngOnInit()" formControlName="email" required email />
            <label for="floatingInput">Adres e-mail</label>
          </div>
          <p class="pt-3 text-center text-success" *ngIf="success">Na twój  adres e-mail został wysłany link  do zmiany hasła</p>
          <p class="pt-3 text-center text-error" *ngIf="error">Link nie został wysłany, spróbuj ponownie</p>
          <button   type="submit" class="w-100 mt-3 btn btn-primary" type="submit">Wygeneruj nowe hasło</button>
          <a [routerLink]="['/login']" class="mt-3 d-block">Powrót do strony logowania</a>
        </form>
      </main>
    </div>




`,
    
})
export class ResetPasswordFormComponent implements OnChanges, OnInit    {
    @Input() success: boolean | null;
    @Input() error: string | null;
    @Output() submitted = new EventEmitter<ResetPassword>();

    public zoomIn: boolean = true;
    
    ngOnChanges(changes: SimpleChanges): void {
        let simpleChange = changes['success'];
        if (simpleChange && !simpleChange.firstChange) {
            this.form.reset();
        }
    }

    form: FormGroup = new FormGroup({
        email: new FormControl('' , [Validators.required ,  Validators.email]  )
    });

    ngOnInit(): void {
        this.error = null;
    }

    submit() {
        if (this.form.valid) {
            // this.form.get('password').enable();
            this.submitted.emit(this.form.value);
        }
        else {
            Utils.validateFormFields(this.form);
        }
        this.zoomIn = false
    }
}
