import { Component, OnInit } from "@angular/core";
import { Store, select } from '@ngrx/store';
import * as fromContractors from 'src/app/contractor/reducers';
import { ContractorPageActions } from "../../actions";

@Component({
  selector: 'app-add-contractor-page',
  template: `<app-add-person

[currentPerson]="person$ | async"
[contractors]="contractors$ | async"
></app-add-person>`
})




export class AddPersonPageComponent implements OnInit {
 
  person$ = this.store.pipe(select(fromContractors.selectCurrentPerson));
  contractors$ = this.store.pipe(select(fromContractors.selectContractor));
  constructor(
    private store: Store) {

  }
  ngOnInit(): void {
    this.store.dispatch(ContractorPageActions.gsetContractors());

  }


}
