import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Store } from "@ngrx/store";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: "app-spinner",
  template: ``


})
export class SpinnerComponent implements OnChanges {

  @Input()
  loader: boolean;
  @Input()
  loaderContractors: boolean;
  @Input()
  spinnerContractors: boolean;


  constructor(
    private spinnerService: NgxSpinnerService,
    private modalService: NgbModal,
    private store: Store
  ) {
  }

  ngOnChanges(changes: SimpleChanges) {

    for (const propName in changes) {
      if (changes.hasOwnProperty(propName) && !changes[propName].firstChange) {
        switch (propName) {
        case "loader":
        {
              if (changes[propName].currentValue) {

            //this.spinnerService.show();
          } else {
            setTimeout(() => {
                //this.spinnerService.hide();
              },
              500);
          }
        }
        case "loaderContractors":
        {
          if (changes[propName].currentValue) {
            //this.spinnerService.show();
          } else {
            setTimeout(() => {
                this.spinnerService.hide();
              },
              500);
          }
        }
        }
      }
    }
  }


}
