import { Contractor, ContractorOptions, Person } from './../models/contractor.model';
import {createAction, props} from '@ngrx/store';

export const getContractorSuccess = createAction(
    '[Home Api] Get Contractor Success',
    props<{contractors: Contractor[]}>()
    );

    export const getContractorError = createAction(
      '[Home Api] Get Contractor Error',
      props<{ error: string }> ()
);



export const getContractorOptionsSuccess = createAction(
  '[Home Api] Get Contractor Options Success',
  props<{ contractors: ContractorOptions[] }>()
);

export const getContractorOptionsError = createAction(
  '[Home Api] Get Contractor Options Error',
  props<{ error: string }>()
);


export const getPersonsSuccess = createAction(
  '[Home Api] Get Persons Success',
  props<{ persons: Person[] }>()
);

export const getPersonsError = createAction(
  '[Home Api] Get   Persons Error',
  props<{ error: string }>()
);



export const updateContractorSuccess = createAction(
  '[Home Api] Update Contractor Success'

);

export const updateContractorError = createAction(
  '[Home Api] Update Contractor Error',
  props<{ error: string }>()
);

export const updatePersonSuccess = createAction(
  '[Home Api] Update Person Success'

);

export const updatePersonError = createAction(
  '[Home Api] Update Person Error',
  props<{ error: string }>()
);





export const addContractorSuccess = createAction(
  '[Home Api] Add Contractor Success'
  
);

export const addContractorError = createAction(
  '[Home Api] Add Contractor Error',
  props<{ error: string }>()
);



export const addPersonSuccess = createAction(
  '[Home Api] Add Person Success'

);

export const addPersonError = createAction(
  '[Home Api] Add Person Error',
  props<{ error: string }>()
);

export const getSinglePersonSuccess = createAction(
  '[Home Api] Get  Single Person Success',
  props<{ person: Person }>()
);

export const getSinglePersonError = createAction(
  '[Home Api] Get Single Person Error',
  props<{ error: string }>()
);






export const getPersonSuccess = createAction(
  '[Home Api] Get Person Success',
  props<{ contractors: Contractor[] }>()
);

export const getPersonError = createAction(
  '[Home Api] Get Person Error',
);



export const getProfileSuccess = createAction(
  '[Home Api] Get Profile Success',
  props<{ profile: any }>()
);

export const getProfileError = createAction(
  '[Home Api] Get Profile Error',
  props<{ error: string }>()
);
