<div class="targets d-flex flex-wrap">
    <div class="target" *ngFor="let target of targets">
        <div class="target-content">
            <div class="circle-wrapper">
                <span class="label fw-bold">{{ target.currentValue }}</span>
                <app-progress-circle
                    class="circle"
                    [percentProgress]="target.percent"
                    [currentTarget]="target.currentValue"
                ></app-progress-circle>
            </div>
            <p class="line target-name fw-bold text-center">{{ target.name }}</p>
            <p class="line target-value text-center">Obecny cel: {{ target.targetValue }} </p>
        </div>
    </div>
</div>
