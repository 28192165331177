import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../auth/services';

import { CreateOfferPageComponent } from "./containers/create-offer-page.component";
import { OfferPageComponent } from "./containers/offer-page.component";



export const routes: Routes = [

  {
    path: 'offer', component: OfferPageComponent, canActivate: [AuthGuard],
    children: [
      {
        path: 'create',
        component: CreateOfferPageComponent, canActivate: [AuthGuard], data: { logged: true, forbidden: ["seller", "distributor", "admin"] },
      },

      ]
  }
]
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OfferRoutingModule { }
