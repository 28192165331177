import { Action, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromRoot from '../../../../reducers';
import * as fromTest from './test.reducer';
import selectQuizStatus = fromTest.selectQuizStatus;

export const testFeatureKey = 'test';

export interface TestState {
    [fromTest.testPageFeatureKey]: fromTest.State;
}

export interface State extends fromRoot.State {
    [testFeatureKey]: TestState;
}

export function reducers(state: TestState | undefined, action: Action) {
    return combineReducers({
        [fromTest.testPageFeatureKey]: fromTest.reducer,
    })(state, action);
}

export const selectTestState = createFeatureSelector<State, TestState>(testFeatureKey);

export const selectTestPageState = createSelector(
    selectTestState,
    (state: TestState) => state.testPage
);

export const selectQuiz1 = createSelector(
    selectTestPageState,
    fromTest.selectQuiz1
);
export const selectQuiz2 = createSelector(
  selectTestPageState,
  fromTest.selectQuiz2
);
export const selectQuiz3 = createSelector(
  selectTestPageState,
  fromTest.selectQuiz3
);
export const selectStatus = createSelector(
  selectTestPageState,
  fromTest.selectQuizStatus
);
export const selectedLevel = createSelector(
  selectTestPageState,
  fromTest.selectedLevel
);
