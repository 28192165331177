import { OnDestroy, Component } from '@angular/core';;
import { select, Store } from '@ngrx/store';
import * as fromAuth from 'src/app/auth/reducers'
import * as fromDentist from 'src/app/dentist/reducers'
import { DentistsPageActions } from '../../actions';


@Component({
 
  template: `<app-single-dentist
  [dentistCurrent]="currentDentist$ | async"
  [role]="role$ | async"
[linkForTest] ="testLink$ | async"
></app-single-dentist>`
})
export class SingleDentistPageComponent implements OnDestroy  {

  constructor(
    private store: Store
  ) {
  }
  currentDentist$ = this.store.pipe(select(fromDentist.selectDentist));
  testLink$ = this.store.pipe(select(fromDentist.selectLink));
  role$ = this.store.pipe(select(fromAuth.selectUserRole));
  currentId:string;

  ngOnDestroy(): void {
    this.store.dispatch(DentistsPageActions.RemoveCurrentDentist());
  }



}
