
<div class="tab-pane" id="pills-player" role="tabpanel" aria-labelledby="pills-player-tab">
  <div class="action-bar p-3 bg-light mb-3">

    <div class="row g-3 w-100 mb-3">
      <div class="col-sm-3">
        <div class="form-floating">
          <select (change)="selectProduct($event.target.value)" class="form-select large" id="specificSizeProduct">
            <option  [selected]="true"   >Wybierz produkt</option>
            <option *ngFor="let product of categories">{{ product }}</option>

          </select>
        </div>
      </div>
    </div>
    <form *ngIf="selectedProduct === 'Eminto Box'" [formGroup]="form" (ngSubmit)="submit(form.value)">
      <div class="row g-3 w-100 mb-3">
        <h5 class="section-title mb-0">eminto box</h5>
        <div class="col-sm-3">
          <div class="form-floating">
            <input formControlName="playerId" type="text" class="form-control" id="number-nip" placeholder="" />
            <label for="number-nip">ID</label>
          </div>
        </div>
        <div class="col-sm-3">
          <div class="form-floating">
            <input formControlName="emintoId" type="text" class="form-control" id="number-nip" placeholder="" />
            <label for="number-nip">Nr. eminto</label>
          </div>
        </div>
        <div class="col-sm-3">
          <div class="form-floating">
            <input formControlName="playerName" type="text" class="form-control" id="number-nip" placeholder="" />
            <label for="number-nip">Nazwa playera</label>
          </div>
        </div>
        <div class="col-sm-3">
          <div class="form-floating">
            <input formControlName="login" type="text" class="form-control" id="number-nip" placeholder="" />
            <label for="number-nip">Login</label>
          </div>
        </div>
      </div>

      <div class="row     align-items-start g-3 w-100">
        <div class="col-sm-3"></div>
        <div class="col-sm-3">
          <div class="form-floating pass_rules">
            <input #password formControlName="password" type="text" class="form-control" id="number-nip" placeholder="Nazwa gabinetu" />
            <label for="number-nip">wygenerowane hasło</label>
            <p class="mb-0">Hasło powinno zawierać:</p>
            <ul>
              <li>- minimum 8 znaków</li>
              <li>- co najmiej 1 wielką litere</li>
              <li>- co najmiej 1 małą litere</li>
              <li>- co najmiej 1  cyfra</li>
              <li>- co najmiej 1  znak specjalny</li>
            </ul>
          </div>
        </div>
        <div class="col-sm-3 d-grid">
          <a (click)="setPassword('box')" class="d-none d-sm-inline-block btn btn-secondary btn-block pt-3">Wygeneruj hasło</a>
        </div>
        <div class="col-sm-3 d-grid">
          <button [disabled]="form.invalid" type="submit" class="d-none d-sm-inline-block btn btn-primary  btn-block">Dodaj produkt</button>
        </div>
      </div>
    </form>
    <form *ngIf="selectedProduct === 'Virtual' " [formGroup]="form2" (ngSubmit)="submit(form2.value)">
      <div class="row g-3 w-100 mb-3">
        <h5 class="section-title mb-0">player virtualny</h5>
        <div class="col-sm-3">
          <div class="form-floating">
            <input type="text" class="form-control" id="number-nip" placeholder="Nazwa gabinetu" disabled />
            <label for="number-nip">ID</label>
          </div>
        </div>
        <div class="col-sm-3">
          <div class="form-floating">
            <input formControlName="emintoId" type="text" class="form-control" id="number-nip" placeholder="" />
            <label for="number-nip">Nr. eminto</label>
          </div>
        </div>
        <div class="col-sm-3">
          <div class="form-floating">
            <input formControlName="playerName" type="text" class="form-control" id="number-nip" placeholder="Nazwa gabinetu" />
            <label for="number-nip">Nazwa playera</label>
          </div>
        </div>
        <div class="col-sm-3">
          <div class="form-floating">
            <input formControlName="login" type="email" class="form-control" id="number-nip" placeholder="Nazwa gabinetu" />
            <label for="number-nip">Login</label>
          </div>
        </div>
      </div>

      <div class="row g-3 w-100">
        <div class="col-sm-3"></div>
        <div class="col-sm-3">
          <div class="form-floating">
            <input #passwordVirtual formControlName="password" type="text" class="form-control" id="number-nip" placeholder="Nazwa gabinetu" />
            <label for="number-nip">wygenerowane hasło</label>
          </div>
        </div>
        <div class="col-sm-3 d-grid">
          <a (click)="setPassword('virtual')" class="d-none d-sm-inline-block btn btn-secondary btn-block pt-3">Wygeneruj hasło</a>
        </div>
        <div class="col-sm-3 d-grid">
          <button [disabled]="form2.invalid"  type="submit" class="d-none d-sm-inline-block btn btn-primary btn-block pt-2">Dodaj produkt</button>
        </div>
      </div>
    </form>
  </div>
  <div class="action-bar p-3 bg-light mb-3">
    <form *ngIf="selectedProduct === 'Aplikacja desktop' " [formGroup]="form3" (ngSubmit)="submit(form3.value)">
      <div class="row g-3 w-100 mb-3">
        <h5 class="section-title mb-0">aplikacja desktop</h5>

        <div class="col-sm-3">
          <div class="form-floating">
            <select formControlName="quantity" class="form-select large" id="specificSizeSelect">
              <option [ngValue]="null" selected>Wybierz ilość</option>
              <option>1</option>
              <option>2</option>
              <option>3</option>
              <option>4</option>
              <option>5</option>
            </select>
          </div>
        </div>
        <div class="col-sm-3">
          <div class="form-floating">
            <select    (change)="selectContractor($event.target.value)" formControlName="contractor" class="form-select large" id="contractor">
              <option  [ngValue]="null" selected>Dystrybutor</option>
              <option *ngFor="let contractor of contractors" [value]="contractor._id">{{ contractor.shortName }}</option>
            </select>
          </div>
        </div>
        <div class="col-sm-3">
          <div class="form-floating">
            <select    (change)="selectDentistBySeller($event.target.value)" formControlName="person" class="form-select large" id="specificSizeSelect">
              <option [ngValue]="null" selected >Opiekun</option>
              <option *ngFor="let person of persons" [value]="person._id">{{ person.name }} {{  person.surname  }}</option>
            </select>
          </div>
        </div>
        <div class="col-sm-3">
          <div class="form-floating">
            <select formControlName="dentist" class="form-select large" id="specificSizeSelect">
              <option  [ngValue]="null" selected >Gabinet</option>
              <option *ngFor="let d of dentist" [value]="d._id">{{ d.shortName }}</option>
            </select>
          </div>
        </div>
      </div>
      <div class="row g-3 w-100 justify-content-end">

     

        <div class="col-sm-3 d-grid">
          <button    [disabled]="form3.invalid" type="submit" class="d-none d-sm-inline-block btn btn-primary btn-block pt-3">Dodaj produkt</button>
        </div>
      </div>
    </form>
  </div>
</div>
