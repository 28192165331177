import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { select, Store } from '@ngrx/store';
import { LeadsPageActions } from "../actions";
import * as fromLeads from 'src/app/leads/reducers'
import { DentistsPageActions } from "../../dentist/actions";
import * as fromDentist from 'src/app/dentist/reducers'
@Component({
 
  template: `<app-lead-add
      (submitData)="postLead($event)"
      [currentLead]="currentLeads$ | async"
      [dataFromGus]="gusData$ | async"
      (submitNip)="getDataFromGus($event)"
></app-lead-add>`
})
export class AddLeadPageComponent implements OnInit {
  currentLeads$ = this.store.pipe(select(fromLeads.selectLead));
  gusData$ = this.store.pipe(select(fromDentist.selectGus));


  constructor(
    private route: ActivatedRoute,
    private store: Store) { }
 
  currentLeadId: string;

  postLead(event) {
    if (!this.currentLeadId) {
      this.store.dispatch(LeadsPageActions.postLead({ lead: event }))
    } else {
      this.store.dispatch(LeadsPageActions.postLead({ lead: event, id: this.currentLeadId }))

    }
    }


  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.currentLeadId = params['id'];
      if (this.currentLeadId) {
        this.store.dispatch(LeadsPageActions.getLeadDetails({ id: this.currentLeadId }));
      } else {
        this.currentLeadId = null;
        this.store.dispatch(LeadsPageActions.removeCurrentLead());
      }}
    );
  }

  getDataFromGus(event:string) {
    this.store.dispatch(DentistsPageActions.GetDataFromGus({ nip: event }));
  }


}
