import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ResetPasswordPageComponent} from './containers/reset-password-page.component';
import { ChangePasswordPageComponent } from "./containers/change-pasword-page.component";


const routes: Routes = [
    {path: 'reset-password', component: ResetPasswordPageComponent},
  { path: 'change-password', component: ChangePasswordPageComponent },
  { path: 'change-password/:id', component: ChangePasswordPageComponent }



];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class ResetPasswordRoutingModule {
}
