import {Injectable} from '@angular/core';
import {Actions, ofType, createEffect} from '@ngrx/effects';
import {of} from 'rxjs';
import {catchError, map, switchMap} from 'rxjs/operators';
import {ResetPasswordPageActions, ResetPasswordApiActions} from '../actions';
import {ResetPasswordService} from '../services/reset-password.service';
import {ResetPassword, ChangePassword } from '../models/reset-password';
import  * as fromLayout from "../../core/actions"
import { Store } from '@ngrx/store';
import { LayoutActions } from '../../core/actions';
import { Router } from '@angular/router';

@Injectable()
export class ResetPasswordEffects {

    resetPassword$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ResetPasswordPageActions.reset),
            map(action => action.resetPassword),
            switchMap((credentials: ResetPassword) => 
                this.resetPasswordService.resetPassword(credentials).pipe(
                    map(() => ResetPasswordApiActions.resetPasswordSuccess()),
                    catchError((error) => of(ResetPasswordApiActions.resetPasswordFailure({error})))
                )
            )
        )
  );


    checkLink$ = createEffect(() =>
      this.actions$.pipe(
        ofType(ResetPasswordPageActions.checkLink),
        map(action => action.token),
        switchMap((credentials : string) =>
          this.resetPasswordService.checkLink(credentials).pipe(
            map(() => ResetPasswordApiActions.checkLinkSuccess()),
            catchError((error) => of(ResetPasswordApiActions.resetPasswordFailure({ error })))
          )
        )
      )
  );



    changePassword$ = createEffect(() =>
      this.actions$.pipe(
        ofType(ResetPasswordPageActions.changePassword),
        map(action => action.data),
        switchMap((credentials: ChangePassword) =>
          this.resetPasswordService.changePassword(credentials).pipe(
            map(() => ResetPasswordApiActions.resetChangePassworsSuccess()),
            catchError((error) => of(ResetPasswordApiActions.resetChangePasswordFailure({ error })))
          )
        )
      )
  );


    changePasswordFromApp$ = createEffect(() =>
      this.actions$.pipe(
        ofType(ResetPasswordPageActions.changePasswordFromApp),
        map(action => action.data),
        switchMap((credentials: ChangePassword) =>
          this.resetPasswordService.changePasswordFromApp(credentials).pipe(
            map(() => ResetPasswordApiActions.resetChangePasswordLoggedSuccess()),
            catchError((error) => of(ResetPasswordApiActions.resetChangePasswordFailure({ error })))
          )
        )
      )
    );



    changePasswordError$ = createEffect(() =>
      this.actions$.pipe(
        ofType(ResetPasswordApiActions.resetChangePasswordFailure),
        map(({ error }) => {
           
          this.store.dispatch(LayoutActions.setError({ error: error.error_description }));
        })
      ),
      { dispatch: false }
    );







changePasswordSuccess$ = createEffect(() =>
      this.actions$.pipe(
        ofType(ResetPasswordApiActions.resetChangePassworsSuccess),
        map(() => {
          this.store.dispatch(LayoutActions.setDialog({ dialog: 'twoje hasło zostało zmienione' }));
          this.router.navigate(['/login']);
        })
      ),
      { dispatch: false }
    );



changePasswordLoggedSuccess$ = createEffect(() =>
  this.actions$.pipe(
    ofType(ResetPasswordApiActions.resetChangePasswordLoggedSuccess),
    map(() => {
      this.store.dispatch(LayoutActions.setDialog({ dialog: 'twoje hasło zostało zmienione' }));
      this.router.navigate(['/contractor/list-contractor']);
    })
  ),
  { dispatch: false }
);






  constructor(
        private router: Router,
        private store : Store,
        private actions$: Actions,
        private resetPasswordService: ResetPasswordService,
    ) {}
}
