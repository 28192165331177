
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ISingleLeadDetails, ISingleLead, IStepsUpdateData } from '../models';





@Injectable({
  providedIn: 'root'
})
export abstract class LeadsApiService   {



  abstract getLeads(): Observable<ISingleLead[]>;
  abstract postLeads(lead: ISingleLeadDetails, id?: string): Observable<any>;
  abstract getLeadDetails(id: string): Observable<ISingleLeadDetails>;
  abstract AssignLead(id: string, personId:string): Observable<any>;
  abstract stepsUpdate(data: IStepsUpdateData): Observable<any>;
  abstract changeLeadStatus(id:string, statusId:string ): Observable<any>;
  abstract removeLead(id: string): Observable<boolean>


}





