import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { SharedModule } from "../common/shared.module";
import { TargetsComponent } from "./components/targets/targets.component";
import { TargetsPageComponent } from "./containers/targets-page.component";
import { TargetsRoutingModule } from "./targets-routing.module";

export const COMPONENTS = [
    TargetsPageComponent,
    TargetsComponent
];

@NgModule({
    imports: [
        CommonModule,
     
        TargetsRoutingModule,
        ReactiveFormsModule,

        // StoreModule.forFeature(fromDealers.dealersFeatureKey, fromDealers.reducers),
        // EffectsModule.forFeature([DealersEffects])
    ],
    providers: [],
    declarations: COMPONENTS
})
export class TargetsModule { }

