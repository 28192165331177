import { Injectable } from '@angular/core';

import { HttpClient, HttpParams } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { ResetPassword, ChangePassword } from '../models/reset-password';
import { Observable, throwError } from 'rxjs';
import {apiUrl} from '../../common/api-url';
import { Route, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ResetPasswordApiActions } from "../actions/index";
import { LayoutActions } from "../../core/actions/index";


@Injectable({
    providedIn: 'root',
})
export class ResetPasswordService {
    
  constructor(
    private store: Store,
    private router: Router,
    private http: HttpClient) { }

    resetPassword(email: ResetPassword): Observable<boolean> {
        const params = new HttpParams()
            .set('email', email.email)
            .set('grant_type', 'basic');
      return this.http.post<boolean>(apiUrl + 'accounts/reset-password', params)
            .pipe(
                map((success: boolean) => {
                    return success;
                }),
                catchError((error) => {
                    return throwError(error.error);
                })
            );
  }


    checkLink(data: string): Observable<boolean> {
      const params = new HttpParams()
        .set('token', data)
        .set('grant_type', 'basic');
      return this.http.post<boolean>(apiUrl + 'accounts/reset-password-link', params)
        .pipe(
          map((success: boolean) => {
            if (!success) {
              
              this.store.dispatch(LayoutActions.setError({ error: "Twój Link jest juz nie aktywny" }));
          
              this.router.navigate(['login'])
            }
            return success;
          }),
          catchError((error) => {
            return throwError(error.error);
          })
        );
    }








  changePassword(data: ChangePassword): Observable<boolean> {
      const params = new HttpParams()
        .set('password', data.password)
        .set('confirmPassword', data.confirmPassword)
        .set('token', data.token)
        .set('grant_type', 'basic');
    return this.http.post<boolean>(apiUrl + 'accounts/change-password', params)
        .pipe(
          map((success: boolean) => {
            return success;
          }),
          catchError((error) => {
            return throwError(error.error);
          })
        );
  }


  changePasswordFromApp(data: ChangePassword): Observable<boolean> {
 
    const params = new HttpParams()
      .set('password', data.password)
      .set('confirmPassword', data.confirmPassword)
    .set('oldPassword', data.oldPassword);
    return this.http.post<boolean>(apiUrl + 'accounts/change-password-logged', params)
      .pipe(
        map((success: boolean) => {
          return success;
        }),
        catchError((error) => {
          return throwError(error.error);
        })
      );
  }



}
