import {ResetPasswordApiActions, ResetPasswordPageActions} from '../actions';
import {ResetPassword} from '../models/reset-password';
import {createReducer, on} from '@ngrx/store';

export const resetPasswordPageFeatureKey = 'resetPasswordPage';

export interface State {
    success: boolean | null;
    error: any | null;
}

export const initialState: State = {
    success: null,
    error: null
};


export const reducer = createReducer(
    initialState,
    on(
      
        ResetPasswordApiActions.resetPasswordSuccess,
        state => ({...state, success: true, error: null})),
    on(
        ResetPasswordApiActions.resetPasswordFailure,
      (state, action) => ({ ...state, error: action.error })),
    on(
   ResetPasswordApiActions.checkLinkFailure,
   (state, action) => ({ ...state, error: action.error }))
);

export const selectSuccess = (state: State) => state.success;

export const selectError = (state: State) => state.error;
