
<div class="ps-3 ms-2 container">
    <div class="row align-items-start">
        <div class="col col-12 col-md-7 me-5">

<div class="form-group d-flex justify-content-end py-1">
        <label class="pe-3" >Ilość lekarzy pracujących w gabinecie</label>
        <div _ngcontent-kse-c284="" class="  inputcontainer">
        <input   value="2"   (keyup)="calc($event)"  #numbersOfDoctors type="number">
</div>
</div>
<div class="form-group d-flex justify-content-end py-1">
    <label class="pe-3" >pacjenci tygodniowo na 1 lekarza</label>
    <div _ngcontent-kse-c284="" class="  inputcontainer">
    <input  value="30"  (keyup)="calc($event)" #patiensPerDoctorOnWeek type="text">
</div>
</div>

<div class="form-group d-flex justify-content-end py-1">
    <label class="pe-3" >suma pacjentów tygodniowo</label>
    <div _ngcontent-kse-c284="" class="  inputcontainer">
    <input  value="60" [ngModel]="sumOfPatiens"   type="text">
    </div>
</div>
<div class="form-group d-flex justify-content-end py-1">
    <label   class="pe-3 patients" >suma pacjentów miesięcznie (4 tygodnie)</label>
    <div _ngcontent-kse-c284="" class="  inputcontainer">    
    <input  value="" (keyup)="calc($event)"     #patients  type="text">
</div>
</div>
<div class="form-group d-flex justify-content-end py-1">
    <label class="pe-3" >zaoszczędzony czas “sprzedaży” (w min.) na jednym pacjencie</label>
    <div _ngcontent-kse-c284="" class="  inputcontainer">
    <input    value="2" (keyup)="calc($event)"  #safeTimePerPatiens type="text">
   </div>
</div>
<div class="form-group d-flex justify-content-end py-1">
    <label class="pe-3" >średni czas pacjenta w poczekalni / gabinecie (w min.)</label>
    <div _ngcontent-kse-c284="" class="  inputcontainer">
    

    <input   value="12" (keyup)="calc($event)"     #time   type="number">
</div>
</div>
<div class="form-group d-flex justify-content-end py-1">
    <label class="pe-3" >średni koszt 1 h pracy lekarza (pln)</label>
    <div _ngcontent-kse-c284="" class="  inputcontainer">
    
    <input  value="250"  (keyup)="calc($event)"     #costPerDoctor  type="number">
</div>
</div>
<div class="form-group d-flex justify-content-end py-1">
    <label class="pe-3" >średni koszt dla pacjenta typowej usługi</label>
    <div _ngcontent-kse-c284="" class="  inputcontainer">
    
    <input value="200" (keyup)="calc($event)"    #averageCostPerService  type="number">
</div>
</div>
<div class="form-group d-flex justify-content-end py-1">
  <label class="pe-3 red">niewykorzystany czas na edukację i sprzedaż usług (miesięcznie)</label>
    <div _ngcontent-kse-c284="" class="  inputcontainer">
   
    <input [ngModel]="lostTimeValue | inputFormat: 'h'"     type="text">
</div>
</div>
<div class="form-group d-flex justify-content-end py-1">
    <label class="pe-3 red" >zaoszczędzony czas pracy lekarzy ( miesięcznie )</label>
    <div _ngcontent-kse-c284="" class="  inputcontainer">
    
    <input   #sefeTimeDoctors  [ngModel]="sefeTimeDoctorsMont | inputFormat: 'h'" type="text">
</div>
</div>
<div class="form-group d-flex justify-content-end py-1">
    <label class="pe-3 red" >extra zysk pracy lekarza (zaoszczędzony czas miesięcznie)</label>
    <div _ngcontent-kse-c284="" class="  inputcontainer">
    
    <input   [ngModel]="extraProfit | inputFormat: 'PLN'" type="text">
</div>
</div>
<div class="form-group d-flex justify-content-end py-1">
  <label class="pe-3"><!--Wskaźnik konwersji nowych usług na pacjencie (w internecie min. 1,5%)--></label>
  <label class="pe-3">procent wszystkich pacjentów, którzy zdecydowali się na nowe usługi (w internecie min. 1,5%)</label>
  <div _ngcontent-kse-c284="" class="  inputcontainer">

    <input (keyup)="calc($event, conversion)" #conversion type="text">
  </div>
</div>
<div class="form-group d-flex justify-content-end py-1">
    <label class="pe-3 red" >ilość nowych usług miesięcznie</label>
    <div _ngcontent-kse-c284="" class="  inputcontainer">
    
    <input   [ngModel]="newServicesPerMont | inputFormat: ''"   type="text">
</div>
</div>
<div class="form-group d-flex justify-content-end py-1">
    <label class="pe-3 red" >Extra zysk na nowych usługach</label>
    <div _ngcontent-kse-c284="" class="  inputcontainer">
 
    <input   [ngModel]="extra | inputFormat: 'PLN'"   type="text">
 </div>

</div>



        </div>
       

        <div class="col-12 col-md-4 order-1 order-md-2">
          
            <div class="calculator mb-4">
				<p>Suma całkowitego zysku miesięcznego</p>
				<input   style="background:#2ee8ff;" [ngModel]="finalProfits | inputFormat: 'PLN'" class="w-100 profits" type="text" value="" />
			</div>
			<div class="calculator mb-4">
				<p>Suma zysku na nowych usługach w miesiącu</p>
				<input  style="background:#fe6f5f;"[ngModel]="finalProfits2 | inputFormat: 'PLN'" class="w-100 profits tw" type="text" value="" />
			</div>
        </div>
    </div>
</div>

