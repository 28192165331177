import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { SharedModule } from "../common/shared.module";
import { AddPersonComponent } from "./components/add- person/add-person.component";
import { AddContractorComponent } from "./components/add-contractor/add-contractor.component";
import { ListContractorComponent } from "./components/list-contractor/components/list-contractor.component";
import { SingleContractorComponent } from "./components/list-contractor/components/single-contractor/single-contractor.component";
import { ContractorListPageComponent } from "./components/list-contractor/list-contractor.page.component";

import * as fromContractor   from  "./reducers"
import { ContractorPageComponent } from "./containers/contractor-page.component";
import { ContractorRoutingModule } from "./contractor-routing.module";
import { ApiService, FakeService } from "./services";
import { ContractorEffects }  from "./effects"
import { ContaractorService } from "./services/contractor.service";
import { AddContractorPageComponent } from "./containers/add-contractor/add-contractor.page.component";
import { ListPersonComponent } from "./components/list-person/component/list-person.components";
import { PersonsListPageComponent } from "./components/list-person/list-persons.page.component";
import { AddPersonPageComponent } from "./containers/add-person/add-person.page.contractor";
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { StatusPageComponent } from "./containers/status-page.component";
import { StatusComponent } from "./components/status/status.component";
export const COMPONENTS = [
    AddContractorComponent,
    AddPersonComponent,
    ContractorPageComponent,
    ListContractorComponent,
    ListPersonComponent,
    ContractorListPageComponent,
  SingleContractorComponent,
  AddContractorPageComponent,
  PersonsListPageComponent,
  AddPersonPageComponent,
  StatusPageComponent,
  StatusComponent,

 

]

const maskConfig: Partial<IConfig> = {
  dropSpecialCharacters: false,
};
@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        ContractorRoutingModule,
        ReactiveFormsModule,
        FormsModule, NgxMaskModule.forRoot(),
    StoreModule.forFeature(fromContractor.contractorFeatureKey, fromContractor.reducers),
    EffectsModule.forFeature([ContractorEffects])
    ],
    providers: [
        //  { provide: ApiService, useClass: FakeService },
      { provide: ApiService, useClass: ContaractorService }
    ],
    declarations: COMPONENTS
})
export class ContractorModule { }

