
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AddPersonComponent } from './components/add- person/add-person.component';
import { AddContractorComponent } from './components/add-contractor/add-contractor.component';
import { ContractorPageComponent } from './containers/contractor-page.component';

import { ContractorListPageComponent } from './components/list-contractor/list-contractor.page.component';
import { AddContractorPageComponent } from './containers/add-contractor/add-contractor.page.component';
import { PersonsListPageComponent } from './components/list-person/list-persons.page.component';
import { AddPersonPageComponent } from './containers/add-person/add-person.page.contractor';
import { AuthGuard } from "../auth/services/auth-guard.service";

export const routes: Routes = [
 
  {
    path: 'contractor', component: ContractorPageComponent, canActivate: [AuthGuard],
    children: [
      {
        path: 'add-contractor',
        component: AddContractorComponent, canActivate: [AuthGuard], data: { logged: true, forbidden: ["seller", "distributor", "admin"] }, 
      },
      {
        path: 'add-contractor/:id',
        component: AddContractorPageComponent, canActivate: [AuthGuard]
      },
      {
        path: 'add-preson',
        component: AddPersonPageComponent, canActivate: [AuthGuard]
      },
      {
        path: 'add-preson/:id',
        component: AddPersonPageComponent, canActivate: [AuthGuard]
      },
      {
        path: 'list-preson',
        component: PersonsListPageComponent, canActivate: [AuthGuard]
      },
      {
        path: 'list-contractor',
        component: ContractorListPageComponent, canActivate: [AuthGuard]
      },
      {
        path: 'list-contractor/:name',
        component: ContractorListPageComponent, canActivate: [AuthGuard]
      }
    ]
  },
]
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ContractorRoutingModule { }
