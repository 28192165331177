import { Component, Output, EventEmitter, Input, OnChanges, SimpleChanges, OnDestroy } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { regions } from "../../models"
import { DentistDetails } from "../../models/dentist.model";
import { ISingleLeadDetails } from "../../../leads/models";
import { Contractor } from "src/app/contractor/models";


@Component({
  selector: "app-add-dentist",
  styleUrls: ["./add-dentist.component.scss"],
  templateUrl: "./add-dentist.component.html",
})
export class AddDentistComponent implements OnChanges, OnDestroy {

  @Output()
  submitData = new EventEmitter();
  @Output()
  submitNip = new EventEmitter();
  @Input()
  currentDentis: DentistDetails;
  @Input()
  status: string;
  @Input()
  currentLead: ISingleLeadDetails;
  @Input()
  distributors: Contractor[];
  @Input() dataFromGus: any;
  constructor(


  ) {

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.currentLead && !changes.currentLead.firstChange) {
      if (!changes.currentLead.currentValue) {
        this.form.reset();
      }
    }


    if (changes.currentDentis && !changes.currentDentis.firstChange) {
      if (!changes.currentDentis.currentValue) {
        this.form.reset();
      }
    }

    if (changes.currentLead && changes.currentLead?.currentValue) {
      this.form.patchValue(changes.currentLead.currentValue);
      if (changes.currentLead.currentValue.contactPerson) {
        this.form.get("addContactPerson").setValue(true);
        this.form.get("contactName").setValidators([Validators.required, Validators.minLength(2)]);
        this.form.get("contactSurname").setValidators([Validators.required, Validators.minLength(2)]);
        this.form.get("contactPhone").setValidators([Validators.required, Validators.minLength(9)]);
        this.form.get("contactEmail").setValidators([Validators.required, Validators.email, Validators.minLength(2)]);
        this.form.get("contactName").updateValueAndValidity();
        this.form.get("contactSurname").updateValueAndValidity();
        this.form.get("contactPhone").updateValueAndValidity();
        this.form.get("contactEmail").updateValueAndValidity();
        this.showContactData = true;
        this.form.get("contactName").setValue(changes.currentLead.currentValue.contactPerson.name);
        this.form.get("contactSurname").setValue(changes.currentLead.currentValue.contactPerson.surname);
        this.form.get("contactPhone").setValue(changes.currentLead.currentValue.contactPerson.phone);
        this.form.get("contactEmail").setValue(changes.currentLead.currentValue.contactPerson.email);
      }

    }

    if (changes.currentDentis?.currentValue && !changes.currentDentis.firstChange) {
      this.form.patchValue(changes.currentDentis.currentValue[0]);
      this.form.get('customDistributor').setValue(changes.currentDentis.currentValue[0].customDistributor ?  changes.currentDentis.currentValue[0].customDistributor : null)
      this.form.get("ownerName").setValue(changes.currentDentis.currentValue[0].owner.name);
      this.form.get("ownerSurname").setValue(changes.currentDentis.currentValue[0].owner.surname);
      this.form.get("ownerPhone").setValue(changes.currentDentis.currentValue[0].owner.phone);
      this.form.get("ownerEmail").setValue(changes.currentDentis.currentValue[0].owner.email);
      this.form.get('region').setValue(changes.currentDentis.currentValue[0].state);
      this.form.get('id').setValue(changes.currentDentis.currentValue[0]._id);
      if (changes.currentDentis.currentValue[0].contactPerson) {
        this.form.get("addContactPerson").setValue(true);
        this.form.get("contactName").setValidators([Validators.required, Validators.minLength(2)]);
        this.form.get("contactSurname").setValidators([Validators.required, Validators.minLength(2)]);
        this.form.get("contactPhone").setValidators([Validators.required, Validators.minLength(9)]);
        this.form.get("contactEmail").setValidators([Validators.required, Validators.email, Validators.minLength(2)]);
        this.form.get("contactName").updateValueAndValidity();
        this.form.get("contactSurname").updateValueAndValidity();
        this.form.get("contactPhone").updateValueAndValidity();
        this.form.get("contactEmail").updateValueAndValidity();
        this.showContactData = true;
        this.form.get("contactName").setValue(changes.currentDentis.currentValue[0].contactPerson.name);
        this.form.get("contactSurname").setValue(changes.currentDentis.currentValue[0].contactPerson.surname);
        this.form.get("contactPhone").setValue(changes.currentDentis.currentValue[0].contactPerson.phone);
        this.form.get("contactEmail").setValue(changes.currentDentis.currentValue[0].contactPerson.email);
      }
      if (changes.currentDentis.currentValue[0].customDistributor) {
        this.form.get('customDidtrbutorInput').setValue(true);
        this.addCustomDistributor = true;
      }



    }
    if (changes.dataFromGus && changes.dataFromGus.currentValue && !changes.dataFromGus.firstChange && !changes.dataFromGus.currentValue.dataZakonczeniaDzialalnosci) {


      this.form.get("name").setValue(changes.dataFromGus.currentValue.nazwa);
      let str: string = `${changes.dataFromGus.currentValue.ulica} ${changes.dataFromGus.currentValue.nrNieruchomosci}${changes.dataFromGus.currentValue.nrLokalu !== null ? "/" + changes.dataFromGus.currentValue.nrLokalu : ""}`;
      str = str.substring(3, str.length);

      this.form.get("city").setValue(changes.dataFromGus.currentValue.miejscowosc);
      this.form.get("street").setValue(str);
      this.form.get("postalCode").setValue(changes.dataFromGus.currentValue.kodPocztowy);
      this.form.get("country").setValue("Polska");
      this.form.get("region").setValue(changes.dataFromGus.currentValue.wojewodztwo.toLowerCase());
    }

  }
 
  showContactData = false;
  currentId;
  addCustomDistributor: boolean = false;
  regions = Object.values(regions);
  nip: string;


  onSubmit(form) {
    if (!this.addCustomDistributor) {
      this.form.get('customDistributor').setValue(null)
    }
    if (this.status) {
      // this.form.get('status').setValue('62387d8a261b6916dd11dd58')
    }

    this.submitData.emit(form);
  }
  addCustomDistributorToDentist() {
    this.addCustomDistributor = !this.addCustomDistributor
    if (this.addCustomDistributor) {
      this.form.get('customDistributor').setValidators([Validators.required, Validators.minLength(5)]);
      this.form.get("customDistributor").updateValueAndValidity();
    } else {
      this.form.get('customDistributor').setValue(null)
      this.form.get('customDistributor').clearValidators()
      this.form.get("customDistributor").updateValueAndValidity();
    }

  }
  addContactPersonData(e) {

    this.showContactData = e;

    if (e) {

      this.form.get("contactName").setValidators([Validators.required, Validators.minLength(2)]);
      this.form.get("contactSurname").setValidators([Validators.required, Validators.minLength(2)]);
      this.form.get("contactPhone").setValidators([Validators.required, Validators.minLength(9)]);
      this.form.get("contactEmail").setValidators([Validators.required, Validators.email, Validators.minLength(2)]);
      this.form.get("contactName").updateValueAndValidity();
      this.form.get("contactSurname").updateValueAndValidity();
      this.form.get("contactPhone").updateValueAndValidity();
      this.form.get("contactEmail").updateValueAndValidity();

    } else {
      this.form.get("contactName").clearValidators();
      this.form.get("contactName").setValue("");
      this.form.get("contactSurname").clearValidators();
      this.form.get("contactPhone").clearValidators();
      this.form.get("contactEmail").clearValidators();
      this.form.get("contactName").updateValueAndValidity();
      this.form.get("contactSurname").updateValueAndValidity();
      this.form.get("contactPhone").updateValueAndValidity();
      this.form.get("contactEmail").updateValueAndValidity();
    }


  }

  form = new FormGroup({
    name: new FormControl("", [Validators.minLength(3), Validators.required]),
    shortName: new FormControl("", [Validators.minLength(3), Validators.required]),
    city: new FormControl("", [Validators.minLength(2), Validators.required]),
    street: new FormControl("", [Validators.minLength(3), Validators.required]),
    postalCode: new FormControl("", [Validators.minLength(5), Validators.required]),
    nip: new FormControl("", [Validators.minLength(10), Validators.maxLength(15), Validators.required]),
    customDidtrbutorInput: new FormControl(false),
    website: new FormControl(""),
    region: new FormControl("", [Validators.minLength(3), Validators.maxLength(20)]),
    customDistributor: new FormControl(null),
    country: new FormControl("", [Validators.minLength(3), Validators.required]),
    ownerName: new FormControl("", [Validators.minLength(3), Validators.required]),
    ownerSurname: new FormControl("", [Validators.minLength(3), Validators.required]),
    ownerPhone: new FormControl("", [Validators.minLength(9), Validators.maxLength(9), Validators.required]),
    ownerEmail: new FormControl("", [Validators.email, Validators.required]),
    contactName: new FormControl("", []),
    contactSurname: new FormControl("", []),
    contactPhone: new FormControl("", []),
    contactEmail: new FormControl("", []),
    invoiceEmail: new FormControl("", []),
    // status: new FormControl(''),

    note: new FormControl("", []),
    addContactPerson: new FormControl(false, [Validators.required]),
    id: new FormControl(""),
  });
  get fullName() {
    return this.form.get('nip');

  }
  focuseOut(e) {
    this.form.get("invoiceEmail").setValue(e);
  }

  ngOnDestroy(): void {
    this.form.reset();

  }

  searchInGus() {
    this.nip = this.form.value["nip"].toString();
    this.submitNip.emit(this.nip);

  }

}
