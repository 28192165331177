import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { delay } from 'rxjs/operators';
import { ISingleLeadDetails, ISingleLead, IStepsUpdateData } from '../models';
import { LeadsApiService } from "./api.services";





@Injectable()
export  class LeadsFakeService implements LeadsApiService {
 randomDate(start, end) {
  return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
}

  getLeads(): Observable<ISingleLead[]> {
    let leads: ISingleLead[] = []
    let arr = Array.from(Array(30).keys())
    arr.forEach((val, i) => {
   
      let singleLead: ISingleLead = {
        date: i !== 0 ? `2022-05-${i} 10:58` : `2022-05-${i + 1} 10:58`,
        DentisName: `nazwa gabinetu${i}`,
        name: 'potr Kontakt',
        email: 'lead@kontakt@gnail.com',
        phone: '565743343',
        accountLead: i % 2 === 0 ? 'Tymoteusz' : 'Majeranek',
        campaign: i % 2 === 0 ? 'facebook' : 'mailing',
        isDentist: i % 2 === 0 ? true : false,
        _id: '123212313',
         steps:   i%2 === 0 ? [
           { stepNumber: 1, currentStep: 'Nowy', date: `2022-10-${30- i}`, active: true }
         ] : [
           //check step 3 
          //   { stepNumber: 1, currentStep: 'Zweryfikowano', date: '2022-09-25', active: false },
          //   { stepNumber: 2, currentStep: 'Wysłano', date: `2022-11-06`, active: false },
          //   { stepNumber: 3, currentStep: 'podłączyć box', date: `2022-11-08`, active: true }
           //check step 4 
              { stepNumber: 1, currentStep: 'Zweryfikowano', date: '2022-09-25', active: false },
              { stepNumber: 2, currentStep: 'Wysłano', date: `2022-11-06`, active: false },
              { stepNumber: 3, currentStep: 'podłączono', date: `2022-11-08`, active: false },
              { stepNumber: 4, currentStep: 'szkolenie-1', date: `2022-11-08`, active: true }




         ],
      }

      leads.push(singleLead)
    })

    return of(leads)
  }
  postLeads(lead: ISingleLeadDetails): Observable<any> {
    return of(false)
  }

  //export interface ISingleStep {
  //stepNumber: number,
  //currentStep: string,
  //date: string,
  //active: boolean

  changeLeadStatus() {
    return of (true)
  }

  stepsUpdate(data: IStepsUpdateData) {
    return of (true)
  }




  getLeadDetails(id: string): Observable<ISingleLeadDetails> {
    let lead: ISingleLeadDetails = {
      nip: '123-123-22-44',
      name: 'jakis name',
      shortName: 'jakis short name',
      city: 'warszawa',
      street: 'jodłowa 23',
      postalCode: '01-323',
      website: 'www.wp.pl',
      region: 'mazowieckie',
      country: 'polska',
      ownerName: 'właściciel name',
      ownerSurname: 'właściciel surname',
      ownerPhone: '123123123',
      ownerEmail: 'wlasciciell@gmail.pl',
      contactPerson: {
        name: 'kontak name',
        surname: 'kontakt surname',
        phone: '884743234',
        email: 'piotr@gmail.com'
      },
      campaign: 'inne',
      note: 'jakas notatka',
      invoiceEmail: 'invoice@gmail.com',
   
      _id: '12312'
    }

    return of (lead)
  }
  removeLead(id): Observable<boolean> {
    return of(true);
  }





  AssignLead(id: string, personId: string): Observable<any> {
    return of(true).pipe(
      delay(3000)
      )
  }

}
