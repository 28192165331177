import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { GusApiData, regions } from "src/app/dentist/models"
import { campaign, ISingleLeadDetails, services } from './../models'
import { Store } from '@ngrx/store';
import { FormControl, FormGroup, Validators } from "@angular/forms";





@Component({
  selector: 'app-lead-add',
  templateUrl: './add-lead.component.html',
})

export class AddLeadComponent implements OnChanges, OnDestroy {
  @Input()
  currentLead: ISingleLeadDetails;
  @Input()
  dataFromGus: GusApiData;
  @Output()
  submitData = new EventEmitter<ISingleLeadDetails>();
  @Output()
  submitNip = new EventEmitter<string>();

  constructor(
    private store: Store,
  ) { }

  showFormErrors: boolean = false;

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.currentLead?.currentValue && !changes.dataFromGus?.currentValue) {
      this.form.reset()
    }
    if (changes.currentLead?.currentValue) {
      this.form.patchValue(changes.currentLead.currentValue);
      if (changes.currentLead.currentValue.addionalService)  {
      this.form.get('additionalService').setValue(true);
      this.showAdditionalService = true;
      this.form.get('service').setValue(changes.currentLead.currentValue.addionalService);
      }

      if (changes.currentLead.currentValue.contactPerson) {
        this.form.get("addContactPerson").setValue(true);
        this.showContactData = true;
        this.form.get("contactName").setValue(changes.currentLead.currentValue.contactPerson.name);
        this.form.get("contactSurname").setValue(changes.currentLead.currentValue.contactPerson.surname);
        this.form.get("contactPhone").setValue(changes.currentLead.currentValue.contactPerson.phone);
        this.form.get("contactEmail").setValue(changes.currentLead.currentValue.contactPerson.email);
      }
    }



    if (changes.dataFromGus?.currentValue && !changes.dataFromGus.firstChange && !changes.dataFromGus.currentValue.dataZakonczeniaDzialalnosci) {
      this.form.get("name").setValue(changes.dataFromGus.currentValue.nazwa);
      let str: string = `${changes.dataFromGus.currentValue.ulica} ${changes.dataFromGus.currentValue.nrNieruchomosci}${changes.dataFromGus.currentValue.nrLokalu !== null ? "/" + changes.dataFromGus.currentValue.nrLokalu : ""}`;
      str = str.substring(3, str.length);
      // this.form.get("nip").setValue(changes.dataFromGus.currentValue.nip);
      this.form.get("city").setValue(changes.dataFromGus.currentValue.miejscowosc);
      this.form.get("street").setValue(str);
      this.form.get("postalCode").setValue(changes.dataFromGus.currentValue.kodPocztowy);
      this.form.get("country").setValue("Polska");
      this.form.get("region").setValue(changes.dataFromGus.currentValue.wojewodztwo.toLowerCase());
    }





  }
  showAdditionalService: boolean = false;
  showContactData: boolean = false;
  regions = Object.values(regions);
  campaign = Object.values(campaign);
  services = Object.values(services)



  form = new FormGroup({
    name: new FormControl(""),
    shortName: new FormControl("", [Validators.required]),
    city: new FormControl(""),
    street: new FormControl(""),
    postalCode: new FormControl(""),
    nip: new FormControl("", [Validators.minLength(10), Validators.maxLength(15)]),
    website: new FormControl(""),
    region: new FormControl(null, [Validators.minLength(3), Validators.maxLength(22)]),
    country: new FormControl("", [Validators.minLength(3)]),
    ownerName: new FormControl("", [Validators.minLength(3)]),
    ownerSurname: new FormControl("", [Validators.minLength(3)]),
    ownerPhone: new FormControl("", [Validators.minLength(9), Validators.maxLength(9)]),
    ownerEmail: new FormControl("", [Validators.email]),
    contactName: new FormControl("", []),
    contactSurname: new FormControl("", []),
    contactPhone: new FormControl("", []),
    contactEmail: new FormControl("", []),
    invoiceEmail: new FormControl("", []),
    campaign: new FormControl(null, [Validators.required]),
    status: new FormControl(null, [Validators.required]),
    note: new FormControl("", []),
    addContactPerson: new FormControl(false), 
    service: new FormControl(null),
    additionalService : new FormControl(false)

  });



  addContactPersonData(e) {
    this.showContactData = e;
  }
  addAdditionalService(e) {
      this.showAdditionalService = e;
      if (!e) {
        this.form.get('service').setValue(null)
      }
  }

  focuseOut(e) {
    this.form.get("invoiceEmail").setValue(e);
  }

  searchInGus() {
    let nip = this.form.value["nip"].toString();
    this.submitNip.emit(nip);

  }


  get fullName() {
    return this.form.get('nip');

  }
  onSubmit(formValue: ISingleLeadDetails) {
    if (this.form.valid) {
      this.showFormErrors = false;
      this.submitData.emit(formValue)
    } else {
      this.showFormErrors = true
    }
  }


  ngOnDestroy(): void {
    this.form.reset()
  }


}
