






<div   class="d-flex flex-wrap flex-xl-nowrap">



  <ul *ngIf="!show"  class="nav  mb-3">
    <li *ngFor="let item of routes" class="nav-item">
      <a [routerLink]="[item.path]" *ngIf="!item.forbiden.includes(role)" [routerLinkActive]="['active']" class=" rounded-btn nav-link btn btn-light-my " aria-current="page">{{ item.name }}</a>
    </li>

  </ul>
  <ul class="nav  mb-3 justify-content-start justify-content-xl-center">
    <li class="nav-item">
      <a (click)="changePass()" *ngIf="show" class="rounded-btn  btn btn-light-my px-5 ms-3">Zmiana hasła</a>
    </li>
    <li class="nav-item">    <a (click)="LogOut()" *ngIf="show" class="rounded-btn     btn btn-primary px-5 ms-3">Wyloguj</a></li>
  </ul>


</div>





