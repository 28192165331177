<div class="container-fluid">
	<div class="row d-flex">

		<div class="">
			<h2 class="d-inline-block title">Cennik</h2>&nbsp;
			<span class="title"><sup>*</sup> wszystkie podane ceny są cenami brutto</span>
		</div>
		<div class="d-flex align-items-center">
			<a class="ms-0 mt-1   rounded-btn btn btn-primary" [href]="'assets/media/PDF/cennik eminto_012022.pdf'" download>Pobierz cennik w pdf</a>
		</div>
	</div>

	<div class="row">
		<div class="col-10">
			<div class="price-table mb-4">
				<table class="w-100">
					<tbody>
						<!-- <tr id="price-info">
							<td></td>
							<td colspan="3"><sup>*</sup> wszystkie podane ceny są cenami brutto</td>
						</tr> -->
						<tr>
							<td class="text-end text-uppercase text-black he"></td>
							<td class="text-end text-uppercase text-black he"></td>
							<td class="text-white text-uppercase bg-gradient-eminto">Abonament<br />miesięczny</td>
							<td class="bg-orange text-white text-uppercase">Abonament<br />roczny</td>
							<td class="bg-black text-white text-uppercase">Opłata<br />jednorazowa</td>
						</tr>
						<tr>
							<td   colspan="2" class="text-end bt br bl bg-darken">1 x eminto Box</td>
							<td class="br bl bg-darken">390 zł</td>
							<td class="br bl bg-darken">4 290zł</td>
							<td class="br bl bg-darken">890 zł</td>
						</tr>
						<tr>
							<td colspan="2" class="text-end br br bl bg-darken">2 x eminto Box</td>
							<td class="br bl bg-darken">630 zł</td>
							<td class="br bl bg-darken">6 900 zł</td>
							<td class="bg-darken br bl">1 390 zł</td>
						</tr>
						<tr>
							<td  colspan="2" class="text-end br bl bb text-nowrap bg-darken">powyżej 2 x eminto Box</td>
							<td class="br bl bb bg-darken">do ustalenia</td>
							<td class="br bl bb bg-darken">do ustalenia</td>
							<td class="bb bg-darken br bl">do ustalenia</td>
						</tr>
						
					</tbody>
				</table>
			</div>
		</div>
	</div>
	<div class="row">
		<div class="col-10">
			<div class="price-table mb-4">
				<table class="w-100">
					<tbody>
						<tr class="d-tr">

							


							<td class="text-end text-uppercase text-black"></td>
							<td class="text-end text-uppercase text-black"></td>

							<td class="text-white text-uppercase ">Abonament<br />miesięczny</td>
							<td class=" text-white text-uppercase">Abonament<br />roczny</td>
							<td class=" text-white text-uppercase">Opłata<br />jednorazowa</td>
						</tr>
						<tr>
							<td  colspan="2" class="text-end bt br bl">1 x eminto Box + 1 x Aplikacja desktop</td>
							<td class="br   bt bl bg-darken">460 zł</td>
							<td class="br bt bl bg-darken">5 060 zł</td>
							<td class="br bt bl bg-darken">890 zł</td>
						</tr>
						<tr>
							<td colspan="2" class="text-end br bl ">1 x eminto Box + 2 x Aplikacja desktop</td>
							<td class="br   bl bg-darken">490 zł</td>
							<td class="br bl bg-darken">5 390 zł</td>
							<td class="bg-darken br bl">890 zł</td>
						</tr>
						<tr>
							<td colspan="2" class="text-end br bl bb text-nowrap bg-darken">* każda następna Aplikacja desktop</td>
							<td class="br bl bb bg-darken">50 zł</td>
							<td class="br bl bb bg-darken">do ustalenia</td>
							<td class="bb bg-darken br bl">890 zł</td>
						</tr>
					
					</tbody>
				</table>
			</div>
		</div>
	</div>
	<div class="row mb-4">
		<div class="col-10">
			<div class="price-table">
				<table class="w-100">
					<tbody>
						<tr class="d-tr">
							<td class="text-end text-uppercase text-black">
								
							</td>
							<td class="text-end text-uppercase text-black">
								
							<td class="text-white text-uppercase ">Abonament<br />miesięczny</td>
							<td class=" text-white text-uppercase">Abonament<br />roczny</td>
							<td class=" text-white text-uppercase">Opłata<br />jednorazowa</td>
						</tr>
						<tr>
							<td colspan="2" class="text-end bt br bl bg-darken">1 x Aplikacja desktop (bez zestawu)</td>
							<td class="br bt bl bg-darken">brak</td>
							<td class="br bt  bl bg-darken">1 870 zł</td>
							<td class="br  bt bl bg-darken">150 zł</td>
						</tr>
						<tr>
							<td colspan="2" class="text-end br bl bg-darken">2 x Aplikacja desktop (bez zestawu)</td>
							<td class="br bl bg-darken">300 zł</td>
							<td class="br bl bg-darken">3 300 zł</td>
							<td class="bg-darken br bl">150 zł</td>
						</tr>
						<tr>
							<td  colspan="2" class="text-end br bl bg-darken">3 x Aplikacja desktop (bez zestawu)</td>
							<td class="br bl bg-darken">360 zł</td>
							<td class="br bl bg-darken">3 960 zł</td>
							<td class="bg-darken br bl">150 zł</td>
						</tr>
						<tr>
							<td colspan="2" class="text-end bb br bl bg-darken">4 lub więcej (cena za 1 szt.)</td>
							<td class="br bl bb bg-darken">100 zł</td>
							<td class="br bl bb bg-darken">1 100 zł</td>
							<td class="bb bg-darken br bl">do ustalenia</td>
						</tr>
					
					</tbody>
				</table>
			</div>
		</div>
	</div>
</div>
<div class="container-fluid p-0">
	<div class="row price-footer py-5">
		<div class="col-5 text-center">
			<h4>ABONAMENT EMINTO OBEJMUJE:</h4>
			<ul class="m-4 list-unstyled ps-5">
				<li>Zestaw Eminto Box (mikro komputer, kabel HDMI, uchwyt mocujący, zasilacz),</li>
				<li>2 licencje dostępowe (główna oraz dla moderatora konta),</li>
				<li>Dostęp do szerokiej bazy mediów,</li>
				<li>Licencja na odtwarzanie publiczne (dla wszystkich mediów),</li>
				<li>Możliwość dodawania własnych filmów i zdjęć,</li>
				<li>Dedykowane miejsce na serwerze 2 GB,</li>
				<li>Szkolenie on-line z funkcjonlaności aplikacji i zarządzania planem emisji,</li>
				<li>Wparcie techniczne na dedykowanej infolinii, czynnej od pn-pt w godzinach 9.00 - 17.00,</li>
				<li class="text-blue">W przypadku abonamentu rocznego, przygotowanie profesjonalnej animacji wizerunkowej gabinetu, na podstawie przesłanych filmów i zdjęć.</li>
			</ul>
		</div>
		<div class="col-5 text-center">
			<h4>OPŁATA JEDNORAZOWA OBEJMUJE:</h4>
			<ul class="m-4 list-unstyled ps-4">
				<li>Wygenerowanie loginów dostępowych i haseł do aplikacji,</li>
				<li>Wygenerewanie i opłata certyfiktów kluczy bezpieczeństwa,</li>
				<li>Przygotowanie, opłata i konfiguracja miejsca 2 GB na serwerze,</li>
				<li>Przygotowanie grafiki z logo gabinetu wraz z implementacją na ekranie monitora w prawym górnym rogu,</li>
				<li>Przygotowanie animacji (splash screen) z logo gabinetu i osadzenie w planie emisji,</li>
				<li>Przygotowanie i wdrożenie planu emisji dopasowanego do indywidualnych potrzeb i specjalizacji gabinetu,</li>
				<li class="text-orange">Opłata jednorazowa, w przypadku Aplikacji Desktop, obejmuje wygenerowanie i instalację klucza licencyjnego na podstawie unikalnego numeru komputera gabinetu.</li>
			</ul>
		</div>
	</div>
</div>
