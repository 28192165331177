import { Component, ElementRef, Input, OnInit, QueryList, SimpleChanges, ViewChild, ViewChildren, OnChanges, AfterViewChecked } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { DentistsPageActions } from "../../actions";
import { Store } from "@ngrx/store";
import { assignedProductToDentis, DentistDetails, productToShow, SelectedStatus } from "../../models/dentist.model";
import { ProductPageActions } from "../../../products/actions";
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";



@Component({
  selector: "app-single-dentist",
  styleUrls: ["./single-dentist.component.scss"],
  templateUrl: "./single-dentist.components.html",
})
export class SingleDentistComponent implements OnInit, OnChanges, AfterViewChecked {

  @Input()
  dentistCurrent: DentistDetails[];
  @Input()
  linkForTest: string;
  @Input()
  role: string;
  @ViewChildren('products') productsInputs: QueryList<ElementRef<HTMLInputElement>>;
  constructor(
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private store: Store
  ) { }

  t: any[];
  products: productToShow[] = [];
  numberOfProductsToAdd: number = 0;
  dentist: DentistDetails[] = [];
  currentId: string;
  isTest = false;
  id: string;
  selectedProducts: productToShow[] = [];
  selectedProduct: string;
  selectedStatusName: SelectedStatus = {};
  isChangePrice: boolean = false;
  isChangeLicens: boolean = false;
  s: string;
  selectedStatu: string | boolean;
  addProduct: boolean = false;
  protocol = false;
  saveStatusView: boolean = false;
  product1: boolean = false;
  isProductSelected: boolean = false;
  currentProduct: assignedProductToDentis;
  isRomove: boolean;
  isFormInvalid: boolean;
  isDesktopAppAllowed: boolean = false;
  isLinkCopied: boolean = false;
  licensLenght: number = 12;
  preview: string | null = null
  file: File;
  logo: boolean = false;
  @ViewChild('confirmBankTransferModal') modal: ElementRef;

  ngOnChanges(changes: SimpleChanges): void {

    if (changes.dentistCurrent.currentValue && !changes.dentistCurrent.firstChange) {

      this.dentist = changes.dentistCurrent.currentValue;
      let isIn = this.selectedProducts.find(val => val._id === this.currentProduct._id)
      this.isDesktopAppAllowed = changes.dentistCurrent.currentValue[0].assigned.some(val => val.login)

      if (!this.dentistCurrent[0].assigned[0]) {
        this.products = [];
      }
      if (this.dentistCurrent[0].assigned[0]) {
        this.isRomove = true;
        let boxProducts: productToShow[] = [];


        this.products = [];
        this.dentistCurrent[0].assigned.forEach(product => {
          let productToShow: productToShow = this.transformProductToShow(product)
          boxProducts.push(productToShow)
        })


        this.products = [...boxProducts];


        this.currentProduct = this.dentistCurrent[0].assigned[0];
        this.s = this.dentistCurrent[0].statusList.find(val => {
          return val.name === this.dentistCurrent[0].assigned[0].status
        })._id;
        this.isProductSelected = true;


      } else {
        this.s = this.dentistCurrent[0].statusList.find(val => val.name === 'testuje')._id;
      }
    }
  }



  transformProductToShow(product: assignedProductToDentis): productToShow {
    let productToShow: productToShow;
    if (product.emintoId) {
      productToShow = {
        name: product.emintoId,
        status: product.status ? product.status : undefined,
        _id: product._id
      }
    }
    if (!product.emintoId && !product.type) {
      productToShow = {
        name: product.login,
        status: product.status ? product.status : undefined,
        _id: product._id
      }
    }
    if (product.type) {
      productToShow = {
        name: product.type,
        status: product.status ? product.status : undefined,
        _id: product._id
      }
    }
    return productToShow;
  }




  copy(e) {
    this.isLinkCopied = true;
    navigator.clipboard.writeText(e.textContent);
  }
  onFileSelected(event) {
    const selectedFiles = event.target.files;
    const reader = new FileReader();

    if (selectedFiles) {
      const file: File | null = event.target.files[0];
      this.file = file;
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.preview = e.target.result;


      };
      reader.readAsDataURL(file);
    }
  }


  removeLogoUpload() {
    this.preview = null;
  }

  saveLogo() {
    this.store.dispatch(DentistsPageActions.addLogo({ logo: this.file }));

  }

  form1 = new FormGroup({
    price1: new FormControl(""),
    price2: new FormControl(""),
  });


  addProduc(e) {
    if (e.checked) {
      let product;
      product = this.products.find(val => val._id === e.value)
      this.selectedProducts.push(product);
    } else {
      let product;
      product = this.products.find(val => val._id === e.value)
      this.selectedProducts = this.selectedProducts.filter(val => val._id !== product._id)
    }
  }

  selectProductFromAvailable(e, remove?) {

    if (e !== "Wybierz produkt" && e !== "appDesktop") {


      this.numberOfProductsToAdd++;
      this.currentProduct = this.dentistCurrent[0].available.find(val => val._id === e);
      let prod = this.products.find(val => val._id === this.currentProduct._id)
      if (!prod) {
        let produscToAdd: productToShow = this.transformProductToShow(this.currentProduct)
        this.products = [...this.products, produscToAdd]
      }



      this.selectedProduct = e;
      this.protocol = false;
      this.selectedStatu = null
      this.isProductSelected = true;

    } else if (e === "appDesktop") {
      this.store.dispatch(ProductPageActions.addProductDesktop({ id: this.currentId }))
    }
  }

  selectStatus(isDentisStatus?: boolean) {

    this.isLinkCopied = false;
    this.isChangePrice = false;
    this.form1.get("price1").setValidators(null);
    this.form1.get("price1").updateValueAndValidity();
    this.form1.get("price2").setValidators(null);
    this.form1.get("price2").updateValueAndValidity();

    this.form1.reset();
    let isIn;
    let t = []
    if (!isDentisStatus) {
      isIn = this.selectedProducts.find(val => val._id === this.currentProduct._id)
    }
    this.selectedStatu = this.s;
    this.selectedStatusName = this.dentistCurrent[0].statusList.find(val => val._id === this.selectedStatu);


    if (this.selectedStatusName.name === 'wysłano ofertę') {
      this.store.dispatch(DentistsPageActions.SetDentistStatus({ dentistId: this.currentId, statusId: this.selectedStatusName._id }));
    }

    if (this.selectedStatusName.name === 'niezainteresowany') {
      this.store.dispatch(DentistsPageActions.SetDentistStatus({ dentistId: this.currentId, statusId: this.selectedStatusName._id }));
    }
    if (this.selectedStatusName.name === 'w rezygnacji') {
      this.store.dispatch(DentistsPageActions.SetDentistStatus({ dentistId: this.currentId, statusId: this.selectedStatusName._id }));
    }

    if (this.selectedStatusName.name === "testuje" || this.selectedStatusName.name === "umowa w trakcie") {
      this.setStatusWithAsingProducts(this.currentId, this.selectedStatu, this.selectedProducts);

    }
    if (this.selectedStatusName.name === "rezygnacja" || this.selectedStatusName.name === 'rezygnacja z umowy') {
      this.setStatusRresignation(this.currentId, this.selectedStatu, this.selectedProducts)
    }
  }

  setStatusRresignation(dentisId: string, status: string, products: any[]) {
    let p = products.map(val => val._id);
    this.store.dispatch(DentistsPageActions.SetDentistStatus({ dentistId: dentisId, statusId: status, productId: p }));
    this.saveStatusView = false;
    this.selectedProducts = [];
    this.protocol = false;
  }





  setStatusWithAsingProducts(dentisId, status, products) {
    let p = products.map(val => val._id);
    this.isTest = true;
    this.saveStatusView = true;
    this.protocol = false;
    this.store.dispatch(DentistsPageActions.SetDentistStatus({ dentistId: dentisId, statusId: status, productId: p }));
  }



  ghostProtocol() {



    let price = this.form1.get('price1').value;
    let monthly = this.form1.get('price2').value;
    let products = this.selectedProducts.map(product => product._id);
    this.selectedProducts = []
    if (this.selectedStatusName.name === "umowa w trakcie") {
      if (this.isChangePrice) {
        this.store.dispatch(DentistsPageActions.GeneratePaymentsLink({ dentistId: this.currentId, productId: products, licenseDuration: this.licensLenght, price: price, monthly: monthly }));
      } else {

        this.store.dispatch(DentistsPageActions.GeneratePaymentsLink({ dentistId: this.currentId, productId: products, licenseDuration: this.licensLenght }));
      }
    } else {
      this.store.dispatch(DentistsPageActions.GenerateTestLink({ dentistId: this.currentId, productId: products }));
    }
    this.protocol = !this.protocol;


    this.productsInputs.forEach(input => {
      input.nativeElement.checked = false;

    })

  }

  changeStatus(e) {

    if (!this.dentistCurrent[0].assigned[0]) {
      this.isRomove = false;
    }
    if (e) {
      this.s = e;
      this.selectedStatusName = {};

    }
    this.selectedStatu = null;
  }



  addAnotherProduct() {
    this.addProduct = !this.addProduct;
  }


  ngAfterViewChecked(): void {
    this.productsInputs.forEach(input => {
      let ischecked = this.selectedProducts.find(val => val._id === input.nativeElement.value);
      if (ischecked) {
        input.nativeElement.checked = true;
      }
    })
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }
  closeResult: string;
  confirmPaymentsPopUp() {
    this.modalService.open(this.modal, { ariaLabelledBy: "modal-basic-title" }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });





  }


  confirmPayments() {
    this.store.dispatch(DentistsPageActions.confirmPayments({ id: this.currentId }));
    this.modalService.dismissAll();
  }


  descentLicenst() {
    if (this.licensLenght > 0) {
      this.licensLenght--;
    }
  }

  incrementLicens() {
    this.licensLenght++;
  }



  changePrice(e) {

    this.isChangePrice = e;

    if (this.isChangePrice) {
      this.form1.get("price1").setValidators([Validators.required]);
      this.form1.get("price1").updateValueAndValidity();
    } else {
      this.form1.get("price1").setValidators(null);
      this.form1.get("price1").updateValueAndValidity();
    }

  }

  ngOnInit(): void {
    this.selectedStatu = false;
    this.route.paramMap.subscribe(params => {
      this.currentId = params.get("id");
      if (this.currentId) {
        this.store.dispatch(DentistsPageActions.getDentistDetails({ dentistId: this.currentId }));
      }
    });
  }

}
