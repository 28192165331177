
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Store } from '@ngrx/store';
import * as authActions from "src/app/auth/actions/auth.actions";
import { TestPageActions } from "../../../e-learning/components/test/actions";
@Component({
  selector: 'app-top-nav',
  templateUrl: './top-nav.component.html',
  styleUrls: ['./top-nav.component.scss'],
})
export class NavTopComponent {
  @Output() changePassword = new EventEmitter<string>();
  constructor(private store: Store,
   
  ) {

  }



  @Input() role: string;
  @Input() routes: any;
  @Input() show: boolean;
  @Input() selectBtnFromTop: boolean;
  LogOut() {
    this.store.dispatch(authActions.logout())
    this.store.dispatch(TestPageActions.clearQuizStore())
  }
  changePass() {
    this.changePassword.emit('change');
  }
}
