import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { SharedModule } from "../common/shared.module";
import { DentistRoutingModule } from "./dentist-routing.modules";
import { AddDentistComponent } from './components/add-dentist/add-dentist.component';
import { ListDentistComponent } from './components/dentist-list/dentist-list.component';
import { SingleDentistComponent } from './components/singe-dentist/single-dentist.component';
import { DentistPageComponent } from './containers/dentist-page.component';
import * as fromDentist from "./reducers"
import { DentistsEffects } from "./effects";
import { ApiService, FakeService } from "./services";
import { AddDentistPageComponent } from './containers/add-dentist.page.component';
import { DentistService } from "./services/dentist.service";
import { SingleDentistPageComponent } from "./containers/single-dentist.page.component/single-dentis.page.component";
import { DentistListPageComponent } from "./containers/dentist-list.page.component/dentist-list.page.component";
import { NgxMaskModule, IConfig } from 'ngx-mask'

export const COMPONENTS = [
  AddDentistComponent,
  ListDentistComponent,
  SingleDentistComponent,
  DentistPageComponent,
  AddDentistPageComponent,
  SingleDentistPageComponent,
  DentistListPageComponent


]
const maskConfig: Partial<IConfig> = {
  dropSpecialCharacters: false,
};

@NgModule({
  imports: [
    FormsModule,
    NgxMaskModule.forRoot(),
    CommonModule,
    SharedModule,
    DentistRoutingModule,
    ReactiveFormsModule,
    

    StoreModule.forFeature(fromDentist.dentistsFeatureKey, fromDentist.reducers),
    EffectsModule.forFeature([DentistsEffects])

  ],
  providers: [
  //  { provide: ApiService, useClass: FakeService },
    { provide: ApiService, useClass: DentistService }
  ],
  declarations: COMPONENTS
})
export class DentistModule { }

