import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Actions, ofType, createEffect} from '@ngrx/effects';
import {of} from 'rxjs';
import {catchError, exhaustMap, switchMap, map, tap} from 'rxjs/operators';
import {LoginPageActions, AuthApiActions, AuthActions} from '../actions';
import {Credentials, Token} from '../models/auth.model';
import {AuthService} from '../services';
import * as fromRegister from '../reducers/';
import {Store} from '@ngrx/store';
import { Utils } from 'src/app/common/utils';
import { LayoutActions } from 'src/app/core/actions';

@Injectable()
export class AuthEffects {
    login$ = createEffect(() =>
        this.actions$.pipe(
            ofType(LoginPageActions.login),
            switchMap((auth: Credentials) => 
                this.authService.login(auth).pipe(
                    map((token: Token) => {
                       return AuthApiActions.loginSuccess({token})}),
                    catchError(({error}) =>  of(AuthApiActions.loginFailure( error)))
                )
                    )
        )
    );

    loginSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthApiActions.loginSuccess),
            tap((token) => {
                console.log(token)
                // this.store.dispatch(LayoutActions.role());
              this.router.navigate(['/products/list']);
            })
        ),
        {dispatch: false}
    );

    loginFailure$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthApiActions.loginFailure),
          map(({ error  }) => {
            this.store.dispatch(LayoutActions.setError({error : "Podane hasło lub login jest nie prawidłowy "}));
            })
        ),
        {dispatch: false}
    );

    loginRedirect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(
                AuthActions.loginRedirect,
                AuthActions.logout
            ),
            tap(() => {
                this.router.navigate(['/login']);
            })
        ),
        {dispatch: false}
    );

    changePassword$ = createEffect(() =>
        this.actions$.pipe(
            ofType(LoginPageActions.changePassword),
            exhaustMap(action =>
                this.authService.changePassword(action.credentials, action.resetPassword).pipe(
                    map(() => AuthApiActions.changePasswordSuccess()),
                    catchError((error: string) => of(AuthApiActions.changePasswordFailure({error})))
                )
            )
        )
    );

    changePasswordSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthApiActions.changePasswordSuccess),
            tap(() => {
                this.router.navigate(['/results']);
            })
        ),
        {dispatch: false}
    );

    clientCredentialsToken$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthActions.clientCredentialsToken),
            exhaustMap(() =>
                this.authService.clientCredentialsToken().pipe(
                    map((token: Token) => AuthApiActions.clientCredentialsTokenSuccess({token}))
                )
            )
        )
    );

    refreshToken$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthActions.refreshToken),
            exhaustMap((token: Token) =>
                this.authService.refreshToken(token).pipe(
                    map((token: Token) => AuthApiActions.refreshTokenSuccess({token})),
                    catchError((error: string) => of(AuthApiActions.refreshTokenFailure({payload:Utils.isJson(error)})))
                )
            )
        )
  );


 logOut$ = createEffect(() =>
   this.actions$.pipe(
     ofType(AuthActions.logout),
     map(() => {
        LayoutActions.LayoutInitialState()
      })
    ),
    { dispatch: false }
  );







    refreshTokenFailure$ = createEffect(() =>
        this.actions$.pipe(
        ofType(AuthApiActions.refreshTokenFailure),
        map(()=> 
            AuthActions.logout()
        )
    ));

    constructor(
        private store: Store<fromRegister.State>,
        private actions$: Actions,
        private authService: AuthService,
        private router: Router
    ) {}
}
