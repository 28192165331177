import { sendForm } from './../actions/offer-page.actions';


import { Action, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromRoot from '../../reducers';
import * as OfferPageFeatureKey from './offer.reducer';

export const offerFeatureKey = 'offers';

export interface OfferState {
  [OfferPageFeatureKey.offerPageFeatureKey]: OfferPageFeatureKey.State;
}

export interface State extends fromRoot.State {
  [offerFeatureKey]: OfferState;
}

export function reducers(state: OfferState | undefined, action: Action) {
    return combineReducers({
      [OfferPageFeatureKey.offerPageFeatureKey]: OfferPageFeatureKey.reducer,
    })(state, action);
}

export const selectOffersState = createFeatureSelector<State, OfferState>(offerFeatureKey);


export const selectOffersPageState = createSelector(
  selectOffersState,
  (state: OfferState) => state.offerPage
);


export const selectSendForm = createSelector(
  selectOffersPageState,
  OfferPageFeatureKey.selectIsFormSend
);




