export interface EmintoBox  {
  id: string,
  idPlayer: string,
  nrEminto: string,
  playerName: string,
  login : string,
  password: string,
  
}



export interface AppDesktop {
  quantity: string,
  dentistId: string,
}



export interface IProduct  {
  city: string,
country: string,
name: string,
postalCode: string,
shortName: string,
state: string,
street: string,
website: string,
emintoId: string,
_id: string,
  playerId?: string,
  type?: string
}




export interface SingleProductCat  {
  name: string,
  _id: "string",

}


export enum ProductsListCategory {
  T = "Eminto Box",
  V = "Virtual",
  D = "Aplikacja desktop"

}



