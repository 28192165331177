import { LayoutActions } from '../actions';
import { createReducer, on } from '@ngrx/store';
import { DialogData, DialogMeta } from '../models/dialog';

export const layoutFeatureKey = 'layoutPage2';






export interface State {
  error: string | null;
  dialog: { data: DialogData, meta: DialogMeta };
  enlarge: boolean;
  role: string | null;
  showLogout: boolean;
  msg: string | null;
  hideBtnsFromTop: boolean;
  showFiltersProducts: boolean,
  showFiltersDenstis: boolean,
  showLeadsFilters: boolean,
  currentDentisStatus: string | null,
  currentSearchTerm: string | null,
  currentCampaing: string | null,
  currentSearchCampaingTerm:string | null,
  isMediaViev: boolean;
  isMenuShow: boolean;
  currentPersonoOfLeads: string | null,
  
  leadsFiltertDate: { startDate: string, endDate: string } | null;
  leadsColumns: {
    all: boolean,
    columns: { name: string, hide: boolean }[] | null
  }
}

const initialState: State = {
    error: null,
    dialog: null,
    enlarge: false,
    role: "ddh",
    showLogout: false,
  msg: null,
  hideBtnsFromTop: true,
  showFiltersProducts: false,
  currentDentisStatus: null,
  currentSearchTerm: null,
  showFiltersDenstis: false,
  isMediaViev: false,
  isMenuShow: true,
  currentCampaing: null,
  currentSearchCampaingTerm: null,
  showLeadsFilters: false,
  currentPersonoOfLeads: null,
  leadsFiltertDate: null,
  
  leadsColumns: {
    all: false,
    columns: [
    { name: 'Data', hide: false, },
    { name: 'Nazwa', hide: false, },
    { name: 'Imię i Nazwisko', hide: true, },
    { name: 'E-mail', hide: true, },
    { name: 'Telefon', hide: true, },
    { name: 'Kampania', hide: false, },
    { name: 'Osoba', hide: false, },
    { name: 'Akcje', hide: false, },
    { name: 'Step 1', hide: false, },
    { name: 'Step 2', hide: false, },
    { name: 'Step 3', hide: false, },
    { name: 'Step 4', hide: false, },
    { name: 'Step 5', hide: false, },
    { name: 'Data-s1', hide: false, },
    { name: 'Data-s2', hide: false, },
    { name: 'Data-s3', hide: false, },
    { name: 'Data-s4', hide: false, },
    { name: 'Data-s5', hide: false, },
    { name: 'Dodatkowe usługi', hide: false, },
  ]
    }
};

export const reducer = createReducer(
  initialState,
  on(LayoutActions.showDialog,
    (state, dialog) => ({ ...state, dialog: { data: dialog.dialog, meta: dialog.meta } })),
  on(LayoutActions.resultDialog,
    (state, r) => ({ ...state, dialog: { data: { form: { value: r.result.result, name: state.dialog.data.form ? state.dialog.data.form.name : null } }, meta: r.result.meta } })
  ),
  on(LayoutActions.hideDialog,
    (state, r) => ({ ...state, dialog: null })
  ),
  on(LayoutActions.toggleEnlarge,
    state => ({ ...state, enlarge: state.enlarge == true ? false : true })),
  on(LayoutActions.role,
    (state, action) => ({ ...state, role: action.role })),
  on(LayoutActions.setError,
    (state, action) => ({ ...state, error: action.error })),
  on(LayoutActions.removeError,
    (state, action) => ({ ...state, error: null })),
  on(LayoutActions.RemoveDialog,
    (state, action) => ({ ...state, msg: null })),
  on(LayoutActions.showLogoutBtn,
    (state, action) => ({ ...state, showLogout: true })),
  on(LayoutActions.hiddenLogoutBtn,
    (state, action) => ({ ...state, showLogout: false })),
  on(LayoutActions.setDialog,
    (state, action) => ({ ...state, msg: action.dialog })),

  on(LayoutActions.showBtnsFromTop,
    (state, action) => ({ ...state, hideBtnsFromTop: true })),
  on(LayoutActions.hideBtnsFromTop,
    (state, action) => ({ ...state, hideBtnsFromTop: false })),
  on(LayoutActions.showFilters,
    (state, action) => ({ ...state, showFiltersProducts: true })),
  on(LayoutActions.hideFilters,
    (state, action) => ({ ...state, showFiltersProducts: false })),
  on(LayoutActions.setIsMediaviev,
    (state, action) => ({ ...state, isMediaViev: action.isMedia })),
  on(LayoutActions.showMenu,
    (state, action) => ({ ...state, isMenuShow: true })),
  on(LayoutActions.hideMenu,
    (state, action) => ({ ...state, isMenuShow: false })),
  on(LayoutActions.showDentisFilter,
    (state, action) => ({ ...state, showFiltersDenstis: true })),
  on(LayoutActions.hideDentisFilter,
    (state, action) => ({ ...state, showFiltersDenstis: false })),
  on(LayoutActions.showLeadsFilter,
    (state, action) => ({ ...state, showLeadsFilters: true })),
  on(LayoutActions.hideLeadsFilter,
    (state, action) => ({ ...state, showLeadsFilters: false })),
  on(LayoutActions.setCurrentStatus,
    (state, action) => ({ ...state, currentDentisStatus: action.status })),
  on(LayoutActions.removeCurrentStatus,
    (state, action) => ({ ...state, currentDentisStatus: null })),
  on(LayoutActions.setSearchTerm,
    (state, action) => ({ ...state, currentSearchTerm: action.search })),
  on(LayoutActions.removeSearchTerm,
    (state, action) => ({ ...state, currentSearchTerm: null })),
  on(LayoutActions.removeSearchCampaignTerm,
    (state, action) => ({ ...state, currentSearchCampaingTerm: null })),
  on(LayoutActions.setCurrentCampaing,
    (state, action) => ({ ...state, currentCampaing: action.campaing })),
  on(LayoutActions.setSearchCampaingTerm,
    (state, action) => ({ ...state, currentSearchCampaingTerm: action.search })),
  on(LayoutActions.LayoutInitialState,
    (state, action) => ({ ...state, initialState })),
  on(LayoutActions.setCurrentPersonOfLeads,
    (state, action) => ({ ...state, currentPersonoOfLeads: action.id })),
  on(LayoutActions.setLeadsFilterDate,
    (state, action) => ({ ...state, leadsFiltertDate: { startDate: action.start, endDate: action.end } })),
  on(LayoutActions.removeLeadsFilterDate,
    (state, action) => ({ ...state, leadsFiltertDate: null })),

  
  on(LayoutActions.setColumnVisible,
    (state, action) => {
      if (action.column['name'] === 'all' && action.column['hide'] === true ) {
        return {
          ...state,
          leadsColumns: {
            ...state.leadsColumns,
            columns: state.leadsColumns.columns.map(val => {
              return {
                ...val,
                hide: false
              }
            }),
            all : true
          }
        }
      } else {

        return {
          ...state,
          leadsColumns: {
            ...state.leadsColumns,
            columns: state.leadsColumns.columns.map(val => {
              return {
                ...val,
                hide: val['name'] === action.column['name'] ? action.column['hide'] : val['hide']
              }
            })
          }
        }
      }
    },

  )
  )


export const selectError = (state: State) => state.error;
export const selectDialog = (state: State) => state.dialog;
export const selectDialogMeta = (state: State) => { if (state.dialog) return state.dialog.meta };
export const selectEnlarge = (state: State) => state.enlarge;
export const selectRole = (state: State) => state.role;
export const selectShowLogout = (state: State) => state.showLogout;
export const selectMsg = (state: State) => state.msg;
export const selectBtnsFromTop = (state: State) => state.hideBtnsFromTop;
export const selectShowFiltersProducts = (state: State) => state.showFiltersProducts;
export const selectIsMediaViev = (state: State) => state.isMediaViev;
export const selectIsMenuShow = (state: State) => state.isMenuShow;
export const selectShowFiltersDentist = (state: State) => state.showFiltersDenstis;
export const selectCurrentStatus = (state: State) => state.currentDentisStatus;
export const selectCurrentSearchTerm = (state: State) => state.currentSearchTerm;
export const selectCurrentCampaing = (state: State) => state.currentCampaing;
export const selectCurrentSearchTermCampaing = (state: State) => state.currentSearchCampaingTerm;
export const selectShowLeadsFilters = (state: State) => state.showLeadsFilters;
export const selectLeadsColumns = (state: State) => state.leadsColumns.columns;
export const selectCurrentPersonOfLeads = (state: State) => state.currentPersonoOfLeads;
export const selectLeadFilterDate = (state: State) => state.leadsFiltertDate;
