
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


import { AuthGuard } from "../auth/services/auth-guard.service";
import { AddLeadPageComponent } from './containers/add-lead.page.component';
import { LeadListPageComponent } from './containers/lead-list-page.component';
import { LeadPageComponent } from './containers/lead-page.component';
import { SingleLeadPageComponent } from './containers/single-lead-page.component';

export const routes: Routes = [

  {
    path: 'leads', component: LeadPageComponent, canActivate: [AuthGuard],
    children: [
      {
        path: 'add-lead',
        component: AddLeadPageComponent, canActivate: [AuthGuard], data: { logged: true, forbidden: ["seller", "distributor", "admin"] },
      },
      {
        path: 'add-lead/:id',
        component: AddLeadPageComponent, canActivate: [AuthGuard], data: { logged: true, forbidden: ["seller", "distributor", "admin"] },
      },
      
      {
        path: 'lead-list',
      component: LeadListPageComponent, canActivate: [AuthGuard]
      },
      {
        path: 'single-lead/:id',
        component: SingleLeadPageComponent, canActivate: [AuthGuard]
      },
      ]
  }
]
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LeadsRoutingModule { }
