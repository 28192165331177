import { Pipe, PipeTransform } from '@angular/core';


@Pipe({ name: 'cutString' })

export class CutStringPipe implements PipeTransform {

  constructor() { }

  transform(value: string, customDistributor?: boolean) {
    let string: string;
    if (value) {
      if (value.length > 20) {
        string = `${value.slice(0, 20)}...`;
      } else {
        string = value;
      }
      if (customDistributor)  {
          string = `${string}*`
      }
      return string;
    }
  }
}
