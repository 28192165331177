import {createSelector, createFeatureSelector, combineReducers, Action} from '@ngrx/store';
import * as fromRoot from '../../reducers';
import * as fromLayourPage from './layout.reducer';

export const LayoutFeatureKey = 'layout';

export interface LayoutState {
    [fromLayourPage.layoutFeatureKey]: fromLayourPage.State;
}

export interface State extends fromRoot.State {
    [LayoutFeatureKey]: LayoutState;
}

export function reducers(state: LayoutState | undefined, action: Action) {
    return combineReducers({
        [fromLayourPage.layoutFeatureKey]: fromLayourPage.reducer,
    })(state, action);
}

export const selectLayoutState = createFeatureSelector<State, LayoutState>(LayoutFeatureKey);

export const selectLayoutPageState = createSelector(
    selectLayoutState,
    (state: LayoutState) => state.layoutPage2
);

export const selectDialog = createSelector(
    selectLayoutPageState,
    fromLayourPage.selectDialogMeta
);
export const selectLoading = createSelector(
    selectLayoutPageState,
    fromLayourPage.selectEnlarge
);

export const selectRole = createSelector(
    selectLayoutPageState,
    fromLayourPage.selectRole
);


export const selectErrorState = createSelector(
  selectLayoutPageState,
  fromLayourPage.selectError
);


export const selectBtn = createSelector(
  selectLayoutPageState,
  fromLayourPage.selectShowLogout
);


export const selectMsg = createSelector(
  selectLayoutPageState,
  fromLayourPage.selectMsg
);





export const selectBtnsFromTop = createSelector(
  selectLayoutPageState,
  fromLayourPage.selectBtnsFromTop
);



export const selectShowfilters = createSelector(
  selectLayoutPageState,
  fromLayourPage.selectShowFiltersProducts
);

export const selectShowfiltersDentist = createSelector(
  selectLayoutPageState,
  fromLayourPage.selectShowFiltersDentist
);


export const selectCurrentStatus= createSelector(
  selectLayoutPageState,
  fromLayourPage.selectCurrentStatus
);
export const selectCurrentSearchTerm = createSelector(
  selectLayoutPageState,
  fromLayourPage.selectCurrentSearchTerm
);



export const selectIsMedia = createSelector(
  selectLayoutPageState,
  fromLayourPage.selectIsMediaViev
);

export const selectIsMenu = createSelector(
  selectLayoutPageState,
  fromLayourPage.selectIsMenuShow
);
export const selectCampaing = createSelector(
  selectLayoutPageState,
  fromLayourPage.selectCurrentCampaing
);



export const selectCampainSearch = createSelector(
  selectLayoutPageState,
  fromLayourPage.selectCurrentSearchTermCampaing
);


export const selectShowLeadsFilters = createSelector(
  selectLayoutPageState,
  fromLayourPage.selectShowLeadsFilters
);


export const selectCurrentLeadsColumns = createSelector(
  selectLayoutPageState,
  fromLayourPage.selectLeadsColumns
);


export const selectCurrentPersonOfLeads = createSelector(
  selectLayoutPageState,
  fromLayourPage.selectCurrentPersonOfLeads
);


export const selectLeadFilterDate = createSelector(
  selectLayoutPageState,
  fromLayourPage.selectLeadFilterDate
);

