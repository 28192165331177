import { Component  } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { select, Store } from '@ngrx/store';
import * as fromAuth from 'src/app/auth/reducers'
import * as fromDentist from 'src/app/dentist/reducers'
import * as fromLayout from 'src/app/core/reducers'

@Component({

  template: `<app-list-dentist
  [role]="role$ | async"
  [d]="dataDentist$ | async"
  [currentDentistStatus]="currentStatus$ | async"
  [currentSearchTerm]="currentSearchTerm$ | async"

></app-list-dentist>`
})
export class DentistListPageComponent  {

  constructor(

    private store: Store,
  ) {


  }
  
  role$ = this.store.pipe(select(fromAuth.selectUserRole));
  dataDentist$ = this.store.pipe(select(fromDentist.selectDentists));
  currentStatus$ = this.store.pipe(select(fromLayout.selectCurrentStatus));
  currentSearchTerm$ = this.store.pipe(select(fromLayout.selectCurrentSearchTerm));



}
