import { getContractor } from './../../contractor/actions/contractor-page.actions';

import { AfterViewInit, Component, OnDestroy, OnInit } from "@angular/core";
import { Store, select } from '@ngrx/store';

import { DentistsPageActions } from "../actions";
import * as fromDentist from '../reducers'
import * as fromLeads from 'src/app/leads/reducers'
import * as fromContractors from 'src/app/contractor/reducers'
import { ActivatedRoute } from "@angular/router";
import { LeadsPageActions } from "../../leads/actions";
import { ContractorPageActions } from 'src/app/contractor/actions';
@Component({
  selector: 'app-add-dentist-page',
  template: `<app-add-dentist
(submitData)="submitform($event)"
(submitNip)="submitNip($event)"
[currentDentis]="currentDentist$ | async"
[distributors]="distributors$ | async"
[currentLead]="currentLead$ | async"
[dataFromGus]="gusData$ | async"
[status]="status"
></app-add-dentist>`
})




export class AddDentistPageComponent implements AfterViewInit, OnDestroy, OnInit {
  currentDentist$ = this.store.pipe(select(fromDentist.selectDentist));
  gusData$ = this.store.pipe(select(fromDentist.selectGus));
  status: string;
  currentLeadId : string;
  isFromLead: boolean = false;
  currentLead$ = this.storeLeads.pipe(select(fromLeads.selectLead));
  distributors$ = this.storeContractors.pipe(select(fromContractors.selectContractor));


  constructor(
    private store: Store<fromDentist.State>,
    private storeContractors : Store<fromContractors.State>,
    private storeLeads: Store<fromLeads.State>,
    private route: ActivatedRoute) {
  }
  ngOnInit(): void {
    this.store.dispatch(ContractorPageActions.gsetContractors())
  }

  ngAfterViewInit(): void {
   
    this.route.params.subscribe(params => {
      let p = params['id'];
      this.currentLeadId = p;
      let lead = params['lead']
      let status = params['status']
      if (status) {
        this.status = status;
      }
      if (p && !lead) {
        this.store.dispatch(DentistsPageActions.getDentistDetails({ dentistId: p }));
      } else {
        this.store.dispatch(DentistsPageActions.RemoveCurrentDentist());
      }
      if (lead) {
        this.isFromLead = true;
        this.store.dispatch(LeadsPageActions.getLeadDetails({ id: p }))
      }
    }
    );
  }

  ngOnDestroy(): void {
    this.store.dispatch(DentistsPageActions.RemoveCurrentDentist());
    this.store.dispatch(DentistsPageActions.RemoveDentisFromGus());
    this.store.dispatch(LeadsPageActions.removeCurrentLead());
  
  }

  submitNip(e) {
    this.store.dispatch(DentistsPageActions.GetDataFromGus({ nip: e }));
  }

  submitform(e) {
    if (this.status === 'not') {
      e.status = '62387d8a261b6916dd11dd58';
    } else if (this.status === 'notContact') {
      e.status = '6253e6839e5903cee82d0a61'
    }
    else if (this.isFromLead) {
      e.status = null
      e.leadId = this.currentLeadId;

    }
    this.store.dispatch(DentistsPageActions.postDentists({ dentist: e }))
  }
}
