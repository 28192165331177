import {
  OnInit,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

import { Subject } from 'rxjs';
import * as fromLeads from 'src/app/leads/reducers';

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
} from '@angular/core';
import { Inject, OnDestroy } from '@angular/core';

import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MatDateFormats,
} from '@angular/material/core';

import { takeUntil } from 'rxjs/operators';
import { MatCalendar } from '@angular/material/datepicker';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-calendar',
  template: `<mat-form-field id="calendar" appearance="fill">
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker
      (closed)="onClose()"
      #picker
      [calendarHeaderComponent]="exampleHeader"
    ></mat-datepicker>
    <input
      [min]="todayDate"
      [max]="currentMaxDate"
      [matDatepickerFilter]="weekendsDatesFilter"
      ngModel
      name="dateSelected"
      [(ngModel)]="dateSelected"
      matInput
      [matDatepicker]="picker"
    />
  </mat-form-field>`,
  styleUrls: ['calendar.component.scss'],
})
export class CalendarComponent implements OnChanges {
  @Output()
  date = new EventEmitter<any>();
  @Input()
  maxDate: string;

  currentMaxDate: Date;

  addDays(date: string, days: number) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.maxDate?.currentValue) {
      this.currentMaxDate = new Date(
        this.addDays(changes.maxDate.currentValue, 14)
      );
    }
  }

  exampleHeader = ExampleHeader;

  weekendsDatesFilter = (d = new Date()): boolean => {
    const day = d.getDay();
    return day !== 0 && day !== 6;
  };
  onClose() {
    if (this.dateSelected) {
      const ys = this.dateSelected.getFullYear();
      const ms = ('0' + (this.dateSelected.getMonth() + 1)).slice(-2);
      let ds = this.dateSelected.getDate().toString();
      if (ds.length === 1) {
        ds = `0${ds}`;
      }
      const date = `${ys}-${ms}-${ds}`;

      this.date.emit(date);
    }
  }

  dateSelected: Date;
  todayDate: Date = new Date();
}
@Component({
  selector: 'example-header',
  styles: [
    `
      .example-header {
        display: flex;
        align-items: center;
        padding: 0.5em;
      }

      .example-header-label {
        flex: 1;
        height: 1em;
        font-weight: 500;
        text-align: center;
      }
      .fw-500 {
        font-weight: 500;
      }

      .example-double-arrow .mat-icon {
        margin: -22%;
      }
    `,
  ],
  template: `
    <p class="text-center py-2 fw-500">{{ title }}</p>
    <div class="example-header">
      <button
        class="mat-icon-button"
        mat-icon-button
        (click)="previousClicked('month')"
      >
        <span> < </span>
      </button>
      <span class="example-header-label">{{ periodLabel }}</span>
      <button
        class="mat-icon-button"
        mat-icon-button
        (click)="nextClicked('month')"
      >
        <span> > </span>
      </button>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExampleHeader<D> implements OnDestroy, OnInit {
  private _destroyed = new Subject<void>();
  subscription$: Subscription;
  title: string;
  constructor(
    private _calendar: MatCalendar<D>,
    private _dateAdapter: DateAdapter<D>,
    private store: Store,
    @Inject(MAT_DATE_FORMATS) private _dateFormats: MatDateFormats,
    cdr: ChangeDetectorRef
  ) {
    _calendar.stateChanges
      .pipe(takeUntil(this._destroyed))
      .subscribe(() => cdr.markForCheck());
  }
  ngOnInit(): void {
    this.subscription$ = this.store
      .pipe(select(fromLeads.selectCalendarTitle))
      .subscribe((title) => {
        this.title = title;
      });
  }

  ngOnDestroy() {
    this.subscription$.unsubscribe();
    this._destroyed.next();
    this._destroyed.complete();
  }

  get periodLabel() {
    return this._dateAdapter
      .format(
        this._calendar.activeDate,
        this._dateFormats.display.monthYearLabel
      )
      .toLocaleUpperCase();
  }

  previousClicked(mode: 'month' | 'year') {
    this._calendar.activeDate =
      mode === 'month'
        ? this._dateAdapter.addCalendarMonths(this._calendar.activeDate, -1)
        : this._dateAdapter.addCalendarYears(this._calendar.activeDate, -1);
  }

  nextClicked(mode: 'month' | 'year') {
    this._calendar.activeDate =
      mode === 'month'
        ? this._dateAdapter.addCalendarMonths(this._calendar.activeDate, 1)
        : this._dateAdapter.addCalendarYears(this._calendar.activeDate, 1);
  }
}
