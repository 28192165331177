import {Injectable} from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpParams,
    HttpErrorResponse
} from '@angular/common/http';
import {Observable,  throwError} from 'rxjs';
import {AuthService} from './auth.service';
import {switchMap, catchError} from 'rxjs/operators';
import {Token} from '../models/auth.model';
import * as fromAuth from '../reducers';
import {Store, select} from '@ngrx/store';
import {AuthActions, AuthApiActions} from '../actions';
import {Actions, ofType} from '@ngrx/effects';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    token: Token;
    constructor(private auth: AuthService, private store: Store<fromAuth.State>, private actions$: Actions) {
        this.store.pipe(select(fromAuth.selectToken))
            .subscribe((token: Token) => {
                this.token = token;
            });
    }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      let params: HttpParams = request.body,
        
            grantType = ['password', 'client_credentials', 'refresh_token'];
        if (grantType.includes(params.get('grant_type'))) {
          return next.handle(this.setHeader(request, this.auth.basicAuthorization()));
          
        }
        else if (params.get('grant_type') === 'basic') {
        
            if (this.token) {
              return next.handle(this.setHeader(request, 'Bearer ' + this.token.access_token))
                    .pipe(catchError((err: HttpErrorResponse) => {
                        if (err.status === 401) {
                            return this.clientCredentialsToken(request, next);
                      }
                        return throwError(err);
                    }));
            }
            else {
              return this.clientCredentialsToken(request, next);
            }
        }
        else {
  
            return next.handle(this.setHeader(request, 'Bearer ' + this.token.access_token))
              .pipe(catchError((err: HttpErrorResponse) => {
      
                let errorDescription
             
                try {
                  errorDescription = JSON.parse(err.error.error_description);
                  if (errorDescription.oldPassword && errorDescription.password) {
                    err.error['error_description'] = "Nowe i stare hasło są identyczne";
                    throwError(err.error.error_description);
                  }
                  if (errorDescription.daysLeft) {
                    err.error['error_description'] = `Gabinet o podanym numerze Nip  już istniej, do końca karencji  zostało ${errorDescription.daysLeft} dni`
                    throwError(err.error.error_description);
                  }
                  if (errorDescription.status) {
 
                    err.error['error_description'] = `Gabinet o podanym numerze Nip już istniej, jego obecny status to: ${errorDescription.status}`
                    throwError(err.error.error_description);
                  }

     
                } catch (e) {
                 
                }

                if (err.status === 400 && err.error.error === "tests_accepted") {
                  err.error.error_description = "Regulamin został już zaakceptowany";

                  throwError(err.error.error_description);
                }
               
                if (err.status === 400 && err.error.error === "validation_error") {

                  if (errorDescription['accountExists']) {
                    err.error.error_description = "Podany login jest zajęty";

                    throwError(err.error.error_description);
                  } else {
                    err.error.error_description = "Poprawnie uzupełnij wszystkie Pola";
                    throwError(err.error.error_description);
                  }
                }
                if (err.status === 400 && err.error.error === "operation_denied") {
                 
                  err.error.error_description = "Uzupełnij wszystkie wymagane pola";
                  throwError(err.error.error_description);
                }
                if (err.status === 400 && err.error.error === "old_password_does_not_match") {

                  err.error.error_description = "Podane stare hasło jest błędne";
                  throwError(err.error.error_description);
                }
                if (err.status === 400 && err.error.error === "user_exists") {
 
                  err.error.error_description = "użytkownik instnieje";
                  throwError(err.error.error_description);
                }


                if (err.status === 500 ) {

                  err.error.error_description = "Błąd serwera";
                  throwError(err.error.error_description);
                }
                if (err.status === 503) {

                  err.error.error_description = "Server chwilowo niedostęny";
                  throwError(err.error.error_description);
                }


                if (err.status === 401) {
                      debugger
                        return this.refreshToken(request, next);
                    }
                    return throwError(err);
                }));
        }
    }

    private clientCredentialsToken(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.store.dispatch(AuthActions.clientCredentialsToken());
        return this.actions$.pipe(
            ofType(AuthApiActions.clientCredentialsTokenSuccess),
          switchMap(action => {
            return next.handle(this.setHeader(request, 'Bearer ' + action.token.access_token))
            }));
  }

  



    private refreshToken(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.store.dispatch(AuthActions.refreshToken(this.token));
        return this.actions$.pipe(
            ofType(AuthApiActions.refreshTokenSuccess),
            switchMap(action => {
                return next.handle(this.setHeader(request, 'Bearer ' + action.token.access_token))
            }));
    }

    private setHeader(request: HttpRequest<any>, authorization: string): HttpRequest<any> {
        return request.clone({
            setHeaders: {
                Authorization: authorization
            }
        });
    }
}
