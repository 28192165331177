import { Injectable } from "@angular/core";
import { CanActivate, ActivatedRouteSnapshot, Router, RouterStateSnapshot } from "@angular/router";
import { Store, select } from "@ngrx/store";
import { Observable } from "rxjs";
import { map, take } from "rxjs/operators";
import { AuthActions } from "../actions";
import * as fromAuth from "../reducers";
import { Token } from "../models/auth.model";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate {
  constructor(private store: Store<fromAuth.State>, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {

    const logged = route.data["logged"] as boolean;
    const forbidden = route.data["forbidden"] as string[];

    return this.store.pipe(
      select(fromAuth.selectToken),
      map((token: Token) => {
        
        if (forbidden) {
          const isForbidden = forbidden.find(val => val === token.type);
          if (isForbidden) {
            this.router.navigate(["/login"]);
          }
        } else if (token && token.refresh_token && logged) {
          this.router.navigate(["/contractor"]);
          return false;
        } else if (!token && !logged) {
          this.store.dispatch(AuthActions.loginRedirect());
          return false;
        }
        // else if(token && token.permissions && token.permissions.find( a => a == 'admin' ) == undefined && state.url.split( '/' )[1] != 'results' ){
        else if (token && token.permissions && state.url.split("/")[1] != "results") {
          return true;
        }
        return true;
      }),
      take(1)
    );
  }
}
