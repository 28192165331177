import { Component, OnInit, Input, SimpleChanges, ViewChild, OnDestroy, OnChanges } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { select, Store } from '@ngrx/store';
import * as fromProducts from "../../reducers/index"
import { ProductPageActions } from "../../actions/index";
import * as fromLayout from 'src/app/core/reducers'
import { LayoutActions } from 'src/app/core/actions'
import { IProduct } from '../../models';
import { Person } from '../../../contractor/models';
;
@Component({
  selector: 'app-list-products',
  styleUrls: ['./products.component.scss'],
  template: `

<app-table-filters
    [showBtn]="showFilters$ | async"       
       [search]="searchTerm || 'Wyszukaj po dowolnych parametrach'"
    (filter)="filterData($event)"
    (filterProducts)="filterProducts($event)"
></app-table-filters>


  <app-data-table
  [tableData]="dataSet"
  [tableColumns]="columns"
  [persons]="persons"
  (sort)="sortData($event)"
  [role]="role"
  (addAdminValue)="showSaveAdminBtn($event)"
></app-data-table>
`
})
export class ProductsComponent implements OnInit, OnDestroy, OnChanges {
  @Input() persons: Person[];
  @Input() d: any;
  @Input() role: string;

  @ViewChild('modalWelcome') modal: any;



  constructor(
    private store: Store,
    private store2: Store<fromProducts.State>,
  ) { }


  dataSet: IProduct[];
  columns = [];
  allProducts: IProduct[];
  imgSrc: string;
  currentProductsCategory: string = "Box"
  closeResult: string;
  showFilters$ = this.store.pipe(select(fromLayout.selectShowfilters));
  selectedProductNr: string;
  isSearchResult: boolean = false;
  searchTerm: string | null = "";

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.d.currentValue
      && !changes.d.firstChange) {
      this.allProducts = changes.d.currentValue[0];
      let productsByCategory: IProduct[];
      switch (changes.d.currentValue[1]) {
        case "Box":
          productsByCategory = changes.d.currentValue[0].filter(val => val.playerId);
          break;
        case "Virtual":
          productsByCategory = changes.d.currentValue[0].filter(val => !val.playerId);
          break;
        case "Wszystkie":
          productsByCategory = changes.d.currentValue[0];
          break;
        case "Desktop":
          productsByCategory = changes.d.currentValue[0].filter(val => val.type);
          break;
      }
      this.dataSet = productsByCategory;
      if (this.isSearchResult) {
        this.filterData(this.searchTerm);
      }
      if (this.selectedProductNr) {
        this.dataSet = this.dataSet.filter(val => val.emintoId === this.selectedProductNr);
      }

    }
    if (changes.role &&  changes.role.currentValue && !changes.role.firstChange) {
    this.role = changes.role.currentValue;

    }

  }


  filterProducts(event: string) {
    this.currentProductsCategory = event;
    if (event === "Virtual") {
      this.store2.dispatch(ProductPageActions.changeProductCategory({ category: "Virtual" }));
      this.dataSet = this.allProducts.filter(val => !val.playerId && !val.type)
     
    } else if (event === "Box") {
      this.store2.dispatch(ProductPageActions.changeProductCategory({ category: "Box" }));
      this.dataSet = this.allProducts.filter(val => val.playerId)
    } else if (event === "Desktop") {
      this.store2.dispatch(ProductPageActions.changeProductCategory({ category: "Desktop" }));
      this.dataSet = this.allProducts.filter(val => val.type)
    } else if (event === "Wszystkie") {
      this.store2.dispatch(ProductPageActions.changeProductCategory({ category: "Wszystkie" }));
      this.dataSet = this.allProducts;
    }
    
  }




  sortData(sortParameters: Sort) {
    let keyName = sortParameters.active;
    if (keyName === "contractors") {
     keyName = "distributor"
    }
    if (keyName === "seller") {
     keyName = "account"
    }
    let d = this.dataSet.filter(item => item[keyName]);
    let empty = this.dataSet.filter(item => !item[keyName]);
    if (sortParameters.direction === 'asc') {

      if (empty) {
        this.dataSet = d.slice().sort((a, b) => {
          return a[keyName].toString().toLowerCase()?.localeCompare(b[keyName].toString().toLowerCase())
        })
        this.dataSet = [...this.dataSet, ...empty]
      } else {
        this.dataSet = this.dataSet.slice().sort((a, b) => {
          return a[keyName].toString()?.localeCompare(b[keyName].toString())
        });
      }

    } else if (sortParameters.direction === 'desc') {

      if (empty) {
        this.dataSet = d.slice().sort((a, b) => b[keyName].toString().toLowerCase()?.localeCompare(a[keyName].toString().toLowerCase()));
        this.dataSet = [...empty, ...this.dataSet]
      }
    } else {
      return this.dataSet;
    }
  }

  filterData(search) {
    this.isSearchResult = true;
    this.searchTerm = search;
    this.dataSet = this.allProducts;
    if (search === 'clear') {
      this.isSearchResult = false;
      this.searchTerm = null;
      this.dataSet = this.filterProductByCategory(this.allProducts, this.currentProductsCategory)

    } else {
      let filteredData = [];
      this.dataSet.forEach(val => {
        if (Object.values(val).some(value => {
          if (typeof value === 'object' && value !== null) {
            if (Object.values(value).some(item => item.toString().toLowerCase().indexOf(search.toLowerCase()) !== -1)) {
              return true
            }
          } else
            if (value) {

              return value.toString().toLowerCase().indexOf(search.toLowerCase()) !== -1;
            }
        })) {
          filteredData.push(val);
        }
      });
      this.dataSet = this.filterProductByCategory(filteredData, this.currentProductsCategory)
     
    }
  }



  filterProductByCategory(products:any, category: string) {
    if (category === "Box") {
      return products.filter(val => val.playerId)
    }
    if (category === "Virtual") {
      return products.filter(val => !val.playerId && !val.type)
    }
    if (category === "Desktop") {
      return products.filter(val => val.type)
    }
    if (category === "Wszystkie") {
      return products;
    }
  }



  showSaveAdminBtn(id) {

  }

  ngOnDestroy(): void {
    this.store.dispatch(LayoutActions.hideFilters())
  }

  ngOnInit(): any {
  
    if (history.state.data) {
      this.selectedProductNr = history.state.data;
    }
    this.store.dispatch(LayoutActions.showFilters())


    if (this.role === "eminto") {
      this.store2.dispatch(ProductPageActions.getProductsList());
      this.columns = [
       
        { name: 'Nr eminto', dataKey: 'emintoId', position: 'left', isSortable: true, type: 'nr' },
        { name: 'Login', dataKey: 'login', position: 'left', isSortable: true, type: 'string' },
        { name: 'Hasło', dataKey: 'password', position: 'left', isSortable: true, type: 'pass' },
        { name: 'Podłącz', dataKey: 'action', position: 'left', isSortable: false, type: 'add-admin' },
        { name: 'Gabinet', dataKey: 'dentist', position: 'left', isSortable: false, type: 'dentistNr' },
        { name: 'Status', dataKey: 'status', position: 'left', isSortable: true, type: 'status' },
        { name: 'Pozostały czas', dataKey: 'testsDays', position: 'left', isSortable: true, type: 'date' },
        { name: 'Dystrybutor', dataKey: 'contractors', position: 'left', isSortable: true, type: 'contractors' },
        { name: 'Handlowiec', dataKey: 'seller', position: 'left', isSortable: true, type: 'sell' },
        { name: 'Akcje', dataKey: 'action', position: 'left', isSortable: false, type: 'product-action' },
        { name: 'Nazwa playera', dataKey: 'playerName', position: 'streat', isSortable: false, type: 'string' },
        { name: 'Id', dataKey: 'playerId', position: 'left', isSortable: true, bold: true, type: 'id' },
        { name: 'Rozliczenie', dataKey: 'Fv', position: 'left', isSortable: false, type: 'string' },

      ]
    }
    if (this.role === 'director' || this.role === 'admin') {
      this.store2.dispatch(ProductPageActions.getProductsListWithoutDistributors());
      this.columns = [
        { name: 'Id', dataKey: 'playerId', position: 'left', isSortable: true, bold: true, type: 'id' },
        { name: 'Handlowiec', dataKey: 'account', position: 'left', isSortable: true, type: 'sell'  },
        { name: 'Nr eminto', dataKey: 'emintoId', position: 'left', isSortable: true, type: 'nr' },
        { name: 'Nazwa playera', dataKey: 'playerName', position: 'streat', isSortable: false, type: 'string' },
        { name: 'Login', dataKey: 'login', position: 'left', isSortable: true, type: 'string' },
        { name: 'Gabinet', dataKey: 'dentist', position: 'left', isSortable: false, type: 'dentistNr' },
        { name: 'Status', dataKey: 'status', position: 'left', isSortable: false, type: 'status' },
        { name: 'Czas do końca eventu', dataKey: 'timeToEnd', position: 'left', isSortable: true, type: 'date' },
        { name: 'Rozliczenie', dataKey: 'Fv', position: 'left', isSortable: true, type: 'string' },
        { name: 'Akcje', dataKey: 'action', position: 'left', isSortable: false, type: 'product-action-admin' },
      ]
    }

    if (this.role === 'seller') {
      this.store2.dispatch(ProductPageActions.getProductsListWithoutDistributors());
      this.columns = [
        { name: 'Id', dataKey: 'playerId', position: 'left', isSortable: true, bold: true, type: 'id' },
        { name: 'Nr eminto', dataKey: 'emintoId', position: 'left', isSortable: true, type: 'nr' },
        { name: 'Nazwa playera', dataKey: 'playerName', position: 'streat', isSortable: false, type: 'string' },
        { name: 'Login', dataKey: 'login', position: 'left', isSortable: true, type: 'string' },
        { name: 'Gabinet', dataKey: 'dentist', position: 'left', isSortable: false, type: 'dentistNr' },
        { name: 'Status', dataKey: 'status', position: 'left', isSortable: false, type: 'status' },
        { name: 'Pozostały czas', dataKey: 'timeToEnd', position: 'left', isSortable: true, type: 'date' },
        { name: 'Rozliczenie', dataKey: 'Fv', position: 'left', isSortable: true, type: 'string' },
      ]
    }

  }

}
