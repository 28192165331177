

<div class="container-fluid">
	<div class="row">
		<form [formGroup]="form" (ngSubmit)="submit(form.value)">
			<div class="tab-pane">
				<div class="action-bar p-3 bg-light d-flex mb-3">
					<div class="row g-3 w-100">
						<div class="col">
							<div class="form-floating">
								<input formControlName="name" type="text" class="form-control" id="number-nip" placeholder="Nazwa dystrybutora" />
								<label for="number-nip">Pełna nazwa dystrybutora</label>
							</div>
						</div>
						<div class="col">
							<div class="form-floating">
								<input formControlName="shortName" type="text" class="form-control" id="number-nip" placeholder="Nazwa dystrybutora" />
								<label for="number-nip">Skrócona nazwa dystrybutora</label>
							</div>
						</div>
					</div>
				</div>
				<div class="row">
					<h5 class="section-title">Dane adresowe</h5>
					<div class="col-3">
						<div class="form-floating mb-3">
							<input formControlName="city" type="text" class="form-control" id="apartment_number"  />
							<label for="apartment_number">Miejscowość</label>
						</div>
					</div>
					<div class="col-3">
						<div class="form-floating mb-3">
							<input formControlName="street" type="text" class="form-control" id="street"  />
							<label for="street">Ulica / NR</label>
						</div>
					</div>

					<div class="col-3">
						<div class="form-floating mb-3">
							<input  [dropSpecialCharacters]="false"   mask="00-000" formControlName="postalCode" type="text" class="form-control" id="apartment_number"  />
							<label    for="apartment_number">Kod pocztowy</label>
						</div>
					</div>

					<div class="col-3">
						<div class="form-floating mb-3">
							<input   mask="0000000000"  formControlName="krs" type="text" class="form-control" id="krs"  />
							<label for="krs">Numer KRS</label>
						</div>
					</div>

					<div class="col-3">
						<div class="form-floating mb-3">
							<input mask="000000000"  formControlName="regon" type="text" class="form-control" id="number-nip"  />
							<label for="number-nip">Numer REGON</label>
						</div>
					</div>

					<div class="col-3">
						<div class="form-floating mb-3">
							<input   [dropSpecialCharacters]="false"  mask="000-000-00-00"  formControlName="nip" type="text" class="form-control" id="number-nip"  />
							<label for="number-nip">Numer NIP</label>
						</div>
					</div>

					<div class="col-3">
						<div class="mb-3">
							<select formControlName="state" class="form-select large" id="specificSizeSelect">
								<option [selected]="true">Województwo</option>
								<option>dolnośląskie</option>
								<option>kujawsko-pomorskie</option>
								<option>lubelskie</option>
								<option>lubuskie</option>
								<option>łódzkie</option>
								<option>małopolskie</option>
								<option>mazowieckie</option>
								<option>opolskie</option>
								<option>podkarpackie</option>
								<option>podlaskie</option>
								<option>pomorskie</option>
								<option>śląskie</option>
								<option>świętokrzyskie</option>
								<option>warmińsko-mazurskie</option>
								<option>wielkopolskie</option>
								<option>zachodniopomorskie</option>
							</select>
						</div>
					</div>

					<div class="col-3">
						<div class="form-floating mb-3">
							<input formControlName="country" type="text" class="form-control" id="country" />
							<label for="country">Kraj</label>
						</div>
					</div>

					<div class="col">
						<div class="row">
						
							<div class="col-3">
								<h5 class="section-title">Strona internetowa</h5>

								<div class="withOut mb-3">
									<input formControlName="website" type="text" class="form-control" id="www"  />
									<label for="www"></label>
								</div>
							</div>
							<div class="col-6">
								<h5 class="section-title">Notatki</h5>

								<div class="mb-3">
									<textarea formControlName="note" class="form-control" id="exampleFormControlTextarea1" rows="4" >Notatki</textarea>
								</div>

								<div class="mb-3"></div>
							</div>
						</div>
					</div>
				</div>

				<hr />
				<div class="d-flex justify-content-end mb-3">
					<button [disabled]="form.invalid" type="submit" class="d-none d-sm-inline-block btn btn-primary px-5 ms-3"><i data-feather="save"></i> Zapisz</button>
				</div>
			</div>

		</form>
	</div>
</div>




