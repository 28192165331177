import { Component, Input, OnChanges, SimpleChanges, OnInit, OnDestroy } from '@angular/core';
import { Sort } from '@angular/material/sort';
import * as fromAuth from 'src/app/auth/reducers'
import * as fromDentist from '../../reducers'
import { select, Store } from '@ngrx/store';
import { DentistsPageActions } from '../../actions'
import * as fromContractors from "src/app/contractor/reducers"
import { ContractorPageActions } from "../../../contractor/actions/index";
import { DentistList } from '../../models';
import * as fromLayout from 'src/app/core/reducers'
import { LayoutActions } from 'src/app/core/actions'


@Component({
  selector: 'app-list-dentist',
  styleUrls: ['./dentist-list.component.scss'],
  template: `
  <app-table-filters
            (filterDentist)="setStatus($event)"
            [search]="currentSearchTerm || 'Wyszukaj po dowolnych parametrach'"
            [showDentistFilters]="showFilters$ | async"
            [currentStatus]="currentDentistStatus"
            (filter)="filterData($event)"
        ></app-table-filters>

  <app-data-table
  [role]="role$ | async"
  [tableData]="data"
  [tableColumns]="columns"
  (sort)="sortData($event)"
></app-data-table>
`
})
export class ListDentistComponent implements OnChanges, OnInit, OnDestroy {
  @Input() role : string;
  @Input() d: DentistList;
  @Input() currentDentistStatus: string;
  @Input() currentSearchTerm: string;


  data;
  allData;
  currentStatus: string;
  curentSearch: string;
  isSearchResults: boolean = false;

  role$ = this.store.pipe(select(fromAuth.selectUserRole));
  showFilters$ = this.store4.pipe(select(fromLayout.selectShowfiltersDentist));


  constructor(
    private store2: Store<fromDentist.State>,
    private store: Store<fromAuth.State>,
    private store3: Store<fromContractors.State>,
    private store4: Store<fromLayout.State>,

  ) { }
  





  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.d?.firstChange  && changes.d?.currentValue ) {
      this.data = changes.d.currentValue;
      this.allData = changes.d.currentValue;
      if (this.currentStatus) {
        this.filterDentistByStatus(this.currentStatus);
      }
      if (this.curentSearch) {

        this.filterDataBySearchTerm(this.curentSearch);
      }

    }


    if (changes.currentDentistStatus?.currentValue) {
      this.currentStatus = changes.currentDentistStatus?.currentValue;
      if (this.data) {
        this.filterDentistByStatus(changes.currentDentistStatus.currentValue)
      }
    }


    if (changes.currentSearchTerm?.currentValue) {
      this.curentSearch = changes.currentSearchTerm?.currentValue;
      if (this.data) {
        this.filterDataBySearchTerm(changes.currentSearchTerm.currentValue)
      }
    }


    if (changes.currentSearchTerm?.currentValue === null && !changes.d) {
      this.curentSearch = changes.currentSearchTerm?.currentValue;
      this.isSearchResults = false;
      if (this.currentStatus) {
        this.filterDentistByStatus(this.currentStatus);
      } else {
        this.data = this.allData;
      }
    }

    if (changes.currentDentistStatus?.currentValue === null && !changes.d) {
      this.currentStatus = changes.currentSearchTerm?.currentValue;
      if (this.isSearchResults) {
        this.filterDataBySearchTerm(this.curentSearch);
      } else {
        this.data = this.allData;
      }

    }

      if (changes.role?.currentValue === 'eminto') {
        this.store2.dispatch(DentistsPageActions.getDentists());
      }
      if (changes.role?.currentValue === 'admin' || changes.role?.currentValue === 'director') {
        this.store2.dispatch(DentistsPageActions.getDentistsWithoutContractors());
        this.store3.dispatch(ContractorPageActions.getPersonsByContractor());
      }
      if (changes.role?.currentValue === 'seller') {
        this.store2.dispatch(DentistsPageActions.getDentistsWithoutContractors());
      }
 
    }



  columns = [

    { name: 'Region', dataKey: 'state', position: 'left', isSortable: true },
    { name: 'Miejscowość', dataKey: 'city', position: 'left', isSortable: true },
    { name: 'Kontrahent', dataKey: 'contractors', position: 'left', isSortable: false, type: 'dentist-contractors' },
    { name: 'Opiekun', dataKey: 'account', position: 'left', isSortable: true, type: 'sell' },
    { name: 'Booking', dataKey: 'bookingDays', position: 'left', isSortable: false, type: 'booking' },
    { name: 'Nazwa Gabinetu', dataKey: 'shortName', position: 'left', isSortable: true, type: 'shortName' },
    //{ name: 'Adres email', dataKey: 'email', position: 'left', isSortable: true, type: 'number' },
    //{ name: 'Nr.Telefonu', dataKey: 'phone', position: 'left', isSortable: true, type: '' },
    { name: 'Osoba kontaktowa', dataKey: 'name', position: 'left', isSortable: false, type: 'contact' },
    { name: 'Status', dataKey: 'status', position: 'left', isSortable: true, type: 'status' },
    { name: 'Pozostały czas', dataKey: 'timeToEnd', position: 'left', isSortable: true, type: 'date' },
    { name: 'Nr.eminto', dataKey: 'emintoId', position: 'left', isSortable: false, type: 'nr-inDesntis' },
  ];


  sortData(sortParameters: Sort) {
    let keyName = sortParameters.active;
    if (keyName === "contractors") {
      keyName = "distributor"
    }

    if (keyName === "timeToEnd") {
      keyName = "testsDays"
    }


    if (keyName === "seller") {
      keyName = "account"
    }
    let d = this.data.filter(item => item[keyName]);
    let empty = this.data.filter(item => !item[keyName]);
    if (sortParameters.direction === 'asc') {

      if (empty) {
        this.data = d.slice().sort((a, b) => {
          return a[keyName].toString().toLowerCase()?.localeCompare(b[keyName].toString().toLowerCase())
        })
        this.data = [...this.data, ...empty]
      } else {
        this.data = this.data.slice().sort((a, b) => {
          return a[keyName].toString()?.localeCompare(b[keyName].toString())
        });
      }

    } else if (sortParameters.direction === 'desc') {
      if (empty) {
        this.data = d.slice().sort((a, b) => b[keyName].toString().toLowerCase()?.localeCompare(a[keyName].toString().toLowerCase()));
        this.data = [...empty, ...this.data]
      }
    } else {
      return this.data;
    }
  }



  setStatus(event) {
     this.store4.dispatch(LayoutActions.setCurrentStatus({status : event }))
  }


  filterDentistByStatus(status: string) {
  
    if (status === "Wyczyść") {
      this.store4.dispatch(LayoutActions.removeCurrentStatus())
      this.data = this.allData;
      this.currentStatus = undefined;
    } else {
      const { length, [length - 1]: astrix } = status.split('')
      if (astrix === "*") {
        let inComplateStatus = status.split('')
          .filter(val => val !== "*")
          .join("")
       
        if (this.isSearchResults) {
          this.data = this.data.filter(val => val.status === inComplateStatus && val.bankTransferIncomplete);
        } else {
          this.data = this.allData;
          this.data = this.data.filter(val => val.status === inComplateStatus && val.bankTransferIncomplete);
        }
      } else { 
      this.currentStatus = status;
      //this.store4.dispatch(LayoutActions.setCurrentStatus({ status }))
        if (this.isSearchResults) {
          this.data = this.allData;
          this.filterDataBySearchTerm(this.currentSearchTerm);
        this.data = this.data.filter(val => val.status === status && !val.bankTransferIncomplete);
      } else {
        this.data = this.allData;
        this.data = this.data.filter(val => val.status === status && !val.bankTransferIncomplete);
      }
    }
    }
  }



  filterDataBySearchTerm(search) {
   
    this.isSearchResults = true;
    this.store4.dispatch(LayoutActions.setSearchTerm({ search }))
    this.data = this.allData;
    if (search === 'clear' ) {

      this.store4.dispatch(LayoutActions.removeSearchTerm())
      this.data = this.allData;
      if (this.currentStatus) {

        this.data = this.data.filter(val => val.status === this.currentStatus)
      }
    } else {
      this.data = this.allData;
      let filteredData = [];
      this.data.forEach(val => {
        if (Object.values(val).some(value => {
          if (typeof value === 'object' && value !== null) {
            if (Object.values(value).some(item => item.toString().trim().toLowerCase().indexOf(search.trim().toLowerCase()) !== -1)) {
              return true
            }
          } else
            if (value) {
              return value.toString().trim().toLowerCase().indexOf(search.trim().toLowerCase()) !== -1;
            }
        })) {
          filteredData.push(val);
        }
      });
      if (this.currentStatus) {
        const { length, [length - 1]: astrix } = this.currentStatus.split('')
        if (astrix === "*") {
          let inComplateStatus = this.currentStatus.split('')
            .filter(val => val !== "*")
            .join("")

          filteredData = filteredData.filter(val => val.status === inComplateStatus && val.bankTransferIncomplete);
        } else {
          filteredData = filteredData.filter(val => val.status === this.currentStatus);
        }   
      }
      this.data = filteredData;
    }
  }
  

  num: number;
  filterData(search) {
    this.store4.dispatch(LayoutActions.setSearchTerm({ search }))
    if (search === 'clear' || search === "") {
     this.store4.dispatch(LayoutActions.removeSearchTerm())
      }
  }


  ngOnInit(): void {
    this.store4.dispatch(LayoutActions.showDentisFilter())
  }

  ngOnDestroy(): void {
    this.store4.dispatch(LayoutActions.hideDentisFilter())
  }
}
