<div *ngIf="steps" ngbDropdownMenu aria-labelledby="dropdown-step1" class="p-4">
  <p ngbDropdownToggle (click)="stepDropDownClose($event.target)" class="text-end close_drop mb-0">
    <i class="fa-regular fa-circle-xmark"></i>
  </p>
  <ul class="p-0 m-0">    
    <li
    [ngClass]="stepNumber > i ? 'notActive' : ''"
    *ngFor="let step of stepsArray.controls; index as i" class="py-2 m-0 d-flex align-items-center flex-wrap">
      <input  [formControl]="stepsArray.controls[i]"  #checkbox class="me-2" type="checkbox" [value]="steps[i].value" [attr.data-days]="steps[i].addDays"
        [attr.data-next]="steps[i].goToNextStep" (change)="selectStepVariant(
                $event.target,
                steps[i].showDatePicker,
                steps[i].calendattitle,
                steps[i].incrementStepVatiant)" />
      <span>{{ steps[i].displayName  ? steps[i].displayName : steps[i].value  }}</span>
      <app-calendar *ngIf="steps[i].showDatePicker" (date)="setDate($event)"></app-calendar>
      <p class="brake m-0"></p>
      <p *ngIf="steps[i].showDatePicker && stepSelectedValue === steps[i].value   "   [ngStyle]="{'opacity' : selectedDate ? '1' : '0' }"  class="text-center mb-0 badge bg-primary">
        {{ selectedDate | date : "dd/M/yyyy" }}
      </p> 
  </ul>
  <div class="text-center mt-3">
    <button [disabled]="!isStepValid" (click)="acceptStep(leadId)" class="btn btn-primary active">
      Zatwierdź
    </button>
  </div>
</div>