import {createAction, props} from '@ngrx/store';



export const resetPasswordSuccess = createAction('[Reset Password/API] Reset Password Success');

export const resetPasswordFailure = createAction(
    '[Reset Password /API] Reset Password Failure',
    props<{error}>()
);

export const checkLinkSuccess = createAction(
  '[Reset Password/API] Check Link Success');

export const checkLinkFailure = createAction(
  '[Reset Password /API]Check Link Failure',
  props<{ error }>()
);


export const resetChangePassworsSuccess = createAction(
  '[Reset Password/API] Reset Change  Password Success'
);

export const resetChangePasswordLoggedSuccess = createAction(
  '[Reset Password/API] Reset Change  Password Logged Success'
);




export const resetChangePasswordFailure = createAction(
  '[Reset Password /API] Change  Password Failure',
  props<{ error }>()
);




