
<ng-template #modal let-modal>
  <div class="modal-header">
    <h4 *ngIf="bottomSlidePopup" class="modal-title" id="modal-basic-title">{{  bottomSlidePopup }}</h4>
    <h4   *ngIf="msg" class="modal-title" id="modal-basic-title">{{  msg }}</h4>

  </div>
  <div class="modal-body">

  </div>
  <div class="modal-footer">

    <button type="button" class="btn btn-primary" (click)="modalClose()">Ok</button>
  </div>
</ng-template>
