import { AfterViewInit, Component, EventEmitter, Input, Output } from "@angular/core";
import * as Feather from 'feather-icons';
import { NavLink } from "../../models/nav-link.model";

@Component({
    selector: 'app-section-nav',
    templateUrl: './section-nav.component.html',
    styleUrls: ['./section-nav.component.scss']
})
export class SectionNavComponent implements AfterViewInit {
    
    @Input() navLinks: NavLink[];
    @Input() view: boolean;
    @Output() navClick = new EventEmitter<NavLink>();

    ngAfterViewInit() {
        Feather.replace();
    }
}