import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import mediaData from "./media.json"
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-invite',
  styleUrls: ['./invite.component.scss'],
  templateUrl: './invite.component.html'
})



export class  InviteComponent implements OnInit {


  title = 'appBootstrap';
  imgSrc: string;
  closeResult: string;
  link: string;
  constructor(private modalService: NgbModal) { }
  open(content, img, link) {

    this.imgSrc = img.getAttribute('src');
    this.link = link.getAttribute('href');




    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {

      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });

  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }


  media: object[];
  ngOnInit(): void {
    this.media = mediaData;

  }





}
