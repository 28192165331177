import { createAction, props } from '@ngrx/store';
import { AppDesktop, EmintoBox } from '../models/products.models';



export const addProductBox = createAction(
  '[Products Api] Add Product Box',
  props<{ emintoBox: EmintoBox  }>()
);
export const changeProductCategory = createAction(
  '[Products Api] Chang Product Category',
  props<{ category : string  }>()
);
export const addProductDesktop = createAction(
  '[Products Api] Add Product Desktop',
  props<{ id: string }>()
);
export const getProductsList = createAction(
  '[Products Api] Get Products List');





export const getProductsListWithoutDistributors = createAction(
  '[Products Api] Get Products List  Without Distributors');


export const addAdmin = createAction(
  "[Products Api] Add Admin",
  props<{ productId: string}>()
);

export const setPassword = createAction(
  "[Products Api]  set Password",
  props<{id:string, pass: string}>());



export const clearPlayer = createAction(
  "[Products Api]  clearPlayer",
  props<{ id: string, remove: boolean  }>());

export const addPlayerToSeller = createAction(
  "[Products Api]  Add Player To Seller",
  props<{ contractorId: string, productId : string, personId? : string, role? : string }>());




export const orderProducts = createAction(
  "[Products Api] Order Products",
  props<{ name: string, qty: string, msg?:string }>()
);


export const AddDAysToProduct = createAction(
  "[Products Api] Add  Days",
  props<{productId: string }>()
);


export const removePlayer = createAction(
  "[Products Api]  Remove Player",
  props<{ id: string }>());

export const removeDistributor = createAction(
  "[Product Page] Remove Distributor From Player",
  props<{ id: string }>()
)







