import { AfterViewInit, Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { NavLink } from "src/app/common/models/nav-link.model";
import { Store, select } from '@ngrx/store';
import * as fromTest from "../reducers";
import { QuizService } from "../services/quiz.services";
import { Subscription } from "rxjs";
import { TestPageActions } from "../actions/index";

@Component({
    selector: 'app-test-page',
    template: `
    <app-test
[quiz]="quiz1$ | async"
[quiz2]="quiz2$ | async"
[quiz3]="quiz3$ | async"

[s]="status$ | async"

>

</app-test>
    `
})
export class TestPageComponent implements OnInit, OnDestroy {
  quiz1$ = this.store.pipe(select(fromTest.selectQuiz1));
  quiz2$ = this.store.pipe(select(fromTest.selectQuiz2));
  quiz3$ = this.store.pipe(select(fromTest.selectQuiz3));
  status$ = this.store.pipe(select(fromTest.selectStatus));
  level$ = this.store.pipe(select(fromTest.selectedLevel));


 
  results$;
  data = [];
  constructor(
    private  testService: QuizService,
    private store: Store<fromTest.State>,
       
  ) { }

 

  ngOnDestroy() {
     
  }
  ngOnInit(): void {
 

    
   

      


    }
 }
