import { Component, OnInit, Input, Output, EventEmitter, ElementRef, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import {  ProductsListCategory} from "../../models/products.models";
import { ActivatedRoute } from "@angular/router";
import { Dentist } from "../../../dentist/models";
import { Contractor, Person } from "../../../contractor/models";
import { DentistsPageActions } from "../../../dentist/actions";
import { Store } from "@ngrx/store";

@Component({
  selector: "app-add-products",
  styleUrls: ["./add-products.component.scss"],
  templateUrl: "./add-products.component.html"
})
export class AddProductsComponent implements OnInit {

  @Input()
  dentist: Dentist[];
  @Input()
  contractors: Contractor[];
  @Input()
  persons: Person[];
  @Output()
  submitProduct = new EventEmitter();
  @Output()
  changeContractor = new EventEmitter();
  @ViewChild('password') password: ElementRef;
  constructor(
    private route: ActivatedRoute,
    private store: Store,
  ) {
  }


  selectedProduct: string;
  productId: string;
  currentId: string;
  categories = Object.values(ProductsListCategory);
  form = new FormGroup({
 
    playerId: new FormControl("", [Validators.required]),
    emintoId: new FormControl("", [Validators.required, Validators.minLength(3), Validators.maxLength(15)]),
    playerName: new FormControl("", [Validators.required]),
    login: new FormControl("", [Validators.required, Validators.email]),
    password: new FormControl("", [Validators.required, Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?}{}+`<>:()|_!@$%^&*-]).{8,}$')     ]),
  });
  form2 = new FormGroup({
   /* id: new FormControl("", [Validators.required]),*/

    playerName: new FormControl("", [Validators.required]),
    login: new FormControl("", [Validators.required, Validators.email]),
    password: new FormControl("", [Validators.required, Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?}{}+`<>:()|_!@$%^&*-]).{8,}$') ]),
  });
  form3 = new FormGroup({
   

    quantity: new FormControl(null, [Validators.required]),
    contractor: new FormControl(null, [Validators.required]),
    person: new FormControl(null, [Validators.required]),
    dentist: new FormControl(null, [Validators.required]),

  });




  selectDentistBySeller(sellerId: string) {
    this.store.dispatch(DentistsPageActions.getDentistsBySeller({ id: sellerId }))
  }




  



  selectProduct(event) {
   
   
    this.selectedProduct = event;
    switch (this.selectedProduct) {
    case "Eminto Box":
      //this.form.get("id").setValue(this.currentId);
      break;
    case "Virtual":
      //this.form2.get("id").setValue(this.currentId);
      break;
    case "Aplikacja desktop":
      //this.form3.get("id").setValue(this.currentId);
      break;
    }
  }

  setPassword(type) {
    const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const specialchars = "!@#$%^&*()_+|}{:?><`!<>?";
    const charsSmall = "qwertyuioplkjhgfdsazxcvbnm";
    const numbers = "1234567890";




    let password = "";

    const randomLeater = Math.floor(Math.random() * chars.length);
    password += chars.substring(randomLeater, randomLeater + 1);
    const randomChar = Math.floor(Math.random() * specialchars.length);
    password += specialchars.substring(randomChar, randomChar + 1);

    for (let i = 0; i < 7; i++) {
      let r = Math.floor(Math.random() * charsSmall.length);
      password += charsSmall.substring(r, r + 1);
    }
    const randomNumber = Math.floor(Math.random() * numbers.length);
    password += numbers.substring(randomNumber, randomNumber + 1);

    if (type === 'box') {
      this.form.get('password').setValue(password);
    }
    if (type === 'virtual') {
      this.form2.get('password').setValue(password);
    }
  }







  selectContractor(contractor) {
    this.changeContractor.emit(contractor);
  }

  submit(form) {
    this.submitProduct.emit(form);
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.currentId = params.get("id");
      if (this.currentId) {
        this.selectedProduct = "aplikacja desktop";
      }
    });
  }
}
