import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ApiService } from '.';
import { Contractor, ContractorOptions, Person } from '../models/contractor.model';



@Injectable()
export class FakeService implements ApiService {
   getProfile(): Observable<any> {
     return of('')
  }
  getSinglePerson(): Observable<any> {
    return of('')
  }


  addContractor(contractor: Contractor): Observable<any> {
    return of("contractor")
  }
  addPerson(person: Person) {
    return of("person")
  }
  updateContractor(contractor: Contractor, id: string): Observable<any>  {
    return of("person")
  }

  updatePerson(person: Person, id: string): Observable<any> {
    return of("person")
  }

  getContractorOptions(): Observable<ContractorOptions[]> {
    const result: ContractorOptions[] = [
      {
        shortName: "piotr",

        _id: "11111111111",

      },
      {
        shortName: "ola",
       _id: "11111111111",

      }
    ]

    return of(result)
  }




  getContractor(): Observable<Contractor[]> {
    const result: Contractor[] = [
      {
        name: "piotr",
        shortName: "piotr shore",
        city: 'warszawa',
        street: "kochanowskiego 1",
        postalCode: "01-864",
        krs: 121212,
        regon: 1111111,
        nip: '22221111111',
        state: "mazowieckie",
        country: 'Polska',
        website: 'wwwww',
        note: "jakas notatka",
        _id: "11111111111",
      
      },
      {
        name: "ola",
        shortName: "ola shore",
        city: 'warszawa',
        street: "kochanowskiego 1",
        postalCode: "01-864",
        krs: 121212,
        regon: 1111111,
        nip: '1111111',
        state: "mazowieckie",
        country: 'Polska',
        website: 'wwwww',
        note: "jakas notatka",
        _id: "11111111111",
       
      }
   
     

    ]

    return of(result);
  }


  getPersons(): Observable<Person[]> {
    let mock: Person[] = [
      {

        contractor: 'badge bg-danger',
        shortName: "badge",
        name: 'nick',
        surname: 'nicnic',
        phone: 22332332,
        email: 'piotr@getMaxListeners.com',
        state: 'Mazowieckie',
        country: 'Polska',
        note: 'jakas notatka',
        city: 'Warszawa',
        distributorId: '123123123',
        type : "ddd"


      },
      {

        contractor: 'badge bg-danger',
        shortName: "badge",
        name: 'nick',
        surname: 'nicnic',
        phone: 22332332,
        email: 'piotr@getMaxListeners.com',
        state: 'Mazowieckie',
        country: 'Polska',
        note: 'jakas notatka',
        city: 'Warszawa',
        distributorId: '123123123',
        type: "ccc"
      }


    ]
    return of(mock);


  }
}
