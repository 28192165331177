import { createAction, props } from "@ngrx/store";



export const sendFromSuccess = createAction(
  '[Market API] Send Form Success',

);
export const sendFromFailure = createAction(
  '[Market API] Send Form Failure',
  props<{ error: any }>()
);




