import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { apiUrl } from 'src/app/common/api-url';
import { ApiService } from './api.services';
import { catchError, map } from 'rxjs/operators';
import { HttpParams, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { IFormData } from '../models/offers.model';
/**
 * A  fake service for sending form data.
 */
@Injectable({
  providedIn: 'root',
})
export class FakeService implements ApiService {

 // apiUrl = 'https://website-api.eminto.pl/'
 //apiUrl = 'http://localhost:7070/'
  constructor(private http: HttpClient) { }



  sendForm(form: IFormData, accountinfo? : any): Observable<any> {
    
    let params = new HttpParams()
       .set('dentistName', form.dentistName!)
       .set('name', form.name!)
       .set('surname', form.surname!)
       .set('email', form.email!)
       .set('phone', form.phone!)
       .set('accountName', form.account.accountName!)
       .set('accountEmail', form.account.accountEmail!)
       .set('accountPhoneNumber', form.account.accountPhoneNumber!)
     
    return this.http.post(apiUrl + 'offer/create-offer', params)
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(() => error);
        })
      );
  }

 
  
}
