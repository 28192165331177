
import { ProductApiActions, ProductPageActions } from "../actions"
import { createReducer, on } from '@ngrx/store';
import { EmintoBox } from "../models/products.models";


export const productsPageFeatureKey = 'produtPage';

export interface State {
  products: EmintoBox[] | null;
  error: string | null;
  productsCat:  string,
  loading: boolean;
  productsList: any;
 }

export const initialState: State = {
  products: null,
  error: null,
  loading: false,
  productsCat: "Box",
  productsList: [],
};

export const reducer = createReducer(
  initialState,
  on(ProductPageActions.addProductBox,
    (state) => ({ ...state, loading: true })),
    on(ProductPageActions.changeProductCategory,
      (state, action ) => ({ ...state, productsCat: action.category })),

  on(ProductApiActions.addProductsFailure,
    (state, actions) => ({ ...state, error: actions.error, loading: false })),
  on(ProductApiActions.addProductSuccess,
    (state) => ({ ...state, loading: false })),
  on(ProductPageActions.getProductsList,
    (state) => ({ ...state, loading: true })),
  on(ProductApiActions.getProductsSuccess,
    (state, action) => ({
      ...state,
      productsList:  [...action.products], loading: false
    })),
  on(ProductApiActions.getProductsFailure,
    (state) => ({ ...state, loading: false })),

);


export const selectError = (state: State) => state.error;
export const selectLoading = (state: State) => state.loading;

export const selectProductsCat = (state: State) => state.productsCat;
export const selectProducts = (state: State) => state.products;
export const selectList = (state: State) => state.productsList;
export const selectCategory = (state: State) => state.productsCat;
