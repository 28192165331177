




<ul  *ngIf="!view" class="nav nav-tabs mb-3" id="nav-tab" role="tablist">
    <li class="nav-item" *ngFor="let nav of navLinks; let i = index" (click)="navClick.emit(nav)">
      <a class="nav-link" [ngClass]="{'active': i === 0}" href="#" id="pills-add-tab" data-bs-toggle="pill" data-bs-target="#pills-add" role="tab" aria-controls="pills-add" aria-selected="false"><i [attr.data-feather]="nav.icon"></i> {{ nav.label }}</a>
    </li>



</ul>
