import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

import { LayoutActions } from "../../core/actions/index";
import { LeadsApiService } from '../services';
import { ISingleLead, ISingleLeadDetails } from '../models';
import { LeadsApiActions, LeadsPageActions } from '../actions';


@Injectable()
export class LeadsEffects {
  constructor(
    private store: Store,
    private actions$: Actions,
    private apiService: LeadsApiService,
    private router: Router
  ) { }


    getLeads$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LeadsPageActions.getLeads),
      switchMap(() => {
        return this.apiService.getLeads().pipe(
          map((res: ISingleLead[]) => {
            return LeadsApiActions.getLeadsSuccess({ leads: res });
          }),
          catchError((error: any) => of(LeadsApiActions.getLeadsFaliure({ error })))
        );
      })
    )
  );

  postLead$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LeadsPageActions.postLead),
      switchMap(({ lead, id }) => {
        if (!id) {
          return this.apiService.postLeads(lead).pipe(
            map((res: ISingleLead[]) => {
              return LeadsApiActions.postLeadSuccess();
            }),
            catchError((error: any) => of(LeadsApiActions.postLeadFaliure({ error })))
          );
        } else {
          return this.apiService.postLeads(lead, id).pipe(
            map((res: ISingleLead[]) => {
              return LeadsApiActions.postLeadSuccess();
            }),
            catchError((error: any) => of(LeadsApiActions.postLeadFaliure({ error })))
          );
        }
        
      })
    )
  );

  getLeadDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LeadsPageActions.getLeadDetails),
      switchMap(({ id }) => {
        return this.apiService.getLeadDetails(id).pipe(
          map((res: ISingleLeadDetails) => {
            return LeadsApiActions.getLeadDetailsSuccess({lead : res});
          }),
          catchError((error: any) => of(LeadsApiActions.getLeadDetailsFaliure({ error })))
        );
      })
    )
  );
  AssignLead$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LeadsPageActions.assingPersonTolead),
      switchMap(({ leadId, personId }) => {
        return this.apiService.AssignLead(leadId, personId).pipe(
          map((res) => {
            return LeadsApiActions.assingPersonToleadSuccess()
          }),
          catchError((error: any) => of(LeadsApiActions.assingPersonToleadFaliure({ error })))
        )
    })
    ))

RemoveLead$ = createEffect(() => 
  this.actions$.pipe(
    ofType(LeadsPageActions.removeLead),
    switchMap(({leadsId}) => {
      return this.apiService.removeLead(leadsId).pipe(
        map((res) =>   LeadsApiActions.removLeadeSuccess()),
        catchError((error) => of(LeadsApiActions.removeoleadFaliure({error})))
      )
    })
  )
)

removeLeadSuccess$ = createEffect(() => 
  this.actions$.pipe(
    ofType(LeadsApiActions.removLeadeSuccess),
      map(() => {
        this.store.dispatch(LeadsPageActions.getLeads())
      })
      ),
      { dispatch: false }
  );

  changeStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LeadsPageActions.changeLeadStatus),
      switchMap(({ id, statusId }) => {
        return this.apiService.changeLeadStatus(id, statusId).pipe(
          map((res) => {
            return LeadsApiActions.changeLeadStatusSuccess()
          }),
          catchError((error: any) => of(LeadsApiActions.changeLeadStatusFaliure({ error })))
        )
      })
    ))

  StatusLeadChangeSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LeadsApiActions.changeLeadStatusSuccess),
      map(() => {
        this.store.dispatch(LeadsPageActions.getLeads())
      })
    ),
    { dispatch: false }
  );



  assingPersonToLeadSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LeadsApiActions.assingPersonToleadSuccess),
      map(() => {
        this.store.dispatch(LeadsPageActions.getLeads())
      })
    ),
    { dispatch: false }
  );









  steoUpdate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LeadsPageActions.stepsUpdate),
      switchMap(({ data }) => {
        return this.apiService.stepsUpdate(data).pipe(
          map((res) => {
            return LeadsApiActions.stepsUdateSuccess()
          }),
          catchError((error: any) => of(LeadsApiActions.assingPersonToleadFaliure({ error })))
        )
      })
    ))


  stepUpdateSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LeadsApiActions.stepsUdateSuccess),
      map(() => {
        this.store.dispatch(LeadsPageActions.getLeads())
      })
    ),
    { dispatch: false }
  );

  postSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LeadsApiActions.postLeadSuccess),
      map(() => {
        this.router.navigate(["/leads/lead-list"]);
      })
    ),
    { dispatch: false }
  );
}
