import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";


import { apiUrl } from 'src/app/common/api-url';
import { IOrders } from "../models/orders.model";

@Injectable({
  providedIn: "root"
})
export class OrdersService {
  constructor(private http: HttpClient) { }





  getOrdes(status:string): Observable<IOrders[]> {
    let params = new HttpParams;
    params = params.append('status',status);
    return this.http.post<IOrders[]>(apiUrl + 'products/orders-list', params).pipe(
      map(res => res),
      catchError((error:any) =>  throwError(error.error.error_description)))
  }



  sendOrder(id): Observable<any> {
    let params = new HttpParams;
    params = params.append('id', id);
    return this.http.post(apiUrl +  'products/complete-order' , params).pipe(
      map(res => res),
      catchError((error: any) => throwError(error.error.error_description)))
  }
}
