import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import * as fromOffers from "./reducers"
import { NgxMaskModule, IConfig } from 'ngx-mask'
import { FormsModule } from '@angular/forms';
import { ApiService } from "./services/api.services";
import { MarketsEffects } from "./effects/offers.effects";
import { FakeService } from "./services/fake.service.";
import { OfferRoutingModule } from "./offer-routing.module";
import { CoreModule } from 'src/app/core/core.module';
import { CreateOfferPageComponent } from "./containers/create-offer-page.component";
import { OfferPageComponent } from "./containers/offer-page.component";
import { CreateOfferComponent } from "./components/offer.component";

import { SharedModule } from "../common/shared.module";

export const COMPONENTS = [
  CreateOfferPageComponent,
  OfferPageComponent,
  CreateOfferComponent,

]
const maskConfig: Partial<IConfig> = {
  dropSpecialCharacters: false,
};

@NgModule({
  declarations: [...COMPONENTS],
  imports: [
    SharedModule,
    CoreModule,
    OfferRoutingModule,
    FormsModule,
    NgxMaskModule.forRoot(),
    CommonModule,
    ReactiveFormsModule,
    StoreModule.forFeature(fromOffers.offerFeatureKey, fromOffers.reducers),
    EffectsModule.forFeature([MarketsEffects])

  ],
  providers: [
    //  { provide: ApiService, useClass: FakeService },
      { provide: ApiService, useClass: FakeService }
    ],

 
})
export class OfferModule { }
