import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'inputFormat'
})
export class ImputFormat implements PipeTransform {

    transform(value: any, afterVal: string, isPercent?:boolean) {

if(isPercent) {
    if (value !== undefined && value > 0) {
        return value + `${afterVal}`
    }
}
        if (value !== undefined && value > 0) {        
            return value + " " +  `${afterVal}`
        }
    }
}

