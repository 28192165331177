import { createAction, props } from "@ngrx/store";
import { ISingleLeadDetails, IStepsUpdateData } from "../models";



export const getLeads = createAction(
  "[Leads Page] Get Leads"
);


export const postLead = createAction(
  "[Leads Page] Post Leads",
  props<{ lead: ISingleLeadDetails, id? : string }>()
);

export const getLeadDetails = createAction(
  "[Leads Page] Get Leads Details",
  props<{ id : string }>()
);

export const changeLeadStatus = createAction(
  "[Leads Page] Change Lead Status",
  props<{ id: string, statusId: string }>()
);





export const removeCurrentLead = createAction(
  "[Leads Page] Remove Current Leads Details",

);


export const assingPersonTolead = createAction(
  "[Leads Page] Assign Person To Lead",
  props<{ leadId: string, personId: string }>()
);

export const removeLead = createAction(
  "[Leads Page] Remove Lead",
  props<{leadsId:string}>()
)


export const setCalendatTitle = createAction(
  "[Leads Page] Set Calendar Title ",
  props<{ title : string }>()
);

export const removeCalendatTitle = createAction(
  "[Leads Page] Set Calendar Title "
);

export const  stepsUpdate = createAction(
  "[Leads Page] Steps Update",
  props<{ data: IStepsUpdateData}>()
);

export const  setCurrentPageList = createAction(
  "[Leads Page] Set Current Page List",
  props<{ number: number}>()
);

export const  removeCurrentPageList = createAction(
  "[Leads Page] Remove Current Page List"
);
