
import { createAction, props } from "@ngrx/store";








export const getQuizSuccess = createAction(
  '[Dentists API] Get Quiz Success',
  props<{ quiz: any}>()
);

export const getQuizFailure = createAction(
  '[Dentists API] Get Quiz Failure',
  props<{ error }>()
);

export const getQuizSuccess2 = createAction(
  '[Dentists API] Get Quiz Success Level2',
  props<{ quiz2: any }>()
);

export const getQuizFailure2 = createAction(
  '[Dentists API] Get Quiz Failure',
  props<{ error }>()
);





export const getQuizSuccess3 = createAction(
  '[Dentists API] Get Quiz Success Level3',
  props<{ quiz3: any }>()
);

export const getQuizFailure3 = createAction(
  '[Dentists API] Get Quiz Failure',
  props<{ error }>()
);






export const getQuizStatusSuccess = createAction(
    '[Dentists API] Get Dentists Success',
    props<{status: any}>()
)
export const getQuizStatusFailure = createAction(
    '[Dentists API] Get Dentists Failure',
    props<{error}>()
)



export const sendQuizResultsSuccess = createAction(
    '[Dentists API] Send Results Success',
   
)
export const sendQuizResultsFailure = createAction(
    '[Dentists API] Send Results Failure',
    props<{error}>()
)
