import { IstatusQuiz } from "../models/test.models";

import { createReducer, on } from "@ngrx/store";
import { TestApiActions, TestPageActions } from "../actions";
import { InitialState } from "@ngrx/store/src/models";

export const testPageFeatureKey = "testPage";


export interface State {


  status: any;
  selectedLevel:number | null,
  quizLevel1: any;
  quizLevel2: any;
  quizLevel3: any;
}

export const initialState: State = {
  quizLevel2: null,
  quizLevel1: null,
  quizLevel3: null,
  selectedLevel: -1,
  status: null,
};


export const reducer = createReducer(
  initialState,
  on(
    TestApiActions.getQuizSuccess,
    (state, action) => ({ ...state, quizLevel1: action.quiz })
  ),
  on(
    TestApiActions.getQuizSuccess2,
    (state, action) => ({ ...state, quizLevel2: action.quiz2 })
  ),
  on(
    TestApiActions.getQuizSuccess3,
    (state, action) => ({ ...state, quizLevel3: action.quiz3 })
  ),


  on(
    TestApiActions.getQuizFailure,
    (state, action) => ({ ...state, error: action.error })
  ),
  on(
    TestApiActions.getQuizFailure2,
    (state, action) => ({ ...state, error: action.error })
  ),
  on(
    TestApiActions.getQuizFailure3,
    (state, action) => ({ ...state, error: action.error })
  ),
  on(
    TestApiActions.getQuizStatusFailure,
    (state, action) => ({ ...state, error: action.error })
  ),
  on(
    TestApiActions.getQuizStatusSuccess,
    (state, action) => ({ ...state, status: action.status })
  ),
  on(
    TestPageActions.clearQuizStore,
    (state, action) => initialState
  ),
  on(
    TestPageActions.setQuizLevel,
    (state, action) => ({ ...state, selectedLevel: action.level })
  ),
  on(
    TestPageActions.cancelQuizLevel,
    (state, action) => ({ ...state })
  ),
);


export const selectQuiz1 = (state: State) => state.quizLevel1;
export const selectQuiz2 = (state: State) => state.quizLevel2;
export const selectQuiz3 = (state: State) => state.quizLevel3;
export const selectQuizStatus = (state: State) => state.status;
export const selectedLevel = (state: State) => state.selectedLevel;
