import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { Contractor, ContractorOptions, Person } from "../models/contractor.model";
import { ApiService } from ".";
import { HttpClient, HttpParams } from "@angular/common/http";
import { apiUrl } from "../../common/api-url";
import { LayoutActions } from "../../core/actions";
import { Store } from "@ngrx/store";
import { Router } from "@angular/router";


@Injectable({
  providedIn: "root"
})
export class ContaractorService implements ApiService {
  constructor(
    private router : Router,
    private store : Store,
    private http: HttpClient) { }

  addContractor(contractor: Contractor): Observable<any> {
 
    let params = new HttpParams();
    for (let [key, val] of Object.entries(contractor)) {
      if (val !== "") {
        params = params.append(key, val);
      }
    }
    
    return this.http.post(apiUrl + "distributors/add", params)
      .pipe(map((res) => {
          return res;
        }),
        catchError((error) => {
          return throwError(error.error.error_description);
        })
      );
  }


  updateContractor(contractor: Contractor, id: string): Observable<any> {
    let params = new HttpParams();
    for (let [key, val] of Object.entries(contractor)) {
      if (val !== "") {
        params = params.append(key, val);
      }
    }

    params = params.append("id", id);
    return this.http.post(apiUrl + "distributors/add", params)
      .pipe(map((res) => {
          if (!res) {
            this.store.dispatch(LayoutActions.setError({ error: 'Zmień dane aby edytować ' }));

          } else {
            this.router.navigate(['contractor/list-contractor']);
            return res;
          }
        }),
        catchError((error) => {
          return throwError(error.error.error_description);
        })
      );
  }

  updatePerson(person: Person, id: string): Observable<any> {
    let params = new HttpParams();
    for (let [key, val] of Object.entries(person)) {
      if (val !== "" && val !== "brak") {
        params = params.append(key, val);
      }
    }
   
    params = params.append("id", id);
    return this.http.post(apiUrl + "accounts/add", params)
      .pipe(map((res) => {
          return res;
        }),
        catchError((error) => {
          return throwError(error.error.error_description);
        })
      );
  }


  addPerson(person: Person): Observable<any> {
     
    let params = new HttpParams();
    for (let [key, val] of Object.entries(person)) {
      if (val !== "" && val !== "brak") {
        params = params.append(key, val);
      }
    }

    return this.http.post(apiUrl + "accounts/add", params).pipe(
      map((res: Person[]) => {
        return res;
      }),
      catchError(error => {
        return throwError(error.error.error_description);
      })
    );


  }


  getContractor(): Observable<Contractor[]> {
    let params = new HttpParams();
    params = params.append("key", "val");
    return this.http.post(apiUrl + "distributors/list", params)
      .pipe(
        map((res: Contractor[]) => {
          return res;
        }),
        catchError((error) => {
          return throwError(error.error.type);
        })
      );
  }

  getContractorOptions(): Observable<ContractorOptions[]> {
    let params = new HttpParams();
    params = params.append("key", "val");
    return this.http.post(apiUrl + "accounts/distributors-option", params)
      .pipe(
        map((res: ContractorOptions[]) => {
          return res;
        }),
        catchError((error) => {
          return throwError(error.error.type);
        })
      );
  }


  getPersons(id?: string): Observable<Person[]> {
    
    let params = new HttpParams();
    if (id) {
      params = params.append("distributorId", id);
    }
    if (id === "")
    {
      params = params.append("distributorId", null);
    }
    return this.http.post(apiUrl + "accounts/list", params)
      .pipe(
        map((res: Person[]) => {
          return res;
        }),
        catchError((error) => {
          return throwError(error.error.error_description);
        }));
  }



  getSinglePerson(id: string): Observable<Person> {

    let params = new HttpParams();
  
      params = params.append("id", id);
    
   
    return this.http.post(apiUrl + "accounts/list", params)
      .pipe(
        map((res: Person) => {
          return res;
        }),
        catchError((error) => {
          return throwError(error.error.error_description);
        }));
  }







  getProfile(): Observable<any> {
    let params = new HttpParams();
   
    return this.http.post(apiUrl + "accounts/profile", params)
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error) => {
          return throwError(error.error.type);
        }));


  }




}
