
import { Component,  OnInit } from "@angular/core";
import { Store, select } from '@ngrx/store';
import * as fromAuth from 'src/app/auth/reducers'
import * as fromContractors from 'src/app/contractor/reducers'
import { ContractorPageActions } from "../../actions";
import { ActivatedRoute } from "@angular/router";
import { LayoutActions } from "../../../core/actions";




@Component({
  selector: 'app-contractor-list-page',
  template: `
    <app-list-contractor   *ngIf="role === 'eminto' && !status"></app-list-contractor>
    <app-single-contractor [data]="profile$ | async"  [role]="role"  *ngIf="role !== 'eminto'"></app-single-contractor>
    <app-single-contractor [data]="profile$ | async"  [role] ="role" *ngIf="status"></app-single-contractor>`
})
export class ContractorListPageComponent implements OnInit {

  profile$ = this.stroeContractor.pipe(select(fromContractors.selectProfileFromState));






  constructor(
    private route: ActivatedRoute,
    private stroeContractor: Store<fromContractors.State>,
    private store: Store<fromAuth.State>) {

  }

  role: string;
  status: string;

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.status = params.get("name");
      if (this.status) {

        this.store.dispatch(LayoutActions.showLogoutBtn());
      } else {

        //this.store.dispatch(LayoutActions.hiddenLogoutBtn());
      }
    });

    

    this.store.pipe(select(fromAuth.selectUserRole)).subscribe((role) => {
      this.role = role;
      if (this.role === 'admin ') {
        this.stroeContractor.dispatch(ContractorPageActions.gsetContractors());
      } else {
        this.stroeContractor.dispatch(ContractorPageActions.getProfile());
       
      }
    })
  }




}
