
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { combineLatest, of } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import { ProductApiActions, ProductPageActions } from "../actions"
import * as fromProducts from "../reducers";
import { ProductsService } from "../services/products.service";
import { ContaractorService } from "../../contractor/services/contractor.service";
import { LayoutActions } from "../../core/actions/index";
import { DentistsPageActions } from "../../dentist/actions";


@Injectable()
export class ProductsEffects {




  addProductBox$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductPageActions.addProductBox),
      switchMap(({ emintoBox }) => this.apiService.addProductBox(emintoBox).pipe(
          map(() => ProductApiActions.addProductSuccess()),
          catchError((error: any) => of(ProductApiActions.addProductsFailure({ error })))
        )
      )
    )
  );



  addProductDesktopApp$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductPageActions.addProductDesktop),
      switchMap(({ id }) => this.apiService.addProductDesktopApp(id).pipe(
        map(() => ProductApiActions.addDesktopAppSuccess({id})),
        catchError((error: any) => of(ProductApiActions.addDesktopAppFailure({ error })))
      )
      )
    )
  );

  removeDistributor$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductPageActions.removeDistributor),
      switchMap(({ id }) => this.apiService.removeDistributorFromProduct(id).pipe(
        map(() => ProductApiActions.removeDistributorSussess()),
        catchError((error: any) => of(ProductApiActions.removeDistributorFailure({ error })))
      )
      )
    )
  );
  removeDistributorSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductApiActions.removeDistributorSussess),
      map(() => {
        this.store.dispatch(ProductPageActions.getProductsList());
      })
    ),
    { dispatch: false }
  );

  addAdmin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductPageActions.addAdmin),
      switchMap(({ productId }) => this.apiService.addAdmin(productId).pipe(
        map(() => ProductApiActions.addAdminSuccess()),
        catchError((error: any) => of(ProductApiActions.addAdminFailure({ error })))
      )
      )
    )
  );

  addAdminSuccess$  = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductApiActions.addAdminSuccess),
      map(() => {
        this.store.dispatch(LayoutActions.setDialog({dialog : 'Admin przypisany'}));
      })
    ),
    { dispatch: false }
  );


  clearPlayer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductPageActions.clearPlayer),
      switchMap(({ id, remove }) => this.apiService.clearPlayer(id, remove).pipe(
        map(() => ProductApiActions.clearPlayerSuccess()),
        catchError((error: any) => of(ProductApiActions.clearPlayerFailure({ error })))
      )
      )
    )
  );


  clearPlayerSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductApiActions.clearPlayerSuccess),
      map(() => {
        this.store.dispatch(ProductPageActions.getProductsList());
      })
    ),
    { dispatch: false }
  );


  removePlayer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductPageActions.removePlayer),
      switchMap(({ id }) => this.apiService.removeProducts(id).pipe(
        map(() => ProductApiActions.removePlayerSuccess()),
        catchError((error: any) => of(ProductApiActions.removePlayerFailure({ error })))
      )
      )
    )
  );


  removePlayerSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductApiActions.removePlayerSuccess),
      map(() => {
        this.store.dispatch(ProductPageActions.getProductsList());
      })
    ),
    { dispatch: false }
  );



  AddDesktopAppSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductApiActions.addDesktopAppSuccess),
      map(({ id }) => {
        this.store.dispatch(DentistsPageActions.getDentistDetails({ dentistId: id }));
      })
    ),
    { dispatch: false }
  );





  AddProductsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductApiActions.addProductSuccess),
      map(() => {
        this.router.navigate(["/products/list"]);
      })
    ),
    { dispatch: false }
  );

  //addProductDesktop$ = createEffect(() =>
  //  this.actions$.pipe(
  //    ofType(ProductPageActions.addProductDesktop),
  //    switchMap(({ emintoDesktop }) => {
  //      return this.apiService.addProductBox(emintoDesktop).pipe(
  //        map(() => {
  //          return ProductApiActions.addProductSuccess();
  //        }),
  //        catchError((error: any) => of(ProductApiActions.addProductsFailure({ error })))
  //      );
  //    })
  //  )
  //);

  getProductsListWithoutDistributors$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductPageActions.getProductsListWithoutDistributors),
      switchMap(() => {
        return this.apiService.getProductList().pipe(
          map((data) => {

              return ProductApiActions.getProductsSuccess({ products: data });
            }
          ));
      })

    )
  );

  getProductsList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductPageActions.getProductsList),
      switchMap(() => {
        return combineLatest([
          this.apiServiceContractor.getContractor(),
          this.apiService.getProductList()]).pipe(
            map((data) => {
                let t = data[1].map(product => {
                  return {
                    ...product,
                    contractors: data[0] 
                  }
                });
                return ProductApiActions.getProductsSuccess({ products: t });
            }
        ));
      })

    )
  );

  addProductToSeller$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductPageActions.addPlayerToSeller),
      switchMap(({ contractorId, productId, personId, role }) => {
        if (role === "director" || role === "admin") {
          return this.apiService.addPlayerToSeller(contractorId, productId, personId).pipe(
            map((data) => {
              
              return ProductApiActions.addPlayerSuccessByAdmin();
            }
            ),
            catchError((error: any) => of(ProductApiActions.addProductsFailure({ error })))
          );
        } 
        return this.apiService.addPlayerToSeller(contractorId, productId, personId)
       .pipe(
         map((data) => {
            
              return ProductApiActions.addPlayerSuccess();
            }
         ),
          catchError((error: any) => of(ProductApiActions.addProductsFailure({ error })))
          );
      })

    )
  );

  addProductToSellerSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductApiActions.addPlayerSuccess),
      map(() => {
        this.store.dispatch(ProductPageActions.getProductsList());
      })
    ),
    { dispatch: false }
  );
  addProductToSellerSuccessByAdmin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductApiActions.addPlayerSuccessByAdmin),
      map(() => {
        this.store.dispatch(ProductPageActions.getProductsListWithoutDistributors());
      })
    ),
    { dispatch: false }
  );




  addProductToSellerError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductApiActions.addProductsFailure),
      map(({ error }) => {
        this.store.dispatch(LayoutActions.setError({ error: error }));
      })
    ),
    { dispatch: false }
  );

  orderProducts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductPageActions.orderProducts),
      switchMap(({ name, qty,  msg }) => {
        return this.apiService.orderProducts(name, qty, msg)
          .pipe(
            map((data) => {
                return ProductApiActions.orderProductsSuccess();
              }
            ),
            catchError((error: any) => of(ProductApiActions.orderProductsFailure({ error })))
          );
      })

    )
  );

  addDays = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductPageActions.AddDAysToProduct),
      switchMap(({ productId }) => {
        return this.apiService.addDays(productId)
          .pipe(
            map((data) => {
              return ProductApiActions.adddaysSuccess();
            }
            ),
            catchError((error: any) => of(ProductApiActions.addDaysFailure({ error })))
          );
      })

    )
  );


  addDaysSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductApiActions.adddaysSuccess),
      map(() => {
        this.store.dispatch(ProductPageActions.getProductsList());
        
      })
    ),
    { dispatch: false }
  );





  orderProductSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductApiActions.orderProductsSuccess),
      map(() => {
        this.store.dispatch(LayoutActions.setDialog({ dialog: 'Zamówienie zostało przekazane do realizacji' }));
        //this.store.dispatch(ProductPageActions.getProductsList());
         this.router.navigate(['products/list']);
      })
    ),
    { dispatch: false }
  );

  generatePassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductPageActions.setPassword),
      switchMap(({ id, pass }) => {
        return this.apiService.generatePassword(id, pass).pipe(
          map(({ products }) => {
            return ProductApiActions.setPasswordSuccess();
          }),
          catchError((error: any) => of(ProductApiActions.setPasswordFailure({ error })))
        );
      })
    )
  );


 







  generatePasswordSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductApiActions.setPasswordSuccess),
      map(() => {
        this.store.dispatch(LayoutActions.setDialog({ dialog: 'Hasło zostało zmienione.' }));
        //this.store.dispatch(ProductPageActions.getProductsList());
      
      })
    ),
    { dispatch: false }
  );

  constructor(
    private store: Store<fromProducts.State>,
    private apiServiceContractor: ContaractorService,
    private actions$: Actions,
    private apiService: ProductsService,
    private router: Router
  ) {
  }
}
