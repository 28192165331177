
import { Component, EventEmitter, Input, Output, OnChanges, SimpleChanges, ViewChild, ElementRef, HostListener, } from "@angular/core";


import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import { Person } from "src/app/contractor/models";
import { IFormData } from "../models/offers.model";

@Component({
  selector: 'app-create-offer',
  templateUrl: './offer.component.html',
  styleUrls: ['./offer.component.scss'],
})
export class CreateOfferComponent implements OnChanges {


  currentDistributor: string = ""
  person : Person;
  name: string = "Anna MAJCHEREK-LEWANDOWSKA, Klinika stomatologii zachowawczej i estetycznej Biały Kasztan.";
  reChaptaValid: boolean = false;

  @Input()
  video: string | null = null;
  @Input()
  isFormSend : boolean | null; 
  @Input()
  data: any

  @Output() sendFormData = new EventEmitter<any>();



  ngOnChanges(changes: SimpleChanges): void {
    let data: Person | undefined = changes['data']?.currentValue;
    let isFormSend = changes['isFormSend'];
    debugger
    if (isFormSend?.currentValue && !isFormSend?.firstChange)  {
     this.form.reset();
      let name = `${this.person.name}  ${this.person.surname}`;
      this.setAccountData(this.person, name) 
    }
   
    if (data) {
      this.person = data;
      let name = `${this.person.name}  ${this.person.surname}`;
      this.setAccountData(this.person, name) 
    }
  }
  setAccountData(person: Person, name : string )  {
    this.form.get('account').setValue(name);
    this.form.get('accountEmail').setValue(this.person.email);
    this.form.get('accountPhoneNumber').setValue(this.person.phone);
  }


  onSubmitForm(form: any) {

    let data: IFormData  =   {
      dentistName: form.dentistName,
      name: form.name,
      surname: form.surname,
      email: form.email,
      phone: form.phone, 
      account: {
        accountName :  form.account,
        accountEmail : form.accountEmail,
        accountPhoneNumber : form.accountPhoneNumber
      }
    }
    this.sendFormData.emit(data)
  }

  constructor(

  ) { }


  form = new FormGroup({
    name: new FormControl("", [Validators.required]),
    surname: new FormControl("", [Validators.required ]),
    email: new FormControl("", [Validators.required, Validators.email]),
    dentistName: new FormControl("", [Validators.required]),
    phone: new FormControl("", [Validators.required, Validators.minLength(9), Validators.maxLength(9)]),
    account: new FormControl(""),
    accountEmail: new FormControl(""),
    accountPhoneNumber: new FormControl(""),

  });





  sendGtmEvent(name: any, name2: any) { }









  getNumber(event: any) {

  }

}



