
<ng-template class="mod" #leadModal let-modal>
    <div *ngIf="name === 'wysłano ofertę/kontakt 1'" class="modal-body">
      Pamiętaj aby wysłać ofertę mailem na adres: {{ currentLead.email }} - oraz SMS ze swojego telefonu o treści: Szanowana Pani! 
      Ofertę eminto wysłałem mailem. Oferta jest ograniczona czasowo. Proszę o informację, czy odpowiada Pani oczekiwaniom 🙂 W razie pytań proszę o bezpośredni kontakt. {{ currentLead.accountLead }}.
    </div>
    <div *ngIf="name === 'podłączyć box / recepcja 1'"  class="modal-body">
     <h4>Wyślij maila do "decydenta" o treści:</h4> 
  <p>Szanowna Pani!</p>
    <p>Zgodnie z ustaleniami zestaw testowy eminto został wysłany do Państwa w dniu {{ currentLead.steps[1].date  | date:'dd/M/yyyy' }} Próbowałem się z Panią skontaktować w celu podłączenia i konfiguracji urządzenia. Przypominam, że testy są darmowe przez okres 15 dni. 
    Proszę o kontakt lub wskazanie osoby z która mogę skonfigurować eminto. 
    {{ currentLead.accountLead }} - tel:  {{ currentLead.accountPhone }}<span *ngIf="currentLead.accountPhone  === ''">dodaj swój numer do konta🙂 </span></p>
  
  <h4>Wyślij SMS z prywatnego telefonu o treści:</h4>
  <p>Szanowna Pani!</p> 
    <p>Zgodnie z ustaleniami zestaw testowy eminto został wysłany do Państwa w dniu {{ currentLead.steps[1].date  | date:'dd/M/yyyy' }} Próbowałem się z Panią skontaktować w celu podłączenia i konfiguracji urządzenia. Przypominam, że testy są darmowe przez okres 15 dni. 
    Proszę o kontakt lub wskazanie osoby z która mogę skonfigurować eminto. 
    {{ currentLead.accountLead }} - tel: {{ currentLead.accountPhone }}<span *ngIf="currentLead.accountPhone  === ''">dodaj swój numer do konta🙂 </span>  </p>    
  
    </div>
    <div class="modal-footer">
      <button type="button"
              (click)="leadModal.close('Ok click')"
              class="btn btn-primary">
              OK
      </button>
    </div>
  </ng-template>
  