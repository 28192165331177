
import {  Component } from '@angular/core';

import { ActivatedRoute } from '@angular/router';
import { select, Store } from '@ngrx/store';
import * as fromAuth from 'src/app/auth/reducers'
import * as fromDentist from 'src/app/dentist/reducers'
import * as fromOrders from 'src/app/orders/reducers'

@Component({

  template: `<app-history
  [role]="role$ | async"
  [d]="orders$ | async"

></app-history>`
})
export class HistoryListPageComponent {

  constructor(

    private store: Store,
    private route: ActivatedRoute
  ) {


  }

  role$ = this.store.pipe(select(fromAuth.selectUserRole));
  orders$ = this.store.pipe(select(fromOrders.selectComplete));


}
