<div class="ms-2 container">
  <div class="row">
  

    <div class="d-flex flex-wrap col-12">
      <li class="media-box me-3 mb-3">
        <a [routerLink]="['/learning/eminto']" class="mb-3">
          <!--<img       #img (click)="open(mymodal, img)"-->
          <img className="icon" [src]="'assets/foldery/brand_book.svg'" alt="icon" />
        </a>
        <div class="d-flex justify-content-between align-items-center">
          <p class="mb-0">Brand book</p>
        </div>
      </li>
      <li class="media-box me-3 mb-3">
        <a [routerLink]="['/learning/facebook']" class="mb-3">
          <!--<img       #img (click)="open(mymodal, img)"-->
          <img className="icon" [src]="'assets/foldery/materialy_social_media.svg'" alt="icon" />
        </a>
        <div class="d-flex justify-content-between align-items-center">
          <p class="mb-0">Materiały do social media</p>
        </div>
      </li>
      <!--<li class="media-box me-3 mb-3">
        <a [routerLink]="['/learning/mailing']" class="mb-3">-->
          <!--<img       #img (click)="open(mymodal, img)"-->
          <!--<img className="icon" [src]="'assets/foldery/mailing.png'" alt="icon" />
        </a>
        <div class="d-flex justify-content-between align-items-center">
          <p class="mb-0">mailing</p>
        </div>
      </li>-->
      <li class="media-box me-3 mb-3">
        <a [routerLink]="['/learning/video']" class="mb-3">
          <!--<img       #img (click)="open(mymodal, img)"-->
          <img className="icon" [src]="'assets/foldery/video.svg'" alt="icon" />
        </a>
        <div class="d-flex justify-content-between align-items-center">
          <p class="mb-0">Video</p>
        </div>
      </li>
      <!--<li class="media-box me-3 mb-3">
        <a [routerLink]="['/learning/invite']" class="mb-3">-->
          <!--<img       #img (click)="open(mymodal, img)"-->
          <!--<img className="icon" [src]="'assets/foldery/webex.png'" alt="icon" />
        </a>
        <div class="d-flex justify-content-between align-items-center">
          <p class="mb-0">webex</p>
        </div>
      </li>-->
      <li class="media-box me-3 mb-3">
        <a [routerLink]="['/learning/www']" class="mb-3">
          <!--<img       #img (click)="open(mymodal, img)"-->
          <img className="icon" [src]="'assets/foldery/materialy_internet.svg'" alt="icon" />
        </a>
        <div class="d-flex justify-content-between align-items-center">
          <p class="mb-0">Materiały do internetu</p>
        </div>
      </li>
      <li class="media-box me-3 mb-3">
        <a [routerLink]="['/learning/pdf']" class="mb-3">
          <!--<img       #img (click)="open(mymodal, img)"-->
          <img className="icon" [src]="'assets/foldery/dokumenty.svg'" alt="icon" />
        </a>
        <div class="d-flex justify-content-between align-items-center">
          <p class="mb-0">Dokumenty</p>
        </div>
      </li>
    </div>

  </div>



  <!--<div class="d-flex flex-wrap col-12">
    <li *ngFor="let med of media" class="media-box me-3 mb-3">
      <a [routerLink]="['/learning/test1']" class="mb-3">-->
        <!--<img       #img (click)="open(mymodal, img)"-->
        <!--<img [className]="med.icon  ? 'icon' : 'fvr'" [src]="med.img" alt="" />
      </a>
      <div class="d-flex justify-content-between align-items-center">
        <p class="mb-0">{{ med.name}}</p>
        <a [href]="med.link" download rel="noopener noreferrer"><img class="p-1" src="../../../../assets/img/download_icon.svg" alt=""></a>
      </div>

    </li>


  </div>-->









</div>
<router-outlet></router-outlet>


<ng-template #mymodal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title"></h4>

  </div>
  <div class="modal-body">
    <img [src]="imgSrc" alt="modalImg">
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="modal.close('Save click')">Ok</button>
  </div>
</ng-template>


