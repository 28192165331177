import { Component } from "@angular/core";
import { select, Store } from '@ngrx/store';
import { Router } from "@angular/router";
import { routesData } from "../../common/models/route.model";
import * as fromAuth from "src/app/auth/reducers"

@Component({
  selector: '',
  template: `
<app-top-nav
  [role]="role$ | async"
   [routes]="routesData"

></app-top-nav>
    <router-outlet></router-outlet>
    `,
 
})
export class LeadPageComponent {
  role$ = this.store.pipe(select(fromAuth.selectUserRole));
  constructor(private store: Store) { }







  routesData: routesData[] = [
    {
      path: "lead-list",
      name: "Leads",
      forbiden: ['admin', 'director']
    },
    {
      path: "add-lead",
      name: "Nowy Lead",
      forbiden: ["seller", "director", "admin"]
    },
    
    
  ];



}







