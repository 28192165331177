
import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { apiUrl } from "../../../../common/api-url";
import { QuizResults, IResults } from "../../../../auth/models/auth.model";

@Injectable({
  providedIn: "root"
})
export class QuizService {


  constructor(private http: HttpClient) { }


  getQuiz(): Observable<any> {
    let params = new HttpParams();
    params = params.append('level', "1");
    return this.http.post(apiUrl + 'quiz/questions', params).pipe(
        map((res) => res),
        catchError((error: any) => throwError(error))
      );

  }


  getQuizLevel2(): Observable<any> {
    let params = new HttpParams();
    params = params.append('level', "2");
    return this.http.post(apiUrl + 'quiz/questions', params).pipe(
      map((res) => res),
      catchError((error: any) => throwError(error))
    );

  }

  getQuizLevel3(): Observable<any> {
    let params = new HttpParams();
    params = params.append('level', "3");
    return this.http.post(apiUrl + 'quiz/questions', params).pipe(
      map((res) => res),
      catchError((error: any) => throwError(error))
    );

  }







  getAgentData(): Observable<any> {
    const params = new HttpParams();

    return this.http.post(apiUrl + "agents/get", params)
      .pipe(
        map((res) => {

          return res;
        }),
        catchError((error: any) => {
          
          return throwError(error);
        })
      );
  }


  getQuizStatus(): Observable<any> {
    let params = new HttpParams;
    return this.http.post(apiUrl + "quiz/status", params)
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error: any) => {
          console.log(error);
          return throwError(error);
        })
      );
  }




  sendQuizResults(results: IResults): Observable<any> {
    let params = new HttpParams();


    for (let [key, val] of Object.entries(results)) {
      if (val !== "") {
   
        params = params.append(key, val);
      }
    }


    return this.http.post(apiUrl + "quiz/complete", params)
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error: any) => {
          console.log(error);
          return throwError(error);
        })
      );
  }


}
