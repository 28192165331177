import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";


import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { AppDesktop, EmintoBox, SingleProductCat } from "../models";
import { apiUrl } from "../../common/api-url";


@Injectable({
  providedIn: "root"
})
export class ProductsService {
  constructor(private http: HttpClient) {}

  getProductsCategory(): Observable<SingleProductCat[]> {
    const params = new HttpParams();
    return this.http.post<SingleProductCat[]>(apiUrl + "products/list", params).pipe(
      map(res => res),
      catchError((error) => throwError(error.error.error_description)));
  }


  addProductBox(products: EmintoBox ): Observable<any> {
    
    let params = new HttpParams();
    for (let [key, val] of Object.entries(products)) {
      if (val !== "") {
        params = params.append(key, val);
      }
    }
    return this.http.post<AppDesktop>(apiUrl + "products/add-box", params).pipe(
      map(res => res),
      catchError((error) => throwError(error.error.error_description)));
  }

  addProductDesktopApp(id: string): Observable<any> {
    let params = new HttpParams();
    params = params.append("dentistId", id);
      
    
    return this.http.post(apiUrl + "products/assign-desktop-app", params).pipe(
      map(res => res),
      catchError((error) => throwError(error.error.error_description)));
  }







  getProductList(): Observable<any> {

    const params = new HttpParams();
    return this.http.post(apiUrl + 'products/list',    params).pipe(
      map(res => res),
      catchError(error => throwError(error.error.error_description)));
  }


  addAdmin(productId: string): Observable<any> {
  let params = new HttpParams();
  if (productId !== "") {
    params = params.append('productId', productId);
  }
  return this.http.post(apiUrl + 'accounts/account-login', params).pipe(
    map(res => res),
    catchError(error => throwError(error.error.error_description)));
  }

generatePassword(id, pass): Observable<any> {
  let params = new HttpParams();
  if (id !== "") {
    params = params.append('id', id);
  }
  if (pass !== "") {
    params = params.append('password', pass.toString());
  }
  return this.http.post(apiUrl + 'products/change-password', params).pipe(
    map(res => res),
    catchError(error => throwError(error.error.error_description)));
  }



clearPlayer(id, remove): Observable<any> {
  let params = new HttpParams();
  if (id !== "") {
    params = params.append('id', id);
  }

  if (remove) {
    params = params.append('remove', remove )
  }
  return this.http.post(apiUrl + 'products/clear', params).pipe(
    map(res => res),
    catchError(error => throwError(error.error.error_description)));
  }


addPlayerToSeller(contractorID:string, productId:string, personId? : string) {
  let params = new HttpParams();

  params = params.append('id', productId);
  params = params.append('accountId', personId);
  if (contractorID !== '') {
    params = params.append('distributorId', contractorID);
  }

  return this.http.post(apiUrl + 'products/assign-box', params).pipe(
    map(res => res),
    catchError(error => throwError(error.error.error_description)));
}



  orderProducts(name: string, qty: string, msg : string = ""): Observable<any> {
    let params = new HttpParams();

    let paramsMap = {
      type : name,
      number : qty,
      message : msg
    }

    for (let [key, val] of Object.entries(paramsMap)) {
      if (val !== "" ) {
        params = params.append(key, val);
      }
    }


    return this.http.post(apiUrl + 'products/order', params).pipe(
      map(res => res),
      catchError(error => throwError(error.error.error_description)));
  }
  

  addDays(productId: string) {
    let params = new HttpParams();
    params = params.append('id', productId);
    

    return this.http.post(apiUrl + 'products/extend-tests', params).pipe(
      map(res => res),
      catchError(error => throwError(error.error.error_description)));
  }



  removeProducts(productId: string) {
    let params = new HttpParams();
    params = params.append('id', productId);


    return this.http.post(apiUrl + 'products/remove', params).pipe(
      map(res => res),
      catchError(error => throwError(error.error.error_description)));
  }

  removeDistributorFromProduct(productId) {
    let params = new HttpParams();
    params.append('id', productId);

    return this.http.post(apiUrl + '', params).pipe(
      map(res => res),
      catchError(error => throwError(error.error.error_description)))
  }







}
