import { createReducer, on } from '@ngrx/store';
import { LeadsApiActions, LeadsPageActions } from '../actions';
import { ISingleLeadDetails } from '../models';

export const LeadsPageFeatureKey = 'leadsPage';

export interface State {
  leads: any[] | null;
  currentLead: ISingleLeadDetails | null;
  calrndatTitle: string;
  currentPage: number;
}

export const initialState: State = {
  leads: null,
  currentLead: null,
  calrndatTitle: '',
  currentPage: 0,
};

export const reducer = createReducer(
  initialState,
  on(LeadsApiActions.getLeadsSuccess, (state, action) => ({
    ...state,
    leads: action.leads,
  })),
  on(LeadsApiActions.getLeadDetailsSuccess, (state, action) => ({
    ...state,
    currentLead: action.lead,
  })),
  on(LeadsPageActions.removeCurrentLead, (state, action) => ({
    ...state,
    currentLead: null,
  })),
  on(LeadsPageActions.setCalendatTitle, (state, action) => ({
    ...state,
    calrndatTitle: action.title,
  })),
  on(LeadsPageActions.removeCalendatTitle, (state, action) => ({
    ...state,
    currentLead: null,
  })),
  on(LeadsPageActions.setCurrentPageList, (state, action) => ({
    ...state,
    currentPage: action.number,
  })),
  on(LeadsPageActions.removeCurrentPageList, (state, action) => ({
    ...state,
    currentPage: 0,
  }))
);

export const selectLeads = (state: State) => state.leads;
export const selectcurrentLead = (state: State) => state.currentLead;
export const selectCalendarTitle = (state: State) => state.calrndatTitle;
export const selectCurrentPage = (state: State) => state.currentPage;
