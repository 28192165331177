import { Target } from "@angular/compiler";
import { AfterViewInit, Component } from "@angular/core";
import { Router } from "@angular/router";
import { NavLink } from "src/app/common/models/nav-link.model";

@Component({
    selector: 'app-dentists',
    template: `
    <div class="container-fluid">
        <div class="d-flex align-items-center mb-4">
            <h4 class="mb-0 me-auto">Cele</h4>
        </div>
    </div>
    <app-targets
        [targets]="targets"
    ></app-targets>
    `
})
export class TargetsPageComponent {

    constructor(private router: Router) {}

    targets: Target[] = [
        { period: 'monthly', name: 'Ilość wizyt', currentValue: 2, targetValue: 20, percent: 10 } as Target,
        { period: 'monthly', name: 'Boxów na testach', currentValue: 3, targetValue: 4, percent: 75 } as Target,
        { period: 'monthly', name: 'Ilość umów', currentValue: 6, targetValue: 12, percent: 50 } as Target,
    ]
}