import { OnInit } from "@angular/core";
import { Component } from "@angular/core";
import * as fromLeads from 'src/app/leads/reducers'
import { select, Store } from '@ngrx/store';
import { LeadsPageActions } from "../actions";
import { ActivatedRoute } from "@angular/router";










@Component({
  selector: '',
  template: `<app-single-lead
[lead]="dataLead$ | async"
 

></app-single-lead>`
})
export class SingleLeadPageComponent implements OnInit {
  dataLead$ = this.store.pipe(select(fromLeads.selectLead));
  constructor(private store: Store,
    private route: ActivatedRoute
  ) { }
  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      let leadId = params.get("id");
      if (leadId) {
        this.store.dispatch(LeadsPageActions.getLeadDetails({ id: leadId }))
      }
    });
  }
}
