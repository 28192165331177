import {createAction, props} from '@ngrx/store';
import {Token} from '../models/auth.model';


export const loginSuccess = createAction(
    '[Auth API] Login Success',
    props<{token:Token}>()
);

export const loginFailure = createAction(
    '[Auth API] Login Failure',
    props<{error: string }>()
);

export const changePasswordSuccess = createAction(
    '[Auth API] Change Password Success'
);

export const changePasswordFailure = createAction(
    '[Auth API] Change Password Failure',
    props<{error: string}>()
);

export const refreshTokenSuccess = createAction(
    '[Auth API] Refresh Token Success',
    props<{token:Token}>()
);
export const refreshTokenFailure = createAction(
    '[Auth API] Refresh Token Failure',
    props<{payload: string}>()
);

export const clientCredentialsTokenSuccess = createAction(
    '[Auth API] Client Credentials Token Success',
    props<{token:Token}>()
);
export const logout = createAction('[Auth] Logout');





