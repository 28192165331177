import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatSortModule } from "@angular/material/sort";
import { MatTableModule } from "@angular/material/table";
import { RouterModule } from "@angular/router";
import { FeatherModule } from "angular-feather";
import { DataTableComponent } from "./components/data-table/data-table.component";
import { ProgressCircleComponent } from "./components/progress-circle/progress-circle.component";
import { SectionNavComponent } from "./components/section-nav/section-nav.component";
import { TableFiltersComponent } from "./components/table-filters/table-filters.component";
import { DataPropertyGetterPipe } from "./pipes/data-property-getter.pipe";
import { ImputFormat } from "./pipes/input-format.pipe";
import { PlusCircle } from 'angular-feather/icons';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NavTopComponent } from "./components/top-nav/top-nav.component";
import { NgxMaskModule, IConfig } from 'ngx-mask'
import { CutStringPipe } from "./pipes/cut-string.pipe";
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule} from '@angular/material/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CalendarComponent } from "./components/calendar/calendar.component";
import { LeadModalComponent } from "./components/lead-modal/lead-modal.component";
import { StepDropDownComponent } from "./components/step-dropdown/step-dropdown.component";




export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

export const COMPONENTS = [
    SectionNavComponent,
    TableFiltersComponent,
    DataTableComponent,
    CalendarComponent,
    DataPropertyGetterPipe,
    CutStringPipe,
    ProgressCircleComponent,
    ImputFormat,
    NavTopComponent,
    LeadModalComponent,
    StepDropDownComponent
   
  
   
]

@NgModule({
    declarations: COMPONENTS,
  imports: [
    MatIconModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTooltipModule,
    NgxMaskModule.forRoot(),
        NgbModule,
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
        FeatherModule.pick({PlusCircle}),
    ],
    exports: COMPONENTS,
  bootstrap: [DataTableComponent, TableFiltersComponent, CalendarComponent],
    providers: []
})
export class SharedModule {}
