import { createAction } from "@ngrx/store";
import { ISingleLeadDetails } from "../models";
import { props } from '@ngrx/store'


export const getLeadsSuccess = createAction(
  "[Leads Page] Get Leads Success",
  props < { leads: ISingleLeadDetails[]  }>()
);


export const getLeadsFaliure = createAction(
  "[Leads Page] Get Leads Faliure",
  props<{ error: any}>()
);



export const postLeadSuccess = createAction(
  "[Leads Page] Post Lead Success",
);


export const postLeadFaliure = createAction(
  "[Leads Page] Post Lead Failure",
  props<{ error: any }>()
);


export const getLeadDetailsSuccess = createAction(
  "[Leads Page] Get Lead Details Success",
  props<{ lead: ISingleLeadDetails }>()
);


export const getLeadDetailsFaliure = createAction(
  "[Leads Page] Get Lead Details Failure",
  props<{ error: any }>()
);

export const stepsUdateSuccess = createAction(
  "[Leads Page] Step Update Success",
);

export const changeLeadStatusFaliure = createAction(
  "[Leads Page] Change Lead Status Failure",
  props<{ error: any }>()
);

export const changeLeadStatusSuccess = createAction(
  "[Leads Page] Change Lead Status Success",
);

export const assingPersonToleadSuccess = createAction(
  "[Leads Page] Assign Person To Lead Success",

);

export const assingPersonToleadFaliure = createAction(
  "[Leads Page] Assign Person To Lead Failure",
  props<{ error: any }>()

);

export const removLeadeSuccess = createAction(
  "[Leads Page] Remove Lead Success",
);

export const removeoleadFaliure = createAction(
  "[Leads Page] Remove Lead Failure",
  props<{ error: any }>()
);




