import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import { TargetsPageComponent } from './containers/targets-page.component';

const routes: Routes = [
   { path : 'targets', component : TargetsPageComponent }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class TargetsRoutingModule {}

