import {createAction, props} from '@ngrx/store';
import {Token} from '../models/auth.model';

export const refreshToken = createAction(
    '[Auth] Refresh Token',
    props<Token>()
);

export const clientCredentialsToken = createAction('[Auth] Client Credentials Token');

export const logout = createAction('[Auth] Logout');

export const loginRedirect = createAction('[Auth] Login Redirect');
export const dismissPopup = createAction('[Auth]   Dismiss Popup');
