
import { createAction, props } from "@ngrx/store";




export const addProductSuccess = createAction(
  "[Products Api] Add Product Success",
);

export const addDesktopAppSuccess = createAction(
  "[Products Api] Add Desktop App Success",
  props<{ id: string }>()
);



export const getDentistBySeller = createAction(
  "[Products Api] ",
);




export const addProductsFailure = createAction(
  "[Products Api] Add Product Failure",
  props<{ error: string }>()
);


export const addDesktopAppFailure = createAction(
  "[Products Api] Add DEsktop App Failure",
  props<{ error: string }>()
);

export const adddaysSuccess = createAction(
  "[Products Api] Add Days Success",
);


export const addDaysFailure = createAction(
  "[Products Api] Add Days Failure",
  props<{ error: string }>()
);


export const getProductsSuccess = createAction(
  "[Products Api] Get Products Success",
  props<{ products: any }>()
);


export const getProductsFailure = createAction(
  "[Products Api] Get Products Failure",
  props<{ error: string }>()
);

export const addAdminSuccess = createAction(
  "[Products Api] Add Admin  Success",
  
);

export const addAdminFailure = createAction(
  "[Products Api] Add Admin Failure",
  props<{ error: string }>()
);


export const setPasswordSuccess = createAction(
  "[Products Api] Generate Password Success",

);

export const setPasswordFailure = createAction(
  "[Products Api] Generate Password Failure",
  props<{ error: string }>()
);


export const clearPlayerSuccess = createAction(
  "[Products Api] Clear Player Success",
 
);


export const clearPlayerFailure = createAction(
  "[Products Api] Clear Player Success Failure",
  props<{ error: string }>()
);





export const addPlayerSuccess = createAction(
  "[Products Api] Add Player Success",
);
export const addPlayerSuccessByAdmin = createAction(
  "[Products Api] Add Player Success By Admin",
);


export const addPlayerFailure = createAction(
  "[Products Api] Add Player Success Failure",
  props<{ error: string }>()
);
export const orderProductsSuccess = createAction(
  "[Products Api] Order Products Success",
);


export const orderProductsFailure = createAction(
  "[Products Api]  Order Products Failure",
  props<{ error: string }>()
);


export const removePlayerSuccess = createAction(
  "[Products Api] Remove Player Success",

);


export const removePlayerFailure = createAction(
  "[Products Api] Remove Player Success Failure",
  props<{ error: string }>()
);


export const removeDistributorSussess = createAction(
  "[Product Api]   Remove Distributor Success"
);


export const removeDistributorFailure = createAction(
  "[Product Api] Remove Distributor Failure",
  props<{ error }>());





