import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable,  throwError } from 'rxjs';
import { catchError,  map } from 'rxjs/operators';
import { ISingleLead, ISingleLeadDetails, IStepsUpdateData } from '../models';
import { LeadsApiService } from './api.services';
import { apiUrl } from 'src/app/common/api-url';




@Injectable()
export class LeadsService implements LeadsApiService {
  constructor(private http: HttpClient) { }


  changeLeadStatus(id, statusId) {
    let params = new HttpParams();
    params = params.append('id', id)
    params = params.append('statusId', statusId)
    return this.http.post(  apiUrl + 'leads/change-status', params).pipe(
      map((res: boolean) => res),
      catchError((error: any) => {
        return throwError(error.error.error_description)
      })
    )
  }



  getLeads(): Observable<ISingleLead[]> {
      let params = new HttpParams();
      return   this.http.post( apiUrl + 'leads/list', params).pipe(
      map((res: ISingleLead[]) =>  res),
      catchError((error: any) => {
        return throwError(error.error.error_description)
      })
      )
  }
  

  postLeads(lead, id?): Observable<any> {
    let params = new HttpParams();
    const mappedLead = {
      name: lead.name,
      campaign: lead.campaign,
      shortName: lead.shortName,
      website: lead.website,
      nip: lead.nip,
      street: lead.street,
      postalCode: lead.postalCode,
      city: lead.city,
      region: lead.region,
      status : lead.status,
      country: lead.country,
      ownerName: lead.ownerName,
      ownerSurname: lead.ownerSurname,
      ownerPhone: lead.ownerPhone,
      ownerEmail: lead.ownerEmail,
      contactPersonName: lead.contactName || "",
      contactPersonSurname: lead.contactSurname || "",
      contactPersonPhone: lead.contactPhone || "",
      contactPersonEmail: lead.contactEmail || "",
      addionalService: lead.service || "",
      contact: lead.addContactPerson ? true : "",
      invoiceEmail: lead.invoiceEmail,
      note: lead.note || "",
    };

    if (id) {
      params = params.append('id', id )
    }



    for (const [key, value] of Object.entries(mappedLead)) {
      if (value !== null && value !== "" ) {
        params = params.append(key, value.toString());
      }
    }



   
    return this.http.post( apiUrl + "leads/add-lead", params).pipe(
      map((res: any) => res),
      catchError((error: any) => {

        return throwError(error.error.error_description)
      })
    );
  }

  removeLead(id): Observable<boolean> {
    let params = new HttpParams();
    params = params.append('id', id)
    

   

    return this.http.post(apiUrl + "leads/remove", params).pipe(
      map((res: any) => res),
      catchError((error: any) => {
        return throwError(error.error.error_description)
      })
    );
  }




  stepsUpdate(data: IStepsUpdateData): Observable<any> {
    let params = new HttpParams();
    for (const [key, val] of Object.entries(data)) {
      if (val) {
        params = params.append(key, val);
      }
    }


    return this.http.post( apiUrl + 'leads/steps', params).pipe(
      map(res => res),
      catchError(error => error)
      )
  }








  getLeadDetails(id: string): Observable<ISingleLeadDetails> {
    let params = new HttpParams();
    params =  params.append("id", id)
    return this.http.post( apiUrl + 'leads/lead-details', params).pipe(
      map((res: ISingleLeadDetails) => res),
      catchError((error) => error)
    )
  }



  AssignLead(id: string, personId : string): Observable<any> {
    let params = new HttpParams();
    params =  params.append("id", id)
    
    params =  params.append("personId", personId)
    return this.http.post( apiUrl + 'leads/assing', params).pipe(
      map((res: ISingleLeadDetails) => res),
      catchError((error) => error)
    )
  }


}
