export interface Dentist {
  contractor?: string,
  shortName?: string,
  dentistshortName?: string,
  dentistName?: string,
  id: string,
  country: string,
  state: string,
  street?: string,
  city: string,
  postalCode: string,
  nip?: number,
  ownerName?: string
  ownerSurname?: string
  ownerPhone?: number
  ownerEmail?: string,
  leadId? : string,
  contactPersonName?: string,
  contactPersonSurname?: string,
  contactPersonPhone?: number,
  contactPersonEmail?: string,
  contactPerson?: boolean,
  invoiceEmail: string,
  www?: string,
  note?: string,
  status?: string,
  seller?: string,
  timeToEnd?: Date | number,
  emintoNr?: string,
}



export interface DentisSlistBySeller {
  _id: string,
  shortName: string,
}
















export interface Contact {
  name: string,
  surname: string,
  phone: string,
  email: string
}

export interface Tests {
  emintoId: string,
  end: string,
  start: string,
}

export interface Cancellation {
  date: string,
  emintoId: string,
}

export interface Status  {
  name: string,
  _id: string
}

export interface productToShow {
  name: string,
  status?: string | undefined,
  _id : string,
}




export interface assignedProductToDentis {
  _id: string,
  emintoId?: string,
  playerName?: string,
  login?: string,
  status: string,
  type: string
}


export interface GusApiData {
  dataZakonczeniaDzialalnosci: string,
  gmina: string,
  kodPocztowy: string,
  miejscowosc: string,
  miejscowoscPoczty: string,
  nazwa: string,
  nip: number,
  nrLokalu: number,
  nrNieruchomosci: number,
  powiat: string,
  regon: number,
  silosId: number,
  statusNip: string,
  typ: string,
  ulica: string,
  wojewodztwo: string
}



export interface DentistDetails {
  assigned: assignedProductToDentis[],
  available: any[],
  city: string,
  country: string,
  invoiceEmail: string,
  name: string,
  nip: string,
  owner: Contact,
  postalCode: string,
  shortName: string,
  state: string,
  status: string,
  statusList: Status[],
  street: string,
  website: string,
  _id: string,
  contactPerson?: Contact,
  note?: string
  id?: "string"
  tests?: Tests,
  cancellation?: Cancellation[],
  bankTransferIncomplete?: boolean
}



export interface AddProductToDentist
{
  _id: string,
  statusId: string,
  productId: string
}

export interface SelectedStatus {
  name?: string,
  _id?: string,
}




export interface ContactData {
  email: string,
  name: string,
  phone: string,
  surname: string,
}






export interface DentistList {
  contact : ContactData,
  country: string,
  shortName: string
  state: string,
  _id: string,
  account: string,
  status: string,
  testsAccept?: boolean,
  testsDays?: number,
  distributor: string,
  emintoId: string | null,
  bookingDays: number,
 


}



export interface DentistListToshow {
  email: string,
  name: string,
  phone: string,
  surname: string
  country: string,
  shortName: string
  state: string,
  _id: string,

}





export enum regions {
  N = "Wybierz województwo",
  A =  'dolnośląskie ',
 B =  'kujawsko-pomorskie',
 C =  'lubelskie', 
 D =  'lubuskie', 
 E =  'łódzkie', 
T =   'małopolskie ',
R  =   'mazowieckie', 
 V =  'opolskie', 
M =   'podkarpackie', 
Y =   'podlaskie', 
Q =   'pomorskie', 
X =   'śląskie' ,
I =   'świętokrzyskie', 
S =   'warmińsko-mazurskie', 
L =   'wielkopolskie', 
 K =   'zachodniopomorskie' 
}
