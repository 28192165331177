
<div class="container-fluid">


  <div class="tab-pane " id="pills-player" role="tabpanel" aria-labelledby="pills-player-tab">
    <form [formGroup]="form" (ngSubmit)="onSubmit(form.value)">

      <div class="row">
        <div class="col-3">
          <div class="form-floating mb-3  ">
            <input [dropSpecialCharacters]="false" mask="000-000-00-00" formControlName="nip" type="text" class="form-control" id="nip_number" />

            <label for="nip_number">Nip</label>
          </div>
        </div>

        <div class="col-3 mb-3 d-flex align-items-center">
          <a [ngClass]="fullName.invalid ? 'disabled'   :   ''" (click)="searchInGus()" class="d-none btn-emi d-sm-inline-block btn  px-5 ms-3">Pobierz z Gus</a>
        </div>
      </div>

      <div class="row g-3 w-100">
        <div class="col">
          <div class="form-floating">
            <input formControlName="name" type="text" class="mb-3 form-control" id="name" />
            <label for="name">Pełna nazwa gabinetu</label>
          </div>
        </div>
        <div class="col">
          <div class="form-floating">
            <input formControlName="shortName" type="text" class="mb-3 form-control" id="shortName" />
            <label for="shortName">Skrócona nazwa gabinetu</label>
          </div>
        </div>
      </div>
      <hr />
      <div class="row">
        <h5 class="section-title">Dane adresowe</h5>
        <div class="col-3">
          <div class="form-floating mb-3">
            <input formControlName="city" type="text" class="form-control" id="apartment_number" />
            <label for="apartment_number">Miejscowość</label>
          </div>
        </div>
        <div class="col-3">
          <div class="form-floating mb-3">
            <input formControlName="street" type="text" class="form-control" id="street" />
            <label for="street">Ulica / NR</label>
          </div>
        </div>

        <div class="col-3">
          <div class="form-floating mb-3">
            <input [dropSpecialCharacters]="false" mask="00-000" formControlName="postalCode" type="text" class="form-control" id="apartment_number" />
            <label for="apartment_number">Kod pocztowy</label>
          </div>
        </div>

        <div class="col-3">
          <div class="form-floating">
            <input formControlName="website" type="text" class="form-control" id="www" />

            <label for="www">Strona internetowa</label>
          </div>
        </div>



        <div class="col-3">

          <select formControlName="region" class="form-select large" id="specifiRegion">

            <option [ngValue]="null">Wybierz województwo</option>
            <option value="{{region}}" *ngFor="let region of regions">{{ region }}</option>
          </select>
        </div>

        <div class="col-3">
          <div class="form-floating mb-3">
            <input formControlName="country" type="text" class="form-control" id="country" />
            <label for="country">Kraj</label>
          </div>
        </div>
      </div>
      <hr />
      <div class="row">
        <h5 class="section-title">Dane kontaktowe właściciela</h5>
        <div class="col-3">
          <div class="form-floating mb-3">
            <input formControlName="ownerName" type="text" class="form-control" id="name" />
            <label for="name">Imię</label>
          </div>
        </div>
        <div class="col-3">
          <div class="form-floating mb-3">
            <input formControlName="ownerSurname" type="text" class="form-control" id="surname" />
            <label for="surname">Nazwisko</label>
          </div>
        </div>

        <div class="col-3">
          <div class="form-floating mb-3">
            <input formControlName="ownerPhone" mask="000000000" type="text" class="form-control" id="phone" />
            <label for="phone">Numer telefonu</label>
          </div>
        </div>

        <div class="col-3">
          <div class="form-floating mb-3">
            <input (change)="focuseOut($event.target.value)" formControlName="ownerEmail" type="text" class="form-control" id="email" />

            <label for="email">Adres e-mail</label>
          </div>
        </div>
      </div>
      <div class="row">
        
        <div class="col-3">
          <h5 class="section-title">Podaj inne dane do kontaktu</h5>
          <input type="checkbox" formControlName="addContactPerson" (change)="addContactPersonData($event.target.checked)" />
        </div>
        <div class="col-3">
          <h5 class="section-title">Dodatkowe usługi</h5>
          <input type="checkbox" formControlName="additionalService" (change)="addAdditionalService($event.target.checked)" />
        </div>
      </div>
      <div *ngIf="showAdditionalService" class="row">
        <h5 class="section-title">Dodatkowe usługi</h5>
        <div class="col-3">
          <div class="form-floating mb-3">
            <select  formControlName="service"  class="form-select large"  id="additionalService">
              <option [ngValue]="null">Wybierz</option>
              <option value="{{service}}" *ngFor="let service of services">{{ service }}</option>
            </select>
          </div>
        </div>
      </div>
      <div *ngIf="showContactData" class="row">
        <h5 class="section-title">Dane osoby kontaktowej</h5>
        <div class="col-3">
          <div class="form-floating mb-3">
            <input formControlName="contactName" type="text" class="form-control" id="contactName" />
            <label for="contactName">Imię</label>
          </div>
        </div>
        <div class="col-3">
          <div class="form-floating mb-3">
            <input formControlName="contactSurname" type="text" class="form-control" id="contactSurname" />
            <label for="contactSurname">Nazwisko</label>
          </div>
        </div>

        <div class="col-3">
          <div class="form-floating mb-3">
            <input mask="000000000" formControlName="contactPhone" type="text" class="form-control" id="contactPhone" />
            <label for="contactPhone">Numer telefonu</label>
          </div>
        </div>

        <div class="col-3">
          <div class="form-floating mb-3">
            <input formControlName="contactEmail" type="text" class="form-control" id="contactEmail" />
            <label for="contactEmail">Adres e-mail</label>
          </div>
        </div>
      </div>
      <hr />
      <div class="row">
        <div class="col-3">
          <div class="form-floating mb-3">
            <input formControlName="invoiceEmail" type="text" class="form-control" id="invoiceEmail" />
            <label for="invoiceEmail">Adres e-mail do wystawiania faktur</label>
          </div>
        </div>
        <div class="col-3">
          <select formControlName="campaign" class="form-select large" id="specifiRegion">
            <option [ngValue]="null" >Wybierz kampanie</option>
            <option value="{{name}}" *ngFor="let name of campaign">{{ name }}</option>
          </select>
        </div>
        <div class="col-3">
          
          <select formControlName="status" class="form-select large" id="specifiRegion">
            <option [ngValue]="null">Wybierz status</option>
            <option [selected]="currentLead?.status ==='637369c1def8d1e5faf8db8b'" value="637369c1def8d1e5faf8db8b">testy</option>
            <option [selected]="currentLead?.status ==='63736cc7def8d1e5faf8db8c'" value="63736cc7def8d1e5faf8db8c">nowy</option>
          </select>
        </div>
        <div class="col-3">
          <textarea formControlName="note" type="text" class="form-control" id="exampleFormControlTextarea1" rows="4" placeholder="Treść notatki"></textarea>

        </div>
      </div>

      <hr />
      <div class="d-flex align-items-end justify-content-end mb-3 flex-column">
        <button  type="submit" href="#" class="d-none d-sm-inline-block btn btn-primary px-5 ms-3"><i data-feather="save"></i> Zapisz</button>
        <div  *ngIf="showFormErrors">
      
         
          <p class="mb-1 text-danger text-end  fw-bold " *ngIf="form.get('campaign')?.errors" >Wybierz kampanię</p>  
        <p class="mb-1 text-danger text-end  fw-bold " *ngIf="form.get('status')?.errors" >Wybierz status</p>  
        <p class="mb-1 text-danger text-end  fw-bold " *ngIf="form.get('shortName')?.errors" >Wprowadź skróconą nazwę</p>  
        <p class="mb-1 text-danger text-end  fw-bold " *ngIf="form.get('nip')?.errors" >Wprowadź nip</p>  
          </div>

      </div>
      
    </form>
  </div>
</div>
