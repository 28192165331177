import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { Dentist, DentistList } from "../models";
import { ApiService } from "../services";
import { DentistDetails, DentisSlistBySeller } from "../models/dentist.model";
import { apiUrl } from 'src/app/common/api-url';

@Injectable({
  providedIn: "root"
})
export class DentistService implements ApiService {
  constructor(private http: HttpClient) {}

  postDentist(dentist): Observable<any> {

    let params = new HttpParams();
    const mappedDentist = {
      name: dentist.name,
      shortName: dentist.shortName,
      website: dentist.website,
      nip: dentist.nip,
      street: dentist.street,
      postalCode: dentist.postalCode,
      city: dentist.city,
      state: dentist.region,
      country: dentist.country,
      ownerName: dentist.ownerName,
      ownerSurname: dentist.ownerSurname,
      ownerPhone: dentist.ownerPhone,
      ownerEmail: dentist.ownerEmail,
      contactPersonName: dentist.contactName || "",
      contactPersonSurname: dentist.contactSurname || "",
      contactPersonPhone: dentist.contactPhone || "",
      contactPersonEmail: dentist.contactEmail || "",
      contactPerson: dentist.addContactPerson,
      invoiceEmail: dentist.invoiceEmail,
      id: dentist.id || "",
      note: dentist.note || "",
      statusId : dentist.status || "",
      customDistributor : dentist.customDistributor  || "",
      leadId : dentist.leadId  || "",
    };
    
   
    for (const [key, value] of Object.entries(mappedDentist)) {
      if (value !== "") {
        params = params.append(key, value.toString());
      }
    }
    
    return this.http.post(apiUrl + "dentists/add", params).pipe(
      map((res: any) => res),
      catchError((error: any) => {
     
        return throwError(error.error.error_description)
      })
    );
  }

  getDentist(): Observable<DentistList[]> {

    let params = new HttpParams();
    params = params.append("key", "val");
    return this.http.post(apiUrl + "dentists/list", params)
      .pipe(
        map((res: DentistList[]) => {
          return res;
        }),
        catchError((error) => {
          return throwError(error.error.type);
        })
      );
  }
  

  getDentistBySeller(id: string): Observable<DentisSlistBySeller[]> {

    let params = new HttpParams();
    params = params.append("accountId", id);
    return this.http.post(apiUrl + "dentists/account-list", params)
      .pipe(
       map((res: DentistList[]) => {
          return res;
        }),
        catchError((error) => {
          return throwError(error.error.type);
        })
      );
  }



  getDentistDetails(id: string): Observable<DentistDetails[]> {
    let params = new HttpParams();
    params = params.append("id", id);

    return this.http.post<DentistDetails[]>(apiUrl + "dentists/details", params).pipe(
      map(res => {
        return res;
      }),
      catchError((error) => throwError(error.error.error_description)));


  }


  upDateDentist(dentist: Dentist): Observable<any> {

    let params = new HttpParams();
    const mappedDentist = {
      name: dentist.dentistName,
      shortName: dentist.dentistshortName,
      website: dentist.www,
      nip: dentist.nip,
      street: dentist.street,
      postalCode: dentist.postalCode,
      city: dentist.city,
      state: dentist.state,
      country: dentist.country,
      ownerName: dentist.ownerName,
      ownerSurname: dentist.ownerSurname,
      ownerPhone: dentist.ownerPhone,
      ownerEmail: dentist.ownerEmail,
      contactPersonName: dentist.contactPersonName || "",
      contactPersonSurname: dentist.contactPersonSurname || "",
      contactPersonPhone: dentist.contactPersonPhone || "",
      contactPersonEmail: dentist.contactPersonEmail || "",
      contactPerson: dentist.contactPerson,
      invoiceEmail: dentist.invoiceEmail,
      note: dentist.note || "",

  };

    for (let [key, val] of Object.entries(mappedDentist)) {
      if (val !== "") {
        params = params.append(key, val.toString());
      }
    }


    return this.http.post(apiUrl + "", params).pipe(
      map(res => res),
      catchError(error => throwError(error.error.error_description))
    );

  }

  addProductToDentist(id: string, dentistId: string, statusId: string): Observable<any> {
    let params = new HttpParams();

    params = params.append("id", id);
    params = params.append("dentistId", dentistId);
    params = params.append("statusId", statusId);

    return this.http.post(apiUrl + "products/assign-dentist", params).pipe(
      map(res => res),
      catchError(error => throwError(error.error.error_description))
    );
  }



  addContractorToDentist(id: string, accountId: string, distributorId?: string): Observable<any> {
    let params = new HttpParams();

    params = params.append("id", id);
    params = params.append("accountId", accountId);
    params = params.append("distributorId", distributorId);

    return this.http.post(apiUrl + "dentists/assign-account", params).pipe(
      map(res => res),
      catchError(error => throwError(error.error.error_description))
    );



  }

  setDentistStatus(dentistId: string, statusId: string, productId?: string): Observable<any> {
   let params = new HttpParams();
    params = params.append('id', dentistId);
    params = params.append('statusId', statusId);
    if (productId) {
      params = params.append('productId', JSON.stringify(productId));
    }
    return this.http.post(apiUrl + 'dentists/change-status', params).pipe(
      map(res => res),
      catchError(error => throwError(error.error.error_description))
    );
  }


  generateTestLink(DentistId: string, productId: string[] ): Observable<any> {
    let params = new HttpParams();
    params = params.append('products', JSON.stringify(productId));
    params = params.append('dentistId', DentistId);
    return this.http.post(apiUrl + 'tests/generate-link', params).pipe(
      map(res => res),
      catchError(error => throwError(error.error.error_description))
    );
  }

 

  generatePaymentLink(DentistId: string, productId: string[], licenseDuration: number, price?: string, monthly?: string): Observable<any> {
    let params = new HttpParams();
    params = params.append('products', JSON.stringify(productId));
    params = params.append('dentistId', DentistId);
    params = params.append('licenseDuration', licenseDuration);
  
    if (price) {
      params = params.append('price', price);
    }
    if (monthly) {
    params = params.append('monthly', monthly);
    }
    return this.http.post(apiUrl + 'payment/generate-link', params).pipe(
      map(res => {
        return res
      }),
      catchError(error => {
        return error
      }))
    
  }


  renewLicense(dentisId: string, laicensDuration: number) {
    let params = new HttpParams();
    params = params.append('dentistId', dentisId);
    params = params.append('licenseDuration', laicensDuration);
    return this.http.post(apiUrl + 'dentists/renew-license', params).pipe(
      map(res => res),
      catchError(error => throwError(error.error.error_description))
    );
  }



  getDataFromGus(nip): Observable<any> {
    let params = new HttpParams();
    params = params.append('nip', nip);
  

    return this.http.post(apiUrl + 'dentists/gus', params).pipe(
      map(res => res),
      catchError(error => throwError(error.error.error_description))
    );
  }

  confirmPayments(dentistId: string) {
    let params = new HttpParams();
    params = params.append('id', dentistId);
  
    return this.http.post(apiUrl + 'payment/bank-transfer-complete', params).pipe(
      map(res => res),
      catchError(error => throwError(error.error.error_description)))
  }


  addLogo(logo: File): Observable<any> {

    const formData: FormData = new FormData();
    formData.append('file', logo);

    return this.http.post(apiUrl + 'payment/bank-transfer-complete', formData).pipe(
      map(res => res),
      catchError(error => throwError(error.error.error_description)))
  }






}
