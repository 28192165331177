import {  Component } from "@angular/core";



@Component({
    selector: 'app-presentation',
    styleUrls: ['./presentation.component.scss'],
    templateUrl: './presentation.component.html'
})
export class PresentationComponent    {
   
}
