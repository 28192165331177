import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';

import { LearningComponent } from './components/learning.component';





import { PresentationComponent } from './components/presentation/presentation.component';
import { SelsCardComponent } from './components/sels-card/sels-card.component';
import { CalculatorComponent } from './components/calculator/calculator.component';
import { TestPageComponent } from './components/test/containers/test.page.component';
import { PricePageComponent } from './components/price/containers/price.page.component';
import { MediaComponent } from './components/media/media.component';
import { OfferComponent } from './components/offer/offer.component';
import { EmintoComponent } from './components/media/components/eminto/eminto.component';
import { WwwComponent } from './components/media/components/www/www.component';
import { VideoComponent } from './components/media/components/video/video.component';
import { PdfComponent } from './components/media/components/pdf/pdf.component';
import { MailingComponent } from './components/media/components/mailing/mailing.component';
import { InviteComponent } from './components/media/components/invite/invite.component';
import { FaceComponent } from './components/media/components/facebook/facebook.component';
import { AuthGuard } from "../auth/services/auth-guard.service";


const routes: Routes = [
  {
    path: 'learning', component: LearningComponent, canActivate: [AuthGuard],
    children: [
     
        {
        path: 'presentation', component: PresentationComponent, canActivate: [AuthGuard]
        },
        {
          path: 'sels-card', component: SelsCardComponent, canActivate: [AuthGuard]
          },
          {
            path: 'calculator', component: CalculatorComponent, canActivate: [AuthGuard]
          },
          {
            path: 'test', component: TestPageComponent, canActivate: [AuthGuard]
          },
          {
            path: 'offer', component: OfferComponent, canActivate: [AuthGuard]
          },
          {
            path: 'price', component: PricePageComponent, canActivate: [AuthGuard]
          },
           {
             path: 'media', component: MediaComponent, canActivate: [AuthGuard]

           },
           {
              path: 'eminto', component: EmintoComponent,
      },
      {
        path: 'facebook', component: FaceComponent ,
      },
      {
        path: 'invite', component: InviteComponent,
      }, {
        path: 'mailing', component: MailingComponent,
      },
      {
        path: 'pdf', component: PdfComponent,
      },
      {
        path: 'video', component: VideoComponent,
      },
      {
        path: 'www', component: WwwComponent,
      },


            
          




   
]
    }
]

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class LearningRoutingModule {}
