import { Component, OnDestroy, OnInit } from "@angular/core";
import { LayoutActions } from 'src/app/core/actions'
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { Store } from "@ngrx/store";


@Component({
    selector: 'app-media',
    styleUrls: ['./media.component.scss'],
    templateUrl: './media.component.html'
})



export class MediaComponent implements OnInit, OnDestroy {

    title = 'appBootstrap';
    imgSrc:string;
    closeResult: string;
   
  constructor(
    private store : Store,   
    private modalService: NgbModal) { }


  ngOnDestroy(): void {
    this.store.dispatch(LayoutActions.setIsMediaviev({ isMedia: false }));
    };
    ngOnInit(): void {
      this.store.dispatch(LayoutActions.setIsMediaviev({ isMedia: true }));
    }
    open(content, img ) {
      this.imgSrc = img.getAttribute('src')
        this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
          this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
         
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
        
  }



  

      
      private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
          return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
          return 'by clicking on a backdrop';
        } else {
          return  `with: ${reason}`;
        }
      }






    media:object[];
}
