import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './core/containers';
import { CoreModule } from './core/core.module';
import { AuthModule } from './auth/auth.module';
import { StoreModule } from '@ngrx/store';
import { ROOT_REDUCERS, metaReducers } from './reducers';
import { StoreRouterConnectingModule, RouterState } from '@ngrx/router-store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { AuthEffects } from './auth/effects';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { TargetsModule } from './targets/targets.module';
import { FeatherModule } from 'angular-feather';
import { allIcons } from 'angular-feather/icons';

import { LearningModule } from './e-learning/learning.modules';
import { NgxSpinnerModule } from "ngx-spinner";
import { ContractorModule } from './contractor/contractor.module';
import { ProductsModule } from './products/products.module';
import { DentistModule } from './dentist/dentist.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ResetPasswordModule } from "./reset-password/reset-password.module";
import { OrdersModule } from './orders/orders.module';
import { LeadsModule } from './leads/leads.module';
import { OfferModule } from './offer/offer.module';
import { MatInputModule } from '@angular/material/input';


@NgModule({
  declarations: [
    AppComponent,


  ],
  imports: [
    ResetPasswordModule,
    BrowserAnimationsModule,
    NgxSpinnerModule,
    DentistModule,
    LeadsModule,
    LearningModule,
    ContractorModule,
    OrdersModule,
    MatInputModule,
    OfferModule,
    ProductsModule,
    CoreModule,
    BrowserModule,
    HttpClientModule,
    AuthModule,
    TargetsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    StoreModule.forRoot(ROOT_REDUCERS, {
      metaReducers,
      runtimeChecks: {
          strictStateImmutability: true,
          strictActionImmutability: true,
          strictStateSerializability: false,
          strictActionSerializability: false
      },
  }),
  StoreRouterConnectingModule.forRoot({
      routerState: RouterState.Minimal
  }),
  StoreDevtoolsModule.instrument({
      name: 'Eminto Panel',
  }),
  EffectsModule.forRoot([AuthEffects]),

  FeatherModule.pick(allIcons),
  ],
  providers: [],
  bootstrap: [AppComponent ],
  exports: [FeatherModule ]
})
export class AppModule { }






