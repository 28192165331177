import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';

@Component({
    selector: 'app-progress-circle',
    template:  `
        <svg id="circle" #circle viewBox="0 0 100 100">
                <circle id="bar" [attr.r]="radius" cx="50%" cy="50%" fill="transparent" [attr.stroke-dasharray]="dashArray" stroke="#cacfdd"  stroke-linecap="round"
                    stroke-width="5px" stroke-dashoffset="0"
                ></circle>
                <circle [attr.r]="radius" cx="50%" cy="50%" fill="transparent"
                    [attr.stroke-dasharray]="dashArray" stroke-dashoffset="0" stroke="#ff7065" stroke-linecap="round" stroke-width="5px"
                    [ngStyle]="{'strokeDashoffset': pct}"
                ></circle>
        </svg>
    `,
    styles: [`
        #circle {
            transform: rotate(-90deg);
        }
        #bar {
            stroke-dashoffset: 0;
        }

        #label {
            width: auto;
            height: auto;
            transform-origin: 50% 50%;
            transform: rotate(90deg) translate(-50%, -50%);
        }
    `]
})
export class ProgressCircleComponent implements OnInit, AfterViewInit {

    @Input() percentProgress = 0;
    @Input() radius = 15;
    @Input() currentTarget;

    @ViewChild('label', {static: false}) label;

    tooltipPosition: {x: number; y: number} = {x: 10, y: 10};
    tooltipOffset: {x: number; y: number} = {x: 0, y: 0};
    dashArray;
    pct = 0;

    ngOnInit() {
        this.dashArray = Math.PI * (this.radius * 2);
        this.pct = this.dashArray - this.percentProgress / 100 * this.dashArray;
    }

    ngAfterViewInit()  {
        console.log(this.label);
    }
}
