import {AuthApiActions, LoginPageActions} from '../actions';
import {createReducer, on} from '@ngrx/store';


export const loginPageFeatureKey = 'loginPage';

export interface State {
    error: string | null;
}

export const initialState: State = {
    error: null
};

export const reducer = createReducer(
    initialState,
    on(
        LoginPageActions.login,
        AuthApiActions.loginSuccess,
        (state) => ({...state, error: null})),
    on(
        AuthApiActions.loginFailure,
        AuthApiActions.changePasswordFailure,
        (state, {error}) => ({...state, error : error, })
    )
);

export const selectError = (state: State) => state.error;
