import { createAction, props } from "@ngrx/store";



export const getOrders = createAction(
  "[Orders Page] Get Orders",
    props<{ status: string }>()
);


export const getComplateOrders = createAction(
  "[Orders Page] Get  Complete Orders",
  props<{ status: string }>()
);


export const sendOrder = createAction(
  "[Orders Page] Send Order",
  props<{ id: string }>()
);


