import { QuizResults } from '../../../../auth/models/auth.model';
import { createAction, props } from "@ngrx/store";

export const getQuiz = createAction(
  '[Dentists API] Get Quiz',
 
);


export const getQuiz2 = createAction(
  '[Dentists API] Get Quiz2',
 
);


export const getQuiz3 = createAction(
  '[Dentists API] Get Quiz3',
 
);


export const getQuizStatus  = createAction(
    '[Test Page] Get Guiz status'
)
export const sendQuizResults = createAction(
    '[Test Page] Send Quiz Results',
    props<{quizResults: any}>()
)
export const clearQuizStore = createAction(
  '[Test Page] Clear Quiz Store',
  
)

export const setQuizLevel = createAction(
  '[Test Page] Set Quiz Level',
  props<{ level: number }>()
)
export const cancelQuizLevel= createAction(
  '[Test Page] Cancel  Quiz Level',

)
