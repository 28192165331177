
import { AppRoutingModule } from '../app-routing.module';
import { MenuComponent } from './components/menu/menu.component';
import { StoreModule } from '@ngrx/store';
import * as fromLayout from './reducers'
import { BottomSlidePopupComponent } from "./components/bottom-slide-popup/bottom-slide-popup";
import { SpinnerComponent } from "./components/spinner/spinner.component";
import { NgxSpinnerModule } from "ngx-spinner";
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';



export const COMPONENTS = [
  MenuComponent,
  BottomSlidePopupComponent,
  SpinnerComponent,
];

@NgModule({
  imports: [
    NgbModule,
    AppRoutingModule,
    CommonModule,
        StoreModule.forFeature(fromLayout.LayoutFeatureKey, fromLayout.reducers),

  ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    declarations: COMPONENTS,
  exports: COMPONENTS,
  bootstrap: [MenuComponent],
 

})
export class CoreModule {}
