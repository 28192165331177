import { Component, EventEmitter, Input, OnChanges,  Output, SimpleChange, SimpleChanges, ViewChild } from '@angular/core';
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import { Store } from '@ngrx/store';
import { LayoutActions } from "../../actions/index";


@Component({
    selector: 'app-bottom-slide-popup',
    templateUrl: './bottom-slide-popup.html',
    styleUrls: ['./bottom-slide-popup.scss'],
   
})
export class BottomSlidePopupComponent implements OnChanges {

  @Input() bottomSlidePopup: any;
  @Input() msg: any;
  @ViewChild('modal') modal : any;
    @Output() closeBottomSlidePopup = new EventEmitter<any>();
    @Output() disableScroll = new EventEmitter<any>();

    showPopup: 'active' | 'inactive' = 'inactive';

  constructor(
    private modalService: NgbModal,
    private store: Store
    ) { }
  closeResult: string;
  content: string = "modal";
  ngOnChanges(changes: SimpleChanges) {
    const msg: SimpleChange = changes.msg;
    const simpleChange: SimpleChange = changes.bottomSlidePopup;

        if (!simpleChange?.firstChange && simpleChange) {
          if (this.bottomSlidePopup) {
            this.modalService.open(this.modal, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
              this.closeResult = `Closed with: ${result}`;
            }, (reason) => {
              this.store.dispatch(LayoutActions.removeError());
              this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            });
          } 
    }
        if (!msg?.firstChange && msg) {
          if (this.msg) {

            this.modalService.open(this.modal, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
              this.closeResult = `Closed with: ${result}`;
            }, (reason) => {
              this.store.dispatch(LayoutActions.RemoveDialog());
              this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            });
          }
        }
  }


  modalClose() {
  
    this.modalService.dismissAll();
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      this.store.dispatch(LayoutActions.removeError());
      this.store.dispatch(LayoutActions.RemoveDialog ());
      return `with: ${reason}`;
    }
  }
 
}
