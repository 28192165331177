import {  Component } from '@angular/core';
import { combineLatest } from "rxjs";
import { ActivatedRoute } from '@angular/router';
import { select, Store } from '@ngrx/store';
import * as fromAuth from 'src/app/auth/reducers'
import * as fromProducts from 'src/app/products/reducers'
import * as fromContractors from "../../../contractor/reducers"


@Component({

  template: `<app-list-products
  [d]="products$ | async"
  [persons]="persons$ | async"
  [role]="role$ | async"
 
></app-list-products>`
})
export class ProductsListPageComponent  {

  constructor(
    private store: Store,
    private route: ActivatedRoute
  ) {}


  
  persons$ = this.store.pipe(select(fromContractors.selectPersons));
  role$ = this.store.pipe(select(fromAuth.selectUserRole));
  products$ = combineLatest([this.store.pipe(select(fromProducts.selectProductsList)),
    this.store.pipe(select(fromProducts.selectCategoryProduct))          
  ])
}
