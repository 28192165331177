<div class="container-fluid">
  <div class="row">
    <ng-container>
      <!-- Table -->
      <table class="table" mat-table [dataSource]="tableDataSource" matSort (matSortChange)="sortTable($event)">
        <!-- action column -->
        <ng-container *ngIf="rowActionIcon?.length" [matColumnDef]="rowActionIcon">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element" [id]="rowActionIcon" (click)="emitRowAction(element)">
            <button mat-button>
              <mat-icon>remove_shopping_cart</mat-icon>
            </button>
          </td>
        </ng-container>
        <ng-container *ngFor="let tableColumn of tableColumns" [matColumnDef]="tableColumn.name">
          <!-- if sortable column header -->
          <ng-container *ngIf="tableColumn.isSortable; else notSortable">
            <th mat-header-cell *matHeaderCellDef [mat-sort-header]="tableColumn.name" [arrowPosition]="
                tableColumn.position === 'right' ? 'before' : 'after'
              ">
              {{ tableColumn.name }}
            </th>
          </ng-container>
          <!-- else not sortable -->
          <ng-template #notSortable>
            <th mat-header-cell *matHeaderCellDef [class.text-right]="tableColumn.position === 'right'">
              {{ tableColumn.name }}
            </th>
          </ng-template>

          <!-- column data -->
          <td mat-cell *matCellDef="let element" [class.text-right]="tableColumn.position === 'right'"
            [class.text-center]="tableColumn.position === 'center'" [ngSwitch]="tableColumn.type">
            <ng-container *ngSwitchCase="'edit'">
              <ng-container>
                <a routerLink="/contractor/add-preson/{{ element._id }}">
                  Edit
                </a>
              </ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="'shortName'">
              <ng-container>
                <a routerLink="/dentist/single-dentist/{{ element._id }}">
                  {{ element.shortName | cutString }}
                </a>
              </ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="'lead-name'">
              <ng-container>
                <a *ngIf="!element.dentistId" routerLink="/leads/single-lead/{{ element._id }}">
                  {{ element.DentisName | cutString }}
                </a>
                <a *ngIf="element.dentistId" routerLink="/dentist/single-dentist/{{ element.dentistId }}">
                  {{ element.DentisName | cutString }}
                </a>
              </ng-container>
            </ng-container>

            <ng-container *ngSwitchCase="'dentistNr'">
              <ng-container>
                <a routerLink="/dentist/single-dentist/{{ element.dentistId }}">{{ element.dentist | cutString }}</a>
              </ng-container>
            </ng-container>

            <ng-container *ngSwitchCase="'edit-contractor'">
              <ng-container>
                <a routerLink="/contractor/add-contractor/{{ element._id }}">Edit</a>
              </ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="'product-action'">
              <ng-container>
                <em class="fa-solid fa-ban" (click)="removeProduct(element._id, true)" mat-raised-button
                  #tooltip="matTooltip" matTooltip="Wyczyść" [matTooltipPosition]="position.value"
                  matTooltipHideDelay="1000" *ngIf="element.status === 'do wyczyszczenia'">
                </em>

                <i (click)="assignPassword()" mat-raised-button #tooltip="matTooltip" matTooltip="Zapisz hasło"
                  [matTooltipPosition]="position.value" matTooltipHideDelay="1000" class="fa-solid fa-floppy-disk"
                  *ngIf="addPassBtn && currentRow === element._id"></i>
                <i (click)="hidePassForm()" mat-raised-button #tooltip="matTooltip" matTooltip="zamknij dodawanie hasła"
                  [matTooltipPosition]="position.value" matTooltipHideDelay="500" class="fa-solid fa-circle-xmark"
                  *ngIf="addPassBtn && currentRow === element._id"></i>
                <i (click)="changeSeller(element._id)" mat-raised-button #tooltip="matTooltip"
                  matTooltip="zmień handlowca" [matTooltipPosition]="position.value" matTooltipHideDelay="500"
                  class="fa-solid fa-person-arrow-down-to-line" *ngIf="element.account"></i>

                <i (click)="assign()" mat-raised-button #tooltip="matTooltip" matTooltip="przypisz produkt"
                  [matTooltipPosition]="position.value" matTooltipHideDelay="1000" class="fa-solid fa-floppy-disk"
                  *ngIf="
                    addContractorBtn && currentRow === element._id && saveBtn
                  "></i>
                <i (click)="removeProduct(element._id, true)" mat-raised-button #tooltip="matTooltip"
                  matTooltip="Wyczyść" [matTooltipPosition]="position.value" matTooltipHideDelay="1000"
                  class="fa-solid fa-trash-can" *ngIf="
                    element.status === 'testuje' &&
                    element.nrEminto === 'virtual'
                  "></i>
                <i (click)="removeProduct(element._id)" mat-raised-button #tooltip="matTooltip" matTooltip="Usuń"
                  [matTooltipPosition]="position.value" matTooltipHideDelay="1000" class="fa-solid fa-trash-can"></i>

                <i mat-raised-button #tooltip="matTooltip" matTooltip="Usuń" [matTooltipPosition]="position.value"
                  matTooltipHideDelay="1000" class="fa-solid fa-trash-can" *ngIf="
                    element.status === 'demo' && element.nrEminto === 'virtual'
                  "></i>
                <i (click)="addDays(element._id)" mat-raised-button #tooltip="matTooltip" matTooltip="Przedłuż  10 dni"
                  [matTooltipPosition]="position.value" matTooltipHideDelay="1000" *ngIf="element.status === 'testuje'"
                  class="fa-solid fa-clock-rotate-left"></i>

                <i mat-raised-button #tooltip="matTooltip" matTooltip="wygeneruj klucz"
                  [matTooltipPosition]="position.value" matTooltipHideDelay="1000" class="fa-solid fa-key" (click)="
                    open(mymodal, element.id, element.playerName, element.login)
                  " *ngIf="
                    element.status === 'demo' &&
                    element.nrEminto === 'aplikacja'
                  "></i>
                <i mat-raised-button #tooltip="matTooltip" matTooltip="wygeneruj klucz"
                  [matTooltipPosition]="position.value" matTooltipHideDelay="1000" class="fa-solid fa-key" (click)="
                    open(mymodal, element.id, element.playerName, element.login)
                  " *ngIf="
                    element.status === 'testuje' &&
                    element.nrEminto === 'aplikacja'
                  "></i>
              </ng-container>
            </ng-container>

            <ng-container *ngSwitchCase="'product-action-admin'">
              <ng-container>
                <i (click)="changeSeller(element._id, element.distributorId)" mat-raised-button #tooltip="matTooltip"
                  matTooltip="zmień handlowca" [matTooltipPosition]="position.value" matTooltipHideDelay="500"
                  class="fa-solid fa-person-arrow-down-to-line" *ngIf="element.account"></i>

                <i (click)="assign()" mat-raised-button #tooltip="matTooltip" matTooltip="przypisz produkt"
                  [matTooltipPosition]="position.value" matTooltipHideDelay="1000" class="fa-solid fa-floppy-disk"
                  *ngIf="
                    addContractorBtn && currentRow === element._id && saveBtn
                  "></i>
              </ng-container>
            </ng-container>

            <ng-container *ngSwitchCase="'role'">
              <ng-container>
                <div [ngSwitch]="element.type">
                  <span *ngSwitchCase="'seller'" class="badge bg-info">handlowiec</span>
                  <span *ngSwitchCase="'admin'" class="badge bg-success">admin</span>
                  <span *ngSwitchCase="'director'" class="badge bg-danger">dyrektor</span>
                </div>
              </ng-container>
            </ng-container>

            <ng-container *ngSwitchCase="'campaign-lead'">
              <ng-container>
                <div [ngSwitch]="element.campaign">
                  <span *ngSwitchCase="'facebook'" class="badge bg-info">{{
                    element.campaign
                    }}</span>
                  <span *ngSwitchCase="'formularz'" class="badge bg-secondary">{{ element.campaign }}</span>
                  <span *ngSwitchCase="'konferencja'" class="badge bg-light">{{
                    element.campaign
                    }}</span>
                  <span *ngSwitchCase="'szkolenie'" class="badge bg-primary">{{
                    element.campaign
                    }}</span>
                  <span *ngSwitchCase="'mailing'" class="badge bg-success">{{
                    element.campaign
                    }}</span>
                  <span *ngSwitchCase="'targi'" class="badge bg-warning">{{
                    element.campaign
                    }}</span>
                  <span *ngSwitchCase="'inne'" class="badge bg-dark">{{
                    element.campaign
                    }}</span>
                </div>
              </ng-container>
            </ng-container>

            <ng-container *ngSwitchCase="'email'">
              <ng-container>
                <span>{{ element.email | cutString }}</span>
              </ng-container>
            </ng-container>

            <ng-container calss="booking-test" *ngSwitchCase="'booking'">
              <ng-container>
                <span class="badge bg-success" *ngIf="element.bookingDays < 41 && element.bookingDays > 0">{{
                  element.bookingDays }}</span>
                <span class="badge bg-danger" *ngIf="element.bookingDays < 0">
                  {{ element.bookingDays }}
                </span>
              </ng-container>
            </ng-container>

            <ng-container calss="booking-test" *ngSwitchCase="'date'">
              <ng-container>
                <span class="badge bg-success" *ngIf="element.testsDays >= 0">
                  {{ element.testsDays }}
                </span>
                <span class="badge bg-danger" *ngIf="element.testsDays < 0">
                  {{ element.testsDays }}
                </span>
                <span class="badge bg-danger" *ngIf="element.licenseDay < 0">
                  {{ element.licenseDay }} dni
                </span>

                <span class="badge bg-success" *ngIf="element.licenseDay > 0">
                  {{ element.licenseDay }} dni
                </span>
                <span class="badge bg-primary" *ngIf="element.statusDays >= 0 && element.statusDays !== null">
                  {{ element.statusDays }}
                </span>
              </ng-container>
            </ng-container>

            <ng-container *ngSwitchCase="'region'">
              <ng-container>
                <span>{{ element.state | cutString }}</span>
              </ng-container>
            </ng-container>

            <ng-container *ngSwitchCase="'contact'">
              <ng-container>
                <span>{{ element.contactToShow | cutString }}</span>
              </ng-container>
            </ng-container>

            <ng-container *ngSwitchCase="'message'">
              <ng-container>
                <span *ngIf="element.message && element.message.length < 20">
                  {{ element.message | cutString }}
                </span>

                <ngb-accordion *ngIf="element.message && element.message.length > 20"
                  (panelChange)="toggleAccordian($event, acc)" #acc="ngbAccordion" [activeIds]="activeIds">
                  <ngb-panel class="text-left" [id]="'ngb-panel-1' + element._id" [title]="element.message | cutString">
                    <ng-template ngbPanelContent>
                      <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
                        <div class="card">
                          <div class="card-body">
                            <ul>
                              <li>{{ element.message }}</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </ng-template>
                  </ngb-panel>
                </ngb-accordion>
              </ng-container>
            </ng-container>

            <ng-container *ngSwitchCase="'www'">
              <ng-container>
                <span>{{ element.website | cutString }}</span>
              </ng-container>
            </ng-container>

            <ng-container *ngSwitchCase="'dentist'">
              <ng-container>
                <span>{{ element.dentist | cutString }}</span>
              </ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="'accountLead'">
              <ng-container>
                <span *ngIf="element.accountLead">{{
                  element.accountLead | cutString
                  }}</span>

                <div *ngIf="!element.accountLead" ngbDropdown [autoClose]="false" class="d-inline-block dropdown-step">
                  <button type="button" id="dropdown1" ngbDropdownToggle>
                    do przypisania
                  </button>
                  <!-- <span   *ngIf="!element.accountLead ">do przypisania</span> -->
                  <div ngbDropdownMenu aria-labelledby="dropdown1" class="p-2">
                    <p ngbDropdownToggle class="text-end close_drop mb-0">
                      <i class="fa-regular fa-circle-xmark"></i>
                    </p>

                    <ul class="mb-0">
                      <li *ngFor="let person of persons" class="py-2 m-0 d-flex">
                        <input (change)="assingPersonToLead($event.target)" [value]="person._id" class="me-2"
                          type="checkbox" />
                        <span>{{ person.name }} {{ person.surname }}</span>
                      </li>
                    </ul>
                    <div class="text-center mt-3">
                      <button [disabled]="!currentPersonId" (click)="assingLead(element._id)"
                        class="rounded-btn btn active">
                        Zatwierdź
                      </button>
                    </div>
                  </div>
                </div>
              </ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="'surname'">
              <ng-container>
                <span>{{ element.surname | cutString }}</span>
              </ng-container>
            </ng-container>

            <ng-container #passchangeBtn *ngSwitchCase="'pass'">
              <form *ngIf="addPassBtn && productId === element._id" class="pass-form px-1">
                <input #password type="text" name="pass" />
              </form>

              <i *ngIf="!addPassBtn" class="fa-solid fa-rotate" (click)="showForm(passchangeBtn, element._id)"></i>
            </ng-container>

            <ng-container *ngSwitchCase="'add-admin'">
              <ng-container *ngIf="element.admin; else noDealer">
                {{ element.admin }}
              </ng-container>
              <div ngbDropdown>
                <ng-template #noDealer>
                  <i (click)="addAdmin(element._id)" mat-raised-button #tooltip="matTooltip" matTooltip="Podłącz"
                    [matTooltipPosition]="position.value" matTooltipHideDelay="1000" class="fa-solid fa-user-plus"></i>

                  <div *ngIf="
                      element.nrEminto !== 'aplikacja' &&
                      element.nrEminto !== 'virtual'
                    " class="dropdown owner">
                    <ul ngbDropdownMenu aria-labelledby="dropdownForm1" class="dropdown-menu text-small shadow"></ul>
                  </div>
                </ng-template>
              </div>
            </ng-container>

            <ng-container *ngSwitchCase="'status'">
              <div [ngSwitch]="element.status">
                <div class="d-flex align-items-center">
                  <span *ngSwitchCase="'nowy'" class="badge bg-warning">nowy</span>
                  <span *ngSwitchCase="'w sprzedaży'" class="badge bg-primary">w sprzedaży</span>
                  <span *ngIf="!element.testsAccept">
                    <span *ngSwitchCase="'testuje'" class="badge bg-danger">testuje</span>
                  </span>
                  <span *ngIf="element.testsAccept">
                    <span *ngSwitchCase="'testuje'" class="badge bg-danger accept">testuje</span>
                  </span>
                  <span *ngSwitchCase="'do wyczyszczenia'" class="badge bg-danger">do wyczyszczenia</span>
                  <span *ngSwitchCase="'wysłano ofertę'" class="badge bg-primary">wysłano ofertę</span>
                  <span *ngIf="
                      !element.bankTransferIncomplete &&
                      element.period === 'annual'
                    ">
                    <span *ngSwitchCase="'umowa'" class="badge bg-success">umowa<span
                        *ngIf="element.period === 'annual'"><sup>R</sup></span></span>
                  </span>
                  <span *ngIf="
                      element.bankTransferIncomplete &&
                      element.period === 'annual'
                    ">
                    <span *ngSwitchCase="'umowa'" class="badge bg-success inComplete">umowa<span
                        *ngIf="element.period === 'annual'"><sup>R</sup></span></span>
                  </span>
                  <span *ngIf="
                      !element.bankTransferIncomplete &&
                      element.period === 'monthly'
                    ">
                    <span *ngSwitchCase="'umowa'" class="badge bg-success">umowa<span
                        *ngIf="element.period === 'monthly'"><sup>M</sup></span></span>
                  </span>
                  <span *ngIf="
                      element.bankTransferIncomplete &&
                      element.period === 'monthly'
                    ">
                    <span *ngSwitchCase="'umowa'" class="badge bg-success inComplete">umowa<span
                        *ngIf="element.period === 'monthly'"><sup>M</sup></span></span>
                  </span>
                  <span *ngIf="!element.bankTransferIncomplete && !element.period">
                    <span *ngSwitchCase="'umowa'" class="badge bg-success">umowa<span
                        *ngIf="!element.period"><sup></sup></span></span>
                  </span>
                  <span *ngSwitchCase="'rezygnacja'" class="badge bg-dark">rezygnacja</span>
                  <span *ngSwitchCase="'rezygnacja z umowy'" class="badge bg-dark">rezygnacja z umowy</span>
                  <span *ngSwitchCase="'demo'" class="badge bg-warning">demo</span>
                  <span *ngSwitchCase="'umowa w trakcie'" class="badge bg-success">umowa w trakcie</span>
                  <span *ngSwitchCase="'umowa wysłana'" class="badge bg-warning">umowa wysłana</span>
                  <span *ngSwitchCase="'niezainteresowany'" class="badge bg-light">niezainteresowany</span>
                  <div ngbDropdown></div>
                </div>
              </div>
            </ng-container>

            <ng-container *ngSwitchCase="'id'">
              <span *ngIf="element.playerId" class="">
                {{ element.playerId }}
              </span>
            </ng-container>

            <ng-container *ngSwitchCase="'nr'">
              <span *ngIf="element.type" class="badge bg-light">DesktopApp</span>

              <span *ngIf="!element.emintoId && !element.type" class="badge bg-info">virtual</span>
              <span *ngIf="element.emintoId" class="">
                {{ element.emintoId }}
              </span>
            </ng-container>

            <ng-container *ngSwitchCase="'nr-inDesntis'">
              <span *ngIf="
                  !element.emintoId &&
                  !element.type &&
                  element.status === 'testuje'
                " class="badge bg-info">virtual</span>
              <span *ngIf="!element.emintoId && element.type" class="badge bg-info">desktopApp</span>
              <a routerLink="/products/list" [state]="{ data: element.emintoId }" *ngIf="element.emintoId">
                {{ element.emintoId }}
              </a>
            </ng-container>

            <ng-container *ngSwitchCase="'product'">
              <span *ngIf="element.nrEminto === 'virtual'" class="badge bg-light">virtual</span>
              <span *ngIf="element.nrEminto === 'aplikacja'" class="badge bg-warning">aplikacja</span>
              <span *ngIf="
                  element.nrEminto !== 'aplikacja' &&
                  element.nrEminto !== 'virtual'
                ">{{ element.nrEminto }}</span>
            </ng-container>

            <i (click)="removeProduct(element._id, true)" mat-raised-button #tooltip="matTooltip" matTooltip="Wyczyść"
              [matTooltipPosition]="position.value" matTooltipHideDelay="1000" class="fa-solid fa-trash-can" *ngIf="
                element.status === 'testuje' && element.nrEminto === 'virtual'
              "></i>

            <ng-container *ngSwitchCase="'isDentisAdded'">
              <span class="point red" *ngIf="!element.isDentist"></span>
              <span class="point" *ngIf="element.isDentist"></span>
            </ng-container>

            <ng-container *ngSwitchCase="'edit-lead'">
              <i (click)="showDelateLeadModal(element._id)" mat-raised-button #tooltip="matTooltip"
                matTooltip="Usuń Lead" [matTooltipPosition]="position.value" matTooltipHideDelay="1000"
                class="fa-solid fa-trash-can"></i>
              <i *ngIf="!element.dentistId" (click)="createDentistFrromLead(element._id)" mat-raised-button
                #tooltip="matTooltip" matTooltip="Stwórz gabinet" [matTooltipPosition]="position.value"
                matTooltipHideDelay="1000" class="fa-solid fa-circle-plus"></i>
            </ng-container>

            <ng-container *ngSwitchCase="'lead-status'">
              <ng-container>
                <div *ngIf="!element.steps[1]; else status" ngbDropdown #ddStatus="ngbDropdown" [autoClose]="false"
                  class="d-inline-block dropdown-step">
                  <button type="button" id="dropdown-status" (click)="
                      closeOthersDropdowns(ddStatus, element, $event, true)
                    " class="dropdown-step" ngbDropdownToggle>
                    {{ element.status }}
                  </button>
                  <div ngbDropdownMenu aria-labelledby="dropdown-status" class="p-2">
                    <p ngbDropdownToggle class="text-end close_drop mb-0">
                      <i class="fa-regular fa-circle-xmark"></i>
                    </p>
                    <p class="text-center">wybierz status</p>
                    <ul class="mb-0">
                      <li class="py-2 m-0 d-flex">
                        <input [checked]="element.status === 'nowy'" class="me-2" type="checkbox"
                          value="63736cc7def8d1e5faf8db8c" data-days="1" data-next="true"
                          (change)="selectLeadStep($event.target)" />
                        <span>nowy</span>
                      </li>
                      <li class="d-flex pb-2 m-0 flex-wrap align-items-center">
                        <input [checked]="element.status === 'testy'" class="me-2" type="checkbox"
                          value="637369c1def8d1e5faf8db8b" (change)="selectLeadStep($event.target)" />
                        <span class="pe-2">testy</span>
                      </li>
                    </ul>
                    <div class="text-center mt-3">
                      <button (click)="changeLeadStatus(element._id)" class="btn-primary btn active">
                        Zatwierdź
                      </button>
                    </div>
                  </div>
                </div>
                <ng-template #status>
                  <span [ngStyle]="{ opacity: '0.5', padding: '0 6px' }">
                    {{ element.status }}
                  </span>
                </ng-template>
              </ng-container>
            </ng-container>

            <ng-container *ngSwitchCase="'step-1'">
              <ng-container *ngIf="element.steps">
                <div ngbDropdown #dd1="ngbDropdown" [autoClose]="false" class="d-inline-block dropdown-step">
                  <button 
                  [ngStyle]="{'pointer-events': element.steps[0]?.active ? 'auto' : 'none', 'opacity' : element.steps[0]?.active ? '1' : '0.5' }"
                  type="button" id="dropdown-step1" (click)="closeOthersDropdowns(dd1, element, $event)"
                    class="dropdown-step" ngbDropdownToggle>
                    {{ element.steps[0]?.currentStep }}
                  </button>
                  <app-step-dropdown [stepNumber]="element.steps[0]?.stepNumber" *ngIf="element.status === 'nowy'" (showModal)="showModal($event)"    [leadId]="element._id" [stepsInput]="dataSteps[0][0]"></app-step-dropdown>
                  <app-step-dropdown [stepNumber]="element.steps[0]?.stepNumber" *ngIf="element.status === 'testy'"  [leadId]="element._id" [stepsInput]="dataSteps[0][1]"></app-step-dropdown>
               
                </div>
              </ng-container>
            </ng-container>

                <!-- Lead step 2 -->

            <ng-container *ngSwitchCase="'step-2'">
              <ng-container *ngIf="element.steps">
                <div ngbDropdown #dd2="ngbDropdown" [autoClose]="false" class="d-inline-block dropdown-step">
                  <button
                    [ngStyle]="{'pointer-events': element.steps[1]?.active ? 'auto' : 'none', 'opacity' : element.steps[1]?.active ? '1' : '0.5' }"
                    type="button" id="dropdown-step1" (click)="closeOthersDropdowns(dd2, null, $event)"
                    class="dropdown-step" ngbDropdownToggle>
                    {{ element.steps[1]?.currentStep }}
                  </button>
                  <app-step-dropdown [stepNumber]="element.steps[1]?.stepNumber"  [leadId]="element._id" [stepsInput]="dataSteps[1]"></app-step-dropdown>
                
                </div>
              </ng-container>
            </ng-container>

            <!-- Lead step 3  -->

            <ng-container *ngSwitchCase="'step-3'">
              <ng-container *ngIf="element.steps">
                <div ngbDropdown #dd3="ngbDropdown" [autoClose]="false" class="d-inline-block dropdown-step">
                  <button
                    [ngStyle]="{'pointer-events': element.steps[2]?.active ? 'auto' : 'none', 'opacity' : element.steps[2]?.active ? '1' : '0.5' }"
                    type="button" id="dropdown-step1" (click)="closeOthersDropdowns(dd3, element, $event)"
                    class="dropdown-step" ngbDropdownToggle>
                    {{ element.steps[2]?.currentStep }}
                  </button>
                  <app-step-dropdown [stepNumber]="element.steps[2]?.stepNumber"  [leadId]="element._id" [stepsInput]="dataSteps[2]"></app-step-dropdown>
                </div>
              </ng-container>
            </ng-container>

            <!-- Lead step 4 -->


            <ng-container *ngSwitchCase="'step-4'">
              <ng-container *ngIf="element.steps">
                <div ngbDropdown #dd4="ngbDropdown" [autoClose]="false" class="d-inline-block dropdown-step">
                  <button
                    [ngStyle]="{'pointer-events': element.steps[3]?.active ? 'auto' : 'none', 'opacity' : element.steps[3]?.active ? '1' : '0.5' }"
                    type="button" (click)="closeOthersDropdowns(dd4, element, $event)" id="dropdown-step1"
                    class="dropdown-step" ngbDropdownToggle>
                    {{ element.steps[3]?.currentStep }}
                  </button>
                  <app-step-dropdown [stepNumber]="element.steps[3]?.stepNumber"  [leadId]="element._id" [stepsInput]="dataSteps[3]"></app-step-dropdown>
                  
                </div>
              </ng-container>
            </ng-container>

            <!-- Lead step 5  -->

            <ng-container *ngSwitchCase="'step-5'">
              <ng-container *ngIf="element.steps">
                <div ngbDropdown #dd5="ngbDropdown" [autoClose]="false" class="d-inline-block dropdown-step">
                  <span *ngIf="!element.steps[4] && element.controlPhoneDate">telefon kontrolny</span>
                  <button *ngIf="element.steps[4]" type="button" (click)="closeOthersDropdowns(dd5, element, $event)"
                    id="dropdown-step1" class="dropdown-step" ngbDropdownToggle>
                    {{ element.steps[4]?.currentStep }}
                  </button>
                  <app-step-dropdown [stepNumber]="element.steps[4]?.stepNumber"  [leadId]="element._id" [stepsInput]="dataSteps[4]"></app-step-dropdown>
                </div>
              </ng-container>
            </ng-container>

            <ng-container *ngSwitchCase="'contractors'">
              <span *ngIf="element.distributor && !changeAcconunt">
                {{ element.distributor | cutString }} 
              </span>
              <span *ngIf="
                  element.distributor &&
                  changeAcconunt &&
                  element._id !== currentRow
                ">
                {{ element.distributor | cutString }}
              </span>
              <i *ngIf="showDistributors && element._id === currentRow" class="fa-solid fa-minus minus" (click)="
                  changeViewOnProducts(false, $event.target, element._id)
                "></i>
              <i *ngIf="!element.account" class="fa-solid fa-plus plus"
                (click)="changeViewOnProducts(true, $event.target, element._id)"></i>
              <div class="d-inline-block" *ngIf="
                  (!element.distributor &&
                    showDistributors &&
                    element._id === currentRow) ||
                  (showDistributors && element._id === currentRow)
                " ngbDropdown #myDrop="ngbDropdown">
                <button class="me-2" id="dropdownManual" ngbDropdownAnchor (focus)="myDrop.open()">
                  {{ currentContractorName | cutString }}
                </button>
                <div ngbDropdownMenu aria-labelledby="dropdownManual">
                  <button (click)="getSelerByContractor('', element._id)" ngbDropdownItem>
                    Nie dotyczy
                  </button>
                  <button (click)="
                      getSelerByContractor(
                        contractor._id,
                        element._id,
                        contractor.shortName
                      )
                    " *ngFor="let contractor of element.contractors" ngbDropdownItem>
                    {{ contractor.shortName }}
                  </button>
                </div>
              </div>
            </ng-container>

            <ng-container *ngSwitchCase="'dentist-contractors'">
              <i *ngIf="
                  !element.distributor && !element.account && !changeAcconunt
                " class="fa-solid fa-minus minus" (click)="changeView(false, $event.target, element._id)"></i>
              <i *ngIf="
                  !element.distributor && !element.account && !changeAcconunt
                " class="fa-solid fa-plus plus" (click)="changeView(true, $event.target, element._id)"></i>
                
              <span *ngIf="element.distributor && !element.customDistributor">
                {{ element.distributor  }} 
              </span>
              <span *ngIf="element.distributor && element.customDistributor">
                {{ element.distributor | cutString: true }} 
              </span>
                 

              <div [ngClass]="'hide'" ngbDropdown #myDrop="ngbDropdown">
                <button class="me-2" id="dropdownManual" ngbDropdownAnchor (focus)="myDrop.open()">
                  {{ currentContractorName }}
                </button>
                <div ngbDropdownMenu aria-labelledby="dropdownManual">
                  <button (click)="getSelerByContractor('', element._id)" ngbDropdownItem>
                    Nie dotyczy
                  </button>
                  <button (click)="
                      getSelerByContractor(
                        contractor._id,
                        element._id,
                        contractor.name
                      )
                    " *ngFor="let contractor of element.contractors" ngbDropdownItem>
                    {{ contractor.name }}
                  </button>
                </div>
              </div>
            </ng-container>

            <ng-container *ngSwitchCase="'sell'">
              <span *ngIf="
                  element.account &&
                  changeAcconunt &&
                  element._id !== currentRow
                ">
                {{ element.account | cutString }}
              </span>
              <span *ngIf="element.account && !changeAcconunt">
                {{ element.account | cutString }}
              </span>

              <div *ngIf="element._id === currentRow && showPersonListByContractor" class="d-inline-block" ngbDropdown
                #myDrop="ngbDropdown">
                <button class="me-2" id="dropdownManual" ngbDropdownAnchor (focus)="myDrop.open()">
                  {{ currentPersonName }}
                </button>
                <div ngbDropdownMenu aria-labelledby="dropdownManual">
                  <button (click)="
                      addProductToSeler(
                        contractorId,
                        currentRow,
                        person._id,
                        person.name
                      )
                    " *ngFor="let person of persons" ngbDropdownItem>
                    {{ person.name }} {{ person.surname }}
                  </button>
                </div>
              </div>

              <i *ngIf="role === 'distributor'" class="fa-solid fa-minus minus"
                (click)="changeViewSellers(false, $event.target, element._id)"></i>
              <i *ngIf="role === 'distributor'" class="fa-solid fa-plus plus"
                (click)="changeViewSellers(true, $event.target, element._id)"></i>

              <div *ngIf="
                  role === 'distributor' &&
                  showPersonListByContractor &&
                  element._id === currentRow
                " class="d-inline-block" ngbDropdown #myDrop="ngbDropdown">
                <button class="me-2" id="dropdownManual" ngbDropdownAnchor (focus)="myDrop.open()">
                  {{ currentPersonName }}
                </button>
                <div ngbDropdownMenu aria-labelledby="dropdownManual">
                  <button (click)="
                      addProductToSeler(
                        contractorId,
                        currentRow,
                        person._id,
                        person.name,
                        element._id
                      )
                    " *ngFor="let person of persons" ngbDropdownItem>
                    {{ person.name }}
                  </button>
                </div>
              </div>
            </ng-container>

            <ng-container *ngSwitchCase="'data-order'">
              {{ element.date | date }}
            </ng-container>

            <ng-container *ngSwitchCase="'lead-date'">
              {{ element.date | date : "dd/M/yyyy" }}
            </ng-container>
            <ng-container *ngSwitchCase="'lead-date-1'">
              <span *ngIf="element.steps && element.steps[0]?.currentStep !== 'niezainteresowany'" [ngStyle]="{
                  opacity: element.steps[0]?.active ? '1' : '0.5',
                  color: element.steps[0]?.active ? '#ff7065' : 'initial',
                  'font-weight': element.steps[0]?.active ? 'bold' : 'initial'
                }">{{ element.steps[0]?.date | date : "dd/M/yyyy" }}</span>
            </ng-container>
            <ng-container *ngSwitchCase="'lead-date-2'">
              <div *ngIf="element.steps" class="d-flex align-items-center position-relative pe-5">
                <span *ngIf="element.steps[1]" [ngClass]="element.steps[1].active ? 'data_active' : ''" [ngStyle]="{
                    opacity: element.steps[1].active ? '1' : '0.5',
                    color: element.steps[1].active ? '#ff7065' : 'initial',
                    'font-weight': element.steps[1].active ? 'bold' : 'initial'
                  }">{{ element.steps[1].date | date : "dd/M/yyyy" }}</span>
              </div>
            </ng-container>
            <ng-container *ngSwitchCase="'lead-date-3'">
              <div *ngIf="element.steps" class="d-flex align-items-center position-relative pe-5">
                <span *ngIf="element.steps[2]" [ngClass]="element.steps[2].active ? 'data_active' : ''" [ngStyle]="{
                    opacity: element.steps[2].active ? '1' : '0.5',
                    color: element.steps[2].active ? '#ff7065' : 'initial',
                    'font-weight': element.steps[2].active ? 'bold' : 'initial'
                  }">{{ element.steps[2].date | date : "dd/M/yyyy" }}</span>
              </div>
            </ng-container>

            <ng-container *ngSwitchCase="'lead-date-4'">
              <div *ngIf="element.steps" class="d-flex align-items-center position-relative pe-5">
                <span *ngIf="element.steps[3]" [ngClass]="element.steps[3].active ? 'data_active' : ''" [ngStyle]="{
                    opacity: element.steps[3].active ? '1' : '0.5',
                    color: element.steps[3].active ? '#ff7065' : 'initial',
                    'font-weight': element.steps[3].active ? 'bold' : 'initial'
                  }">{{ element.steps[3].date | date : "dd/M/yyyy" }}</span>
              </div>
            </ng-container>

          


            <ng-container *ngSwitchCase="'lead-date-5'">
              <div *ngIf="element.steps" class="d-flex align-items-center position-relative pe-5">
                <span *ngIf="element.steps[4]" [ngClass]="element.steps[4].active ? 'data_active' : ''" [ngStyle]="{
                    opacity: element.steps[4].active ? '1' : '0.5',
                    color: element.steps[4].active ? '#ff7065' : 'initial',
                    'font-weight': element.steps[4].active ? 'bold' : 'initial'
                  }">{{ element.steps[4].date | date : "dd/M/yyyy" }}</span>
                <span *ngIf="
                    element.steps[1] &&
                    !element.steps[1].active &&
                    !element.steps[4] &&
                    element.controlPhoneDate &&
                    !isToday(element.controlPhoneDate)
                  ">{{ element.controlPhoneDate | date : "dd/M/yyyy" }}
                </span>
                <span [ngStyle]="{
                    color: '#ff7065',
                    'font-weight': 'bold'
                  }" *ngIf="
                    element.steps[1] &&
                    !element.steps[1].active &&
                    !element.steps[4] &&
                    element.controlPhoneDate &&
                    isToday(element.controlPhoneDate)
                  ">{{ element.controlPhoneDate | date : "dd/M/yyyy" }}
                </span>
              </div>
            </ng-container>
          
            <ng-container *ngSwitchCase="'lead-service'">
              <span *ngIf="element.addionalService" >{{ element.addionalService }}  </span>
            </ng-container>
            <ng-container *ngSwitchCase="'data-send'">
              {{ element.complateDate | date : "dd/M/yyyy" }}
            </ng-container>

            <ng-container *ngSwitchCase="'send-product'">
              <i (click)="sendProduct(element._id)" class="fa-regular fa-circle-check send-icon"></i>
            </ng-container>

            <ng-container *ngSwitchCase="'date'">
              <div>
                <span *ngIf="element.timeToEnd < 0" class="badge bg-danger">{{ element.timeToEnd }} dni</span>
                <span *ngIf="element.timeToEnd >= 0 && element.timeToEnd < 100" class="badge bg-light">{{
                  element.timeToEnd }} dni</span>
                <span *ngIf="element.timeToEnd >= 100" class="badge bg-success">
                  {{ element.timeToEnd }} dni
                </span>
                <span *ngIf="element.licenseDays < 0" class="badge bg-danger">{{ element.licenseDays }} dni</span>
                <span *ngIf="element.licenseDays >= 0" class="badge bg-success">{{ element.licenseDays }} dni</span>
                <i mat-raised-button #tooltip="matTooltip" matTooltip="Przedłuż licencje"
                  [matTooltipPosition]="position.value" matTooltipHideDelay="1000"
                  (click)="renewLicensModalOpen(element._id)" *ngIf="element.licenseDays < 500 && element.shortName"
                  class="fa-solid fa-calendar-plus"></i>
              </div>
            </ng-container>

            <ng-container *ngSwitchDefault>
              <div [ngClass]="{ 'fw-bold': tableColumn.bold }">
                {{ element | dataPropertyGetter : tableColumn.dataKey }}
              </div>
            </ng-container>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>

      <!-- Pagination -->
      <mat-paginator   (page)="pageEvent($event)" [pageSizeOptions]="paginationSizes" [pageSize]="defaultPageSize" showFirstLastButtons>
      </mat-paginator>
    </ng-container>
  </div>
</div>

<ng-template class="mod" #productModal let-modal>
  <div class="modal-header">
    <h4 *ngIf="!isClear" class="modal-title" id="modal-basic-title">
      Czy napewno chcesz usunąć player z listy?
    </h4>
    <h4 *ngIf="isClear" class="modal-title" id="modal-basic-title">
      Wyczyśc produkt
    </h4>
  </div>
  <div class="modal-body">
    <div *ngIf="isClear" class="align-items-center d-flex">
      <p class="order-2 m-0 ms-2">
        Odepnij produkt od handlowca - ustaw status produktu jako nowy
      </p>
      <div class="order-1 form-group">
        <input (click)="setCheckBoxValue($event.target.checked)" type="checkbox" name="name" value="" />
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button *ngIf="!isClear" type="button" (click)="remove()" class="btn btn-primary">
      Usuń
    </button>
    <button *ngIf="isClear" type="button" (click)="clearPlayer()" class="btn btn-primary">
      Wyczyść
    </button>
  </div>
</ng-template>

<ng-template class="mod" #removeLeadModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      Czy napewno chcesz usunąć lead z listy?
    </h4>
  </div>
  <div class="modal-body"></div>
  <div class="modal-footer">
    <button type="button" (click)="removeLead()" class="btn btn-primary">
      Usuń lead
    </button>
  </div>
</ng-template>

<app-lead-modal [currentLead]="currentLead" [stepSelected]="stepSelected" [show]="showLeadModal"
  (closeModal)="closeLeadModal()"></app-lead-modal>

<ng-template class="mod" #licensModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Przedłuż licencje</h4>
  </div>
  <div class="modal-body">
    <p>Długość licencji</p>
    <div class="d-flex align-items-center">
      <i (click)="descentLicenst()" class="fa-solid fa-minus minus me-2"></i>
      <input [(ngModel)]="licensLenght" class="text-center" type="text" name="licensLenght" />
      <i (click)="incrementLicens()" class="fa-solid fa-plus plus ms-2"></i>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" (click)="renevLicens()" class="btn btn-primary">
      Przedłuż
    </button>
  </div>
</ng-template>
