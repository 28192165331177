import { select } from '@ngrx/store';
import { AfterViewInit, Component, ElementRef, ViewChild } from "@angular/core";



@Component({
  selector: 'app-calculator',
  styleUrls: ['./calculator.component.scss'],
  templateUrl: './calculator.component.html'
})



export class CalculatorComponent implements AfterViewInit {



  lostTimeValue: any = 22.4;
  lostTimeValuePerDoctor: any;
  extraProfit: any = 672;
  newServicesPerMont: any = 2.24;
  extra: any = 448;
  sumOfPatiens: any;;
  sefeTimeDoctorsMont: any = 3.7;
  finalProfits: any;
  finalProfits2: any;


  @ViewChild('patients') patients: ElementRef;
  @ViewChild('time') time: ElementRef;
  @ViewChild('lostTime') lostTime: ElementRef;
  @ViewChild('safeTimePerPatiens') safeTimePerPatiens: ElementRef;
  @ViewChild('costPerDoctor') costPerDoctor: ElementRef;
  @ViewChild('sefeTimeDoctors') sefeTimeDoctors: ElementRef;
  @ViewChild('conversion') conversion: ElementRef;
  @ViewChild('averageCostPerService') averageCostPerService: ElementRef;
  @ViewChild('numbersOfDoctors') numbersOfDoctors: ElementRef;
  @ViewChild('patiensPerDoctorOnWeek') patiensPerDoctorOnWeek: ElementRef;




  calc(e, conversion?) {
    var x = '301.474.4062';

    x = x.replace(/\D+/g, '')
      .replace(/(\d{2})(\d{1})(\d{4})/, '$1 %');


    const reg = /\d{3}/g
    const str = "Java323Scr995ip4894545t";
    const newStr = str.replace(reg, "%");
    console.log(newStr);
    function setCharAt(str, index, chr) {
      if (index > str.length - 1) return str;
      return str.substring(0, index) + chr + str.substring(index + 1);
    }
    console.log(this.conversion.nativeElement.value.length)

    if (e.keyCode !== 8) {
      this.conversion.nativeElement.value = this.conversion.nativeElement.value.replace(',', '.').replace(/(\d{3})/, '$1 ');
    }





    this.lostTimeValuePerDoctor =
      (this.patients.nativeElement.value * this.safeTimePerPatiens.nativeElement.value / 60).toFixed(1);
    this.sumOfPatiens =
      this.numbersOfDoctors.nativeElement.value * this.patiensPerDoctorOnWeek.nativeElement.value;
    this.patients.nativeElement.value =
      4 * this.sumOfPatiens;
    this.lostTimeValue =
      (this.patients.nativeElement.value * this.time.nativeElement.value / 60).toFixed(1);
    this.sefeTimeDoctorsMont =
      (this.patients.nativeElement.value * this.safeTimePerPatiens.nativeElement.value / 60).toFixed(1);
    this.extraProfit =
      (this.costPerDoctor.nativeElement.value * this.sefeTimeDoctorsMont).toFixed(1);
    this.newServicesPerMont =
      this.conversion.nativeElement.value.includes('%') ?

        (this.conversion.nativeElement.value.substring(0, this.conversion.nativeElement.value.length - 1) / 100 * this.patients.nativeElement.value).toFixed(2) :
        (this.conversion.nativeElement.value.substring(0, this.conversion.nativeElement.value.length) / 100 * this.patients.nativeElement.value).toFixed(2)





    this.extra =
      (this.newServicesPerMont * parseInt(this.averageCostPerService.nativeElement.value)).toFixed(1);
    
    this.finalProfits =
      parseInt(this.extraProfit) + parseInt(this.extra);
    this.finalProfits2 =
      parseInt(this.extra);


  }




  ngAfterViewInit(): void {


    this.conversion.nativeElement.value = '4%'
    this.sumOfPatiens = this.numbersOfDoctors.nativeElement.value * this.patiensPerDoctorOnWeek.nativeElement.value;
    this.patients.nativeElement.value =
      4 * this.sumOfPatiens;
    this.finalProfits =
      this.extraProfit + this.extra;
    this.finalProfits2 =
      this.extra;

    this.newServicesPerMont =
      this.conversion.nativeElement.value.includes('%') ?

        (this.conversion.nativeElement.value.substring(0, this.conversion.nativeElement.value.length - 1) / 100 * this.patients.nativeElement.value).toFixed(2) :
        (this.conversion.nativeElement.value.substring(0, this.conversion.nativeElement.value.length) / 100 * this.patients.nativeElement.value).toFixed(2)



    this.extra =
      (this.newServicesPerMont * parseInt(this.averageCostPerService.nativeElement.value)).toFixed(1);
    this.finalProfits2 =
      this.extra;
    this.sefeTimeDoctorsMont =
      (this.patients.nativeElement.value * this.safeTimePerPatiens.nativeElement.value / 60).toFixed(1);
    this.extraProfit =
      (this.costPerDoctor.nativeElement.value * this.sefeTimeDoctorsMont).toFixed(1);
    this.finalProfits =
      parseInt(this.extraProfit) + parseInt(this.extra);




  }
}
