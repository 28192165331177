import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { SharedModule } from "../common/shared.module";
import * as fromOrders from "./reducers"
import { NgxMaskModule, IConfig } from 'ngx-mask'
import { FormsModule } from '@angular/forms';
import { OrdersRoutingModule } from "./orders-routing.module";
import { OrdersEffects } from "./effects"
import { ListOrdersComponent } from "./components/orders/orders-list.component";
import { HistoryOrdersComponent } from "./components/history/history.component";
import { OrdersPageComponent } from "./containers/orders.page.component";
import { OrdersListPageComponent } from "./containers/orders-list.page.component";
import { HistoryListPageComponent } from "./containers/history.page.componernt";
export const COMPONENTS = [

  ListOrdersComponent,
  HistoryOrdersComponent,
  OrdersPageComponent,
  OrdersListPageComponent,
  HistoryListPageComponent


]
const maskConfig: Partial<IConfig> = {
  dropSpecialCharacters: false,
};

@NgModule({
  imports: [
    FormsModule,
    NgxMaskModule.forRoot(),
    CommonModule,
    SharedModule,
    OrdersRoutingModule,
    ReactiveFormsModule,
    StoreModule.forFeature(fromOrders.ordersFeatureKey, fromOrders.reducers),
    EffectsModule.forFeature([OrdersEffects])

  ],

  declarations: COMPONENTS
})
export class OrdersModule { }
