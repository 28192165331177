import {Component, Input, Output, EventEmitter, OnInit} from '@angular/core';
import {FormGroup, FormControl} from '@angular/forms';
import {Credentials} from '../models/auth.model';
import {Utils} from '../../common/utils';

@Component({
    selector: 'app-login-form',
    template: `
    <div class="d-flex text-center justify-content-center w-100 form-container">
        <main class="form-signin align-items-center">
        <form class="w-100" [formGroup]="form" (ngSubmit)="submit()">
            <img class="mb-4" src="assets/img/logo-dark.svg" alt="" height="40" />
          

            <div class="form-floating">
            <input type="email" class="form-control" id="floatingInput" placeholder="name@example.com" (click)="ngOnInit()" formControlName="email" required email />
            <label for="floatingInput">Adres e-mail</label>
            </div>
            <div class="form-floating">
            <input type="password" class="form-control" id="floatingPassword" placeholder="Password" (click)="ngOnInit()" formControlName="password" />
            <label for="floatingPassword">Hasło</label>
            </div>
            <button class="w-100 btn btn-primary" type="submit">Zaloguj się</button>
            <a  [routerLink]="['/reset-password']"  class="mt-3 d-block text-decoration-none">Zapomniałeś hasła?</a>
        </form>
        </main>
    </div>
    `,
    styleUrls: ['../../../assets/scss/_login.scss']
})
export class LoginFormComponent implements OnInit {
    @Input() error: any | null;
    @Output() submitted = new EventEmitter<Credentials>();
    
    public zoomIn: boolean = true;

    form: FormGroup = new FormGroup({
        email: new FormControl(''),
        password: new FormControl('')
    });

    ngOnInit(): void {
        this.error = null;
    }

    submit() {
        if (this.form.valid) {
            this.form.get('password').enable();
            this.submitted.emit(this.form.value);
        }
        else {
            Utils.validateFormFields(this.form);
        }
        this.zoomIn = false
    }
}
