<div *ngIf="dentist[0]" class="container-fluid mb-3">
  <hr />
  <div class="col">

    <h2 class="title">Dodaj produkt / zmień status</h2>

  </div>

  <div class="row">
    <div class="col-4">
      <div class="card     h-100 p-1 p-xxl-4">
        <div class="card-body">
          <h5 class="section-title"><!--Produkty--></h5>
         


          <p *ngIf="dentist[0].assigned.length === 0">Brak przypisanych produktów</p>




          <a *ngIf="dentist[0].assigned.length >  0 " (click)="addAnotherProduct()" class="d-none d-sm-inline-block btn btn-primary px-5 mb-3">Dodaj kolejny produky</a>


          <div *ngIf="addProduct || dentistCurrent[0].assigned.length === 0" class="row mb-3">
            <h5 class="section-title">Wybierz produkt</h5>
            <div *ngIf="dentistCurrent[0]?.available" class="col">

              <select (change)="selectProductFromAvailable($event.target.value)" class="form-select large" id="">
                <option [ngValue]="null" [selected]="true">Wybierz produkt</option>
                <option [value]="prod._id" *ngFor="let prod of dentist[0]?.available">{{ prod.login }}</option>
                <option  [disabled]="!isDesktopAppAllowed" [value]="'appDesktop'" >Aplikacja Desktop</option>
              </select>
            </div>

          </div>
        </div>
      </div>
    </div>
    <div class="col-4">
      
      <div *ngIf="products" class="h-100 card p-1 p-xxl-4 mb-4">
        <div class="card-body">
          
        <div class="d-flex" *ngFor="let product of products" >
          <input   #products (change)="addProduc($event.target)"  name="{{product._id }}" class="me-1" type="checkbox" value="{{ product._id  }}" />
          <div>
            <label for="{{ product._id }} "> {{ product.name }}</label>
            <span  *ngIf="product.status"  class="ms-1">{{ product.status}}</span>
          </div>
          <!--<div *ngIf="product.type">
            <label *ngIf="product.type" for="{{ product._id }} "> {{ product.type }}</label>
            <span class="ms-1">{{ product.status}}</span>

          </div>-->

         
        </div>


          

      
          <div *ngIf="!isRomove">

          </div>
          <h5 class="section-title mt-1">Status Produktu</h5>
          <div class="row mb-3">

            <div class="col-12">
              <div *ngIf="isRomove">
                <select (change)="changeStatus($event.target.value)" class="form-select large" id="specificSizeSelect">
                  <option [selected]="s?.name === dentistCurrent[0]?.assigned[0]?.status " [ngClass]="s.name === 'w rezygnacji' ? 'selected' :  s.name === 'rezygnacja z umowy' ? 'selected'  : s.name ===  'testuje'  ? 'selected' : s.name === 'umowa w trakcie' ? 'selected'  :s.name === 'rezygnacja' ? isRomove ? 'selected' : '' : ''   " [value]="s._id" *ngFor="let s of dentist[0].statusList">{{ s.name }}</option>
                </select>
              </div>
              <div *ngIf="!isRomove">
                <select (change)="changeStatus($event.target.value)" class="form-select large" id="specificSizeSelect">
                  <option [ngClass]="s.name === 'rezygnacja z umowy' ? 'selected'  :   s.name ===  'testuje'  ? 'selected' : s.name === 'umowa w trakcie' ? 'selected'  : s.name === 'rezygnacja' ? isRomove ? 'selected' : '' : ''   " [value]="s._id" *ngFor="let s of dentist[0].statusList">{{ s.name }}</option>
                </select>
              </div>
            </div>
         
            <div class="col my-3"><button (click)="selectStatus()" type="submit" [disabled]="selectedProducts.length > 0  ? false : true " class="d-none d-sm-inline-block btn btn-primary px-5">Wybierz</button></div>
            <div  *ngIf="dentistCurrent[0]?.bankTransferIncomplete && role === 'eminto'" class="col-12"><button (click)="confirmPaymentsPopUp()"   class="d-none d-sm-inline-block btn btn-primary px-5">Rozlicz</button></div>

          </div>


        </div>
      </div>






    </div>
    <div *ngIf="selectedStatu" class="col-4">
      <div *ngIf="selectedStatu   && !protocol && saveStatusView  " class=" h-100 card p-1 p-xxl-4 mb-4">
        <div class="card-body">
          <h5 class="section-title">Akcja</h5>
          <div *ngFor="let p of  selectedProducts; last as isLast">
            <span  [ngClass]="isLast ? 'mb-1' : ''"  class="d-block">{{ p.name  }}</span>
          </div>

          <div class="col">
            <button (click)="ghostProtocol()" [disabled]="form1.invalid" class="d-none d-sm-inline-block btn btn-primary px-5 mb-3">Wygeneruj Link</button>
            <div *ngIf="selectedStatusName.name === 'umowa w trakcie' ">
              <div class="d-flex mb-2">
                <input class=" me-1" (change)="changePrice($event.target.checked)" type="checkbox" name="price" id="price" />
                <span for="price">Cena indywidualna</span>
              </div>
              <div class="mb-2" *ngIf="isChangePrice">
                <form [formGroup]="form1">
                  <div class="d-flex align-items-center ">
                    <input formControlName="price1" #price1 type="text" class="flex-shrink-4 my-1 me-1 form-control" id="price1" />
                    <span class="flex-grow-4">opłata startowa / abonament rocznym </span>
                  </div>
                  <div class="d-flex align-items-center ">
                    <input formControlName="price2" #price2 type="text" class="flex-shrink-4 my-1 me-1 form-control" id="price2" />
                    <span class="flex-grow-4">opłata miesięczna ( jeżeli abonament roczny zostaw puste )</span>
                  </div>
                </form>
              </div>
              <div class="d-flex flex-column">
                <div>
                  <p>Długość Licencji</p>
                </div>
                <div class="d-flex   align-items-center">
                  <i  (click)="descentLicenst()" class="fa-solid fa-minus minus me-2"></i>
                  <input    [(ngModel)]="licensLenght"  class="text-center" type="text" name="licensLenght" />
                  <i (click)="incrementLicens()" class="fa-solid fa-plus plus ms-2"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--<div *ngIf="saveStatusView" class="card p-4 mb-4">
    <div class="card-body">
      <h5 class="section-title">Akcja</h5>
      <div class="col">
        <a (click)="setStatusDentist()" class="d-none d-sm-inline-block btn btn-primary px-5 mb-3">Zapisz status</a>
      </div>
    </div>
  </div>-->
      <div *ngIf="protocol && !isTest" class="h-100 card p-1 p-xxl-4 mb-4">
        <div class="card-body">
          <h5 class="section-title">Akcjadcsdc</h5>
          <div class="col">
            <!--<a href="#" class="d-none d-sm-inline-block btn btn-secondary px-5 mb-3"><Wygenerowano</a><br />-->
            <p #link>http://sale.eminto.pl/p</p>
            <a (click)="copy(link)" class="d-none d-sm-inline-block btn btn-primary px-5 me-3">Pobierz </a>
            <!--<a (click)="addProcduct()"  (click)="copy(link) class="d-none d-sm-inline-block btn btn-primary px-5 me-3">Pobierz </a>-->

          </div>
        </div>
      </div>
     
      <div *ngIf="protocol && isTest && linkForTest" class="card p-1 p-xxl-4 mb-4">
        <div class="card-body">
          <h5 class="section-title">Wygenerowano link:</h5>
          <div class="col">
            <p #link>{{  linkForTest  }}</p>
            <!--<a href="#" class="d-none d-sm-inline-block btn btn-secondary px-5 mb-3"><Wygenerowano</a><br />-->
            <a *ngIf="!isLinkCopied" class=" btn btn-primary px-5" (click)="copy(link)">Skopiuj Link </a>
            <a *ngIf="isLinkCopied"   class=" btn btn-primary px-5" (click)="copy(link)">Skopiowano link </a>

            <!--<a  class="d-none d-sm-inline-block btn btn-primary px-5 me-3">Pobierz</a>-->
            <!--<a href="#" class="d-none d-sm-inline-block btn btn-primary px-5">Prześlij skan protokołu</a>-->
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="selectedProduct ===  'virtual'" class="col-4">
      <div class="card p-4 mb-4">
        <div class="card-body">
          <div class="row mb-3">
            <div class="col">
              <select class="form-select large" id="specificSizeSelect">
                <option selected>Wybierz ilość dni</option>
              </select>
            </div>
            <div class="col"><a class="d-none d-sm-inline-block btn btn-primary px-5">Wybierz</a></div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="selectedProduct ===  'aplikacja desktop'" class="col-4">
      <div class="card p-4 mb-4">
        <div class="card-body">
          <div class="row mb-3">
            <div class="col">

            </div>

            <a routerLink="/products/add-product/{{ dentist._id  }}" class="d-none d-sm-inline-block btn btn-primary px-5 mb-3">Wygeneruj klucz dostępowy</a>
          </div>
        </div>
      </div>
    </div>

  </div>

  <hr />

  <div class="row pt-4 d-flex justify-content-between">
    <div class="col">
      <h2 class="title">{{ dentist[0].shortName }}</h2>
    </div>
    <div class="my-3 d-flex align-items-center" >
      <p *ngIf="!logo && !preview" class="mb-0 pe-5">Dodaj logo gabinetu .... </p>
      <img *ngIf="!preview && logo" src="assets/img/logo-sobczak.png" class="me-3" alt="Alternate Text" />
      <img *ngIf="preview" [src]="preview" class="preview me-3">
      <input type="file" class="file-input" (change)="onFileSelected($event)" #fileUpload>
      <i *ngIf="!preview" (click)="fileUpload.click()" class="fa-solid fa-paperclip"></i>
      <i (click)="removeLogoUpload()" *ngIf="preview" class="fa-solid fa-circle-xmark me-1"></i>
      <i (click)="saveLogo()" *ngIf="preview" class="fa-solid fa-floppy-disk"></i>
    </div>
 

    <div class="file-upload">

      {{fileName}}

      <!--<button mat-mini-fab color="primary" class="upload-btn"
            (click)="fileUpload.click()">
      <mat-icon>załącz plik</mat-icon>
    </button>-->
    </div>
   

    <div class="row  align-items-stretch">
      <div class="col-4">
        <div class="h-100 card p-1 p-xxl-4 mb-3">
          <div class="card-body">
            <p class="mb-0"><span class="">Pełna nazwa gabinetu:</span> <span class="fw-bold"> {{ dentist[0]?.name }}</span></p>
            <p class="mb-0"><span >Skrócona nazwa gabinetu:</span>   <span class="fw-bold"> {{ dentist[0].shortName }}</span> </p>
            <p class="mb-0" *ngIf="dentist[0].customDistributorName"><span>Dystrybutor dodatkowy:</span>   <span class="fw-bold"> {{ dentist[0].customDistributorName }}</span> </p>
            <p *ngIf="dentist[0].paymentsInfo"><span>Metoda płatnośći:</span><span class="fw-bold"> {{ dentist[0].paymentsInfo }}</span> </p>
            <h5 class="section-title">Dane adresowe</h5>
            <p class="mb-0"><span class="">Miejscowość:</span><span class="fw-bold">{{ dentist[0].city }}</span></p>
            <p class="mb-0"><span class="">Ulica / nr:</span><span class="fw-bold">{{ dentist[0].street }}</span> </p>
            <p class="mb-0"><span class="">Kod pocztowy:</span> <span class="fw-bold">{{ dentist[0].postalCode }}</span> </p>
            <p class="mb-0"><span class="">Strona internetowa:</span> <span class="fw-bold"> {{ dentist[0].website }}</span></p>
            <p class="mb-0"><span class="">Numer NIP:</span>   <span class="fw-bold">{{ dentist[0].nip }}</span>  </p>
            <p class="mb-0"><span class="">Województwo:</span>  <span class="fw-bold">{{ dentist[0].state }}</span> </p>
            <p><span class="fw-bold">Kraj:</span>  <span class="fw-bold"></span> {{ dentist[0].country }}</p>
            <p class="mb-0"><span class="">Adres e-mail do wystawiania faktur:</span>  <span class="fw-bold"> {{ dentist[0].invoiceEmail }}</span></p>
          </div>
        </div>
      </div>
      <div class="col-4">
        <div class="h-100 card p-1 p-xxl-4">
          <div class="card-body">
            <h5 class="section-title">Dane kontaktowe właściciela</h5>
            <p class="mb-0"><span class="">Imię:</span>  <span class="fw-bold">{{ dentist[0].owner.name }}</span>  </p>
            <p class="mb-0"><span class="">Nazwisko:</span> <span class="fw-bold">{{ dentist[0].owner.surname }}</span></p>
            <p class="mb-0"><span class="">Numer telefonu:</span> <span class="fw-bold">{{ dentist[0].owner.phone}}</span></p>
            <p><span class="">Adres e-mail:</span>  <span class="fw-bold">{{ dentist[0].owner.email }}</span></p>
            <h5 class="section-title">Adres e-mail osoby kontaktowej</h5>
            <p class="mb-0"><span class="">Imię:</span> <span class="fw-bold">{{ dentist[0].contactPerson?.name}}</span></p>
            <p class="mb-0"><span class="">Nazwisko:</span> <span class="fw-bold">{{  dentist[0].contactPerson?.surname }}</span> </p>
            <p class="mb-0"><span class="">Numer telefonu:</span> <span class="fw-bold">{{ dentist[0].contactPerson?.phone }}</span></p>
            <p class="mb-0"><span class="">Adres e-mail:</span> <span class="fw-bold">{{ dentist[0].contactPerson?.email }}</span></p>
          </div>
        </div>
      </div>
      <div class="col-4">
        <div class=" card p-1 p-xxl-4 mb-3">
          <div class="card-body">
            <h5 class="section-title">Notatki</h5>
            <p>
              {{ dentist[0].note }}
            </p>
          </div>
        </div>
        <div class="d-grid">
          <a routerLink="/dentist/add-dentist/{{ currentId  }}" class="mb-3 d-none d-sm-inline-block btn btn-primary px-5">Edytuj</a>
        </div>
        <div class="">
          <div *ngIf="!selectedStatu" class="card p-1 p-xxl-4 mb-4">
            <div class="card-body">
              <div class="mb-1" *ngIf="dentistCurrent[0]?.cancellation">
                <div *ngFor="let item  of dentistCurrent[0]?.cancellation">
                  <p class="mb-0"><span class="">Data zakończenia umowy:</span> <span class="fw-bold">{{ item.date }}</span></p>
                  <p class="mb-0"><span class="">Box: </span> <span class="fw-bold">{{  item.emintoId  }}</span> </p>
                </div>
              </div>
              <div *ngIf=" (  dentistCurrent[0]?.status === 'testuje'   && dentist[0].tests  ) ||   ( dentistCurrent[0]?.status === 'rezygnacja'    && dentist[0].tests  ) ">
                <p class="mb-0"><span class="">Data rozpoczęcia testów:</span> <span class="fw-bold">{{ dentist[0].tests.start }}</span></p>
                <p class="mb-0"><span class="">Box: </span> <span class="fw-bold">{{  dentist[0].tests.emintoId }}</span> </p>
                <p class="mb-1"><span class="">Data zakończenia testów: </span> <span class="fw-bold">{{ dentist[0].tests.end }}</span></p>
              </div>

              <h5 class="section-title">Status gabinetu</h5>
              <span *ngIf="dentistCurrent[0]?.status === 'testuje'" class="badge bg-danger">{{ dentist[0].status }}</span>
              <span *ngIf="dentistCurrent[0]?.status === 'umowa w trakcie'" class="badge bg-success">{{ dentist[0].status  }}</span>
              <span *ngIf="dentistCurrent[0]?.status === 'umowa'" [ngClass]="dentistCurrent[0]?.bankTransferIncomplete ? 'inComplete' : '' "   class="badge bg-success">{{ dentist[0].status  }}</span>
              <span *ngIf="dentistCurrent[0]?.status === 'rezygnacja'" class="badge bg-light">{{ dentist[0].status  }}</span>
              <span *ngIf="dentistCurrent[0]?.status === 'niezainteresowany'" class="badge bg-light">{{ dentist[0].status  }}</span>
              <span *ngIf="dentistCurrent[0]?.status === 'wysłano ofertę'" class="badge bg-success">{{ dentist[0].status   }}</span>
              <span *ngIf="dentistCurrent[0]?.status === 'nowy'" class="badge bg-warning ">{{  dentist[0].status }}</span>
              <span *ngIf="dentistCurrent[0]?.status === 'rezygnacja z umowy'" class="badge bg-dark">{{ dentist[0].status  }}</span>
              <span *ngIf="dentistCurrent[0]?.status === 'w rezygnacji'" class="badge bg-light">{{ dentist[0].status  }}</span>
              <div class="row mt-3 mb-3">
                <div class="col">
                  <!-- formControlName="status" -->
                  <select (change)="changeStatus($event.target.value)" *ngIf="dentistCurrent[0].status === 'nowy' || dentistCurrent[0].status === 'wysłano ofertę'   "  class="form-select large" id="specificSizeSelect">
                    <option selected>Wybierz status</option>

                    <option [ngClass]="s.name ===  'wysłano ofertę'  ? 'selected' : s.name === 'niezainteresowany' ? 'selected'  :''"
                            [value]="s._id" *ngFor="let s of dentistCurrent[0].statusList">
                      {{ s.name }}
                    </option>

                  </select>

                </div>
                <div *ngIf="dentist[0].status === 'nowy' || dentist[0].status === 'wysłano ofertę'   " class="col"><a (click)="selectStatus(true)" class="mt-1 d-none d-sm-inline-block btn btn-primary px-5">Wybierz</a></div>
              </div>


            </div>
          </div>
          <div *ngIf="selectedStatu" class="card  p-1 p-xxl-4 mb-4">
            <div class="card-body">
              <div class="mb-1" *ngIf="dentistCurrent[0]?.cancellation">
                <div *ngFor="let item  of dentistCurrent[0]?.cancellation">
                  <p class="mb-0"><span class="">Data zakończenia umowy:</span> <span class="fw-bold">{{ item.date }}</span></p>
                  <p class="mb-0"><span class="">Box: </span> <span class="fw-bold">{{  item.emintoId  }}</span> </p>
                </div>
              </div>
              <div *ngIf=" (  dentistCurrent[0]?.status === 'testuje'   && dentist[0].tests  ) ||   ( dentistCurrent[0]?.status === 'rezygnacja'    && dentist[0].tests  ) ">
                <p class="mb-0"><span class="">Data rozpoczęcia testów:</span> <span class="fw-bold">{{ dentist[0].tests.start }}</span></p>
                <p class="mb-0"><span class="">Box: </span> <span class="fw-bold">{{  dentist[0].tests.emintoId }}</span> </p>
                <p class="mb-1"><span class="">Data zakończenia testów: </span> <span class="fw-bold">{{ dentist[0].tests.end }}</span></p>
              </div>

              <h5 class="section-title">Status gabinetu</h5>
              <span *ngIf="dentistCurrent[0]?.status === 'testuje'" class="badge bg-danger">{{ dentist[0].status }}</span>
              <span *ngIf="dentistCurrent[0]?.status === 'umowa w trakcie'" class="badge bg-success">{{ dentist[0].status  }}</span>
              <span *ngIf="dentistCurrent[0]?.status === 'umowa'" class="badge bg-success">{{ dentist[0].status  }}</span>
              <span *ngIf="dentistCurrent[0]?.status === 'rezygnacja'" class="badge bg-light">{{ dentist[0].status  }}</span>
              <span *ngIf="dentistCurrent[0]?.status === 'niezainteresowany'" class="badge bg-light">{{ dentist[0].status  }}</span>
              <span *ngIf="dentistCurrent[0]?.status === 'wysłano ofertę'" class="badge bg-success">{{ dentist[0].status   }}</span>
              <span *ngIf="dentistCurrent[0]?.status === 'nowy'" class="badge bg-warning ">{{  dentist[0].status }}</span>
              <span *ngIf="dentistCurrent[0]?.status === 'rezygnacja z umowy'" class="badge bg-dark">{{ dentist[0].status  }}</span>
              <div class="row mt-3 mb-3">
                <div class="col">
                  <!-- formControlName="status" -->
                  <select (change)="changeStatus($event.target.value)" *ngIf="dentistCurrent[0].status === 'nowy' || dentistCurrent[0].status === 'wysłano ofertę'   "  class="form-select large" id="specificSizeSelect">
                    <option selected>Wybierz status</option>

                    <option [ngClass]="s.name ===  'wysłano ofertę'  ? 'selected' : s.name === 'niezainteresowany' ? 'selected'  :''"
                            [value]="s._id" *ngFor="let s of dentistCurrent[0].statusList">
                      {{ s.name }}
                    </option>

                  </select>

                </div>
                <div *ngIf="dentist[0].status === 'nowy' || dentist[0].status === 'wysłano ofertę'   " class="col"><a (click)="selectStatus(true)" class="mt-1 d-none d-sm-inline-block btn btn-primary px-5">Wybierz</a></div>
              </div>


            </div>
          </div>

        </div>




      </div>
    </div>






    <section style="height: 500px"></section>



    <ng-template class="mod" #confirmBankTransferModal let-modal>
      <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">
          Czy napewno chcesz rozliczyc płatność?
        </h4>

      </div>
      <div class="modal-body">

      </div>
      <div class="modal-footer">

        <button type="button"
                (click)="confirmPayments()"
                class="btn btn-primary">
          OK
        </button>
      </div>
    </ng-template>


  </div>
</div>
