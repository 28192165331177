
import { createAction, props } from "@ngrx/store";
import { IFormData } from "../models/offers.model";

export const sendForm = createAction(
  '[Market Form API] Send Data',
  props<{ form : IFormData }>()
);



export const getAccountData = createAction(
  '[Offer  Page Action] GetAccount Data',
  props<{ name : string }>()
);


export const getAccountDataSuccess = createAction(
  '[Offer  Page Action] GetAccount Data Success',
  props<{ name : string }>()
);
