
import { Component } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as fromResetPassword from '../reducers';
import { ResetPasswordPageActions } from '../actions';
import { ResetPassword, ChangePassword } from '../models/reset-password';

@Component({
  template: `
    <app-change-password-form
      (submitted)="onSubmit($event)"
      [success]="success$ | async"
      [error]="error$ | async">
    </app-change-password-form>`
})
export class ChangePasswordPageComponent {

  success$ = this.store.pipe(select(fromResetPassword.getChangePasswordSuccess));
  error$ = this.store.pipe(select(fromResetPassword.getResetPasswordPageError));

  constructor(private store: Store<fromResetPassword.State>) { }

  onSubmit(changePassword: ChangePassword) {
    
    this.store.dispatch(ResetPasswordPageActions.changePassword({ data :  changePassword  }));
  }
}
