import { Action, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromRoot from '../../reducers';
import * as fromOrdersPage from './orders.reducer';

export const ordersFeatureKey = 'orders';

export interface OrdersState {
  [fromOrdersPage.ordersPageFeatureKey]: fromOrdersPage.State;
}

export interface State extends fromRoot.State {
  [ordersFeatureKey]: OrdersState;
}

export function reducers(state: OrdersState | undefined, action: Action) {
    return combineReducers({
      [fromOrdersPage.ordersPageFeatureKey]: fromOrdersPage.reducer,
    })(state, action);
}

export const selectOrdersState = createFeatureSelector<State, OrdersState>(ordersFeatureKey);

export const selectOrdersPageState = createSelector(
  selectOrdersState,
  (state: OrdersState) => state.ordersPage
);

export const selectError = createSelector(
  selectOrdersPageState,
  fromOrdersPage.selectError
);
export const selectOrders = createSelector(
  selectOrdersPageState,
  fromOrdersPage.selectOrders
);

export const selectComplete = createSelector(
  selectOrdersPageState,
  fromOrdersPage.selectComplete
);




