import { Component, Input, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { OrdersPageActions } from 'src/app/orders/actions';
import { LayoutActions } from 'src/app/core/actions'
@Component({
    selector: 'app-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.scss']
})
export class MenuComponent  implements OnInit  {

  @Input() user;
  @Input() role;
  @Input() orders;
  @Input() url;
  @Input() mediaUrl;


  constructor(
    private store: Store,
  ) { }
  menu: boolean = true;




  activeIds: string[] = [];
  toggleAccordian(event, acc) {

 
    
    acc.expand('test')
    if (!event.nextState) {
      this.activeIds = [];
    }
    if (this.activeIds[0] !== event.panelId && event.nextState) {
      this.activeIds = [];
      this.activeIds = [event.panelId, event.panelId];
    }
    acc.collapse('ngb-panel-5')
  }

  public isCollapsed = false;


  toggleMenu()  {
    this.menu = !this.menu;
    this.menu ?
      this.store.dispatch(LayoutActions.showMenu()) :
      this.store.dispatch(LayoutActions.hideMenu())
}





  ngOnInit(): void {
    this.store.dispatch(OrdersPageActions.getOrders({ status :  'active' }))
  }
}
