
<div class="container-fluid">
  <h2>E-learning</h2>
  <ul class="mb-4 d-flex nav">
    <li class="mt-2"><a [routerLink]="[ 'presentation']" [routerLinkActive]="['active']" class="rounded-btn btn-light-my nav-link" aria-current="page">Prezentacja eminto</a></li>
    <li class="mt-2"><a [routerLink]="[ 'sels-card']" [routerLinkActive]="['active']" class="rounded-btn btn-light-my nav-link" aria-current="page">Karta sprzedaży</a></li>
    <li class="mt-2"><a [routerLink]="[ 'calculator']" [routerLinkActive]="['active']" class="rounded-btn btn-light-my nav-link" aria-current="page">Kalkulator zysku</a></li>
    <li class="mt-2"><a [routerLink]="[ 'test']" [routerLinkActive]="['active']" class="rounded-btn btn-light-my nav-link" aria-current="page">Test wiedzy o produkcie</a></li>

    <li class="mt-2"><a [routerLink]="[ 'price']" [routerLinkActive]="['active']" class="rounded-btn btn-light-my nav-link" aria-current="page">Cennik handlowca</a></li>
    <li class="mt-2"><a [routerLink]="[ 'media']" [ngClass]="isMediaActive   ?   'active'   : ''" [routerLinkActive]="['active']" class="rounded-btn btn-light-my nav-link" aria-current="page">Media do pobrania</a></li>
  </ul>
</div>


<router-outlet></router-outlet>


