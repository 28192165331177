import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';

import { select, Store } from '@ngrx/store';
import { LayoutActions } from 'src/app/core/actions';
import { Sort } from '@angular/material/sort';
import { ISingleLead, IStepsUpdateData } from '../models';
import * as fromLayout from 'src/app/core/reducers';
import { OnDestroy } from '@angular/core';
import { Person } from '../../contractor/models';
import { LeadsPageActions } from '../actions';

@Component({
  selector: 'app-list-leads',

  template: ` <app-table-filters
      [search]="currentData.search || 'Wyszukaj po dowolnych parametrach'"
      [showLeadsFilters]="showFilters$ | async"
      (filterLeads)="filterLeads($event)"
      (filter)="filterData($event)"
      [currentCampaing]="currentData.currentCampaing"
      [person]="currentData.currentPerson"
      [persons]="persons"
      [hiddenColumns]="allColumns"
      [dates]="currentData.dates"
      (tabelColumn)="filterColumns($event)"
      (currentLeadsPerson)="filterLeadsByPerson($event)"
      (dateRange)="filterByDate($event)"
    ></app-table-filters>
    <app-data-table
      [tableData]="data"
      [tableColumns]="columns"
      [persons]="persons"
      [currentPage]="currentPage"
      (sort)="sortData($event)"
      (stepUpdate)="sendStep($event)"
      (assingPresonToLead)="assingPersonToLead($event)"
      (removeLeadSubmit)="removeLead($event)"
      (currentPageNumber)="setCurrentPageNumber($event)"
    ></app-data-table>`,
})
export class ListLeadComponent implements OnInit, OnChanges, OnDestroy {
  showFilters$ = this.store.pipe(select(fromLayout.selectShowLeadsFilters));
  @Input() leads: ISingleLead[];
  @Input() search: string;
  @Input() persons: Person[];
  @Input() currentleadsColumns: any;
  @Input() currentData: any;
  @Input() currentPage: any;
  data: ISingleLead[];
  allData: ISingleLead[];
  currentCamping: string;
  curentSearch: string;
  isSearchResults: boolean = false;
  currentSortParametr: Sort;

  constructor(private store: Store) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.currentData?.currentValue) {
      if (changes.currentData?.currentValue['leads']) {
        this.allData = changes.currentData?.currentValue['leads'];
        this.data = changes.currentData?.currentValue['leads'];
      }
      if (changes.currentData?.currentValue['currentPerson']) {
        this.leadsByPerson(this.data);
      }
      if (changes.currentData?.currentValue['currentCampaing']) {
        this.filterDentistBycamping(
          changes.currentData?.currentValue['currentCampaing']
        );
      }
      if (changes.currentData?.currentValue['search']) {
        this.filterDataBySearchTerm(
          changes.currentData?.currentValue['search']
        );
      }
      if (changes.currentData?.currentValue['dates']) {
        this.filterByDate(changes.currentData?.currentValue['dates']);
      }
      if (this.currentSortParametr) {
        this.sortData(this.currentSortParametr);
      }
    }

    if (changes.currentleadsColumns?.currentValue) {
      this.allColumns.forEach((val) => {
        changes.currentleadsColumns?.currentValue.forEach((column) => {
          if (val.name === column.name) {
            val.hide = column.hide;
          }
        });
      });
      this.columns = this.allColumns.filter((val) => {
        return !val.hide;
      });
      this.allColumns = [...this.allColumns];
    }
  }

  assingPersonToLead(event: any) {
    this.store.dispatch(
      LeadsPageActions.assingPersonTolead({
        leadId: event.id,
        personId: event.accountId,
      })
    );
  }

  sortData(sortParameters: Sort) {
    this.currentSortParametr = sortParameters;
    const keyName = sortParameters.active;
    if (sortParameters.direction === 'asc') {
      this.data = this.data
        .slice()
        .sort((a, b) =>
          a[keyName].toString()?.localeCompare(b[keyName].toString())
        );
    } else if (sortParameters.direction === 'desc') {
      this.data = this.data
        .slice()
        .sort((a, b) =>
          b[keyName].toString()?.localeCompare(a[keyName].toString())
        );
    }
  }

  filterData(search: string) {
    this.store.dispatch(LayoutActions.setSearchCampaingTerm({ search }));
  }

  filterLeads(event) {
    this.store.dispatch(LayoutActions.setCurrentCampaing({ campaing: event }));
  }

  removeLead(id) {
    this.store.dispatch(LeadsPageActions.removeLead({ leadsId: id }));
  }

  leadsByPerson(data: ISingleLead[]) {
    let filteredData: ISingleLead[] = data.filter((lead) => {
      return lead.accountId === this.currentData.currentPerson;
    });
    this.data = filteredData;
  }

  filterDentistBycamping(camping: string) {
    if (camping === 'Wyczyść') {
      this.store.dispatch(LayoutActions.removeCurrentCampaing());
      this.data = this.allData;
      this.currentCamping = undefined;
    } else {
      this.data = this.data.filter((val) => {
        return val.campaign === camping;
      });
    }
  }

  filterDataBySearchTerm(search) {
    if (search === 'clear') {
      this.store.dispatch(LayoutActions.removeSearchCampaignTerm());
    } else {
      let filteredData: ISingleLead[] = [];
      this.data.forEach((val) => {
        if (
          Object.values(val).some((value) => {
            if (typeof value === 'object' && value !== null) {
              if (
                Object.values(value).some(
                  (item) =>
                    item
                      .toString()
                      .trim()
                      .toLowerCase()
                      .indexOf(search.trim().toLowerCase()) !== -1
                )
              ) {
                return true;
              }
            } else if (value) {
              return (
                value
                  .toString()
                  .trim()
                  .toLowerCase()
                  .indexOf(search.trim().toLowerCase()) !== -1
              );
            }
          })
        ) {
          filteredData.push(val);
        }
      });
      this.data = filteredData;
    }
  }

  filterColumns(columnTarget: { name: string; hide: boolean }) {
    this.store.dispatch(
      LayoutActions.setColumnVisible({ column: columnTarget })
    );
  }

  allColumns = [
    {
      name: 'Data',
      dataKey: 'date',
      hide: false,
      position: 'left',
      isSortable: true,
      bold: false,
      type: 'lead-date',
    },
    {
      name: 'Nazwa',
      dataKey: 'DentisName',
      hide: false,
      position: 'left',
      isSortable: true,
      type: 'lead-name',
    },
    {
      name: 'Imię i Nazwisko',
      dataKey: 'name',
      hide: false,
      position: 'street',
      isSortable: false,
      type: 'string',
    },
    {
      name: 'E-mail',
      dataKey: 'email',
      hide: false,
      position: 'left',
      isSortable: true,
      type: 'string',
    },
    {
      name: 'Telefon',
      dataKey: 'phone',
      hide: false,
      position: 'left',
      isSortable: true,
      type: 'string',
    },
    {
      name: 'Kampania',
      dataKey: 'campaign',
      hide: false,
      position: 'left',
      isSortable: true,
      type: 'campaign-lead',
    },
    {
      name: 'Osoba',
      dataKey: 'accountLead',
      hide: false,
      position: 'street',
      isSortable: false,
      type: 'accountLead',
    },
    {
      name: 'Status',
      dataKey: 'status',
      hide: false,
      position: 'street',
      isSortable: false,
      type: 'lead-status',
    },
    {
      name: 'Step 1',
      dataKey: 'step-1',
      hide: false,
      position: 'street',
      isSortable: false,
      type: 'step-1',
    },
    {
      name: 'Data-s1',
      dataKey: 'lead-date-1',
      hide: false,
      position: 'street',
      isSortable: false,
      type: 'lead-date-1',
    },
    {
      name: 'Step 2',
      dataKey: 'step-2',
      hide: false,
      position: 'street',
      isSortable: false,
      type: 'step-2',
    },
    {
      name: 'Data-s2',
      dataKey: 'lead-date-2',
      hide: false,
      position: 'street',
      isSortable: false,
      type: 'lead-date-2',
    },
    {
      name: 'Step 3',
      dataKey: 'step-3',
      hide: false,
      position: 'street',
      isSortable: false,
      type: 'step-3',
    },
    {
      name: 'Data-s3',
      dataKey: 'lead-data-3',
      hide: false,
      position: 'street',
      isSortable: false,
      type: 'lead-date-3',
    },
    {
      name: 'Step 4',
      dataKey: 'step-4',
      hide: false,
      position: 'street',
      isSortable: false,
      type: 'step-4',
    },
    {
      name: 'Data-s4',
      dataKey: 'data-4',
      hide: false,
      position: 'street',
      isSortable: false,
      type: 'lead-date-4',
    },
    {
      name: 'Step 5',
      dataKey: 'step-5',
      hide: false,
      position: 'street',
      isSortable: false,
      type: 'step-5',
    },
    {
      name: 'Data-s5',
      dataKey: 'data-5',
      hide: false,
      position: 'street',
      isSortable: false,
      type: 'lead-date-5',
    },
    {
      name: 'Dodatkowe usługi',
      dataKey: 'service',
      hide: false,
      position: 'street',
      isSortable: false,
      type: 'lead-service',
    },
    {
      name: 'Akcje',
      dataKey: 'action',
      hide: false,
      position: 'left',
      isSortable: false,
      type: 'edit-lead',
    },
  ];

  columns = [];

  filterLeadsByPerson(person: string | null) {
    this.store.dispatch(LayoutActions.setCurrentPersonOfLeads({ id: person }));
  }

  isToday(date) {
    const someDate = new Date(date);
    const today = new Date();
    if (
      someDate.getDate() == today.getDate() &&
      someDate.getMonth() == today.getMonth() &&
      someDate.getFullYear() == today.getFullYear()
    ) {
      return true;
    }
  }

  filterByDate(dataRange) {
    this.data = this.data.filter((lead) => {
      let activeStep = lead.steps.find((step) => step.active);
      if (activeStep) {
        const ys = new Date(activeStep.date).getFullYear();
        const ms = ('0' + (new Date(activeStep.date).getMonth() + 1)).slice(-2);
        let ds = new Date(activeStep.date).getDate().toString();
        if (ds.length === 1) {
          ds = `0${ds}`;
        }
        const date = `${ys}-${ms}-${ds}`;
        let dataStep = new Date(date).getTime();
        let startDate = new Date(dataRange.startDate).getTime();
        let endDate = new Date(dataRange.endDate).getTime();
        if (dataStep >= startDate && dataStep <= endDate) {
          return lead;
        }
      }
      if (this.isToday(lead.controlPhoneDate)) {
        return lead;
      }
    });
    this.data = this.data.sort((a, b) => {
      let activeStepA = a.steps.find((step) => step.active);
      let activeStepB = b.steps.find((step) => step.active);
      return new Date(activeStepA.date)
        .getTime()
        .toString()
        .localeCompare(new Date(activeStepB.date).getTime().toString());
    });
  }

  sendStep(event: IStepsUpdateData) {
    this.store.dispatch(LeadsPageActions.stepsUpdate({ data: event }));
  }

  setCurrentPageNumber(event:number) {
      this.store.dispatch(LeadsPageActions.setCurrentPageList({number : event}))
  }


  ngOnInit(): void {
    this.store.dispatch(LayoutActions.showLeadsFilter());
  }
  ngOnDestroy(): void {
    this.store.dispatch(LayoutActions.hideLeadsFilter());
  }
}
