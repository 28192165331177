
<div class="container m-0 mr-auto ps-3">
  <div class="row">
    <div class="action-bar p-3 bg-light d-flex mb-3">
      <form class="w-100">
        <input type="search" #item (keydown.enter)="applyFilters()" class="form-control" aria-label="Search" />
      </form>
      <a (click)="applyFilters()" class="col-2 d-none d-sm-inline-block btn btn-outline-secondary ms-3">Szukaj</a>
      <a (click)="clearFilters()" class="col-2 d-none d-sm-inline-block btn btn-outline-secondary ms-3">Resetuj</a>
      <!-- <a  class="d-none d-sm-inline-block btn btn-outline-secondary ms-3">Export</a> -->
    </div>
    <div *ngIf="showBtn" class="d-flex">
      <button #filterBnt (click)="applyFiltersProducts($event)" class="rounded-btn btn btn-primary  Box">Box </button>
      <button #filterBnt (click)="applyFiltersProducts($event)" class="rounded-btn btn btn-primary ms-1 Virtual">Virtual </button>
      <button #filterBnt (click)="applyFiltersProducts($event)" class="rounded-btn btn btn-primary ms-1 Desktop">Desktop </button>
      <button #filterBnt (click)="applyFiltersProducts($event)" class="rounded-btn btn btn-primary ms-1 Wszystkie">Wszystkie </button>
    </div>


    <div *ngIf="showDentistFilters" class="d-flex align-items-center filters">
      <span #dentistFilterBnt (click)="applyDentistFilters($event)" class="badge bg-warning">nowy</span>
      <span #dentistFilterBnt (click)="applyDentistFilters($event)" class="badge bg-danger">testuje</span>
      <span #dentistFilterBnt (click)="applyDentistFilters($event)" class="badge bg-success">umowa w trakcie</span>
      <span #dentistFilterBnt (click)="applyDentistFilters($event)" class="badge bg-primary">wysłano ofertę</span>
      <span #dentistFilterBnt (click)="applyDentistFilters($event)" class="badge bg-success">umowa</span>
      <span #dentistFilterBnt data-transfer="inComplate" (click)="applyDentistFilters($event)" class="badge bg-success inComplete">umowa</span>
      <span #dentistFilterBnt (click)="applyDentistFilters($event)" class="badge bg-dark">rezygnacja</span>
      <span #dentistFilterBnt (click)="applyDentistFilters($event)" class="badge bg-dark">w rezygnacji</span>
      <span #dentistFilterBnt (click)="applyDentistFilters($event)" class="badge bg-dark">rezygnacja z umowy</span>
      <span #dentistFilterBnt (click)="applyDentistFilters($event)" class="badge bg-light">niezainteresowany</span>
      <!--<span   (click)="applyDentistFilters($event)" >Wyczyść</span>-->
      <i [ngClass]="isActiveFilter ? 'active'  : ''" (click)="applyDentistFilters($event, true)"
         mat-raised-button
         data-id="Wyczyść"
         matTooltipHideDelay="500"
         class="fa-solid fa-circle-xmark"></i>

    </div>


    <div *ngIf="showLeadsFilters" class="d-flex align-items-center filters">

      <span #leadsFilterBnt (click)="applyLeadsFilters($event)" class="badge bg-info">facebook</span>
      <span #leadsFilterBnt (click)="applyLeadsFilters($event)" class="badge bg-secondary">formularz</span>
      <span #leadsFilterBnt (click)="applyLeadsFilters($event)" class="badge bg-light">konferencja</span>
      <span #leadsFilterBnt (click)="applyLeadsFilters($event)" class="badge bg-primary">szkolenie</span>
      <span #leadsFilterBnt (click)="applyLeadsFilters($event)" class="badge bg-success">mailing</span>
      <span #leadsFilterBnt (click)="applyLeadsFilters($event)" class="badge bg-warning ">targi</span>
      <span #leadsFilterBnt (click)="applyLeadsFilters($event)" class="badge bg-dark">inne</span>
      <!--<span   (click)="applyDentistFilters($event)" >Wyczyść</span>-->
      <i [ngClass]="isActiveFilter ? 'active'  : ''" (click)="applyLeadsFilters($event, true)"
         mat-raised-button
         data-id="Wyczyść"
         matTooltipHideDelay="500"
         class="fa-solid fa-circle-xmark"></i>




      <div ngbDropdown [autoClose]="false" class="d-inline-block dropdown-step">
        <button type="button" id="dropdown1" ngbDropdownToggle><i class="fa-solid fa-eye-slash"></i>Ukryj</button>
        <div ngbDropdownMenu aria-labelledby="dropdown1" class="p-4">
          <p ngbDropdownToggle class="text-end close_drop mb-0"><i class="fa-regular fa-circle-xmark"></i></p>
          <p>Wybierz kolumny do wyświetlenia</p>
          <div class="d-flex justify-content-between">
            <span>Wszystkie kolumny </span>
            <input [checked]="isAllColumn" type="checkbox" name="wszystkie" value="all" (change)="selectColumnToShow($event.target)" />
          </div>

          <div class="d-flex justify-content-between my-2" *ngFor="let item of hiddenColumns">
            <span>{{ item.name }}</span>
            <input type="checkbox" name="name" [value]="item.name" (change)="selectColumnToShow($event.target)" [checked]="!item.hide" />
          </div>
        </div>
      </div>

      <div ngbDropdown [autoClose]="false" class="d-inline-block dropdown-step">
        <button type="button" id="dropdown-persons" ngbDropdownToggle>
          <i class="fa-solid fa-user-plus"></i>
        </button>
        <div ngbDropdownMenu aria-labelledby="dropdown-persons" class="p-4">
          <p ngbDropdownToggle class="text-end close_drop mb-0"><i class="fa-regular fa-circle-xmark"></i></p>

          <ul class="m-0 p-0">
            <li class="py-2 m-0 d-flex">
              <input (change)="filterLeadsByPerson($event.target)" [checked]="!person" [value]="null" class="me-2" type="checkbox" />
              <span>Wszyscy</span>
            </li>
            <li *ngFor="let p of persons" class="py-2 m-0 d-flex">
              <input (change)="filterLeadsByPerson($event.target)" [checked]="p.name === person" [value]="p._id" class="me-2" type="checkbox" />
              <span>{{ p.name }} {{ p.surname }}</span>
            </li>
          </ul>

        </div>
      </div>
    

      <mat-date-range-input [rangePicker]="myRangePicker">
        <input ngModel
               name="start"
               [(ngModel)]="start"
               matStartDate
               (dateInput)="dataChange('end', $event)"
               placeholder="Start date" />
        <input matEndDate
               placeholder="End date"
               name="end"
               ngModel
               (stateChanges)="dataChange('end', $event)"
               [(ngModel)]="end" />
      </mat-date-range-input>
      <mat-datepicker-toggle matSuffix [for]="myRangePicker">
      </mat-datepicker-toggle>
      <mat-date-range-picker #myRangePicker  (opened)="onOpen()">
      </mat-date-range-picker>
      
      <span  (click)="applyCalendarRange(false, true)"  class="pe-3 today">dzisiaj</span>
      <div style="display: none">
        <div class="datepicker-footer d-flex justify-content-end my-2 me-2" #datepickerFooter>
          <button  *ngIf="start" class="rounded-btn btn active" (click)="applyCalendarRange(true)" #todayButton>
            Wyczyść
          </button>
          <button  *ngIf="!start" class="rounded-btn btn active" (click)="applyCalendarRange(true)" #todayButton>
            Zamknij
          </button>
          <button [disabled]="!start || !end" class="rounded-btn btn active" (click)="applyCalendarRange()" #doneButton>
            Zatwierdz
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
