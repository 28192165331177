import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { combineLatest, of } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import { DentistsApiActions, DentistsPageActions } from "../actions";
import { DentistList, DentistDetails } from "../models/dentist.model";
import * as fromDentists from "../reducers";
import { ApiService } from "../services";
import { ProductApiActions } from "../../products/actions/index";
import { ContaractorService } from "../../contractor/services/contractor.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { LayoutActions } from "../../core/actions/index";

@Injectable()
export class DentistsEffects {

  getDentists$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DentistsPageActions.getDentistsWithoutContractors),
      switchMap((data) => {
        return this.apiService.getDentist().pipe(
          map((res: DentistList[]) => {
            let t = res.map(denyist => {
              return {
                ...denyist,

                contactToShow: denyist.contact.name + " " + denyist.contact.surname
              }
            });
            return DentistsApiActions.getDentistsSuccess({ dentists: t });
          }),
          catchError((error: any) => of(DentistsApiActions.getDentistsFailure({ error })))
        );
      })
    )
  );


  getDentistBySeller$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DentistsPageActions.getDentistsBySeller),
      switchMap(({ id }) => {
        return this.apiService.getDentistBySeller(id).pipe(
          map((res: any) => {
            return DentistsApiActions.getDentistsBySellerSuccess({ dentists: res });
          }),
          catchError((error: any) => of(DentistsApiActions.getDentistsBySellerFailure({ error })))
        );
      })
    )
  );



  getGusDentist$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DentistsPageActions.GetDataFromGus),
      switchMap(({ nip }) => {
        return this.apiService.getDataFromGus(nip).pipe(
          map((res: any) => {
            return DentistsApiActions.GetDatafromGusSuccess({ data: res });
          }),
          catchError((error: any) => of(DentistsApiActions.GetDatafromGusFaliur({ error })))
        );
      })
    )
  );



  closeResult: string;

  getDentistList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DentistsPageActions.getDentists),
      switchMap(() => {
        return combineLatest([
          this.apiServiceContractor.getContractor(),
          this.apiService.getDentist()]).pipe(
            map((data) => {
              let t = data[1].map(denyist => {
                return {
                  ...denyist,
                  contractors: data[0],
                  contactToShow: denyist.contact.name + " " + denyist.contact.surname
                }
              });
              return DentistsApiActions.getDentistsSuccess({ dentists: t });

            }
            ),
            catchError((error: any) => {
              this.modalService.open("Nie udało sie pobrac listy", { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
                this.closeResult = `Closed with: ${result}`;
              }, (reason) => {

              });
              return of(DentistsApiActions.getDentistsFailure({ error }))
            })
          );
      })

    )
  );



  addProductToDentist$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DentistsPageActions.AddProductToDentist),
      switchMap(({ id, dentistId, statusId }) => {
        return this.apiService.addProductToDentist(id, dentistId, statusId).pipe(
          map((res: DentistList[]) => {
            return DentistsApiActions.addProductToDentistSuccess();
          }),
          catchError((error: any) => of(DentistsApiActions.addProductToDentistFailure({ error })))
        );
      })
    )
  );




  addContractorToDentist$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DentistsPageActions.AddContractorToDentist),
      switchMap(({ id, accountId, distributorId }) => {
        return this.apiService.addContractorToDentist(id, accountId, distributorId).pipe(
          map((res: any) => {
            return DentistsApiActions.AddContractorToDentistSuccess();
          }),
          catchError((error: any) => of(DentistsApiActions.AddContractorToDentistFailure({ error })))
        );
      })
    )
  );



  AddContractorToDentistSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DentistsApiActions.AddContractorToDentistSuccess),
      map(() => {
        this.store.dispatch(DentistsPageActions.getDentists());
      })
    ),
    { dispatch: false }
  );





  AddContractorToDentistFailure = createEffect(() =>
    this.actions$.pipe(
      ofType(DentistsApiActions.AddContractorToDentistFailure),
      map(({ error }) => {
        this.store.dispatch(LayoutActions.setError({ error: error }));
      })
    ),
    { dispatch: false }
  );






  getDentistDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DentistsPageActions.getDentistDetails),
      switchMap(({ dentistId }) => {
        return this.apiService.getDentistDetails(dentistId).pipe(
          map((res: DentistDetails[]) => {
            return DentistsApiActions.getDentistDetailsSuccess({ dentist: res });
          }),
          catchError((error: any) => of(DentistsApiActions.getDentistDetailsFailure({ error })))
        );
      })
    )
  );


  getDentistDetailsFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DentistsApiActions.getDentistDetailsFailure),
      map(({ error }) => {
        this.store.dispatch(LayoutActions.setError({ error: error }));
      })
    ),
    { dispatch: false }
  );



  postDentist$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DentistsPageActions.postDentists),
      switchMap(({ dentist }) => {

        return this.apiService.postDentist(dentist).pipe(
          map(val => DentistsApiActions.addDentistSuccess({ dentist })),
          catchError(error => {

            return of(DentistsApiActions.addDentistFailure({ error }))
          })
        )
      })
    ));


  postDentistWithStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DentistsPageActions.postDentistsWithStatus),
      switchMap(({ dentist, status }) =>
        this.apiService.postDentist(dentist).pipe(
          map(val => DentistsApiActions.addDentistWithStatusSuccess({ dentistId: "g", status: status })),
          catchError(error => {
            return of(DentistsApiActions.addDentistFailure({ error }))
          })
        ))
    ));




  postDentistWithStatusSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DentistsApiActions.addDentistWithStatusSuccess),
      map(() => {
        this.router.navigate(["/dentist/list-dentist"]);
      })
    ),
    { dispatch: false }
  );




  postSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DentistsApiActions.addDentistSuccess),
      map(({ dentist }) => {
        if (dentist.id) {
          this.router.navigate([`dentist/single-dentist/${dentist.id}`]);
        } else {
          this.router.navigate(["/dentist/list-dentist"]);
        }
      })
    ),
    { dispatch: false }
  );



  postDentistFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DentistsApiActions.addDentistFailure),
      map(({ error }) => {
        this.store.dispatch(LayoutActions.setError({ error: error }));
      })
    ),
    { dispatch: false }
  );


  setStatusDentist$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DentistsPageActions.SetDentistStatus),
      switchMap(({ dentistId, statusId, productId }) =>
        this.apiService.setDentistStatus(dentistId, statusId, productId).pipe(
          map(val => DentistsApiActions.SetDentistStatusSuccess({ id: dentistId })),
          catchError(error => {
            return of(DentistsApiActions.SetDentistStatusFailure({ error }));
          })
        ))
    ));

  setDentistStatuSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DentistsApiActions.SetDentistStatusSuccess),
      map(({ id }) => {

        this.store.dispatch(DentistsPageActions.getDentistDetails({ dentistId: id }));
      })
    ),
    { dispatch: false }
  );

  setStatusFaliure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DentistsApiActions.SetDentistStatusFailure),
      map(({ error }) => {
        this.store.dispatch(LayoutActions.setError({ error: error }));
      })
    ),
    { dispatch: false }
  );




  getTestLink$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DentistsPageActions.GenerateTestLink),
      switchMap(({ dentistId, productId }) => {
        return this.apiService.generateTestLink(dentistId, productId).pipe(
          map((res: any) => {
            let link = 'https://sale.eminto.pl/15-days-test/#/form/' + res;
            return DentistsApiActions.GenerateLinkSuccess({ link: link });
          }),
          catchError((error: any) => of(DentistsApiActions.GenerateTestLinkFailure({ error })))
        );
      })
    )
  );



  renewLicense$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DentistsPageActions.renewLicense),
      switchMap(({ dentistId, laicensDuration }) => {
        return this.apiService.renewLicense(dentistId, laicensDuration).pipe(
          map((res: any) => {
            return DentistsApiActions.RenewLicenseSuccess();
          }),
          catchError((error: any) => of(DentistsApiActions.RenewLicenseFailure({ error })))
        );
      })
    )
  );


  addLogo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DentistsPageActions.addLogo),
      switchMap(({ logo }) => {
        return this.apiService.addLogo(logo).pipe(
          map((res: any) => {
            return DentistsApiActions.RenewLicenseSuccess();
          }),
          catchError((error: any) => of(DentistsApiActions.RenewLicenseFailure({ error })))
        );
      })
    )
  );





  renewLicensSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DentistsApiActions.RenewLicenseSuccess),
      map(() => {
        this.store.dispatch(DentistsPageActions.getDentists());
      })
    ),
    { dispatch: false }
  );










  getPaymentsLink$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DentistsPageActions.GeneratePaymentsLink),
      switchMap(({ dentistId, productId, licenseDuration, price, monthly }) => {
        return this.apiService.generatePaymentLink(dentistId, productId, licenseDuration, price, monthly).pipe(
          map((res: any) => {
            let link = 'https://payment.eminto.eu/#/form/' + res;
            return DentistsApiActions.GenerateLinkSuccess({ link: link });
          }),
          catchError((error: any) => of(DentistsApiActions.GeneratePaymentsLinkFailure({ error })))
        );
      })
    )
  );




  GenerateTestLinkFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DentistsApiActions.GenerateTestLinkFailure),
      map(({ error }) => {
        this.store.dispatch(LayoutActions.setError({ error: error }));
      })
    ),
    { dispatch: false }
  );


  confirmPayments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DentistsPageActions.confirmPayments),
      switchMap(({ id }) => {
        return this.apiService.confirmPayments(id).pipe(
          map(() => {
            return DentistsApiActions.confirmPaymentsSussess({ id });
          }),
          catchError((error: any) => of(DentistsApiActions.confirmPaymentsFailure({ error })))
        );
      })
    )
  );

  confirmPaymentsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DentistsApiActions.confirmPaymentsSussess),
      map(({ id }) => {
        this.store.dispatch(DentistsPageActions.getDentistDetails({ dentistId: id }));
      })
    ),
    { dispatch: false }
  );










  constructor(
    private modalService: NgbModal,
    private store: Store<fromDentists.State>,
    private actions$: Actions,
    private apiService: ApiService,
    private router: Router,
    private apiServiceContractor: ContaractorService,
  ) {
  }
}
