import {AuthApiActions, AuthActions} from '../actions';
import {Token} from '../models/auth.model';
import {createReducer, on} from '@ngrx/store';

export const statusFeatureKey = 'status';

export interface State {
    token: Token | null;
}

export const initialState: State = {
    token: null,
};

export const reducer = createReducer(
    initialState,
    on(
         AuthApiActions.loginSuccess,
        AuthApiActions.refreshTokenSuccess,
        AuthApiActions.clientCredentialsTokenSuccess,
        (state, action) =>   (  {...state, token : action.token})),
    on(
        AuthApiActions.changePasswordSuccess,
        state => ({token: {...state.token, resetPassword: false}})
    ),
  on(AuthActions.logout, () => initialState),
  on(
    AuthActions.dismissPopup,
    state => ({ token: { ...state.token, welcomePopup: false } })
  )
);







export const selectToken = (state: State) => state.token;
export const selectUserType = (state: State) => state.token?state.token.userType:null;
export const selectUserPermissions = (state: State) => state.token?state.token.permissions:[];
export const selectUserName = (state: State) => state.token?state.token.name:'';
export const selectRole = (state: State) => state.token?.type;
export const selectwelcomePopup = (state: State) => state.token?.welcomePopup;
