
import { Component } from "@angular/core";

import { Store, select } from '@ngrx/store';

import * as fromAuth from 'src/app/auth/reducers'







@Component({
  selector: 'app-status-page',
  template: `<app-status></app-status>`
})
export class StatusPageComponent  {
  


  roleSel$ = this.store.select(fromAuth.selectUserRole)
  constructor(
    private store: Store) {

  }


}
