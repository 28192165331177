import { createSelector, createFeatureSelector, combineReducers, Action } from '@ngrx/store';
import * as fromRoot from '../../reducers';
import * as fromContractorPage from './contractor.reducer';

export const contractorFeatureKey = 'contractor';

export interface ContractorState {
  [fromContractorPage.ContractorPageFeatureKey]: fromContractorPage.State;
}

export interface State extends fromRoot.State {
  [contractorFeatureKey]: ContractorState;
}

export function reducers(state: ContractorState | undefined, action: Action) {
  return combineReducers({
    [fromContractorPage.ContractorPageFeatureKey]: fromContractorPage.reducer,
  })(state, action);
}

export const selectContractorState = createFeatureSelector<State, ContractorState>(contractorFeatureKey);

export const selectCintractorPageState = createSelector(
  selectContractorState,
  (state: ContractorState) => state.contractorPage
);
export const selectContractorError = createSelector(
  selectCintractorPageState,
  fromContractorPage.selectError
);
export const selectContractor = createSelector(
  selectCintractorPageState,
  fromContractorPage.selectContractor
); 
export const selectContractorOptions = createSelector(
  selectCintractorPageState,
  fromContractorPage.selectContractorOptions
);



export const selectCurrentContractor = createSelector(
  selectCintractorPageState,
  fromContractorPage.selectCurrent
);

export const selectPersons = createSelector(
  selectCintractorPageState,
  fromContractorPage.selectPersons
);

export const selectCurrentPerson = createSelector(
  selectCintractorPageState,
  fromContractorPage.selectCurentPerson
);



export const selectLoading = createSelector(
  selectCintractorPageState,
  fromContractorPage.selectLoading
);



export const selectProfileFromState = createSelector(
  selectCintractorPageState,
  fromContractorPage.selectProfile
);


