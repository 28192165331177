
import { Component  } from "@angular/core";
import * as fromContractors from 'src/app/contractor/reducers'
import { Store, select } from '@ngrx/store';
import * as fromLayout from 'src/app/core/reducers'
import * as fromAuth from 'src/app/auth/reducers'






@Component({
  selector: 'app-persons-list-page',
  template: `
    <app-list-person
      [role]="roleSel$ | async"
      [hide]="hideBtnFromTop$ | async"
      [data]="persons$ | async"
></app-list-person>
  `
})
export class PersonsListPageComponent  {
  hideBtnFromTop$ = this.store.select(fromLayout.selectBtnsFromTop)
  persons$ = this.store.pipe(select(fromContractors.selectPersons));

  roleSel$  = this.store.select(fromAuth.selectUserRole)
  constructor(
    private store: Store) {

  }

  
}
