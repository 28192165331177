import {createAction, props} from '@ngrx/store';
import { Contractor, Person } from './../models/contractor.model';



export const gsetContractors = createAction(
    '[Home Api] Get Api Contractors',
);

export const getContractor = createAction(
  '[Home Api] Get  Contractor',
  props<{ id: string }>()
);


export const getPerson = createAction(
  '[Home Api] Get  Person',
  props<{ id: string }>()
);

export const removePerson = createAction(
  '[Home Api] Remove  Person',
  );





export const getSinglePerson = createAction(
  '[Home Api] Get   Single  Person',
  props<{ id: string }>()
);






export const getContractorOptions = createAction(
  '[Home Api] Get Api Contractor Options',
);


export const getPersons = createAction(
  '[Home Api] Get Api Persons',
  
);

export const getPersonsByContractors = createAction(
  '[Home Api] Get Api Persons By Contractor',
  props<{ contractorId  :  string  }>()
);


export const getPersonsByContractor = createAction(
  '[Home Api] Get Api Persons By Contractor With Contractor Role'
  
);


export const addContractor = createAction(
  '[Home Api] Add Api Contractor',
  props<{ contractor: Contractor }>()
);
export const upDateContractor = createAction(
  '[Home Api] Update Api Contractor',
  props<{ contractor: Contractor, id: string }>()
);

export const upDatePerson = createAction(
  '[Home Api] Update Api Person',
  props<{ person: Person, id: string }>()
);


export const getProfile = createAction(
  '[Home Api] Get Profile'
  
);

export const addPerson = createAction(
  '[Home Api] Add Api person',
  props<{ person: Person }>()

);
