import { createAction, props } from "@ngrx/store";
import { IOrders } from "../models/orders.model";


export const getOrdersSuccess = createAction(
  '[Orders API] Get Orders Success',
  props<{ orders: IOrders[] }>()
);
export const getOrdersFailure = createAction(
  '[Orders API] Get Orders Failure',
  props<{ error }>()
);



export const getCompleteOrdersSuccess = createAction(
  '[Orders API] Get Complate Orders Success',
  props<{ orders: IOrders[] }>()
);
export const getCompleteOrdersFailure = createAction(
  '[Orders API] Get Orders Failure',
  props<{ error }>()
);







export const sendOrderSuccess = createAction(
  '[Orders API] Send Order Success',
 
);
export const sendOrderFailure = createAction(
  '[Orders API] Sen Order Failure',
  props<{ error }>()
);
