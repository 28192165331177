import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { SharedModule } from "../common/shared.module";
import * as fromLeads from "./reducers"
import * as leadEffects from "./effects"
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { LeadsRoutingModule } from "./leads-routing.module";
import { AddLeadComponent } from "./components/add-lead.component";
import { ListLeadComponent } from "./components/lead-list.component";
import { LeadPageComponent } from "./containers/lead-page.component";
import { LeadListPageComponent } from "./containers/lead-list-page.component";
import { AddLeadPageComponent } from "./containers/add-lead.page.component";
import { LeadsApiService, LeadsFakeService, LeadsService } from "./services";
import { SingleLeadPageComponent } from "./containers/single-lead-page.component";
import { SingleLeadComponent } from "./components/single-lead.component";

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';




import { MatNativeDateModule } from '@angular/material/core';


export const COMPONENTS = [
  ListLeadComponent,
  AddLeadComponent,
  LeadPageComponent,
  LeadListPageComponent,
  AddLeadPageComponent,
  SingleLeadPageComponent,
  SingleLeadComponent





]

const maskConfig: Partial<IConfig> = {
  dropSpecialCharacters: false,
};
@NgModule({
  imports: [
    MatNativeDateModule,
    CommonModule,
    SharedModule,
    LeadsRoutingModule,
    ReactiveFormsModule,
    FormsModule, NgxMaskModule.forRoot(),
    StoreModule.forFeature(fromLeads.leadsFeatureKey, fromLeads.reducers),
    EffectsModule.forFeature([leadEffects.LeadsEffects])
  ],
  providers: [
      { provide: LeadsApiService, useClass: LeadsService },
 //   { provide: LeadsApiService, useClass: LeadsFakeService }
  ],
  declarations: COMPONENTS
})
export class LeadsModule { }
