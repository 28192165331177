import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {StoreModule} from '@ngrx/store';
import {reducers} from './reducers';
import {EffectsModule} from '@ngrx/effects';
import {ReactiveFormsModule} from '@angular/forms';
import {ResetPasswordRoutingModule} from './reset-password-routing.module';
import {ResetPasswordEffects} from './effects/reset-password.effects';
import {ResetPasswordPageComponent} from './containers/reset-password-page.component';
import {ResetPasswordFormComponent} from './components/reset-password-form.component';
import * as fromReset from "./reducers"
import { ChangePasswordPageComponent } from "./containers/change-pasword-page.component";
import { ChangePasswordFormComponent } from "./components/change-password-form.component";
export const COMPONENTS = [
    ResetPasswordPageComponent,
  ResetPasswordFormComponent,
  ChangePasswordFormComponent,
    ChangePasswordPageComponent

];

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
    ResetPasswordRoutingModule,
        
    

    StoreModule.forFeature(fromReset.resetPasswordFeatureKey, fromReset.reducers),
        EffectsModule.forFeature([ResetPasswordEffects])
    ],
    declarations: COMPONENTS
})
export class ResetPasswordModule {
}
