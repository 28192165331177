

import { Injectable } from "@angular/core";

import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { of } from "rxjs";
import { catchError, map,  switchMap } from "rxjs/operators";
import * as fromMarkets from "../reducers";
import { OffersApiActions, OffersPageActions } from "../actions";

import { ApiService } from '../services/api.services';

import { LayoutActions } from "src/app/core/actions";

@Injectable()
export class MarketsEffects {

  sendForm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OffersPageActions.sendForm),
      switchMap(({ form}) => {
        return this.apiService.sendForm(form).pipe(
          map(() => {
            return OffersApiActions.sendFromSuccess();
          }),
          catchError((error: any) => of(OffersApiActions.sendFromFailure({ error })))
        );
      })
    )
  )


  sendOfferSuccess$  = createEffect(() =>
  this.actions$.pipe(
    ofType(OffersApiActions.sendFromSuccess),
    map(() => {
      this.store.dispatch(LayoutActions.setDialog({dialog : 'Oferta wysłana, sprawdz swoją skrzynkę '}));
    })
  ),
  { dispatch: false }
);





  constructor(
    private store: Store<fromMarkets.State>,
    private actions$: Actions,
    private apiService: ApiService,
   

  ) {
  }
}
