import { ContractorApiActions, ContractorPageActions } from "../actions"
import { createReducer, on } from '@ngrx/store';
import { Contractor, ContractorOptions, Person } from '../models'

export const ContractorPageFeatureKey = 'contractorPage';

export interface State {
  contractors: Contractor[] | null;
  contractorsOptions: ContractorOptions[] | null;
  error: string | null;
  loading: boolean;
  persons: Person[] | null;
  currentContractor: Contractor | null;
  currentPerson: Person | null;
  profile : any

}

export const initialState: State = {
  contractorsOptions: null,
  contractors: null,
  error: null,
  loading: false,
  persons: null,
  currentContractor: null,
  currentPerson: null,
  profile: null
};

export const reducer = createReducer(
  initialState,
  on(ContractorPageActions.getProfile,
    (state) => ({ ...state, loading: true })),
  on(ContractorApiActions.getProfileSuccess,
    (state, action) => ({ ...state, profile: action.profile, loading: false })),
  on(ContractorApiActions.getProfileError,
    (state, action) => ({ ...state, error: action.error, loading: false })),
  on(ContractorPageActions.gsetContractors,
    (state) => ({ ...state, loading: true })),
  on(ContractorApiActions.getContractorSuccess,
    (state, actions) => ({ ...state, contractors: actions.contractors, loading: false })),
  on(ContractorApiActions.getContractorError,
    (state, actions) => ({ ...state, error: actions.error, loading: false })),
  on(ContractorApiActions.addContractorSuccess,
    (state) => ({ ...state, loading: false })),
  on(ContractorApiActions.addContractorError,
    (state, actions) => ({ ...state, error: actions.error, loading: false })),
  on(ContractorPageActions.addContractor,
    (state, actions) => ({ ...state, loading:true  })),
  on(ContractorPageActions.addPerson,
    (state, actions) => ({ ...state, loading: true })),
  on(ContractorApiActions.addPersonSuccess,
    (state, actions) => ({ ...state, loading: false })),
  on(ContractorApiActions.addPersonError,
    (state, actions) => ({ ...state, error: actions.error, loading: false })),
  on(ContractorApiActions.getContractorOptionsSuccess,
    (state, actions) => ({ ...state, contractorsOptions: actions.contractors,  loading: false })),
  on(ContractorApiActions.getContractorOptionsError,
    (state, actions) => ({ ...state, error: actions.error, loading: false })),
  on(ContractorPageActions.getPersons,
    (state, actions) => ({ ...state, loading: true })),
  on(ContractorPageActions.getPersonsByContractors,
    (state, actions) => ({ ...state, loading: true })),
  on(ContractorApiActions.getPersonsSuccess,
    (state, actions) => ({ ...state, persons: actions.persons, loading: false })),
  on(ContractorApiActions.getPersonsError,
    (state, actions) => ({ ...state, error: actions.error, loading: false })),
  on(ContractorPageActions.getContractor,
    (state, actions) => ({
      ...state,
      currentContractor: state.contractors.find(contractor =>  contractor._id == actions.id),
      loading: true
    })),
  on(ContractorPageActions.upDateContractor,
    (state, actions) => ({ ...state, loading: true })),
  on(ContractorApiActions.updateContractorSuccess,
    (state, actions) => ({ ...state, loading: false })),
  on(ContractorApiActions.updateContractorError,
    (state, actions) => ({ ...state, error: actions.error, loading: false })),
  on(ContractorPageActions.getPerson,
    (state, actions) => ({
      ...state,
      currentPerson: state.persons.find(person => person._id == actions.id),
      loading: true
    })),
  on(ContractorPageActions.removePerson,
    (state, actions) => ({
      ...state,
      currentPerson: null,
      loading: true
    })),
  on(ContractorApiActions.getSinglePersonSuccess,
    (state, actions) => ({ ...state, currentPerson: actions.person, loading: false })),
  on(ContractorApiActions.getSinglePersonError,
    (state, actions) => ({ ...state, error: actions.error, loading: false })),
);




export const selectError = (state: State) => state.error;
export const selectLoading = (state: State) => state.loading;
export const selectContractor = (state: State) => state.contractors;
export const selectContractorOptions = (state: State) => state.contractorsOptions;
export const selectCurrent = (state: State) => state.currentContractor;
export const selectPersons = (state: State) => state.persons;
export const selectCurentPerson = (state: State) => state.currentPerson;
export const selectProfile = (state: State) => state.profile;




