import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Sort } from '@angular/material/sort';
import * as fromAuth from 'src/app/auth/reducers'

import { select, Store } from '@ngrx/store';

import * as fromContractors from "src/app/contractor/reducers"
import { OrdersPageActions } from '../../actions';


@Component({
  selector: 'app-orders',
  styleUrls: ['./orders-list.component.scss'],
  template: `
  <app-table-filters
          
            [search]="'Wyszukaj po dowolnych parametrach'"
            (filter)="filterData($event)"
        ></app-table-filters>

  <app-data-table
  [role]="role$ | async"
  [tableData]="data"
  [persons]="persons$ | async"
  [tableColumns]="columns"
  (sort)="sortData($event)"
></app-data-table>
`
})
export class ListOrdersComponent implements   OnChanges  {
  @Input() role : string;
  @Input() d : any



  data;
  role$ = this.store.pipe(select(fromAuth.selectUserRole));
  persons$ = this.store3.pipe(select(fromContractors.selectPersons));

  constructor(
  
    private store: Store,
    private store3: Store<fromContractors.State>
  ) { }





  ngOnChanges(changes: SimpleChanges): void {

    if (changes.d.currentValue) {
      this.data = changes.d.currentValue;
    }
    }



  columns = [

    { name: 'Imię i Nazwisko', dataKey: 'person', position: 'left', isSortable: true },
    { name: 'Dystrybutor', dataKey: 'distributor', position: 'left', isSortable: true },
    { name: 'Nr tel', dataKey: 'phone', position: 'left', isSortable: false },
    { name: 'Data', dataKey: 'date', position: 'left', isSortable: false, type: 'data-order'},
    { name: 'Notatka', dataKey: 'message', position: 'left', isSortable: false, type: 'message' },
    { name: 'Typ', dataKey: 'type', position: 'left', isSortable: true},
    { name: 'Ile sztuk', dataKey: 'number', position: 'left', isSortable: false },
    { name: 'Wyślij', dataKey: 'number', position: 'left', isSortable: false,  type: 'send-product' },


  ];

  sortData(sortParameters: Sort) {

    const keyName = sortParameters.active;
    if (sortParameters.direction === 'asc') {
      this.data = this.data.slice().sort((a, b) => a[keyName].toString()?.localeCompare(b[keyName].toString()));
    } else if (sortParameters.direction === 'desc') {
      this.data = this.data.slice().sort((a, b) => b[keyName].toString()?.localeCompare(a[keyName].toString()));
    }
  }


  days_between(date1, date2) {
    if (date2 > date1) {
      const ONE_DAY = 1000 * 60 * 60 * 24;
      const differenceMs = Math.abs(date1 - date2);
      return Math.abs(Math.round(differenceMs / ONE_DAY)) * -1;
    }
    const ONE_DAY = 1000 * 60 * 60 * 24;
    const differenceMs = Math.abs(date1 - date2);
    return Math.abs(Math.round(differenceMs / ONE_DAY)) * 1;
  }


  

  num: number;
  filterData(search) {
    let filteredData = [];
    this.data.forEach(val => {
      if (Object.values(val).some(value => {
        if (typeof value === 'object' && value !==null) {
          if (Object.values(value).some(item =>  item.toString().indexOf(search) !== -1 )) {
            filteredData.push(val);
          }
      } else
        if (value) {
          return value.toString().indexOf(search) !== -1;
        }
      })) {
        filteredData.push(val);
      }
    });
    this.data = filteredData;
  }

}
