import { createSelector, createFeatureSelector, combineReducers, Action } from '@ngrx/store';
import * as fromRoot from '../../reducers';
import * as LeadsPageFeatureKey from './leads.reducer';

export const leadsFeatureKey = 'leads';

export interface LeadsState {
  [LeadsPageFeatureKey.LeadsPageFeatureKey]: LeadsPageFeatureKey.State;
}

export interface State extends fromRoot.State {
  [leadsFeatureKey ]: LeadsState;
}

export function reducers(state: LeadsState| undefined, action: Action) {
  return combineReducers({
    [LeadsPageFeatureKey.LeadsPageFeatureKey]: LeadsPageFeatureKey.reducer,
  })(state, action);
}



export const selectLeadsState = createFeatureSelector<State, LeadsState>(leadsFeatureKey);

export const selectLeadsPageState = createSelector(
  selectLeadsState,
  (state: LeadsState) => state.leadsPage
);


export const selectLeads = createSelector(
  selectLeadsPageState,
  LeadsPageFeatureKey.selectLeads
);


export const selectLead = createSelector(
  selectLeadsPageState,
  LeadsPageFeatureKey.selectcurrentLead);


export const selectCalendarTitle = createSelector(
  selectLeadsPageState,
  LeadsPageFeatureKey.selectCalendarTitle);

  export const selectCurrentPage = createSelector(
    selectLeadsPageState,
    LeadsPageFeatureKey.selectCurrentPage);
