export const apiUrl = 'https://panel-api.eminto.pl/';
//export const apiUrl = 'https://panel-api-dev.eminto.pl';

// export const apiUrl = 'http://localhost:8080/';
//export const apiUrl = 'http://ec2-18-157-238-4.eu-central-1.compute.amazonaws.com:4200/';






