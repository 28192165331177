
import {  Component, ElementRef, ViewChild, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { ProductPageActions } from "../../actions/index";


@Component({
  selector: 'app-order-products',
  styleUrls: ['./order.component.scss'],
  templateUrl: './order.component.html'
})





export class OrderComponent implements OnInit  {




  constructor(
    private store : Store
    ) {

  }

  @ViewChild('key') key: ElementRef;
  selectedProduct: string;
  selectedQty: string;
  form = new FormGroup({
    product: new FormControl("eminto-box", [Validators.required]),
  
    mesage: new FormControl("", [Validators.minLength(3)]),
   
  
   
  })
  form2 = new FormGroup({
    product: new FormControl("virtual", [Validators.required]),

   
  })
  form3 = new FormGroup({
    product: new FormControl("desktop", [Validators.required]),
    computerId: new FormControl("", [Validators.required]),
    time: new FormControl("", [Validators.minLength(3), Validators.maxLength(15)]),
    key: new FormControl("", [Validators.required]),
    dentist: new FormControl("", [Validators.required]),

  })
  selectProduct(event) {
    this.selectedProduct = event;
  }

  selectQty(e) {
    this.selectedQty = e;
 
  }


  submit(form) {
 
    this.store.dispatch(ProductPageActions.orderProducts({ name: this.selectedProduct, qty: this.selectedQty, msg: form.mesage }));
  }
  
  generateKey() {
 
    this.key.nativeElement.value = "DVfefrfwwvvwfD"
  }

  copy() {
    navigator.clipboard.writeText("link");
  }


  ngOnInit(): void {
    throw new Error('Method not implemented.');
  }



}
