
import { createAction, props } from "@ngrx/store";
import { Dentist, DentistList, DentisSlistBySeller } from "../models/";
import { DentistDetails } from "../models/dentist.model";

export const getDentistsSuccess = createAction(
    '[Dentists API] Get Dentists Success',
  props<{ dentists: DentistList[]}>()
);
export const getDentistsFailure = createAction(
    '[Dentists API] Get Dentists Failure',
    props<{error}>()
);

export const getDentistsBySellerSuccess = createAction(
  '[Dentists API] Get Dentists By Seller Success',
  props<{ dentists: DentisSlistBySeller[] }>()
);
export const getDentistsBySellerFailure = createAction(
  '[Dentists API] Get Dentists By Seller Failure',
  props<{ error }>()
);





export const addDentistSuccess = createAction(
    '[Dentists API] Post Dentists Success',
    props<{dentist: Dentist}>()
);
export const addDentistFailure = createAction(
    '[Dentists API] Post Dentists Failure',
    props<{error}>()
);

export const addDentistWithStatusSuccess = createAction(
  '[Dentists API] Post Dentists With Status Success',
  props<{ dentistId: string, status: string}>()
);
export const addDentistWithStatusFailure = createAction(
  '[Dentists API] Post Dentists With Status Failure',
  props<{ error }>()
);





export const getStatusListSuccess = createAction(
    '[Dentists API] Get Status List Success',
    props<{statusList: any}>()
);
export const getStatusListFailure = createAction(
    '[Dentists API] Get Status List Failure',
    props<{error}>()
);


export const getDentistDetailsSuccess = createAction(
  '[Dentists API] Get Dentist Details Success',
  props<{ dentist: DentistDetails[] }>()
);
export const getDentistDetailsFailure = createAction(
  '[Dentists API] Get Dentists Details Failure',
  props<{ error }>()
);


export const addProductToDentistSuccess = createAction(
  '[Dentists API] Add Product  To Dentist Success',
 
);
export const addProductToDentistFailure = createAction(
  '[Dentists API] Add Product  To Dentist Failure',
  props<{ error }>()
);

export const AddContractorToDentist = createAction(
  "[Dentists Page] Add Contractor To Dentist",
  props<{ id: string, account: string }>()
);


export const AddContractorToDentistSuccess = createAction(
  '[Dentists API] Add Contractor To DentistSuccess',

);
export const AddContractorToDentistFailure = createAction(
  '[Dentists API] Add Contractor To Dentist Failure',
  props<{ error }>()
);

export const SetDentistStatusSuccess = createAction(
  '[Dentists API] Set Status Dentist Success',
  props<{ id : string }>()

);
export const SetDentistStatusFailure = createAction(
  '[Dentists API] Set  Status  Dentist Failure',
  props<{ error }>()
);



export const GenerateLinkSuccess = createAction(
  '[Dentists API]  Generate Test Link   Success',
  props<{ link : string }>()

);
export const GenerateTestLinkFailure = createAction(
  '[Dentists API] Generate Test Link Failure',
  props<{ error }>()
);

export const GeneratePaymentsLinkFailure = createAction(
  '[Dentists API] Generate Payments Link Failure',
  props<{ error }>()
);







export const GetDatafromGusSuccess = createAction(
  '[Dentists API]  Get Data from Gus Success',
  props<{ data: any }>()

);
export const GetDatafromGusFaliur = createAction(
  '[Dentists API] Get Data from Gus Failure',
  props<{ error }>()
);



export const confirmPaymentsSussess = createAction(
  "[Product Api]   Confirm Payments    Success",
  props<{ id:string }>());



export const confirmPaymentsFailure = createAction(
  "[Product Api] Confirm Payments  Failure",
  props<{ error }>());



export const RenewLicenseSuccess = createAction(
  "[Product Api]  Renew License Success" )
  
export const RenewLicenseFailure  = createAction(
  "[Product Api]  Renew License Failure",
  props<{ error }>()
)

export const AddLogoSuccess = createAction(
  "[Product Api]  Add Logo Success")

export const AddLogoFailure = createAction(
  "[Product Api]  Add Logo Failure",
  props<{ error }>()
)









