
import {  Component, ElementRef, ViewChild} from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import { Store, select } from "@ngrx/store";
import * as fromAuth from "src/app/auth/reducers"
import { routesData } from "../../common/models/route.model";

import * as fromLayout from "../../core/reducers"
import { ResetPasswordPageActions } from "../../reset-password/actions";
import { ChangePassword } from "../../reset-password/models/reset-password";
@Component({
  selector: "app-contractor-page",
  template: `


<ng-template class="mod" #mymodal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Zmiana hasła</h4>

  </div>
  <div class="modal-body">
    <form class="w-100" [formGroup]="form">
    
      <div class="form-floating">
        <input  #oldPassword   type="email" class="form-control" id="floatingInput"  formControlName="oldPpassword" />
        <label for="floatingInput">stare hasło</label>
      </div>

      <div class="form-floating">
        <input  #password   type="email" class="form-control" id="floatingInput"  formControlName="password" />
        <label for="floatingInput">Nowe hasło</label>
      </div>
      <div class="form-floating">
        <input    #repeatPassword  type="email" class="form-control" id="floatingInput"   formControlName="repeatPassword"  />
        <label for="floatingInput">Powtórz hasło</label>
      </div>
      <p class="mb-0 mt-3 text-start" >Hasło powinno zawierać:</p>
      <ul>
        <li> minimum 8 znaków</li>
        <li> co najmiej 1 wielką litere</li>
        <li> co najmiej 1 małą litere</li>
        <li> co najmiej 1  cyfra</li>
        <li> co najmiej 1  znak specjalny</li>
      </ul>

      <p class="pt-3 text-center text-danger" *ngIf="checkPasswords">Podane hasła nie są takie same. </p>
      <button [disabled]="form.invalid"   (click)="submit(password,repeatPassword, oldPassword)" class="w-100 mt-3 btn btn-primary" >Ustaw nowe hasło</button>
   
    </form>
  </div>
  <div class="modal-footer">
    <button type="button"  (click)="modalClose()"class="btn btn-primary" >Anuluj</button>
  </div>
</ng-template>

<app-top-nav
    [role]="role$ | async"
    [routes]="routesData"
    [show]="showBtn$ | async"
    (changePassword)="showModal()"
[selectBtnFromTop]="showBtnFromTop$  | async"
    ></app-top-nav>
    <router-outlet></router-outlet>
    `,
  styleUrls:[ './contractor-page.component.scss'],
})
export class ContractorPageComponent   {


  @ViewChild('mymodal') modal: ElementRef;

  role$ = this.store.pipe(select(fromAuth.selectUserRole));
  showBtn$ = this.store2.pipe(select(fromLayout.selectBtn));
  showBtnFromTop$ = this.store2.pipe(select(fromLayout.selectBtnsFromTop));
  checkPasswords:boolean;
  closeResult: string;

  routesData: routesData[] = [
    {
      path: "list-contractor",
      name: "Dystrybutor",
      forbiden: ['admin', 'director']
    },
    {
      path: "add-contractor",
      name: "Dodaj Dystrybutora",
      forbiden: ["seller", "director", "admin"]
    },
    {
      path: "list-preson",
      name: "Lista osób",
      forbiden: ["seller", 'admin', 'director'  ]
    },
    {
      path: "add-preson",
      name: "Dodaj osobę",
      forbiden: ["seller", "director", "admin"]
    }
  ];

  constructor(
    private modalService: NgbModal,
    private store: Store,
    private store2: Store<fromLayout.State>,
    
  ) {}
  form: FormGroup = new FormGroup({
    oldPpassword: new FormControl('', [Validators.required, Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!@#$%^&*()_+|}{:?><`!<>?]).{8,}$')]),
    password: new FormControl('', [Validators.required, Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!@#$%^&*()_+|}{:?><`!<>?]).{8,}$')]),
    repeatPassword: new FormControl('', [Validators.required, Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!@#$%^&*()_+|}{:?><`!<>?]).{8,}$')])
  });




  modalClose() {
    this.form.reset();
    this.modalService.dismissAll();
  }




  submit(password, repeatPassword, oldPassword ) {
    if (password.value === repeatPassword.value) {
      this.checkPasswords = false;
      let t: ChangePassword = {
        oldPassword : oldPassword.value,
        password: this.form.value['password'],
        confirmPassword: this.form.value['repeatPassword'],
      
      }
      this.store.dispatch(ResetPasswordPageActions.changePasswordFromApp({data : t}));
    } else {
      this.checkPasswords = true;
    }
  
  }



  showModal() {
    this.modalService.open(this.modal, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
     
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }


  private getDismissReason(reason: any): string {
    this.form.reset();
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  } 
}
