
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { ApiService } from 'src/app/contractor/services';
import { Contractor } from 'src/app/contractor/models';
import { select, Store } from '@ngrx/store';
import * as fromContractors from 'src/app/contractor/reducers'
import { ContractorPageActions } from '../../../actions';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-list-contractor',
    styleUrls: ['./list-contractor.component.scss'],
    template: `
  <app-table-filters
          
            [search]="'Wyszukaj po dowolnych parametrach'"
            (filter)="filterData($event)"
        ></app-table-filters>

  <app-data-table
  [tableData]="dataSet"
  [tableColumns]="columns"
  (sort)="sortData($event)"
></app-data-table>
 
`
})
export class ListContractorComponent implements OnInit, OnDestroy  {

 



  constructor(
    private store: Store,
    private stroeContractor: Store<fromContractors.State>,
        private apiService:ApiService,
    ) {
  }
  data$: Subscription;
    ngOnDestroy(): void {
      this.data$.unsubscribe();
    }
 
    columns = [
        { name: 'Kraj', dataKey: 'country', position: 'left', isSortable: false, bold: false },
        { name: 'Miejscowość', dataKey: 'city', position: 'left', isSortable: true },
        { name: 'Ulica', dataKey: 'street', position: 'street', isSortable: false, type: 'string' },
        { name: 'Kod pocztowy', dataKey: 'postalCode', position: 'left', isSortable: true, type: 'string' },
        { name: 'Skrócona nazwa', dataKey: 'shortName', position: 'left', isSortable: true, type: 'string' },
        { name: 'Nip', dataKey: 'nip', position: 'left', isSortable: true, type: 'number' },
         { name: 'www', dataKey: 'website', position: 'left', isSortable: true, type: 'www' },
        { name: 'Akcje', dataKey: 'action', position: 'left', isSortable: false, type: 'edit-contractor' },
    ]

    sortData(sortParameters: Sort) {
        const keyName = sortParameters.active;
        if (sortParameters.direction === 'asc') {  
            this.dataSet = this.dataSet.slice().sort((a, b) => a[keyName].toString()?.localeCompare(b[keyName].toString()));
        } else if (sortParameters.direction === 'desc') {
            this.dataSet = this.dataSet.slice().sort((a, b) => b[keyName].toString()?.localeCompare(a[keyName].toString()));
        } else {
            return this.dataSet;
        }
    }

    num: number;
  filterData(search) {
    if (search === 'clear') {
      this.dataSet = this.allData;
    } else {
      let filteredData = [];
      this.dataSet.forEach(val => {
        if (Object.values(val).some(value => {
          if (typeof value === 'object' && value !== null) {
            if (Object.values(value).some(item => item.toString().indexOf(search) !== -1)) {
              filteredData.push(val);
            }
          } else
            if (value) {
              return value.toString().indexOf(search) !== -1;
            }
        })) {
          filteredData.push(val);
        }
      });
      this.dataSet = filteredData;
    }
  }



    



  dataSet: Contractor[];
  allData: Contractor[];
    role = ['dystrybutor', 'handlowiec', 'admin']
    selectedRole = this.role[1];
    
  ngOnInit(): void {
    this.stroeContractor.dispatch(ContractorPageActions.gsetContractors());
    this.data$ =   this.apiService.getContractor().subscribe((res) => {

    this.dataSet = res;
    this.allData = res;
    });

  }


}
