import {createAction, props} from '@ngrx/store';
import {ResetPassword, ChangePassword } from '../models/reset-password';

export const reset = createAction(
    '[Reset Password Page] Reset',
    props<{resetPassword: ResetPassword}>()
);




export const checkLink = createAction(
  '[Reset Password Page] Check Link',
  props<{ token: string}>() 
);




export const changePassword = createAction(
  '[Reset Password Page] Change Password ',
  props<{ data: ChangePassword}>()
);


export const changePasswordFromApp = createAction(
  '[Reset Password Page] Change Password From App ',
  props<{ data: ChangePassword }>()
);


