
import { AfterViewInit, Component, ElementRef, EventEmitter, Renderer2, Input, Output, QueryList, ViewChild, ViewChildren, OnDestroy, OnChanges, SimpleChanges } from "@angular/core";
import * as Feather from 'feather-icons';
import { select, Store } from '@ngrx/store';
import * as fromProducts from 'src/app/products/reducers'
import { Subscription } from "rxjs";
import { LayoutActions } from "../../../core/actions";
import { MatDatepicker } from "@angular/material/datepicker";
import { Person } from "../../../contractor/models";



@Component({
  selector: 'app-table-filters',
  templateUrl: './table-filters.component.html',
  styleUrls: ['./table-filters.component.scss']
})
export class TableFiltersComponent implements AfterViewInit, OnDestroy, OnChanges {


  @Input() search;
  @Input() showBtn;
  @Input() showDentistFilters: boolean;
  @Input() showLeadsFilters: boolean;
  @Input() currentStatus: string;
  @Input() currentCampaing: any;
  @Input() hiddenColumns: any[];
  @Input() person: string | null;
  @Input() persons: Person[]
  @Input() dates: { startDate: string, endDate: string };
  @Output() filter = new EventEmitter();
  @Output() filterProducts = new EventEmitter();
  @Output() filterDentist = new EventEmitter();
  @Output() filterLeads = new EventEmitter();
  @Output() tabelColumn = new EventEmitter();
  @Output() currentLeadsPerson = new EventEmitter();
  @Output() dateRange = new EventEmitter();

  @ViewChildren('filterBnt') categoryBtns: QueryList<any>;
  @ViewChildren('dentistFilterBnt') dentistFilterBtns: QueryList<any>;
  @ViewChildren('leadsFilterBnt') leadsFilterBnt: QueryList<any>;
  @ViewChild('item') item: ElementRef;
  @ViewChild('datepickerFooter', { static: false }) datepickerFooter: ElementRef;
  @ViewChild('myRangePicker', { static: false }) datepicker: MatDatepicker<any>;

  subscription$: Subscription
  observable = this.store.pipe(select(fromProducts.selectCategoryProduct));
  isActiveFilter: boolean = false;
  isAllColumn: boolean = false;
  start: Date | null;
  end: Date | null;
  initClose: any;


  constructor(
    private renderer: Renderer2,
    private store: Store

  ) {

  }




  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hiddenColumns?.currentValue) {
      let isSomecolumHide = changes.hiddenColumns?.currentValue.some(val => val.hide === true);
      this.isAllColumn = !isSomecolumHide;
    }


    if (changes.dates?.currentValue === null) {
      this.start = null;
      this.end = null;
    }
    if (changes.dates?.currentValue) {
      this.start = changes.dates.currentValue.startDate;
      this.end = changes.dates.currentValue.endDate
      if(this.start === this.end)  {
        
      }
    }



    if (!changes.search?.firstChange && changes.search?.currentValue) {
      if (this.search != "Wyszukaj po dowolnych parametrach") {
        this.item.nativeElement.value = changes.search.currentValue;
      } else {
        this.item.nativeElement.placeholder = changes.search.currentValue;
        this.item.nativeElement.value = null;
      }
    }
  }


  setCategory(category: string) {
    this.categoryBtns.forEach(el => {
      el.nativeElement.classList.remove('active')
    })
    this.categoryBtns.forEach(el => {

      if (el.nativeElement.classList.contains(category)) {
        el.nativeElement.classList.add('active')

      }
    })
  }
  ngOnDestroy(): void {
    this.subscription$.unsubscribe()
  }


  ngAfterViewInit(): void {
    if (this.dates) {
      this.start = new Date(this.dates.startDate)
      this.end = new Date(this.dates.endDate)
    } else {
      this.start = null
      this.end = null
    }



    if (this.search != "Wyszukaj po dowolnych parametrach") {
      this.item.nativeElement.value = this.search;
    } else {
      this.item.nativeElement.placeholder = this.search;
    }

    this.dentistFilterBtns.forEach(el => {
      el.nativeElement.classList.remove('active')
    })
    if (this.currentStatus) {
      const { length, [length - 1]: astrix } = this.currentStatus.split('')
      if (astrix === "*") {
        this.dentistFilterBtns.forEach(el => {
          if (el.nativeElement.innerText === 'umowa' && el.nativeElement.getAttribute('data-transfer')) {
            this.isActiveFilter = true;
            el.nativeElement.classList.add('active')
          }
        })
      } else {
        this.dentistFilterBtns.forEach(el => {
          if (el.nativeElement.innerText === this.currentStatus && !el.nativeElement.getAttribute('data-transfer')) {
            this.isActiveFilter = true;
            el.nativeElement.classList.add('active')
          }
        })
      }
    }

    if (this.currentCampaing) {
      this.leadsFilterBnt.forEach(el => {
        if (el.nativeElement.innerText === this.currentCampaing) {
          this.isActiveFilter = true;
          el.nativeElement.classList.add('active')
        }
      })

    }




    Feather.replace();
    this.subscription$ = this.observable.subscribe(val => {

      this.setCategory(val)
    })
  }


  applyFiltersProducts(e): void {
    let box = this.renderer.parentNode(e.currentTarget);
    let btns = box.querySelectorAll('button');
    btns.forEach(el => {
      el.classList.remove('active')
    })
    this.renderer.addClass(e.currentTarget, 'active');
    this.filterProducts.emit(e.target.innerText);
  }




  applyFilters(): void {

    this.filter.emit(this.item.nativeElement.value);
  }

  applyDentistFilters(e, clear?: boolean) {
    if (!clear) {
      this.isActiveFilter = true;
      this.dentistFilterBtns.forEach(el => {
        el.nativeElement.classList.remove('active')
      })
      if (e.target.getAttribute('data-transfer')) {
        this.filterDentist.emit(e.target.innerText + "*");

      } else {
        this.filterDentist.emit(e.target.innerText);
      }
      this.renderer.addClass(e.target, "active");

    } else {
      this.dentistFilterBtns.forEach(el => {
        el.nativeElement.classList.remove('active')
      })
      this.isActiveFilter = false;
      this.filterDentist.emit("Wyczyść");
    }

  }


  applyLeadsFilters(e, clear?: boolean) {
    if (!clear) {
      this.isActiveFilter = true;
      this.leadsFilterBnt.forEach(el => {
        el.nativeElement.classList.remove('active')
      })

      this.filterLeads.emit(e.target.innerText);

      this.renderer.addClass(e.target, "active");

    } else {
      this.leadsFilterBnt.forEach(el => {
        el.nativeElement.classList.remove('active')
      })
      this.isActiveFilter = false;
      this.filterLeads.emit(null);
    }

  }


  selectColumnToShow(column: HTMLInputElement) {

    let columnTarget = {
      name: column.value,
      hide: column.value === 'all' ? column.checked : !column.checked,
    }
    this.tabelColumn.emit(columnTarget);
  }




  clearFilters(): void {
    this.filter.emit('clear');
  }



  personsToAssing = [
    { name: "Tymoteusz", _id: 'Tymoteusz' },
    { name: "Majeranek", _id: 'Majeranek' },

  ]

  filterLeadsByPerson(slectedPerson) {
    let li = this.renderer.parentNode(slectedPerson);
    this.renderer.addClass(li, 'active')
    let list = this.renderer.parentNode(li);
    let allLi = [...list.querySelectorAll('li')];
    allLi.forEach(li => {
      li.querySelector('input').checked = false;
    })
    slectedPerson.checked = true;
    this.currentLeadsPerson.emit(slectedPerson.value)
  }



  applyCalendarRange(clear?: boolean, isToday?: boolean) {
    if (isToday) {
      let today = new Date()
      const ys = today.getFullYear();
      const ms = ('0' + (today.getMonth() + 1)).slice(-2);
      let ds = today.getDate().toString();
      if (ds.length === 1) {
        ds = `0${ds}`;
      }

      const dateRange: any = {
        today: `${ys}-${ms}-${ds}`,
      };

      this.store.dispatch(LayoutActions.setLeadsFilterDate({ start: dateRange.today, end: dateRange.today }))
    } else {
      this.datepicker.close = this.initClose;
      if (clear) {
        this.store.dispatch(LayoutActions.removeLeadsFilterDate())
      } else {
        if (this.start && this.end) {
          const ys = this.start.getFullYear();
          const ms = ('0' + (this.start.getMonth() + 1)).slice(-2);
          let ds = this.start.getDate().toString();
          if (ds.length === 1) {
            ds = `0${ds}`;
          }
          const ye = this.end.getFullYear();
          const me = ('0' + (this.end.getMonth() + 1)).slice(-2);
          let de = this.end.getDate().toString();
          if (de.length === 1) {
            de = `0${de}`;
          }

          const dateRange: any = {
            startDate: `${ys}-${ms}-${ds}`,
            endDate: `${ye}-${me}-${de}`,
          };
          /* this.dateRange.emit(dateRange)*/
          this.store.dispatch(LayoutActions.setLeadsFilterDate({ start: dateRange.startDate, end: dateRange.endDate }))
        }
      }
      this.datepicker.close();
    }
  }

  private appendFooter() {
    const matCalendar = document.getElementsByClassName('mat-datepicker-content')[0] as HTMLElement;
    matCalendar.appendChild(this.datepickerFooter.nativeElement);
  }


  onOpen() {
    this.appendFooter();
    this.initClose = this.datepicker.close;
    this.datepicker.close = () => {

    }
    this.datepicker.stateChanges.subscribe(val => console.log(val))
  }

  dataChange(type: any, event: any) {

    
  }



}
