import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Renderer2,
} from "@angular/core";
import stepsJson  from '../../../../assets/json/steps.json';
import { Store } from "@ngrx/store";
import { NgbModal,   } from "@ng-bootstrap/ng-bootstrap";
import { FormControl, FormGroup, Validators, FormArray } from "@angular/forms";
import { Router } from "@angular/router";
import { LeadsPageActions } from "../../../leads/actions";
import {  IStepsUpdateData } from "../../../leads/models/leads.models";
import { ILeadPopup, ILeadStep } from "../../models/ledasSteps.models";


const today = new Date();
const month = today.getMonth();
const year = today.getFullYear();


@Component({
  selector: "app-step-dropdown",
  templateUrl: "./setp-dropdown.component.html",
  styleUrls: ["./step-dropdown.component.scss"]
})
export class StepDropDownComponent implements OnInit {


  @Output()
  showModal = new EventEmitter<ILeadPopup>();
  @Output()
  removeLeadSubmit = new EventEmitter<any>();
  @Input()
  stepsInput: any[];
  @Input()
  leadId : any[];
  @Input() 
  stepNumber: number;

  constructor(
    private renderer: Renderer2,
    private router: Router,
    private modalService: NgbModal,
    private store: Store,
  ) { }

  isStepValid: boolean;
  incrementStepVariant: boolean;
  nextStepDays: string;
  isNewStep: boolean;
  stepSelectedValue: string;
  maxDateOnCalendar: Date;
  selectedDate: string | undefined;
  stepsArray = new FormArray([]);
  steps: ILeadStep[];
  selectedStep:IStepsUpdateData;


  stepDropDownClose(e) {
    this.selectedDate = undefined;
  }

  selectLeadStep(selectedStatus) {
    let li = this.renderer.parentNode(selectedStatus);
    this.renderer.addClass(li, 'active')
    let list = this.renderer.parentNode(li);
    let allLi = [...list.querySelectorAll('li')];
    allLi.forEach(li => {
      li.querySelector('input').checked = false;
    })
    selectedStatus.checked = true;
    // this.selectedLeadStatus = selectedStatus.value;
  }

  setDate(event) {
    this.isStepValid = true;
    this.selectedDate = event;
  }


  selectStepVariant(stepSelected: HTMLInputElement, setCustomDatedate?: boolean, titleOfCalendar?: string, incrementStepVatiant?: boolean) {
    this.selectedDate = undefined;
    this.isStepValid = true;
    this.incrementStepVariant = incrementStepVatiant;
    this.isStepValid = !setCustomDatedate;
    if (titleOfCalendar) {
      this.store.dispatch(LeadsPageActions.setCalendatTitle({title : titleOfCalendar }));
    }
    this.stepsArray.controls.forEach(input => {
      input.setValue(false)
    })
    stepSelected.checked = true;
    this.stepSelectedValue = stepSelected.value;

    this.nextStepDays = stepSelected.getAttribute('data-days');
   
    if (stepSelected.getAttribute('data-next')) {
      this.isNewStep = true;
    } else {
      this.isNewStep = false;
    }
  }


  isToday(date) {
    const someDate = new Date(date)
    const today = new Date()
    if (someDate.getDate() == today.getDate() &&
      someDate.getMonth() == today.getMonth() &&
      someDate.getFullYear() == today.getFullYear()) {
      return true
    }
  }

  acceptStep(id) {
    if (this.stepSelectedValue === 'wysłano ofertę/kontakt 1' || this.stepSelectedValue === 'podłączyć box / recepcja 1') {
      this.showModal.emit({active : true, name : this.stepSelectedValue})
    }
    if (this.stepSelectedValue === 'niezainteresowany') {
      let newStep: IStepsUpdateData = {
        id,
        step: this.stepSelectedValue,
        days: this.nextStepDays || '0',
        isNewStep: this.isNewStep,
        newDate: this.selectedDate,
      }
      // update step to do:
      this.store.dispatch(LeadsPageActions.stepsUpdate({ data: newStep }));
      this.router.navigateByUrl('dentist/add-dentist/' + id + '/lead/not');
    } else if (this.stepSelectedValue === 'brak kontaktu') {
      let newStep: IStepsUpdateData = {
        id,
        step: this.stepSelectedValue,
        days: this.nextStepDays || '0',
        isNewStep: this.isNewStep,
        newDate: this.selectedDate,
      }
      // update step to do:
      // this.stepUpdate.emit(newStep)
      this.router.navigateByUrl('dentist/add-dentist/' + id + '/lead/notContact');
    }
    else {
      let newStep: IStepsUpdateData = {
        id,
        step: this.stepSelectedValue,
        days: this.nextStepDays,
        isNewStep: this.isNewStep,
        newDate: this.selectedDate,
        incrementStepVariant: this.incrementStepVariant,
      }
      // update step to do:
      // this.stepUpdate.emit(newStep)
      this.store.dispatch(LeadsPageActions.stepsUpdate({ data: newStep }));
    }
  }
  weekendsDatesFilter = (d = new Date()): boolean => {
    const day = d.getDay();
    return day !== 0 && day !== 6;
  }
  
  form_date = new FormGroup({
    date: new FormControl(new Date(year, month, 13)),
  });


  ngOnInit(): void {
    this.steps = this.stepsInput;
    this.steps.forEach(element => {
      this.stepsArray.push(new FormControl(''))
    });
  }
}


