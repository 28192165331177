import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild,
  ElementRef,
  ViewChildren,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TableColumn } from '../../models/table-column.model';
import { Store } from '@ngrx/store';
import {
  NgbModal,
  ModalDismissReasons,
  NgbDropdown,
} from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TooltipPosition } from '@angular/material/tooltip';
import { ProductPageActions } from '../../../products/actions/index';
import * as Contractorpageactions from '../../../contractor/actions/contractor-page.actions';
import { DentistsPageActions } from '../../../dentist/actions';
import { OrdersPageActions } from '../../../orders/actions';
import { Person } from '../../../contractor/models/contractor.model';
import { Router } from '@angular/router';
import { QueryList } from '@angular/core';
import { LeadsPageActions } from '../../../leads/actions';
import {
  ISingleLead,
} from '../../../leads/models/leads.models';
import stepsJson from '../../../../assets/json/steps.json';
import { ILeadPopup } from '../../models/ledasSteps.models';

declare var feather: any;

const today = new Date();
const month = today.getMonth();
const year = today.getFullYear();

@Component({
  selector: 'app-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss'],
})
export class DataTableComponent implements OnInit, AfterViewInit, OnChanges {
  constructor(
    private renderer: Renderer2,
    private router: Router,
    private modalService: NgbModal,
    private store: Store
  ) {}

  positionOptions: TooltipPosition[] = ['below', 'above', 'left', 'right'];
  position = new FormControl(this.positionOptions[0]);

  tableDataSource = new MatTableDataSource([]);
  displayedColumns: string[];
  addAdminSaveBtn: boolean;
  productId;

  @ViewChild('productModal') modal: ElementRef;
  @ViewChild('licensModal') modalLicens: ElementRef;
  @ViewChild('removeLeadModal ') modalLead: ElementRef;
  @ViewChildren(NgbDropdown) dropdowns: QueryList<NgbDropdown>;
  @ViewChild(MatPaginator, { static: false })
  matPaginator: MatPaginator;
  @ViewChild(MatSort, { static: true })
  matSort: MatSort;
  @ViewChild('password')
  pass: ElementRef;
  @ViewChild('contractorsform')
  contractorsform: ElementRef;
  @ViewChild('checkBox')
  checkBox: ElementRef;
  @Input()
  isPageable = false;
  @Input()
  isSortable = false;
  @Input()
  role: string;
  @Input()
  tableColumns: TableColumn[];
  @Input()
  rowActionIcon: string;
  @Input()
  paginationSizes: number[] = [10, 20, 30];
  @Input()
  defaultPageSize = this.paginationSizes[2];
  @Input()
  persons: Person[];
  @Input() currentPage : any;

  @Output()
  sort: EventEmitter<Sort> = new EventEmitter();
  @Output()
  rowAction = new EventEmitter();
  @Output()
  addAdminValue = new EventEmitter();
  @Output()
  assingPresonToLead = new EventEmitter<any>();
  @Output()
  removeLeadSubmit = new EventEmitter<any>();
  @Output()
  currentPageNumber = new EventEmitter<number>();

  @Input()
  set tableData(data: any[]) {
    this.setTableDataSource(data);
  }

  closeOthersDropdowns(
    currentDropDown,
    currentLead?: ISingleLead,
    event?,
    isStatusDropDown?: boolean
  ) {
    this.currentLead = currentLead;
    this.dropdowns.toArray().forEach((dropDown) => {
      dropDown.close();
    });
    currentDropDown.open();
  }

  selectLeadStep(selectedStatus) {
    let li = this.renderer.parentNode(selectedStatus);
    this.renderer.addClass(li, 'active');
    let list = this.renderer.parentNode(li);
    let allLi = [...list.querySelectorAll('li')];
    allLi.forEach((li) => {
      li.querySelector('input').checked = false;
    });
    selectedStatus.checked = true;
    this.selectedLeadStatus = selectedStatus.value;
  }

  changeLeadStatus(id) {
    this.store.dispatch(
      LeadsPageActions.changeLeadStatus({
        id: id,
        statusId: this.selectedLeadStatus,
      })
    );
  }

  selectStepVariant(
    stepSelected,
    date?,
    titleOfCalendar?,
    incrementStepVatiant?
  ) {
    this.isStepValid = true;
    this.incrementStepVariant = incrementStepVatiant;

    this.isStepValid = !date;
    if (titleOfCalendar) {
      this.store.dispatch(
        LeadsPageActions.setCalendatTitle({ title: titleOfCalendar })
      );
    }
    let li = this.renderer.parentNode(stepSelected);

    let list = this.renderer.parentNode(li);
    let allLi = [...list.querySelectorAll('li')];
    allLi.forEach((li) => {
      li.classList.remove('active');
      li.querySelector('input').checked = false;
    });
    this.renderer.addClass(li, 'active');
    stepSelected.checked = true;
    this.stepSelected = stepSelected.value;
    this.nextStepDays = stepSelected.getAttribute('data-days');
    if (stepSelected.getAttribute('data-next')) {
      this.isNewStep = true;
    } else {
      this.isNewStep = false;
    }
  }

  isToday(date) {
    const someDate = new Date(date);
    const today = new Date();
    if (
      someDate.getDate() == today.getDate() &&
      someDate.getMonth() == today.getMonth() &&
      someDate.getFullYear() == today.getFullYear()
    ) {
      return true;
    }
  }

  showModal(event) {
    this.showLeadModal = {
      ...this.showLeadModal,
      active: event.active,
      name: event.name,
    };
  }

  closeLeadModal() {
    this.showLeadModal = { ...this.showLeadModal, active: false };
  }

  /* Prevent Saturday and Sunday for select. */
  weekendsDatesFilter = (d = new Date()): boolean => {
    const day = d.getDay();
    return day !== 0 && day !== 6;
  };

  setDate(event) {
    this.isStepValid = true;
    this.selectedDate = event;
  }

  stepDropDownClose(element) {
    let parent = this.renderer.parentNode(element);
    let ul = this.renderer.nextSibling(parent);
    let allLi = [...ul.querySelectorAll('li')];
    allLi.forEach((li) => {
      li.querySelector('input').checked = false;
    });
    this.isStepValid = true;
    this.selectedDate = undefined;
  }

  form_date = new FormGroup({
    date: new FormControl(new Date(year, month, 13)),
  });

  onDate(e) {}

  assingPersonToLead(selectedPerson) {
    let li = this.renderer.parentNode(selectedPerson);
    this.renderer.addClass(li, 'active');
    let list = this.renderer.parentNode(li);
    let allLi = [...list.querySelectorAll('li')];
    allLi.forEach((li) => {
      li.querySelector('input').checked = false;
    });
    selectedPerson.checked = true;
    this.currentPersonId = selectedPerson.value;
  }

  assingLead(leadId) {
    this.assingPresonToLead.emit({
      id: leadId,
      accountId: this.currentPersonId,
    });
  }

  showForm(e, id) {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const specialchars = '!@#$%^&*()_+|}{:?><`!<>?';
    const charsSmall = 'qwertyuioplkjhgfdsazxcvbnm';
    const numbers = '1234567890';

    let password = '';

    const randomLeater = Math.floor(Math.random() * chars.length);
    password += chars.substring(randomLeater, randomLeater + 1);
    const randomChar = Math.floor(Math.random() * specialchars.length);
    password += specialchars.substring(randomChar, randomChar + 1);

    for (let i = 0; i < 7; i++) {
      let r = Math.floor(Math.random() * charsSmall.length);
      password += charsSmall.substring(r, r + 1);
    }
    const randomNumber = Math.floor(Math.random() * numbers.length);
    password += numbers.substring(randomNumber, randomNumber + 1);
    this.currentRow = id;
    this.productId = id;
    this.addPassBtn = !this.addPassBtn;

    setTimeout(() => {
      const parent = this.renderer.parentNode(e);
      const form = parent.querySelector('form');
      const input = form.querySelector('input');
      input.value = password.toString();
      this.password = password.toString();
    }, 500);
  }
  dataSteps: any[];
  changeAcconunt: boolean = false;
  isAll: boolean = false;
  isClear: boolean;
  productToRemove: string;
  productToRenewLicense: string;
  currentPersonName: string;
  currentContractorName: string = '';
  saveAdminBtn = false;
  addPassBtn = false;
  addContractorBtn: boolean;
  currentRow: string;
  contractorId: string;
  sellerId: string;
  showContractorList: boolean = false;
  showPersonListByContractor: boolean = false;
  saveBtn: boolean = false;
  showAddContracrotBtn: boolean = false;
  password: string;
  changeSellerView: boolean = false;
  showDistributors: boolean = false;
  licensLenght: number = 12;
  isStepValid: boolean = true;
  selectedDate: string | null;
  start: Date;
  todayDate: Date = new Date();
  stepSelected: any;
  isNewStep: boolean;
  nextStepDays: string;
  selectedLeadStatus: string;
  currentPersonId: string;
  incrementStepVariant: boolean;
  currentLead: ISingleLead;
  currentPageNumberOfList:number = 0;
  showLeadModal: ILeadPopup = { active: false, name: '' };

  changeView(e, element, id) {
    this.persons = [];
    const parent = this.renderer.parentNode(element);
    if (e) {
      parent.classList.add('show');
      this.showContractorList = e;
    }
    if (!e) {
      parent.classList.remove('show');
      this.showContractorList = e;
      this.showPersonListByContractor = false;
    }
  }

  changeViewSellers(e, element, id) {
    this.currentRow = id;

    const parent = this.renderer.parentNode(element);
    if (e) {
      parent.classList.add('show');
      this.showPersonListByContractor = e;
    }
    if (!e) {
      parent.classList.remove('show');
      this.showPersonListByContractor = e;
    }
  }

  changeViewOnProducts(e, element, id) {
    this.currentPersonName = '';
    this.showDistributors = true;
    this.addPassBtn = false;
    this.currentRow = id;
    const parent = this.renderer.parentNode(element);
    if (e) {
      parent.classList.add('show');
      this.saveBtn = true;
    }
    if (!e) {
      this.currentRow = '';
      this.showPersonListByContractor = false;
      this.saveBtn = false;
      parent.classList.remove('show');
    }
  }

  form2 = new FormGroup({
    product: new FormControl('virtual', [Validators.required]),
    playerName: new FormControl('', [Validators.required]),
    login: new FormControl('', [Validators.required]),
    pass: new FormControl('', [Validators.required]),
  });

  changeSeller(element, contractorId?) {
    if (contractorId) {
      this.contractorId = contractorId;
      this.store.dispatch(
        Contractorpageactions.getPersonsByContractors({
          contractorId: this.contractorId,
        })
      );
      this.showPersonListByContractor = true;
    }
    this.currentContractorName = '';
    this.changeAcconunt = true;
    this.currentRow = element;
    this.showDistributors = true;
    this.addContractorBtn = true;
    this.saveBtn = true;
  }

  getSelerByContractor(contractorId, element, name?) {
    this.showPersonListByContractor = true;
    this.currentContractorName = name;
    this.contractorId = contractorId;
    this.currentRow = element;
    this.store.dispatch(
      Contractorpageactions.getPersonsByContractors({
        contractorId: this.contractorId,
      })
    );
    this.addContractorBtn = true;
    if (this.showContractorList) {
      this.showAddContracrotBtn = true;
    }
  }

  addProductToSeler(contractor, itemId, sellerId, name, id?) {
    if (id) {
      this.showAddContracrotBtn = true;
      this.currentRow = id;
    }
    this.currentPersonName = name;
    this.addContractorBtn = true;
    this.sellerId = sellerId;
  }
  assingContractorToDentist() {
    this.showPersonListByContractor = false;
    this.showAddContracrotBtn = false;
    if (this.sellerId) {
      this.store.dispatch(
        DentistsPageActions.AddContractorToDentist({
          id: this.currentRow,
          accountId: this.sellerId,
          distributorId: this.contractorId,
        })
      );
    } else {
      this.store.dispatch(
        DentistsPageActions.AddContractorToDentist({
          id: this.currentRow,
          accountId: this.contractorId,
          distributorId: this.contractorId,
        })
      );
    }
  }

  assignPassword() {
    this.password = this.pass.nativeElement.value;
    this.store.dispatch(
      ProductPageActions.setPassword({
        id: this.productId,
        pass: this.password,
      })
    );
    this.currentRow = '';
    this.productId = '';
    this.addPassBtn = !this.addPassBtn;
  }

  hidePassForm() {
    this.currentRow = '';
    this.productId = '';
    this.addPassBtn = !this.addPassBtn;
  }

  assign() {
    this.changeAcconunt = false;
    this.changeSellerView = false;
    this.showAddContracrotBtn = false;
    this.showDistributors = false;
    this.showPersonListByContractor = false;
    if (this.sellerId) {
      this.store.dispatch(
        ProductPageActions.addPlayerToSeller({
          contractorId: this.contractorId,
          productId: this.currentRow,
          personId: this.sellerId,
          role: this.role,
        })
      );
    } else {
      this.store.dispatch(
        ProductPageActions.addPlayerToSeller({
          contractorId: this.contractorId,
          productId: this.currentRow,
        })
      );
    }
    this.addContractorBtn = false;
  }

  ngOnInit(): void {
    this.dataSteps = stepsJson;
    const columns = this.tableColumns.filter((tableColumn: TableColumn) => {
      if (tableColumn.name === 'Kontrahent' && this.role === 'eminto') {
        return tableColumn.name;
      }
      if (tableColumn.name === 'Opiekun' && this.role === 'eminto') {
        return tableColumn.name;
      }
      if (tableColumn.name === 'Opiekun' && this.role === 'director') {
        return tableColumn.name;
      }
      if (tableColumn.name === 'Opiekun' && this.role === 'admin') {
        return tableColumn.name;
      }
      if (tableColumn.name !== 'Kontrahent' && tableColumn.name !== 'Opiekun') {
        return tableColumn.name;
      }
    });

    const columnNames = columns.map(
      (tableColumn: TableColumn) => tableColumn.name
    );
    if (this.rowActionIcon) {
      this.displayedColumns = [this.rowActionIcon, ...columnNames];
    } else {
      this.displayedColumns = columnNames;
    }
  }

  ngAfterViewInit(): void {
    this.matPaginator.pageIndex = this.currentPageNumberOfList;
    this.tableDataSource.paginator = this.matPaginator;
    feather.replace();
    this.matSort.disableClear = true;
  }

  ngOnChanges(changes: SimpleChanges) {
      if(changes.currentPage)  {
      this.currentPageNumberOfList = changes.currentPage.currentValue;
      }


    if (
      changes.tableColumns?.currentValue &&
      !changes.tableColumns?.firstChange
    ) {
      const columns = this.tableColumns.filter((tableColumn: TableColumn) => {
        if (tableColumn.name === 'Kontrahent' && this.role === 'eminto') {
          return tableColumn.name;
        }
        if (tableColumn.name === 'Opiekun' && this.role === 'eminto') {
          return tableColumn.name;
        }
        if (tableColumn.name === 'Opiekun' && this.role === 'director') {
          return tableColumn.name;
        }
        if (tableColumn.name === 'Opiekun' && this.role === 'admin') {
          return tableColumn.name;
        }
        if (
          tableColumn.name !== 'Kontrahent' &&
          tableColumn.name !== 'Opiekun'
        ) {
          return tableColumn.name;
        }
      });

      const columnNames = columns.map(
        (tableColumn: TableColumn) => tableColumn.name
      );
      if (this.rowActionIcon) {
        this.displayedColumns = [this.rowActionIcon, ...columnNames];
      } else {
        this.displayedColumns = columnNames;
      }
    }

    if (changes.tableData?.currentValue && changes?.tableData) {
      this.tableData = changes.tableData.currentValue.map((dentist) => {
        const d = {};
        for (let [key, val] of Object.entries(dentist)) {
          if (key === 'contact') {
            for (let [key, value] of Object.entries(val)) {
              d[key] = value;
            }
          }
          if (key === 'complete') {
            for (let [key, value] of Object.entries(val)) {
              d[key] = value;
            }
          }
          d[key] = val;
        }
        return d;
      });
    }
  }

  setTableDataSource(data: any) {
    this.tableDataSource = new MatTableDataSource<any>(data);
    this.tableDataSource.paginator = this.matPaginator;
    this.tableDataSource.sort = this.matSort;
  }


  pageEvent(pageEvent) {
    this.currentPageNumber.emit(pageEvent.pageIndex);
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.tableDataSource.filter = filterValue.trim().toLowerCase();
  }

  sortTable(sortParameters: Sort) {
    sortParameters.active = this.tableColumns.find(
      (column) => column.name === sortParameters.active
    ).dataKey;
    this.sort.emit(sortParameters);
  }

  emitRowAction(row: any) {
    this.rowAction.emit(row);
  }

  addDays(id: string) {
    this.store.dispatch(ProductPageActions.AddDAysToProduct({ productId: id }));
  }

  closeResult: string;

  remove(): void {
    this.modalService.dismissAll();
    this.store.dispatch(
      ProductPageActions.removePlayer({ id: this.productToRemove })
    );
  }

  removeProduct(id: string, isClear?: boolean) {
    this.isClear = isClear;
    this.productToRemove = id;
    this.modalService
      .open(this.modal, { ariaLabelledBy: 'modal-basic-title' })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  removeLead() {
    this.modalService.dismissAll();
    this.removeLeadSubmit.emit(this.productToRemove);
  }

  showDelateLeadModal(id: string) {
    this.productToRemove = id;
    this.modalService
      .open(this.modalLead, { ariaLabelledBy: 'modal-basic-title' })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  setCheckBoxValue(e) {
    this.isAll = e;
  }

  clearPlayer() {
    this.modalService.dismissAll();
    this.store.dispatch(
      ProductPageActions.clearPlayer({
        id: this.productToRemove,
        remove: this.isAll,
      })
    );
  }

  addAdmin(id) {
    this.currentRow = id;
    this.productId = id;
    this.addAdminSaveBtn = true;
    this.store.dispatch(ProductPageActions.addAdmin({ productId: id }));
  }

  removeDistributor(productId) {
    this.store.dispatch(
      ProductPageActions.removeDistributor({ id: productId })
    );
  }

  removeAdmin() {
    this.currentRow = '';
    this.productId = '';
    this.addAdminSaveBtn = false;
  }

  renewLicensModalOpen(id) {
    this.productToRenewLicense = id;
    this.modalService
      .open(this.modalLicens, { ariaLabelledBy: 'modal-basic-title' })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  descentLicenst() {
    if (this.licensLenght > 0) this.licensLenght--;
  }
  incrementLicens() {
    this.licensLenght++;
  }

  renevLicens() {
    this.store.dispatch(
      DentistsPageActions.renewLicense({
        dentistId: this.productToRenewLicense,
        laicensDuration: this.licensLenght,
      })
    );
    this.modalService.dismissAll();
  }

  createDentistFrromLead(id) {
    this.router.navigateByUrl('dentist/add-dentist/' + id + '/lead');
  }

  sendProduct(id) {
    this.store.dispatch(OrdersPageActions.sendOrder({ id: id }));
  }

  activeIds: string[] = [];

  toggleAccordian(event, acc) {
    acc.expand('test');

    if (!event.nextState) {
      this.activeIds = [];
    }
    if (this.activeIds[0] !== event.panelId && event.nextState) {
      this.activeIds = [];
      this.activeIds = [event.panelId, event.panelId];
    }

    acc.collapse('ngb-panel-5');
  }

  public isCollapsed = false;
}
