import { Component } from "@angular/core";
import { select, Store } from '@ngrx/store';
import { Router } from "@angular/router";
import { routesData } from "../../common/models/route.model";
import * as fromAuth from "src/app/auth/reducers"
import * as fromContractors from "src/app/contractor/reducers"
import { combineLatest } from "rxjs";
import { map } from "rxjs/operators";

@Component({
  selector: '',
  template: `

    <router-outlet></router-outlet>
    `,
 
})
export class OfferPageComponent {
  role$ = this.store.pipe(select(fromAuth.selectUserRole));



 

  constructor(private store: Store) { }
  routesData: routesData[] = [
  
  ];



}







