import { OnInit } from "@angular/core";
import { Component } from "@angular/core";
import * as fromLeads from 'src/app/leads/reducers'
import * as fromLayout from 'src/app/core/reducers'
import * as fromContractor from 'src/app/contractor/reducers'
import { select, Store } from '@ngrx/store';
import { LeadsPageActions } from "../actions";
import {  merge, combineLatest  } from "rxjs";
import { map, mergeAll, combineAll  } from "rxjs/operators";
import { ContractorPageActions } from "../../contractor/actions";










@Component({
  selector: '<app-lead-page-list></app-lead-page-list>',
  
  template: `<app-list-leads
  [currentData]="data$ | async"
  [persons]="persons$ | async"
  [currentPage]="currentPage$ | async"
  [currentleadsColumns]="currentleadsColumns$ | async"
></app-list-leads>`
})
export class LeadListPageComponent implements OnInit {
  //dataLeads$ = this.store.pipe(select(fromLeads.selectLeads));
  //currentCampaing$ = this.store.pipe(select(fromLayout.selectCampaing));
  //currentSearch$ = this.store.pipe(select(fromLayout.selectCampainSearch));
  currentleadsColumns$ = this.store.pipe(select(fromLayout.selectCurrentLeadsColumns));
  //currentLeadsPerson$ = this.store.pipe(select(fromLayout.selectCurrentPersonOfLeads));
  persons$ = this.store.pipe(select(fromContractor.selectPersons));
  currentPage$ = this.store.pipe(select(fromLeads.selectCurrentPage))


  data$ = combineLatest ([
    this.store.pipe(select(fromLayout.selectCurrentPersonOfLeads)),
    this.store.pipe(select(fromLayout.selectCampaing)),
    this.store.pipe(select(fromLeads.selectLeads)),
    this.store.pipe(select(fromLayout.selectCampainSearch)),
    this.store.pipe(select(fromLayout.selectLeadFilterDate))
  ]).pipe(
    map(data => {
      return {
        currentPerson: data[0],
        currentCampaing: data[1],
        leads: data[2] || [],
        search: data[3],
        dates : data[4]
      }
    
    })
    )

  constructor(private store: Store) { }
  ngOnInit(): void {
   this.store.dispatch(LeadsPageActions.getLeads())

    this.store.dispatch(ContractorPageActions.getPersonsByContractors({contractorId: '62824908073a3aa72eaafcab'}))

    }





}
