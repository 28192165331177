import { Action, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromRoot from '../../reducers';
import * as fromDentistsPage from './dentists.reducer';

export const dentistsFeatureKey = 'dentists';

export interface DentistsState {
  [fromDentistsPage.dentistsPageFeatureKey]: fromDentistsPage.State;
}

export interface State extends fromRoot.State {
    [dentistsFeatureKey]: DentistsState;
}

export function reducers(state: DentistsState | undefined, action: Action) {
    return combineReducers({
      [fromDentistsPage.dentistsPageFeatureKey]: fromDentistsPage.reducer,
    })(state, action);
}

export const selectDentistsState = createFeatureSelector<State, DentistsState>(dentistsFeatureKey);

export const selectDentistsPageState = createSelector(
    selectDentistsState,
    (state: DentistsState) => state.dentistsPage
);

export const selectError = createSelector(
    selectDentistsPageState,
    fromDentistsPage.selectError
);
export const selectDentists = createSelector(
    selectDentistsPageState,
    fromDentistsPage.selectDentists
);


export const selectDentist = createSelector(
  selectDentistsPageState,
  fromDentistsPage.selectCurrent
);


export const selectLoading = createSelector(
  selectDentistsPageState,
  fromDentistsPage.selectLoading
);


export const selectGus = createSelector(
  selectDentistsPageState,
  fromDentistsPage.selectGusDentist
);


export const selectLink = createSelector(
  selectDentistsPageState,
  fromDentistsPage.selectLink
);


export const selectDentistBySeller = createSelector(
  selectDentistsPageState,
  fromDentistsPage.selectDentistBySeller
);

