import { AfterViewInit, Component, OnDestroy, ViewChild } from "@angular/core";
import { select, Store } from "@ngrx/store";
import * as fromAuth from "../../auth/reducers";
import * as fromContractors from "../../contractor/reducers"
import * as fromProducts from "../../products/reducers"
import * as fromDentist from "../../dentist/reducers"
import * as fromOrders from "../../orders/reducers"
import { Subscription } from 'rxjs';
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import { ThemePalette } from "@angular/material/core";
import { ProgressSpinnerMode } from "@angular/material/progress-spinner";
import * as fromLayout from "../../reducers/index";
import { AuthActions } from "src/app/auth/actions";
declare var feather;

@Component({
  selector: "app-root",
  template: `

<ngx-spinner 
    bdColor="rgba(51,51,51,0.8)"
    size="medium"
    color="#fff"
    type="ball-scale-multiple" >
    <p style="font-size: 20px; color: white"> Loading...</p>
      </ngx-spinner>
<app-spinner [loader]="spinner$ | async"
              [loaderContractors]="spinnerContractors$ | async"
              
></app-spinner>
<app-bottom-slide-popup
[bottomSlidePopup]="error$ | async"
[msg]="msg$ | async"

></app-bottom-slide-popup>




<div *ngIf="person$ | async"></div>
    <ng-container
        *ngIf="loggedIn; then loggedInTpl; else notLoggedInTpl"
    ></ng-container>
    <ng-template #loggedInTpl>
        <div id="wrapper">
            <app-menu
                [orders]="orders$ | async"
                [user]="user$ | async"
                [role]="role$ | async"
                [url]="currentUrl$ | async"    
                *ngIf="isLogin"
            ></app-menu>
            <div id="content-wrapper"   [ngClass]=" { 'showMenu'  : isMenu$ | async,  'login' :  isLogin, 'notLogin' : !isLogin} " class="d-flex flex-column">
                <div id="content">
                    <router-outlet></router-outlet>
                </div>
            </div>
        </div>         
    </ng-template>
    <ng-template #notLoggedInTpl>
        <router-outlet></router-outlet>
    </ng-template>
<ng-template #modalWelcome let-modal>
  <div class="modal-header">
<img  src="assets/party.gif"  />

  </div>
  <div class="modal-body">
<h4  class="modal-title mb-2" id="modal-basic-title">Witamy na pokładzie eminto!</h4>
<p>
Cieszymy się, że dołączyłeś do nas!<br>
Teraz wspólnie będziemy dostarczać nowoczesne rozwiązania do&nbsp;gabinetów stomatologicznych.
Panel będzie Cię&nbsp;wspierać w&nbsp;codziennej pracy.
Tutaj znajdziesz informacje o&nbsp;produkcie:</p>

<ul>
<li>zamówisz eminto box’y na testy do gabinetów</li>
<li>zrobisz symulację dodatkowych zysków w przygotowanym kalkulatorze</li>
<li>jak również sprawdzisz statusy swojej aktywności.</li>

</ul><p>
Trzymamy kciuki! </p>
  </div>
  <div class="modal-footer">

        <button type="button" class="btn btn-primary" (click)="modalClose()">Rozpocznij</button>
  </div>
</ng-template>
    `
})
export class AppComponent implements AfterViewInit, OnDestroy {

  [x: string]: any;
  @ViewChild('modalWelcome') modal: any;

  subscription: Subscription
  subscriptionPopUp: Subscription
  closeResult: string;
  content = "modal";

  loggedIn = true;
  getRouterOdElearning
  user$ = this.store.pipe(select(fromAuth.selectUserName));
  role$ = this.store.pipe(select(fromAuth.selectUserRole));
  welcomePopu$ = this.store.pipe(select(fromAuth.selectwelcomePopup));
  currentUrl$ = this.store.pipe(select(fromLayout.getRouter));
  person$ = this.stor2.pipe(select(fromContractors.selectContractorError));
  error$ = this.store3.pipe(select(fromLayout.selectError));
  msg$ = this.store3.pipe(select(fromLayout.selectMsgInfo));
  isMenu$ = this.store3.pipe(select(fromLayout.selectIsMenuShow));
  spinner$ = this.storeSpiner.pipe(select(fromDentist.selectLoading));
  loaderProducts = this.storeSpiner.pipe(select(fromProducts.selectLoading));
  spinnerContractors$ = this.stor2.pipe(select(fromContractors.selectLoading));
  orders$ = this.storeSpiner.pipe(select(fromOrders.selectOrders))
  isLogin: boolean = false;
  constructor(

    private modalService: NgbModal,
    private storeSpiner: Store,
    private stor2: Store<fromContractors.State>,
    private store3: Store<fromLayout.State>,
    private store: Store<fromAuth.State>) {

  }

  color: ThemePalette = "primary";
  mode: ProgressSpinnerMode = "determinate";
  value = 50;




  open() {
    this.modalService.open(this.modal, {
      ariaLabelledBy: 'modal-basic-title', size: 'sm',
      windowClass: 'custom-class'
    }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {

      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  modalClose() {

    this.store.dispatch(AuthActions.dismissPopup())

    this.modalService.dismissAll();
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe()
    this.subscriptionPopUp.unsubscribe()
  }
  ngAfterViewInit() {
    
    feather.replace();
    this.subscription = this.role$.subscribe((res => {
      if (res !== "api" && res) {
        this.isLogin = true;
        this.subscriptionPopUp = this.welcomePopu$.subscribe((res => {
          if (res) {
            this.open()
          }
        }))
      } else {
        this.isLogin = false;
      }

    }));

  }
}
