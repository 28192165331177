import {  Component } from "@angular/core";

@Component({
    selector: 'app-offer',
    styleUrls: ['./offer.component.scss'],
    templateUrl: './offer.component.html'
})
export class OfferComponent    {
   
}
