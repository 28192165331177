

import {  Component, OnDestroy, OnInit } from "@angular/core";
import * as fromRoot from '../../reducers';
import {  OffersPageActions  }  from '../actions'
import * as fromOffers from '../reducers';
import { select, Store } from '@ngrx/store';
import { IFormData } from "../models/offers.model";
import * as fromOffer from "src/app/offer/reducers"
import * as fromAuth from "src/app/auth/reducers"
import * as fromContractors from "src/app/contractor/reducers"
import { combineLatest } from "rxjs";
import { map } from "rxjs/operators";
import { ContractorPageActions } from "src/app/contractor/actions";

// import * as fromAuth from "../../auth/reducers";
@Component({
  template: `<app-create-offer
  (sendFormData)="sendFormData($event)"
  [data]="data$ | async"
  [isFormSend]="isOfferSend$ | async"
  ></app-create-offer>`,
})
export class CreateOfferPageComponent implements OnInit, OnDestroy {
 
  user$ = this.store.pipe(select(fromAuth.selectUserName));
  isOfferSend$ = this.store.pipe(select(fromOffer.selectSendForm));
  




  data$ = combineLatest ([
    this.store.pipe(select(fromAuth.selectUserName)),
    this.store.pipe(select(fromContractors.selectPersons))

  ]).pipe(
    map(data => {
      if (data[1])  {
        let person = data[1].find(val => {
          let name = `${val.name} ${val.surname}`
          if ( name === data[0])  {
            return val;
          }
        })
      return person
      }
    })
    )




  constructor(
    private store: Store
  ) {
  }
  ngOnDestroy(): void {   
    console.log('ondestroy')
  }
  

  onShowVideo(event: string) {

  }

  submitNipNumber(nip: string) {    
  }

  sendFormData(form:IFormData) {
    console.log(form);
  this.store.dispatch(OffersPageActions.sendForm({ form }))
  
  }

  hideVideo()  {
  
  }

  ngOnInit(): void {
    this.store.dispatch(ContractorPageActions.getPersons())
  }

}
