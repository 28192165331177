
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Dentist, DentisSlistBySeller } from '../models/';
import { DentistDetails } from "../models/dentist.model";


@Injectable({
    providedIn: 'root'
})
export abstract class ApiService {
  abstract postDentist(dentist): Observable<any>;
  abstract getDentistDetails(id: string): Observable<DentistDetails[]>;
  abstract getDentist(): Observable<any>;
  abstract getDentistBySeller(id: string): Observable<DentisSlistBySeller[]>;
  abstract confirmPayments(dentistId: string): Observable<any>;
  abstract renewLicense(dentistId: string, laicensDuration: number): Observable<any>;
  abstract getDataFromGus(nip: string): Observable<any>;
  abstract addLogo(logo: File): Observable<any>;
  //abstract addDentist(dentist: Dentist);
  abstract upDateDentist(dentist: Dentist): Observable<any>;
  abstract setDentistStatus(dentistId: string, statusId: string | boolean, productId?: string): Observable<any>;
  abstract generateTestLink(DentistId: string, productId: string[]):  Observable<any>;
  abstract generatePaymentLink(DentistId: string, productId: string[], licenseDuration:number, price?: string, monthly?: string):  Observable<any>;
  abstract addProductToDentist(id: string, dentistId: string, statusID): Observable<any>; 
  abstract addContractorToDentist(id: string, accountId: string, distributorId?:string): Observable<any>;
}
