

export interface ISingleLead {
  date: string,
  DentisName: string,
  name: string,
  email: string,
  phone: string,
  campaign: string,
  isDentist: boolean,
  accountLead?: string,
  accountId?: string,
  _id: string,
  steps: ISingleStep[],
  controlPhoneDate?: Date,
  
}


export enum campaign {

  N = "facebook",
  A = 'formularz',
  B = 'konferencja',
  C = 'szkolenie',
  D = 'mailing',
  E = 'targi',
  T = 'inne',
}



export enum services {
  N = "strona internetowa",
 
}





  export interface IStepsUpdateData {
    id: string,
    step: string,
    days?: string,
    isNewStep?: boolean,
    newDate?: string,
    incrementStepVariant? : boolean


}



export interface IContact {
  name: string,
  surname: string,
  phone: string,
  email: string
}

export interface ISingleLeadDetails {
  
  nip?: string,
  name?: string,
  shortName?: string,
  city?: string,
  street?: string,
  postalCode?: string,
  website?: string,
  region?: string,
  country?: string,
  account?: string,
  status?: string, 
  ownerName?: string,
  ownerSurname?: string,
  ownerPhone?: string,
  ownerEmail?: string,
  contactPerson?: IContact,
  campaign?: string,
  note?: string,
  invoiceEmail?: string,
  _id?: string
}



export interface ISingleStep {
  stepNumber : number,
  currentStep: string,
  date: string,
  active: boolean

}





