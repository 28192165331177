import { Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import {  Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';

import * as fromContractors from 'src/app/contractor/reducers';
import { regions } from '../../../dentist/models';
import { ContractorPageActions } from '../../actions';
import {  Person } from '../../models';
import { Contractor } from "../../models/contractor.model";

@Component({
  selector: 'app-add-person',
  styleUrls: ['./add-person.component.scss'],
  templateUrl: './add-person.component.html',
})
export class AddPersonComponent implements OnInit, OnChanges, OnDestroy  {
  
  @Input() currentPerson: Person;
  @Input() contractors: Contractor[];
  @ViewChild('login') login: ElementRef;
  @ViewChild('password') password: ElementRef;
  constructor(
    private store: Store<fromContractors.State>,
    private route: ActivatedRoute
  ) {

  }
  ngOnChanges(changes: SimpleChanges): void {
  

    if (!changes.currentPerson?.firstChange && changes.currentPerson?.currentValue) {
      this.form.patchValue(changes.currentPerson.currentValue);
      this.currentDistrubiutor = this.contractors.find(val => val.shortName === changes.currentPerson.currentValue.distributor);
      if (this.currentDistrubiutor) {
        this.form.get("distributorId").setValue(this.currentDistrubiutor._id);
      }
      this.form.get("state").setValue(this.currentPerson.state);
      this.form.get("login").setValue(this.currentPerson.email);
      this.form.get("password").disable();
     
    }
  }
  regions = Object.values(regions);
  currentId: string;
  currentDistrubiutor: Contractor;
  noDistributor:boolean =  false;
 
0;


  submit(form) {
    if (this.currentId) {
      this.store.dispatch(ContractorPageActions.upDatePerson({ person: form, id: this.currentId }));
    } else {
      this.store.dispatch(ContractorPageActions.addPerson({ person: form }));
    }


    
    
  }

  changeContractor(event) {
   
    if (event ==="brak") {
      this.noDistributor = true;
      this.form.get("distributorId").setValue(event);
    } else {
      this.noDistributor = false;
      this.form.get("distributorId").setValue(event);
    }

  }
  changeType(event) {
    this.form.get("type").setValue(event);
  }
  setPassword() {
    const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const specialchars = "!@#$%^&*()_+|}{:?><`!<>?";
    const charsSmall = "qwertyuioplkjhgfdsazxcvbnm";
    const numbers = "1234567890";

    let password = "";
   
    const randomLeater = Math.floor(Math.random() * chars.length);
    password += chars.substring(randomLeater, randomLeater + 1);
    const randomChar = Math.floor(Math.random() * specialchars.length);
    password += specialchars.substring(randomChar, randomChar + 1);

    for (let i  = 0; i < 7; i++) {
      let r = Math.floor(Math.random() * charsSmall.length);
      password += charsSmall.substring(r, r + 1);
    }
    const randomNumber = Math.floor(Math.random() * numbers.length);
    password += numbers.substring(randomNumber, randomNumber + 1);

    this.form.get('password').setValue(password);


  }



  form = new FormGroup({
 
    name: new FormControl("", [Validators.minLength(3), Validators.required ]),
    surname: new FormControl("", [Validators.minLength(3), Validators.required]),
    phone: new FormControl("", [Validators.minLength(9), Validators.maxLength(9), Validators.required]),
    email: new FormControl("", [Validators.email, Validators.required]),
    state: new FormControl("", [Validators.required]),
    country: new FormControl("", [Validators.required]),
    city: new FormControl("", [Validators.minLength(3) ,Validators.required]),
    distributorId: new FormControl("", [Validators.required]),
    password: new FormControl("", [Validators.required, Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!@#$%^&*()_+|}{:?><`!<>?]).{8,}$') ]  ),
    note: new FormControl(""),
    login: new FormControl(""),
    type: new FormControl("", [Validators.required]),

  });



  focuseOut(e) {
    this.form.get('login').setValue(e);
  }


  ngOnDestroy() {
    this.form.reset();
    this.store.dispatch(ContractorPageActions.removePerson());
  }



  ngOnInit(): void {
    this.store.dispatch(ContractorPageActions.getContractorOptions());
    this.route.paramMap.subscribe(params => {
      this.currentId = params.get('id');
      if (this.currentId) {
        this.store.dispatch(ContractorPageActions.getPerson({ id: this.currentId }));
      }
    });
  }



}
