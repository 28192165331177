<div *ngIf="lead" class="container-fluid mb-3">
 

  <div class="row pt-4 d-flex justify-content-between">
    <div class="col">
      <h2 class="title">{{ lead.shortName }}</h2>
    </div>




    <div class="row  align-items-stretch">
      <div class="col-4">
        <div class="h-100 card p-1 p-xxl-4 mb-3">
          <div class="card-body">
            <p class="mb-0"><span class="">Pełna nazwa gabinetu:</span> <span class="fw-bold"> {{ lead?.name }}</span></p>
            <p><span class="fw-bold">Skrócona nazwa gabinetu:</span>   <span class="fw-bold">{{ lead.shortName }}</span> </p>

            <h5 class="section-title">Dane adresowe</h5>
            <p class="mb-0"><span class="">Miejscowość:</span><span class="fw-bold">{{ lead.city }}</span></p>
            <p class="mb-0"><span class="">Ulica / nr:</span><span class="fw-bold">{{ lead.street }}</span> </p>
            <p class="mb-0"><span class="">Kod pocztowy:</span> <span class="fw-bold">{{ lead.postalCode }}</span> </p>
            <p class="mb-0"><span class="">Strona internetowa:</span> <span class="fw-bold"> {{ lead.website }}</span></p>
            <p class="mb-0"><span class="">Numer NIP:</span>   <span class="fw-bold">{{ lead.nip }}</span>  </p>
            <p class="mb-0"><span class="">Województwo:</span>  <span class="fw-bold">{{ lead.region }}</span> </p>
            <p><span class="fw-bold">Kraj:</span>  <span class="fw-bold"></span> {{ lead.country }}</p>
            <p class="mb-0"><span class="">Adres e-mail do wystawiania faktur:</span>  <span class="fw-bold"> {{ lead.invoiceEmail }}</span></p>
          </div>
        </div>
      </div>
      <div class="col-4">
        <div class="h-100 card p-1 p-xxl-4">
          <div class="card-body">
            <h5 class="section-title">Dane kontaktowe właściciela</h5>
            <p class="mb-0"><span class="">Imię:</span>  <span class="fw-bold">{{ lead.ownerName }}</span>  </p>
            <p class="mb-0"><span class="">Nazwisko:</span> <span class="fw-bold">{{ lead.ownerSurname }}</span></p>
            <p class="mb-0"><span class="">Numer telefonu:</span> <span class="fw-bold">{{ lead.ownerPhone}}</span></p>
            <p><span class="">Adres e-mail:</span>  <span class="fw-bold">{{ lead.ownerEmail }}</span></p>
            <h5 class="section-title">Adres e-mail osoby kontaktowej</h5>
            <p class="mb-0"><span class="">Imię:</span> <span class="fw-bold">{{ lead.contactPerson?.name}}</span></p>
            <p class="mb-0"><span class="">Nazwisko:</span> <span class="fw-bold">{{  lead.contactPerson?.surname }}</span> </p>
            <p class="mb-0"><span class="">Numer telefonu:</span> <span class="fw-bold">{{ lead.contactPerson?.phone }}</span></p>
            <p class="mb-0"><span class="">Adres e-mail:</span> <span class="fw-bold">{{ lead.contactPerson?.email }}</span></p>
          </div>
        </div>
      </div>
      <div class="col-4">
        <div class=" card p-1 p-xxl-4 mb-3">
          <div class="card-body">
            <h5 class="section-title">Notatki</h5>
            <p>
              {{ lead.note }}
            </p>
          </div>
        </div>
        <div class="d-grid">
          <a routerLink="/leads/add-lead/{{ lead._id }}" class="mb-3 d-none d-sm-inline-block btn btn-primary px-5">Edytuj</a>
        </div>
        <div class="">
          <div  class="card p-1 p-xxl-4 mb-4">
            <div class="card-body">
             

              <h5 class="section-title">Kampania</h5>
              <span   *ngIf="lead?.campaign === 'facebook'" class="badge bg-danger">{{ lead.campaign }}</span>
              <span  *ngIf="lead?.campaign === 'formularz'" class="badge bg-success">{{ lead.campaign  }}</span>
              <span *ngIf="lead?.campaign === 'konferencja'" class="badge bg-light">{{ lead.campaign  }}</span>
              <span *ngIf="lead?.campaign === 'szkolenie'" class="badge bg-light">{{ lead.campaign  }}</span>
              <span *ngIf="lead?.campaign === 'mailing'" class="badge bg-success">{{ lead.campaign   }}</span>
              <span *ngIf="lead?.campaign === 'targi'" class="badge bg-warning ">{{  lead.campaign }}</span>
              <span *ngIf="lead?.campaign === 'inne'" class="badge bg-dark">{{ lead.campaign  }}</span>
             


            </div>
          </div>
          <div *ngIf="lead.addionalService" class="card p-1 p-xxl-4 mb-4">
            <div class="card-body">
             

              <h5 class="section-title">Dodatkowe usługi</h5>
              <p>{{ lead.addionalService }}</p>
             


            </div>
          </div>
 

        </div>




      </div>
    </div>






    <section style="height: 500px"></section>



    <ng-template class="mod" #confirmBankTransferModal let-modal>
      <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">
          Czy napewno chcesz rozliczyc płatność?
        </h4>

      </div>
      <div class="modal-body">

      </div>
      <div class="modal-footer">

        <button type="button"
             
                class="btn btn-primary">
          OK
        </button>
      </div>
    </ng-template>


  </div>
</div>
