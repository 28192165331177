import { sendForm } from './../actions/offer-page.actions';


import { createReducer, on } from "@ngrx/store";
import { OffersApiActions, OffersPageActions } from "../actions";
import { IFormData  } from "../models/offers.model";
export const offerPageFeatureKey = "offerPage";

export interface State {
  error : any,
  offerSend : boolean
}

export const initialState: State = {
    error : "",
    offerSend : false

};

export const reducer = createReducer(
  initialState,
  on(OffersApiActions.sendFromSuccess,
    (state, action) => ({ ...state, offerSend : true })),
    on(OffersPageActions.sendForm,
      (state, action) => ({ ...state, offerSend : false })),

);






export const selectError = (state: State) => state.error;
export const selectIsFormSend = (state: State) => state.offerSend;

