import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from "../auth/services/auth-guard.service";
import { HistoryOrdersComponent } from './components/history/history.component';
import { ListOrdersComponent } from './components/orders/orders-list.component';
import { HistoryListPageComponent } from './containers/history.page.componernt';
import { OrdersListPageComponent } from './containers/orders-list.page.component';
import { OrdersPageComponent } from './containers/orders.page.component';


export const routes: Routes = [

  {
    path: 'orders', component: OrdersPageComponent, canActivate: [AuthGuard],
    children: [
      {
        path: 'orders-list',
        component: OrdersListPageComponent, canActivate: [AuthGuard]
      },
      {
        path: 'history',
        component: HistoryListPageComponent, canActivate: [AuthGuard]
      },

    ]
  },
]
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OrdersRoutingModule { }
