

<div class=" ms-2  container-fluid">
    <div class="row">

<ngx-extended-pdf-viewer  [scrollMode]="1" [src]="'assets/eminto_prezentacja.pdf'"  [showPresentationModeButton]="true" useBrowserLocale="true" height="70vh"></ngx-extended-pdf-viewer>
  <!-- <pdf-viewer [src]="'/assets/eminto_prezentacja.pdf'"
 [stick-to-page]="true"
[fit-to-page]="true"

style="width: 100%; height: 500px"
></pdf-viewer>  -->
</div>
</div>
