
<div class="container-fluid">
  <div class="row">

    <!--<div *ngIf="" class="col-12 col-lg-6">
      <div class="card mb-3">
        <div class="card-body">
          <h5 class="section-title">Dane informacyjne</h5>
          <ul class="list-group list-group-flush">
            <li class="list-group-item ps-0">
              Nazwa:
              <div class="float-end">{{  }}</div>
            </li>
            <li class="list-group-item ps-0">
              Nazwa skrócona:
              <div class="float-end">{{ data.shortName }}</div>
            </li>
            <li class="list-group-item ps-0">
              WWW:
              <div class="float-end">{{ data.www }}</div>
            </li>
            <li class="list-group-item ps-0">
              NIP:
              <div class="float-end">{{ data.nip }}</div>
            </li>
            <li class="list-group-item ps-0">
              KRS:
              <div class="float-end">{{ data.krs }}</div>
            </li>
            <li class="list-group-item ps-0">
              REGON:
              <div class="float-end">{{ data.regon }}</div>
            </li>
            <li class="list-group-item ps-0">
              Ulica / NR:
              <div class="float-end">{{ data.street }}</div>
            </li>
            <li class="list-group-item ps-0">
              Kod pocztowy:
              <div class="float-end">{{ data.postalCode }}</div>
            </li>
            <li class="list-group-item ps-0">
              Miejscowość:
              <div class="float-end">{{ data.city }}</div>
            </li>
            <li class="list-group-item ps-0">
              Województwo:
              <div class="float-end">{{ data.state }}</div>
            </li>
            <li class="list-group-item ps-0">
              Kraj:
              <div class="float-end">{{ data.country }}</div>
            </li>
            <li class="list-group-item ps-0">
              Notatki:
              <div class="float-end text-end">{{ data.note }}</div>
            </li>
          </ul>
        </div>
      </div>
    </div>-->

    <!--<div class="col-12 col-lg-6">
      <div class="card mb-3">
        <div class="card-body">
          <h5 class="section-title">Dane informacyjne</h5>
          <ul class="list-group list-group-flush">
            <li class="list-group-item ps-0">
              Nazwa Dystrybutora:
              <div class="float-end">{{ data.distributor?.name }}</div>
            </li>
            <li class="list-group-item ps-0">
              Nazwa skrócona:
              <div class="float-end">{{ data.distributor?.shortName }}</div>
            </li>
            <li class="list-group-item ps-0">
              Rola:
              <div *ngIf="data.type === 'director'" class="float-end">dyrektor</div>
              <div *ngIf="data.type === 'admin'" class="float-end">admin</div>
              <div *ngIf="data.type === 'seller'" class="float-end">handlowiec</div>



            </li>
            <li class="list-group-item ps-0">
              Imię:
              <div class="float-end">{{ data.name }}</div>
            </li>
            <li class="list-group-item ps-0">
              Nazwisko:
              <div class="float-end">{{ data.surname }}</div>
            </li>
            <li class="list-group-item ps-0">
              Numer telefonu:
              <div class="float-end">{{ data.phone }}</div>
            </li>
            <li class="list-group-item ps-0">
              Adre e-mail:
              <div class="float-end">{{ data.email }}</div>
            </li>
            <li class="list-group-item ps-0">
              Miejscowość:
              <div class="float-end">{{ data.city }}</div>
            </li>
            <li class="list-group-item ps-0">
              Województwo:
              <div class="float-end">{{ data.state }}</div>
            </li>
            <li class="list-group-item ps-0">
              Kraj:
              <div class="float-end">{{ data.country }}</div>
            </li>

            <li class="list-group-item ps-0">
              Notatki:
              <div class="float-end text-end">{{ data.note }}</div>
            </li>
          </ul>
        </div>
      </div>
    </div>-->

  </div>
</div>

