
import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/contractor/services';
import { Contractor } from 'src/app/contractor/models';
import { select, Store } from '@ngrx/store';
import * as fromContractors from 'src/app/contractor/reducers'
import { OnDestroy } from '@angular/core';
import { LayoutActions } from '../../../core/actions';

@Component({
    selector: 'app-status',

  templateUrl: './status.component.html'
})
export class StatusComponent implements OnInit, OnDestroy  {


  constructor(
    private store: Store,
    private stroeContractor: Store<fromContractors.State>,
        private apiService:ApiService,
    ) {

    }
    ngOnDestroy(): void {
      this.store.dispatch(LayoutActions.hiddenLogoutBtn());
    }
 
    dataSet: Contractor[];
    role = ['dystrybutor', 'handlowiec', 'admin']
    selectedRole = this.role[1];
    
  ngOnInit(): void {
    this.store.dispatch(LayoutActions.showLogoutBtn());
  }


}
