import {createFeatureSelector, createSelector, combineReducers, Action} from '@ngrx/store';
import * as fromRoot from '../../reducers';
import * as fromResetPassword from './reset-password.reducer';

export const resetPasswordFeatureKey = 'resetPassword';

export interface ResetPasswordState {
    [fromResetPassword.resetPasswordPageFeatureKey]: fromResetPassword.State;
}

export interface State extends fromRoot.State {
    [resetPasswordFeatureKey]: ResetPasswordState;
}

export function reducers(state: ResetPasswordState | undefined, action: Action) {
    return combineReducers({
        [fromResetPassword.resetPasswordPageFeatureKey]: fromResetPassword.reducer
    })(state, action);
}

export const selectResetPasswordState = createFeatureSelector<State, ResetPasswordState>(resetPasswordFeatureKey);

export const selectResetPasswordPageState = createSelector(
    selectResetPasswordState,
    (state: ResetPasswordState) => state.resetPasswordPage
);
export const getResetPasswordPageError = createSelector(
    selectResetPasswordPageState,
    fromResetPassword.selectError
);

export const getChangePasswordSuccess = createSelector(
    selectResetPasswordPageState,
    fromResetPassword.selectSuccess
);


export const selectError = createSelector(
  selectResetPasswordPageState,
  fromResetPassword.selectError
);
