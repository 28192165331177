import { Component } from "@angular/core";

@Component({
    selector: 'app-price',
    styleUrls: ['./price.component.scss'],
    templateUrl: './price.component.html'
})
export class PriceComponent    {
   
}
