import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Dentist } from '../models/';



@Injectable()
export class FakeService   {

 







  getDentist(): Observable<Dentist[]> {
    const result: Dentist[] = [
      {
        dentistshortName: "col-dental",
        dentistName: "col-dental full",
        contractor: "Col-dental Pełne",
        shortName: "Col-dental",
        seller: "NikodemDyzma",
        id: "123123312312",
        country: "Polska",
        state: "Mazowieckie",
        city: "Warszawa",
        street: "Jodlowa",
        postalCode: "01-232",
        nip: 1561230101,
        ownerName: "Jan",
        ownerSurname: "Kowalski",
        ownerPhone: 73345112,
        ownerEmail: 'email@gmail.com',
        contactPersonName: "Eugen",
        contactPersonSurname: "Kowalski",
        contactPersonPhone: 434321213,
        contactPersonEmail: "contact@email.com",
        invoiceEmail: "invoice@gmail.com",
        note: "jakaś notatka",
        www: "www.dentist.pl",
    
       

      },
      {
        dentistshortName: "col-dental",
        dentistName: "col-dental full",
        contractor: "Col-dental Pełne",
        shortName: "Col-dental",
        seller: "NikodemDyzma",
        id: "123123312312",
        country: "Polska",
        state: "Mazowieckie",
        city: "Warszawa",
        street: "Jodlowa",
        postalCode: "01-232",
        nip: 1561230101,
        ownerName: "Jan",
        ownerSurname: "Kowalski",
        ownerPhone: 73345112,
        ownerEmail: 'email@gmail.com',
        contactPersonName: "Eugen",
        contactPersonSurname: "Kowalski",
        contactPersonPhone: 434321213,
        contactPersonEmail: "contact@email.com",
        invoiceEmail: "invoice@gmail.com",
        note: "jakaś notatka",
        www: "www.dentist.pl",
       
       
      },
     
     


        ]

        return of(result);
    }


}
