
import { Component,  OnInit } from "@angular/core";
import * as fromAuth  from 'src/app/auth/reducers';
import {Store, select} from '@ngrx/store';
import { Router } from "@angular/router";
import { routesData } from "../../common/models/route.model";
@Component({
    selector: 'app-products-page',
    template: `<app-top-nav
    [role]="role$ | async"
    [routes]="routesData"
  
    ></app-top-nav>
    <router-outlet></router-outlet>
    `
})
export class ProductsPageComponent implements OnInit   {
    role$ = this.store.pipe(select(fromAuth.selectUserRole));
  




  routesData: routesData[] = [
    {
      path: 'list',
      name: 'Produkty',
      forbiden: []
    },
    {
      path: "add-product",
      name: 'Dodaj produkt',
      forbiden: ["director", "admin", "seller"]
    },
    {
      path: "order-product",
      name: 'Zamów produkt',
      forbiden: []
    }
    ]
    
  constructor(
    private router: Router,
    private store: Store<fromAuth.State>) { }
  ngOnInit(): void {
   this.router.navigate(['products/list']);
    }
}
