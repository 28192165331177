import {  Component,  Input, OnChanges, OnInit, SimpleChanges  } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import * as fromContractors from 'src/app/contractor/reducers';
import { ContractorPageActions } from '../../actions';


@Component({
  selector: 'app-add-contractor',
  styleUrls: ['./add-contractor.component.scss'],
  templateUrl: './add-contractor.component.html',
})
export class AddContractorComponent implements OnInit, OnChanges  {


  @Input() currentContractor: any;
  @Input() list: any;

  currentId: string;
  

  constructor(
    private store: Store<fromContractors.State>,
    private route: ActivatedRoute
    

  ) {

  }
  ngOnChanges(changes: SimpleChanges): void {

    if (!changes.currentContractor.firstChange && changes.currentContractor.currentValue) {
      this.form.patchValue(changes.currentContractor.currentValue);
    }
    }


  submit(form) {
    if (this.currentContractor) {
      this.store.dispatch(ContractorPageActions.upDateContractor({ contractor: form, id: this.currentId }));
    } else {
      this.store.dispatch(ContractorPageActions.addContractor({ contractor: form }));
    }
  }

  form = new FormGroup({
    name: new FormControl("", [Validators.minLength(2),Validators.required]),
    shortName: new FormControl("", [Validators.minLength(2),Validators.required]),
    city: new FormControl("", [Validators.minLength(2), Validators.maxLength(15), Validators.required]),
    street: new FormControl("", [Validators.minLength(2),Validators.required]),
    krs: new FormControl("", [Validators.pattern("^[0-9]*$"),Validators.minLength(10), Validators.maxLength(10),Validators.required]),
    postalCode: new FormControl("", [Validators.minLength(6), Validators.maxLength(6),Validators.required]),
    regon: new FormControl("", [Validators.pattern("^[0-9]*$"),Validators.minLength(9), Validators.maxLength(9),Validators.required]),
    nip: new FormControl("", [Validators.minLength(13), Validators.maxLength(13),Validators.required]),
    state: new FormControl("", [Validators.required]),
    country: new FormControl("", [Validators.minLength(2),Validators.required]),
    
    website: new FormControl(""),
    note: new FormControl(""),
  });

  ngOnInit(): void {

    this.route.paramMap.subscribe(params => {
  
      this.currentId = params.get('id');
      if (this.currentId) {
        
        this.store.dispatch(ContractorPageActions.getContractor({ id: this.currentId }))
      
        
      }
    })
  }

}
