import { QuizResults } from '../../../../auth/models/auth.model';
import { sendQuizResultsFailure } from '../actions/test-api.actions';

import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { of } from "rxjs";
import { catchError, map, switchMap, tap } from "rxjs/operators";
import { TestApiActions, TestPageActions } from "../actions";
import { AgentData } from "../models/test.models";
import * as fromTest from '../reducers';
import { QuizService } from '../services/quiz.services';


@Injectable()
export class TestEffects {  



    getQuiz$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TestPageActions.getQuiz),
            switchMap(() => {
                return this.quizService.getQuiz().pipe(
                    map((res: any) => {
                        return TestApiActions.getQuizSuccess({ quiz: res })
                    }),
                    catchError((error: any) => of(TestApiActions.getQuizFailure({ error })))
                )
            })
        )
    );



    getQuiz2$ = createEffect(() =>
      this.actions$.pipe(
        ofType(TestPageActions.getQuiz2),
        switchMap(() => {
          return this.quizService.getQuizLevel2().pipe(
            map((res: any) => {
              return TestApiActions.getQuizSuccess2({ quiz2: res })
            }),
            catchError((error: any) => of(TestApiActions.getQuizFailure2({ error })))
          )
        })
      )
    );



    getQuiz3$ = createEffect(() =>
      this.actions$.pipe(
        ofType(TestPageActions.getQuiz3),
        switchMap(() => {
          return this.quizService.getQuizLevel3().pipe(
            map((res: any) => {
              return TestApiActions.getQuizSuccess3({ quiz3: res })
            }),
            catchError((error: any) => of(TestApiActions.getQuizFailure3({ error })))
          )
        })
      )
    );








    getQuizStatus$ = createEffect(() =>
    this.actions$.pipe(
        ofType(TestPageActions.getQuizStatus),
        switchMap(() => {
            return this.quizService.getQuizStatus().pipe(
                map((res: any) => {
                  return TestApiActions.getQuizStatusSuccess({ status: res });
                }),
                catchError((error: any) => of(TestApiActions.getQuizStatusFailure({ error })))
            )
        })
    )
)




    sendResults= createEffect(() =>
      this.actions$.pipe(
        ofType(TestPageActions.sendQuizResults),
        switchMap(({ quizResults }) => {
          return this.quizService.sendQuizResults(quizResults).pipe(
            map((res: any) => {
              return TestApiActions.sendQuizResultsSuccess();
            }),
            catchError((error: any) => of(TestApiActions.sendQuizResultsFailure({ error })))
          );
        })
      )
    );







constructor(
    // private store: Store<fromDentists.State>,
    private actions$: Actions,
    private quizService: QuizService,
    private router: Router
) { }
}




