
import { ProductApiActions, ProductPageActions } from "../actions"
import { Action, combineReducers, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { EmintoBox } from '../models'
import * as fromProductsPage from './products.reducer';

import * as fromRoot from '../../reducers';




export const productFeatureKey = 'products';

export interface ProductsState {
  [fromProductsPage.productsPageFeatureKey]: fromProductsPage.State;
}

export interface State extends fromRoot.State {
  [productFeatureKey]: ProductsState;
}

export function reducers(state: ProductsState | undefined, action: Action) {
  return combineReducers({
    [fromProductsPage.productsPageFeatureKey]: fromProductsPage.reducer,
  })(state, action);
}

export const selectProductsState = createFeatureSelector<State, ProductsState>(productFeatureKey);


export const selectProductsPageState = createSelector(
  selectProductsState,
  (state: ProductsState) => state.produtPage
);

export const selectError = createSelector(
  selectProductsPageState,
  fromProductsPage.selectError
);



export const selectLoading = createSelector(
  selectProductsPageState,
  fromProductsPage.selectLoading
);




export const selectProductsList = createSelector(
  selectProductsPageState,
  fromProductsPage.selectList
);


export const selectCategory = createSelector(
  selectProductsPageState,
  fromProductsPage.selectProductsCat
);

export const selectCategoryProduct = createSelector(
  selectProductsPageState,
  fromProductsPage.selectCategory
);





