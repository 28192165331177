import {
    ActionReducerMap,
    ActionReducer,
    MetaReducer,
    createFeatureSelector,
    createSelector,
    Action,
} from '@ngrx/store';
import {environment} from '../../environments/environment';
import * as fromRouter from '@ngrx/router-store';
import {localStorageSync} from 'ngrx-store-localstorage'


import * as fromLayout from '../core/reducers/layout.reducer';
import {InjectionToken} from '@angular/core';

export interface State {
    [fromLayout.layoutFeatureKey]: fromLayout.State;
    router: fromRouter.RouterReducerState<any>;
}

export const ROOT_REDUCERS = new InjectionToken<
    ActionReducerMap<State, Action>
>('Root reducers token', {
    factory: () => ({
        [fromLayout.layoutFeatureKey]: fromLayout.reducer,
        router: fromRouter.routerReducer,
    }),
});

export function logger(reducer: ActionReducer<State>): ActionReducer<State> {
    return (state, action) => {
        const result = reducer(state, action);
       // console.groupCollapsed(action.type);
       // console.log('prev state', state);
      //  console.log('action', action);
      //  console.log('next state', result);
        console.groupEnd();
        return result;
    };
}

export function localStorageSyncReducer(reducer: ActionReducer<State>): ActionReducer<State> {
    return localStorageSync({keys: ['auth', 'layout', 'contractor'], rehydrate: true})(reducer);
}

export const metaReducers: MetaReducer<State>[] = !environment.production
    ? [logger, localStorageSyncReducer]
    : [localStorageSyncReducer];

export const selectLayoutState = createFeatureSelector<State, fromLayout.State>(fromLayout.layoutFeatureKey);

export const getRouterState = (state: State) => state.router;

export const getCurrentUrl = createSelector(
    getRouterState, 
    (state: fromRouter.RouterReducerState) => state && state.state && state.state.url
);
export const getRouter = createSelector(
    getRouterState, 
    (state: fromRouter.RouterReducerState) => state   && state.state && state.state.root.firstChild.url[0].path
);


export const selectError = createSelector(
    selectLayoutState,
    fromLayout.selectError
);
export const selectDialog = createSelector(
    selectLayoutState,
    fromLayout.selectDialog
);
export const selectDialogMeta = createSelector(
    selectLayoutState,
    fromLayout.selectDialogMeta
);
export const selectEnlarge = createSelector(
    selectLayoutState,
    fromLayout.selectEnlarge
);

export const selectMsgInfo = createSelector(
  selectLayoutState,
  fromLayout.selectMsg
);

export const selectIsMenuShow = createSelector(
  selectLayoutState,
  fromLayout.selectIsMenuShow
);












