<section class=" row container">
  <!-- <div class="col-10 offset-1 section-pulled">
    <span class="fw-bold">{{ data?.name }} {{ data?.surname }} </span>
    <span>{{ data?.email }} </span>
    <p>{{ data?.phone }}</p>

    <h2>Dane do oferty eminto</h2>
  </div> -->
  <div class="form-container col-10 offset-1 section-pulled">
    <span class="fw-bold">{{ data?.name }} {{ data?.surname }}, </span>
    <span>{{ data?.email }} </span>
    <p>{{ data?.phone }}</p>

    <h2>Dane do oferty eminto</h2>


    <form class="pt-2" [formGroup]="form" (ngSubmit)="onSubmitForm(form.value)">
      <div class="form-group">
        <label class="fw-bold pb-2" for="dentistName">Nazwa</label>
        <input
          formControlName="dentistName"
          id="dentistName"
          class="form-control mb-2"
          type="text"
        />
        <span
          *ngIf="form.get('dentistName')?.invalid && (form.get('dentistName')?.dirty || form.get('dentistName')?.touched) && form.get('dentistName')?.errors?.['required']"
          class="validation-error"
          >to pole jest obowiazkowe</span
        >
      </div>

      <div class="d-flex gap-3">
        <div class="form-group flex-grow-1">
          <label class="fw-bold pb-2" for="e-mail">Imię</label>
          <input formControlName="name" class="form-control mb-2" type="text" />
          <span
            *ngIf="form.get('name')?.invalid && (form.get('name')?.dirty || form.get('name')?.touched) && form.get('name')?.errors?.['required']"
            class="validation-error"
          >to pole jest obowiazkowe</span>
        </div>

        <div class="form-group flex-grow-1">
          <label class="fw-bold pb-2" for="e-mail">Nazwisko</label>
          <input
            formControlName="surname"
            class="form-control mb-2"
            type="text"
          />
          <span
            *ngIf="form.get('surname')?.invalid && (form.get('surname')?.dirty || form.get('surname')?.touched) && form.get('surname')?.errors?.['required']"
            class="validation-error"
          >to pole jest obowiazkowe</span>
        </div>
      </div>
      <div class="d-flex gap-3 ">
        <div class="form-group flex-grow-1">
          <label class="fw-bold pb-2" for="e-mail"
            >Nr telefonu komórkowego</label
          >
          <input
            formControlName="phone"
            class="form-control mb-2"
            type="text"
          />
         
          <span
            *ngIf="form.get('phone')?.invalid && (form.get('phone')?.dirty || form.get('phone')?.touched) && form.get('phone')?.errors?.['required']"
            class="validation-error"
          >To pole jest obowiązkowe</span>
          <span
            *ngIf=" form.get('phone')?.touched  && form.get('phone')?.errors?.['maxlength']"
            class="validation-error"
          >Podany nr jest za długi</span>
          <span
            *ngIf=" form.get('phone')?.touched && form.get('phone')?.errors?.['minlength']"
            class="validation-error"
          >Podany nr jest za krótki</span>
          <input
            hidden
            formControlName="account"
            class="form-control mb-2"
            type="text"
          />
          <input
            hidden
            formControlName="accountEmail"
            class="form-control mb-2"
            type="text"
          />
          <input
            hidden
            formControlName="accountPhoneNumber"
            class="form-control mb-2"
            type="text"
          />
        </div>
        <div class="form-group flex-grow-1">
          <label class="fw-bold pb-2" for="e-mail">E-mail</label>
          <input
            formControlName="email"
            class="form-control mb-2"
            type="email"
          />
          <span
            *ngIf="form.get('email')?.invalid && (form.get('email')?.dirty || form.get('email')?.touched) && form.get('email')?.errors?.['email']"
            class="validation-error"
          >niepoprawny format email</span>
          <span
          *ngIf="form.get('email')?.invalid && (form.get('email')?.dirty || form.get('email')?.touched) && form.get('email')?.errors?.['required']"
          class="validation-error"
        >to pole jest obowiązkowe</span></div></div>

          
          
        
      

      <div class="text-center pt-3">
        <button
          [disabled]="form.invalid"
          type="submit"
          class="d-none d-sm-inline-block btn btn-primary px-5 ms-3"
        >
          Wyślij
        </button>
      </div>
    </form>
  </div>
</section>
