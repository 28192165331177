<div class="container-fluid">
   <div class="row">
     <div class="d-flex align-items-center">
       <a class="ms-4 mt-2   rounded-btn btn btn-primary" [href]="'assets/karta.pdf'" download>Pobierz schemat sprzedaży w pdf</a>
       </div>
</div>
   <div class="areas p-4">
      <div class="row pb-2">
         <div class="col">
         
         </div>
      </div>
      <div class="row">
        <div class="col-5">
          <div class="card-title">
            <div class="card-icon doctor d-flex align-items-center justify-content-center">PROBLEM</div>
            <h5> >&nbsp;obszary biznesu gabinetu</h5>
          </div>
          <div class="item">
            <ngb-accordion (panelChange)="toggleAccordian($event, acc)" #acc="ngbAccordion" [activeIds]="activeIds">


              <ngb-panel class="text-left" id="ngb-panel-1" title="A. CYFROWA TRANSFORMACJA JEST SZYBKA I WYMAGAJĄCA">

                <ng-template ngbPanelContent>
                  <!-- ngbCollapseChange -->


                  <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
                    <div class="card">
                      <div class="card-body">
                        <ul>
                          <li>rynek bardzo się zmienił, mam wrażenie że nie robię wystarczająco dużo</li>
                          <li>
                            media cyfrowe, wymagają specjalistycznej wiedzy, nie mam przestrzeni na dodatkowe
                            kompetencje
                          </li>
                          <li>
                            przeraża mnie ilość operacji, które trzeba wykonać aby wdrożyć nowy system IT,
                            obsługa
                            tez nie jest łatwa
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                </ng-template>
              </ngb-panel>



            </ngb-accordion>
          </div>
          <div class="item">
            <ngb-accordion (panelChange)="toggleAccordian($event, acc)" #acc="ngbAccordion" [activeIds]="activeIds">


              <ngb-panel cardClass='light-blue' class="text-left" id="ngb-panel-2"
                         title="EDUKCJA PACJENTA
                  TO ZANIEDBANY OBSZAR">

                <ng-template ngbPanelContent>


                  <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
                    <div class="card">
                      <div class="card-body">
                        <ul>
                          <li>
                            tłumaczenie ciągle tego samego
                            nie powoduje u mnie entuzjazmu
                          </li>
                          <li>
                            ppacjenci są bardzo słabo wyedukowani,
                            nie mają podstawowej wiedzy, zwykle
                            chcą załatwić nagłe potrzeby
                          </li>
                          <li>
                            nie ma komu zająć się profesjonalną
                            edukacją i wskazywaniem szerokiego
                            wachlarza naszych usług, nie tylko tych
                            z którymi spotka się pacjen
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                </ng-template>
              </ngb-panel>


            </ngb-accordion>
          </div>
          <div class="item">
            <ngb-accordion (panelChange)="toggleAccordian($event, acc)" #acc="ngbAccordion" [activeIds]="activeIds">


              <ngb-panel cardClass='dark-blue' class="text-left" id="ngb-panel-3"
                         title="PROFESJONALNA REKLAMA
                  ZABIERA CZAS I BUDŻET">

                <ng-template ngbPanelContent>


                  <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
                    <div class="card">
                      <div class="card-body">
                        <ul>
                          <li>
                            próbujem reklamować się we własnym
                            zakresie, mam świadomość, że nie jest
                            to szczyt marzeń i oczekiwań
                          </li>
                          <li>
                            korzystamy czasem z zewnętrznych
                            zasobów, jest to odczuwalny koszt,
                            jak również wymaga zaangażowania
                            z naszej strony
                          </li>
                          <li>
                            mam kłopot z wytłumaczeniem
                            specyfiki branży stomatologicznej
                            a co za tym idzie skutecznych kierunków
                          </li>

                        </ul>
                      </div>
                    </div>
                  </div>

                </ng-template>
              </ngb-panel>


            </ngb-accordion>
          </div>
          <div class="item">
            <ngb-accordion (panelChange)="toggleAccordian($event, acc)" #acc="ngbAccordion" [activeIds]="activeIds">


              <ngb-panel cardClass='dark' class="text-left" id="ngb-panel-4"
                         title="SYSTEMY INFORMATYCZNE
                  TO SAME KŁOPOTY">

                <ng-template ngbPanelContent>


                  <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
                    <div class="card">
                      <div class="card-body">
                        <ul>
                          <li>
                            kolejnego systemu informatycznego
                            nie przeżyje, to mnie przerasta
                          </li>
                          <li>
                            późniejsza obsługa jest uciążliwa,
                            czekamy zwykle na serwis
                          </li>
                          <li>
                            usugi informatyczne są drogie,
                            jeżeli tylko nie muszę, nie korzystam
                            i nie szukam
                          </li>
                          <li>
                            zdaję sobie sprawę, że mają duży
                            potencjał, ale bariera wejściajest 
                            dla mnie bardzo wysoka, za wysoka
                          </li>

                        </ul>
                      </div>
                    </div>
                  </div>

                </ng-template>
              </ngb-panel>


            </ngb-accordion>
          </div>
          <div class="item">
            <ngb-accordion (panelChange)="toggleAccordian($event, acc)" #acc="ngbAccordion" [activeIds]="activeIds">


              <ngb-panel cardClass='red' class="text-left" id="ngb-panel-5"
                         title="SPRZEDAŻ JEST CIĄGLE
                  NIEZAGOSPODAROWANA">

                <ng-template ngbPanelContent>

                  <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
                    <div class="card">
                      <div class="card-body">
                        <ul>
                          <li>
                            nie mamy procesu sprzedaży naszych
                            wszystkich usług, zwykle to luźna rozmowa
                          </li>
                          <li>
                            zatrudnianie osób dedykownych do
                            sprzedaży to wątpliwa inwestycja
                          </li>
                          <li>
                            nie prowadzimy badań skuteczności
                            sprzedaży
                          </li>
                          <li>
                            lekarz sprzedaje najlepiej, ma autorytet,
                            chociaż nie to jest jego główna rolą
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                </ng-template>
              </ngb-panel>


            </ngb-accordion>
          </div>
          <div class="item">
            <ngb-accordion (panelChange)="toggleAccordian($event, acc)" #acc="ngbAccordion" [activeIds]="activeIds">
              <ngb-panel cardClass='s-blue' class="text-left" id="ngb-panel-6"
                         title="BIZNES DENTYSTYCZNY
                  JEST WYMAGAJĄCY">

                <ng-template ngbPanelContent>
                  <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
                    <div class="card">
                      <div class="card-body">
                        <ul>
                          <li>
                            tomatologia się ciągle zmienia,
                            trzeba trzymać rekę na pulsie
                            i być na bieżąco w nowościach
                          </li>
                          <li>
                            jako dentyści nie mamy czasu na
                            dodatkowe zajęcia, wykonujemy
                            te najbardziej potrzebne działania
                          </li>
                          <li>
                            Moda na niektóre zabiegi, wystepuje
                            okresowo. Trudno przewidzieć je
                            w czasie.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                </ng-template>
              </ngb-panel>


            </ngb-accordion>
          </div>
        </div>
        <div class="col-1 col-xxl-1"></div>
        <div class="col-5 results-boxes">
          <div class="functioning">
            <div class="row pb-2">
              <div class="col">
                <div class="card-title">
                  <div class="card-icon eminto d-flex align-items-center justify-content-center">ROZWIĄZANIE</div>
                  <h5> > pomoc eminto w biznesie</h5>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="">
                <ngb-accordion  test=isExpanded() (panelChange)="toggleAccordian($event, acc)" #acc="ngbAccordion" [activeIds]="activeIds">


                  <ngb-panel cardClass="fun" class="text-left" id="ngb-panel-1"
                             title="A. DOPASOWANIE DO
                     CYFROWEJ TRANSFORMACJI">

                    <ng-template ngbPanelContent>



                      <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
                        <div class="card">
                          <div class="card-body">
                            <ul>
                              <li>
                                profesjonalna usługa display solution (10 lat doświadczenia, współpraca z
                                największymi markami)
                              </li>
                              <li>
                                zautomatyzowane rozwiązanie (podepnij kabel HDMI do TV, zaloguj się do swojego
                                WI-FI)
                              </li>
                              <li>
                                nowoczesny wizerunek gabinetu (pacjenci będą mieli poczucie profesjonalnego
                                miejsca - zostaną)
                              </li>
                              <li>
                                spersonalizowana usługa > własny kanał TV, który promuje inne (nowe) usługi na
                                obecnych pacjentach
                              </li>
                            </ul>

                          </div>
                        </div>
                      </div>

                    </ng-template>
                  </ngb-panel>



                </ngb-accordion>
              </div>
              <div class="">
                <ngb-accordion (panelChange)="toggleAccordian($event, acc)" #acc="ngbAccordion" [activeIds]="activeIds">


                  <ngb-panel cardClass='light-blue fun' class="text-left" id="ngb-panel-2"
                             title="B. PROFESJONALNE MEDIA KTóRE EDUKUJĄ">

                    <ng-template ngbPanelContent>


                      <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
                        <div class="card">
                          <div class="card-body">
                            <ul>
                              <li>najwyższa jakość filmów, niedostępna dla kieszeni pojedynczego odbiorcy</li>
                              <li>media są dopasowane do współczesnych standardów konsumpcji informacji</li>
                              <li>
                                zbadana skuteczność, media niwelują barierę wejścia w nowe projekty, pacjenci
                                sami pytają o nowe możliwości i decydują się na zabiegi z TV
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>

                    </ng-template>
                  </ngb-panel>


                </ngb-accordion>
              </div>
              <div class="">
                <ngb-accordion (panelChange)="toggleAccordian($event, acc)" #acc="ngbAccordion" [activeIds]="activeIds">


                  <ngb-panel cardClass='dark-blue fun' class="text-left" id="ngb-panel-3"
                             title="C. NARZĘDZIE DO KOMUNIKACJI I ZARZĄDZANIA MEDIAMI">

                    <ng-template ngbPanelContent>


                      <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
                        <div class="card">
                          <div class="card-body">
                            <ul>
                              <li>możliwość łatwego dodawania własnych zdjęć i filmów</li>
                              <li>gotowe szablony do własnej edycji</li>
                              <li>
                                przygotowany design przez doświadczony zespół na co dzień pracujący przy
                                produkcjach dla TV
                              </li>
                              <li>polskie twarze i uzębienie w mediach, które uwiarygadniają usługi</li>
                            </ul>
                          </div>
                        </div>
                      </div>

                    </ng-template>
                  </ngb-panel>


                </ngb-accordion>
              </div>
              <div class="">
                <ngb-accordion (panelChange)="toggleAccordian($event, acc)" #acc="ngbAccordion" [activeIds]="activeIds">


                  <ngb-panel cardClass='dark fun' class="text-left" id="ngb-panel-4"
                             title="D. WSZYSTKO W JEDNEJ APLIKACJI NA TELEFONIE">

                    <ng-template ngbPanelContent>


                      <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
                        <div class="card">
                          <div class="card-body">
                            <ul>
                              <li>zarządzanie i monitoring z telefonu / tabletu gdziekolwiek jesteś</li>
                              <li>ustawienie godziny pracy TV, box sam włączy i wyłączy telewizor</li>
                              <li>
                                ustalanie, co i w jakie dni powinno się wyświtlać - personalizacja pod
                                specjalizację gabinetu
                              </li>
                              <li>
                                dodatkowa pomoc dla lekarza - media i zdjęcia do konsultacji z pacjentem, pod
                                ręką
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>

                    </ng-template>
                  </ngb-panel>


                </ngb-accordion>
              </div>
              <div class="">
                <ngb-accordion (panelChange)="toggleAccordian($event, acc)" #acc="ngbAccordion" [activeIds]="activeIds">


                  <ngb-panel cardClass='red fun' class="text-left" id="ngb-panel-5"
                             title="E. SKUTECZNY ASYSTENT SPRZEDAŻY">

                    <ng-template ngbPanelContent>

                      <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
                        <div class="card">
                          <div class="card-body">
                            <ul>
                              <li>efektywne zagospodarowanie czasu pacjenta w gabinecie (świadomy pacjent)</li>
                              <li>
                                niwelujemy barierę wejścia w nowe usługi, pacjenci sami pytają się o zabiegi
                              </li>
                              <li>
                                szeroki wachlarz usług, na które nie ma przestrzeni w normalnym leczeniu
                                (pozyskiwanie nowych osób - rodzin i znajomych obecnych pacjentów)
                              </li>
                              <li>wizualizacja usługi bez wyobrażenia krwawego i bolesnego zabiegu</li>
                              <li>
                                świetne narzędzie (app mobile / desktop) do konsultacji z pacjentem na fotelu
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>

                    </ng-template>
                  </ngb-panel>


                </ngb-accordion>
              </div>
              <div class="">
                <ngb-accordion (panelChange)="toggleAccordian($event, acc)" #acc="ngbAccordion" [activeIds]="activeIds">
                  <ngb-panel cardClass='s-blue fun' class="text-left" id="ngb-panel-6"
                             title="F. CYFROWA TRANSFORMACJA JEST SZYBKA I WYMAGAJĄCA">

                    <ng-template ngbPanelContent>
                      <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
                        <div class="card">
                          <div class="card-body">
                            <ul>
                              <li>rynek bardzo się zmienił, mam wrażenie że nie robię wystarczająco dużo</li>
                              <li>
                                media cyfrowe, wymagają specjalistycznej wiedzy, nie mam przestrzeni na
                                dodatkowe
                                kompetencje
                              </li>
                              <li>
                                przeraża mnie ilość operacji, które trzeba wykonać aby wdrożyć nowy system IT,
                                obsługa
                                tez nie jest łatwa
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>

                    </ng-template>
                  </ngb-panel>


                </ngb-accordion>
              </div>

            </div>
          </div>

        </div>


      </div>

      <!-- </div> -->
      <hr />
      <!--<div class="functioning">
         <div class="row pb-2">
            <div class="col">
               <div class="card-title">
                  <div class="card-icon eminto d-flex align-items-center justify-content-center">ROZWIĄZANIE</div>
                  <h5> > pomoc eminto w biznesie</h5>
               </div>
            </div>
         </div>
         <div class="row">
            <div class="col-4 pb-4">
               <ngb-accordion      (panelChange)="toggleAccordian($event, acc)" #acc="ngbAccordion" [activeIds]="activeIds">


                  <ngb-panel    cardClass="fun" class="text-left" id="ngb-panel-7"
                     title="A. DOPASOWANIE DO
                     CYFROWEJ TRANSFORMACJI">

                     <ng-template ngbPanelContent>



                        <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
                           <div class="card">
                              <div class="card-body">
                                 <ul>
                                    <li>profesjonalna usługa display solution (10 lat doświadczenia, współpraca z
                                       największymi markami)</li>
                                    <li>zautomatyzowane rozwiązanie (podepnij kabel HDMI do TV, zaloguj się do swojego
                                       WI-FI)</li>
                                    <li>nowoczesny wizerunek gabinetu (pacjenci będą mieli poczucie profesjonalnego
                                       miejsca - zostaną)</li>
                                    <li>spersonalizowana usługa > własny kanał TV, który promuje inne (nowe) usługi na
                                       obecnych pacjentach</li>
                                 </ul>

                              </div>
                           </div>
                        </div>

                     </ng-template>
                  </ngb-panel>



               </ngb-accordion>
            </div>
            <div class="col-4">
               <ngb-accordion (panelChange)="toggleAccordian($event, acc)" #acc="ngbAccordion" [activeIds]="activeIds">


                  <ngb-panel cardClass='light-blue fun' class="text-left" id="ngb-panel-8"
                     title="B. PROFESJONALNE MEDIA KTóRE EDUKUJĄ">

                     <ng-template ngbPanelContent>


                        <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
                           <div class="card">
                              <div class="card-body">
                                 <ul>
                                    <li>najwyższa jakość filmów, niedostępna dla kieszeni pojedynczego odbiorcy</li>
                                    <li>media są dopasowane do współczesnych standardów konsumpcji informacji</li>
                                    <li>zbadana skuteczność, media niwelują barierę wejścia w nowe projekty, pacjenci
                                       sami pytają o nowe możliwości i decydują się na zabiegi z TV</li>
                                 </ul>
                              </div>
                           </div>
                        </div>

                     </ng-template>
                  </ngb-panel>


               </ngb-accordion>
            </div>
            <div class="col-4">
               <ngb-accordion (panelChange)="toggleAccordian($event, acc)" #acc="ngbAccordion" [activeIds]="activeIds">


                  <ngb-panel cardClass='dark-blue fun' class="text-left" id="ngb-panel-9"
                     title="C. NARZĘDZIE DO KOMUNIKACJI I ZARZĄDZANIA MEDIAMI">

                     <ng-template ngbPanelContent>


                        <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
                           <div class="card">
                              <div class="card-body">
                                 <ul>
                                    <li>możliwość łatwego dodawania własnych zdjęć i filmów</li>
                                    <li>gotowe szablony do własnej edycji</li>
                                    <li>przygotowany design przez doświadczony zespół na co dzień pracujący przy
                                       produkcjach dla TV</li>
                                    <li>polskie twarze i uzębienie w mediach, które uwiarygadniają usługi</li>
                                 </ul>
                              </div>
                           </div>
                        </div>

                     </ng-template>
                  </ngb-panel>


               </ngb-accordion>
            </div>
            <div class="col-4">
               <ngb-accordion (panelChange)="toggleAccordian($event, acc)" #acc="ngbAccordion" [activeIds]="activeIds">


                  <ngb-panel cardClass='dark fun' class="text-left" id="ngb-panel-10"
                     title="D. WSZYSTKO W JEDNEJ APLIKACJI NA TELEFONIE">

                     <ng-template ngbPanelContent>


                        <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
                           <div class="card">
                              <div class="card-body">
                                 <ul>
                                    <li>zarządzanie i monitoring z telefonu / tabletu gdziekolwiek jesteś</li>
                                    <li>ustawienie godziny pracy TV, box sam włączy i wyłączy telewizor</li>
                                    <li>ustalanie, co i w jakie dni powinno się wyświtlać - personalizacja pod
                                       specjalizację gabinetu</li>
                                    <li>dodatkowa pomoc dla lekarza - media i zdjęcia do konsultacji z pacjentem, pod
                                       ręką</li>
                                 </ul>
                              </div>
                           </div>
                        </div>

                     </ng-template>
                  </ngb-panel>


               </ngb-accordion>
            </div>
            <div class="col-4">
               <ngb-accordion (panelChange)="toggleAccordian($event, acc)" #acc="ngbAccordion" [activeIds]="activeIds">


                  <ngb-panel cardClass='red fun' class="text-left" id="ngb-panel-11"
                     title="E. SKUTECZNY ASYSTENT SPRZEDAŻY">

                     <ng-template ngbPanelContent>

                        <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
                           <div class="card">
                              <div class="card-body">
                                 <ul>
                                    <li>efektywne zagospodarowanie czasu pacjenta w gabinecie (świadomy pacjent)</li>
                                    <li>niwelujemy barierę wejścia w nowe usługi, pacjenci sami pytają się o zabiegi
                                    </li>
                                    <li>szeroki wachlarz usług, na które nie ma przestrzeni w normalnym leczeniu
                                       (pozyskiwanie nowych osób - rodzin i znajomych obecnych pacjentów)</li>
                                    <li>wizualizacja usługi bez wyobrażenia krwawego i bolesnego zabiegu</li>
                                    <li>świetne narzędzie (app mobile / desktop) do konsultacji z pacjentem na fotelu
                                    </li>
                                 </ul>
                              </div>
                           </div>
                        </div>

                     </ng-template>
                  </ngb-panel>


               </ngb-accordion>
            </div>
            <div class="col-4">
               <ngb-accordion (panelChange)="toggleAccordian($event, acc)" #acc="ngbAccordion" [activeIds]="activeIds">
                  <ngb-panel cardClass='s-blue fun' class="text-left" id="ngb-panel-12"
                     title="F. CYFROWA TRANSFORMACJA JEST SZYBKA I WYMAGAJĄCA">

                     <ng-template ngbPanelContent>
                        <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
                           <div class="card">
                              <div class="card-body">
                                 <ul>
                                    <li>rynek bardzo się zmienił, mam wrażenie że nie robię wystarczająco dużo</li>
                                    <li>media cyfrowe, wymagają specjalistycznej wiedzy, nie mam przestrzeni na
                                       dodatkowe
                                       kompetencje</li>
                                    <li>przeraża mnie ilość operacji, które trzeba wykonać aby wdrożyć nowy system IT,
                                       obsługa
                                       tez nie jest łatwa</li>
                                 </ul>
                              </div>
                           </div>
                        </div>

                     </ng-template>
                  </ngb-panel>


               </ngb-accordion>
            </div>
      
         </div>
      </div>-->
   </div>
