import { Component } from "@angular/core";



@Component({
    selector: 'app-sels',
    styleUrls: ['./sels-card.component.scss'],
    templateUrl: './sels-card.component.html'
})
export class SelsCardComponent    {
    test
    activeIds:string[] = [];

  toggleAccordian(event, acc) { 
       acc.expand('test')
   
       if  (!event.nextState)  { 
        this.activeIds = [];
       }
        if (this.activeIds[0] !== event.panelId &&  event.nextState     )  {
            this.activeIds = [];
            this.activeIds = [event.panelId , event.panelId  ];       
        } 
        acc.collapse('ngb-panel-5') 
    }

    public isCollapsed = false;
}





