
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { IFormData } from '../models/offers.model';



/**
 * A service for sending form data.
 */
@Injectable({
  providedIn: 'root',
})
export abstract class ApiService {
  abstract sendForm(form: IFormData, accountinfo? : any): Observable<any>
  
}
