
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AddProductsComponent } from './components/add-product/add-products.component';
import { OrderComponent } from './components/order/order.component';
import { ProductsComponent } from './components/products/products.component';
import { AddProductPageComponent } from './containers/add.products.component';

import { ProductsPageComponent } from './containers/products-page.component';
import { AuthGuard } from "../auth/services/auth-guard.service";
import { ProductsListPageComponent } from "./containers/product-page.component/product-page.component";


const routes: Routes = [
 
  {
    path: 'products', component: ProductsPageComponent,
    children: [
      {
        path: "list",
        component: ProductsListPageComponent, pathMatch: 'full', canActivate: [AuthGuard]
      },
      { path: "add-product",
        component: AddProductPageComponent, canActivate: [AuthGuard]
      },
      {
        path: "add-product/:id",
        component: AddProductsComponent, canActivate: [AuthGuard]
      },
      {
        path: "order-product",
        component: OrderComponent, canActivate: [AuthGuard]
      }
    ]
  },
]
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProductsRoutingModule { }
