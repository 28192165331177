import { AfterViewInit, Component } from "@angular/core";
import { Router } from "@angular/router";
import { NavLink } from "src/app/common/models/nav-link.model";
import { Store, select } from '@ngrx/store';

@Component({
    selector: 'app-price-page',
    template: `
    <app-price></app-price>
    `
})
export class PricePageComponent { 

 }