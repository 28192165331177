<div *ngIf="selectedLevel === -1" class="container-fluid">
  
  <div class="row pt-4">
    <div class="col-4 text-center">
      <div class="box level mb-3">
        <img *ngIf="s[0]" src="assets/ikony/lvl1_done.svg" class="mb-4" />
        <img *ngIf=" !s[0]" src="assets/ikony/lvl1_undone.svg" class="mb-4" />
        <h4 class="pb-3">Na dobry początek</h4>
    

        <div *ngIf="s.length  > 0 " class="points">
          <span>{{ s[0].correct  }}/ 8 pytań</span>
        </div>
        <div *ngIf="!s[0]" class="points">8 pytań</div>
      </div>
      <a *ngIf="s[0]" (click)="selectQuizLevel(0)" class="btn btn-outline-secondary">Rozwiąż ponownie</a>

      <a *ngIf="!s[0]" (click)="selectQuizLevel(0)" class="btn btn-primary w-100  ">Rozpocznij</a>
    </div>
    <!--zaloguj@gmail.com-->
    <div class="col-4 text-center">
      <div class="box level mb-3" [ngClass]="quiz2 ? '' : 'dissable'">
        <img *ngIf="s[1]" src="assets/ikony/lvl2_done.svg" class="mb-4" />
        <img *ngIf="!s[1]" src="assets/ikony/lvl2_undone.svg" class="mb-4" />

        <h4 class="pb-3">Wiedzieć więcej</h4>
      
        <div *ngIf="s[1]  " class="points">{{ s[1].correct  }}/{{ quiz2.length  }} pytań</div>
        <div *ngIf="!s[1] && quiz2" class="points">{{ quiz2.length  }} pytań</div>
      </div>
      <a *ngIf="s[1]" (click)="selectQuizLevel(1)" class="btn btn-outline-secondary">Rozwiąż ponownie</a>
      <p *ngIf="!quiz2" (click)="selectQuizLevel(1)" class="alert alert-info">Aby odblokować poziom, musisz rozwiązać quiz z poziomu “Warto więcej”</p>
      <p *ngIf="quiz2 && !s[1]" (click)="selectQuizLevel(1)" class="btn btn-primary w-100">Rozpocznij </p>

    </div>

    <div class="col-4 text-center">
      <div class="box level mb-3 " [ngClass]="quiz3 ? '' : 'dissable'">
        <img *ngIf=" s[2]" src="assets/ikony/lvl3_done.svg" class="mb-4" />
        <img *ngIf="!s[2]" src="assets/ikony/lvl3_undone.svg" class="mb-4" />

        <h4 class="pb-3">Ekspert</h4>
      
        <div *ngIf="s.length  === 3" class="points"> {{ s[2].correct  }} / {{ quiz3.length  }} pytań</div>
        <div *ngIf="s.length  === 2 && quiz3" class="points"> {{ quiz3.length  }} pytań</div>
      </div>
      <a *ngIf="s[2]" (click)="selectQuizLevel(2)" class="btn btn-outline-secondary">Rozwiąż ponownie</a>
      <p *ngIf="!quiz3" class="alert alert-info">Aby odblokować poziom, musisz rozwiązać quiz z poziomu “Wiedzieć więcej”</p>
      <p *ngIf="quiz3 && !s[2]" (click)="selectQuizLevel(2)" class="btn btn-primary w-100">Rozpocznij </p>
    </div>
  </div>
</div>


<div *ngIf="selectedLevel >= 0" class="ms-3 container mt-4">
<div class="d-flex align-items-center">
  <a (click)="goBack()" class="rounded-btn  btn btn-primary">Powrót</a>
</div>
<div class="row">
  <div class="col-12 col-md-8">
    <swiper #swiper class="swiper my-swiper"
            (slideChange)="onSlideChange($event)"
            (swiper)="onSwiper($event)"
            [config]="config">


      <ng-template *ngFor="let question of questionsData; index as i;let count = count; last as last" swiperSlide id="q1" class="swiper-slide">

        <div class="question-header d-flex justify-content-between align-items-end mb-4 short-box">
          <h4 id={{i}} class="question-nr q-box">Pytanie {{i+1}}/{{count}}</h4>

          <div class="box w-50 d-flex align-items-center justify-content-center p-4">
            <img *ngIf="selectedLevel === 0" src="../../../../assets/ikony/lvl1_done.svg" class="me-4"/>
            <img *ngIf="selectedLevel === 1" src="../../../../assets/ikony/lvl2_done.svg" class="me-4"/>
            <img *ngIf="selectedLevel === 2" src="../../../../assets/ikony/lvl3_done.svg" class="me-4"/>
            <p *ngIf="selectedLevel === 0" class="m-0">Na dobry początek</p>
            <p *ngIf="selectedLevel === 1" class="m-0">Wiedzieć więcej</p>
            <p *ngIf="selectedLevel === 2" class="m-0">Ekspert</p>
          </div>
        </div>
        <!-- <p   id={{i}} class="question-nr q-box">Pytanie {{i+1}}/{{count}}</p> -->
        <div class="question">
          <h4 class="p-3 fw-normal">
            {{ question.question }}
          </h4>

        </div>
        <div  #responseBox  class="responses-box"  >
          <div *ngFor="let answer of question.answers; index as i;let count = count;" data-id={{i}} class="ps-4">
            <label class="answers"
                   stopPropagation
                   (click)="labelAddClass($event)">
              {{ answer.answer }}

              <input #q1 data-id="0" type="checkbox" />
              <span class="checkmark"></span>
            </label>
            <span *ngIf="i == question.answer " class="explanation">{{question.tip}}</span>
          </div>
          <!--test555piotr@gmail.com-->
          <!--wefwefwefwef@gmail.com-->
        </div>
        <div class="btns my-4">
          <div *ngIf="!last" (click)="next($event)" [class]='nextSlide  ? "active"  : ""' class="swiper-button-next">Kolejne pytanie</div>
          <div *ngIf="last" (click)="sendResultsTest()" [class]='nextSlide  ? "active"  : ""' class="swiper-button-next">Zakończ test</div>
        </div>
      </ng-template>
      <ng-template swiperSlide class="swiper-slide">
        <div class="question-results">
          <div class="question-header d-flex justify-content-between align-items-end mb-4 short-box">
            <h4>Podsumowanie</h4>
            <div class="box w-50 d-flex align-items-center justify-content-center p-4">
              <img *ngIf="selectedLevel === 0" src="../../../../assets/ikony/lvl1_done.svg" class="me-4"/>
              <img *ngIf="selectedLevel === 1" src="../../../../assets/ikony/lvl2_done.svg" class="me-4"/>
              <img *ngIf="selectedLevel === 2" src="../../../../assets/ikony/lvl3_done.svg" class="me-4"/>
              <p *ngIf="selectedLevel === 0" class="m-0">Na dobry początek</p>
              <p *ngIf="selectedLevel === 1" class="m-0">Wiedzieć więcej</p>
              <p *ngIf="selectedLevel === 2" class="m-0">Ekspert</p>
            </div>
          </div>
          <div class="question-body mb-4">
            <div class="box p-5">
              <h4 class="pb-3 m-0">{{ googAnswers }} na {{ questionsData?.length }} poprawnych odpowiedzi</h4>
              <p *ngIf="googAnswers < 6">
                Upss! Nie poszło Ci najlepiej.
                Spróbuj poszukać informacji o eminto w prezentacji i innych materiałach z zakładki e-Learning.
                Następnym razem na pewno Ci się uda osiągnąć lepszy wynik!
              </p>
              <p *ngIf="googAnswers < 5" class="p-0 m-0">Trzymamy kciuki!</p>
              <p *ngIf="googAnswers > 5 && selectedLevel === 0 && googAnswers < questionsData.length" class="p-0 m-0">
                Świetnie Ci poszło, na większość pytań udzieliłeś poprawnej odpowiedzi.
                Jeśli chcesz dowiedzieć się więcej, przejdź do pozostałych zakładek w e-Learning, w których znajdziesz odpowiedzi na wszystkie pytania.
                Gratulacje!
               
              </p>
              <p *ngIf="googAnswers > 5 && selectedLevel === 1 && googAnswers < questionsData.length" class="p-0 m-0">
                Świetnie Ci poszło, na większość pytań udzieliłeś poprawnej odpowiedzi.
                Jeśli chcesz dowiedzieć się więcej, przejdź do pozostałych zakładek w e-Learning, w których znajdziesz odpowiedzi na wszystkie pytania.
                Gratulacje!
              </p>
              <p *ngIf="googAnswers > 5 && selectedLevel === 2 &&  googAnswers <  questionsData.length" class="p-0 m-0">
                Świetnie Ci poszło, na większość pytań udzieliłeś poprawnej odpowiedzi.
                Jeśli chcesz dowiedzieć się więcej, przejdź do pozostałych zakładek w e-Learning, w których znajdziesz odpowiedzi na wszystkie pytania.
                Gratulacje!
        
              </p>


              <p *ngIf="googAnswers === questionsData.length " class="p-0 m-0">
                Wow!
                Udało Ci się poprawnie odpowiedzieć na wszystkie pytania!
                Gratulacje Mistrzu eminto!
              </p>
             










            </div>
          </div>
          <div class="controls d-flex justify-content-end">
            <a    (click)="testAgain()"  *ngIf="googAnswers < 7"  class="btn btn-primary">Rozpocznij test jeszcze raz</a>
          </div>
        </div>
      </ng-template>
    </swiper>
  </div>
</div>
</div>
