<div class="ms-2 container">
  <div class="row">
    <div   class="d-flex align-items-center" >
      <h2 class="title">Brand book</h2>
    <a    class="ms-4 btn btn-primary rounded-btn " [routerLink]="['/learning/media']"   >Powrót</a>
      </div>
    <div class="d-flex flex-wrap col-12">
  <li *ngFor="let med of media" class="media-box me-3 mb-3">
    <a [routerLink]="['/learning/test1']" class="mb-3">
    <img   [className]="med.icon  ? 'icon' : 'fvr'" [src]="med.img"     #img (click)="open(mymodal, img, link)"/>
      </a>
      <div class="d-flex justify-content-between align-items-center">
        <p class="mb-0">{{ med.name}}</p>
        <a #link    [href]="med.link" download rel="noopener noreferrer"><img class="p-1" src="../../../../assets/img/download_icon.svg" alt=""></a>
      </div>

    </li>


  </div>



  </div>
  </div>



<ng-template #mymodal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title"></h4>

  </div>
  <div class="modal-body">
    <img [src]="imgSrc" alt="modalImg">
  </div>
  <div class="modal-footer">
    <a [href]="link" download rel="noopener noreferrer"><img class="p-1" src="../../../../assets/img/download_icon.svg" alt=""></a>
    <button type="button" class="btn btn-primary" (click)="modal.close('Save click')">Ok</button>
  </div>
</ng-template>
