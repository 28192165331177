
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Contractor, ContractorOptions, Person } from '../models/contractor.model';




@Injectable({
  providedIn: 'root'
})
export abstract class ApiService {
  abstract getContractor(): Observable<Contractor[]>;
  abstract getPersons(id?: string): Observable<Person[]>;
  abstract addContractor(contractor: Contractor): Observable<any>;
  abstract addPerson(person: Person): Observable<any>;
  abstract getContractorOptions(): Observable<ContractorOptions[]>;
  abstract updateContractor(contractor: Contractor, id: string): Observable<any>
  abstract updatePerson(person: Person, id: string): Observable<any>
  abstract getProfile(): Observable<any>
  abstract getSinglePerson(id: string): Observable<Person>
}
