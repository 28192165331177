import {createSelector, createFeatureSelector, combineReducers, Action} from '@ngrx/store';
import * as fromRoot from '../../reducers';
import * as fromAuthPage from './auth.reducer';
import * as fromLoginPage from './login-page.reducer';

export const authFeatureKey = 'auth';

export interface AuthState {
    [fromAuthPage.statusFeatureKey]: fromAuthPage.State;
    [fromLoginPage.loginPageFeatureKey]: fromLoginPage.State;
}

export interface State extends fromRoot.State {
    [authFeatureKey]: AuthState;
}

export function reducers(state: AuthState | undefined, action: Action) {
    return combineReducers({
        [fromAuthPage.statusFeatureKey]: fromAuthPage.reducer,
        [fromLoginPage.loginPageFeatureKey]: fromLoginPage.reducer,
    })(state, action);
}

export const selectAuthState = createFeatureSelector<State, AuthState>(authFeatureKey);

export const selectAuthStatusState = createSelector(
    selectAuthState,
   ( state : AuthState)  => state.status
);


export const selectToken = createSelector(selectAuthStatusState, fromAuthPage.selectToken);
export const selectUserType = createSelector(selectAuthStatusState, fromAuthPage.selectUserType);
export const selectUserPermissions = createSelector(selectAuthStatusState, fromAuthPage.selectUserPermissions);
export const selectUserName = createSelector(selectAuthStatusState, fromAuthPage.selectUserName);
export const selectUserRole = createSelector(selectAuthStatusState, fromAuthPage.selectRole);
export const selectwelcomePopup = createSelector(selectAuthStatusState, fromAuthPage.selectwelcomePopup);

export const selectLoggedIn = createSelector(selectToken, token => !!token && token.refresh_token !== null && token.refresh_token !== undefined);

export const selectLoginPageState = createSelector(
    selectAuthState,
    (state: AuthState) => state.loginPage
);
export const selectLoginPageError = createSelector(
    selectLoginPageState,
    fromLoginPage.selectError
);
