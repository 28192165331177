import { Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ResetPassword, ChangePassword, ChangePassword as IChangePassword } from '../models/reset-password';

import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { ResetPasswordPageActions } from "../actions/index";

@Component({
  selector: 'app-change-password-form',
  template: `
<style>
ul {
    padding: 0;
text-align: left;
}


ul li {
    list-style:none;
}
.pass_rules  {
    font-size: 12px;
}

</style>

    <div class="d-flex text-center justify-content-center w-100 form-container">
      <main class="form-signin align-items-center">
        <form class="w-100" [formGroup]="form" (ngSubmit)="submit()">
          <img class="mb-4" src="assets/img/logo-dark.svg" alt="" height="40" />


          <div class="form-floating">
            <input  #password   type="email" class="form-control" id="floatingInput" (click)="ngOnInit()" formControlName="password" />
            <label for="floatingInput">Nowe hasło</label>
          </div>
          <div class="form-floating">
            <input    #repeatPassword  type="email" class="form-control" id="floatingInput"  (click)="ngOnInit()" formControlName="repeatPassword"  />
            <label for="floatingInput">Powtórz hasło</label>
          </div>
          <p class="mb-0 mt-3 text-start" >Hasło powinno zawierać:</p>
          <ul>
            <li>- minimum 8 znaków</li>
            <li>- co najmiej 1 wielką litere</li>
            <li>- co najmiej 1 małą litere</li>
            <li>- co najmiej 1  cyfra</li>
            <li>- co najmiej 1  znak specjalny</li>
          </ul>
        
          <p class="pt-3 text-center text-error" *ngIf="checkPasswords">Podane hasła nie są takie same. </p>
          <button [disabled]="form.invalid"   type="submit" class="w-100 mt-3 btn btn-primary" type="submit">Wygeneruj nowe hasło</button>
          <a [routerLink]="['/login']" class="mt-3 d-block">Powrót do strony logowania</a>
        </form>
      </main>
    </div>
`
})
export class ChangePasswordFormComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private store: Store
    ) {

  }



  @Input() success: boolean | null;
  @Input() error: ResetPassword | null;
  @Output() submitted = new EventEmitter<ChangePassword>();
  @ViewChild('password') password: ElementRef;
  @ViewChild('repeatPassword') repeatPassword: ElementRef;
  checkPasswords: boolean = false;
  token: string;

  public zoomIn: boolean = true;

  ngOnChanges(changes: SimpleChanges): void {
    let simpleChange = changes['success'];
    if (simpleChange && !simpleChange.firstChange) {
      this.form.reset();
    }
  }

  form: FormGroup = new FormGroup({
    password: new FormControl('', [Validators.required, Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!@#$%^&*()_+|}{:?><`!<>?]).{8,}$')]  ),
    repeatPassword: new FormControl('', [Validators.required, Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!@#$%^&*()_+|}{:?><`!<>?]).{8,}$')])
  });

  ngOnInit(): void {
    this.error = null;
    this.route.paramMap.subscribe(params => {
      
      this.token = params.get("id");
     
      if (this.token) {
        this.store.dispatch(ResetPasswordPageActions.checkLink({token : this.token}));
        /*  this.form.patchValue(this.dentist)*/
      }
    });

  }

  submit() {
    if (this.password.nativeElement.value === this.repeatPassword.nativeElement.value) {
      this.checkPasswords = false;
    


      let t: ChangePassword = {
        password : this.form.value['password'],
        confirmPassword: this.form.value['repeatPassword'],
        token: this.token
      }
      this.submitted.emit(t);
    } else {
      this.checkPasswords = true;
    }
      // this.form.get('password').enable();
      
    
   
    this.zoomIn = false
  }
}
