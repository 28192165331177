import { createReducer, on } from "@ngrx/store";
import { DentistsApiActions, DentistsPageActions } from "../actions";
import { DentistList, DentistDetails, DentisSlistBySeller } from "../models/dentist.model";

export const dentistsPageFeatureKey = "dentistsPage";

export interface State {
  error: string | null;
  dentists: DentistList[] | null;
  currentDentist: DentistDetails[] | null;
  dentistBySeller: DentisSlistBySeller[] | null
  loading: boolean;
  gusDentist: any;
  link: string | null;
}




export const initialState: State = {
  error: null,
  dentists: null,
  currentDentist: null,
  dentistBySeller: null,
  loading: false,
  gusDentist: null,
  link: null,
};

export const reducer = createReducer(
  initialState,
  on(
    DentistsApiActions.getDentistsSuccess,
    (state, action) => ({ ...state, dentists: action.dentists, loading : false })
  ),
  on(
    DentistsPageActions.getDentists,
    (state, action) => ({ ...state, loading : true})
  ),
  on(
    DentistsApiActions.getDentistsBySellerSuccess,
    (state, action) => ({ ...state, dentistBySeller: action.dentists, loading: true })
  ),
  on(
    DentistsApiActions.getDentistsFailure,
    (state, action) => ({ ...state, error: action.error, loading: false })
  ),
  on(
    DentistsApiActions.addDentistSuccess,
    (state, action) => ({
      ...state,
      loading: false
    })
  ),
  on(
    DentistsApiActions.addDentistFailure,
    (state, action) => ({
      ...state, error: action.error,


      loading: false
    })
  ),

  on(
    DentistsApiActions.getDentistDetailsSuccess,
    (state, action) => ({
      ...state,
      currentDentist: action.dentist.map(val => {
        return val;
      }),
      loading: false
    })
  ),

    on(DentistsPageActions.RemoveCurrentDentist,
      (state, action) => ({
        ...state,
        currentDentist: null
      })
    ),
  on(DentistsPageActions.RemoveDentisFromGus,
    (state, action) => ({
      ...state,
      gusDentist: null
    })
  ),
  on(DentistsPageActions.removeCurrentDentist,
    (state, action) => ({
      ...state,
      currentDentist: null
    })
  ),


  on(DentistsPageActions.getDentistDetails,
    (state, action) => ({
      ...state,
      loading: true
    })
  ),

  on(DentistsApiActions.getDentistDetailsFailure,
    (state, action) => ({
      ...state,
      loading: true,
      error: action.error
    })
  ),


  on(DentistsApiActions.GetDatafromGusSuccess,
    (state, action) => ({
      ...state,
      gusDentist: action.data
    })
  ),
  on(DentistsApiActions.getDentistDetailsFailure,
    (state, action) => ({
      ...state,
      loading: false,
      error: action.error
    })
  ),

  on(DentistsApiActions.GenerateLinkSuccess,
    (state, action) => ({
      ...state,
      loading: false,
      link: action.link
    })
  ),

  on(DentistsApiActions.GenerateLinkSuccess,
    (state, action) => ({
      ...state,
      loading: false,
      link: action.link
    })
  ),

  on(DentistsApiActions.GeneratePaymentsLinkFailure,
    (state, action) => ({
      ...state,
      loading: false,
      link: null
    })
  ),




  on(DentistsApiActions.GenerateTestLinkFailure,
    (state, action) => ({
      ...state,
      loading: false,
      link: null
    })
  ),







);


export const selectError = (state: State) => state.error;
export const selectLoading = (state: State) => state.loading;
export const selectDentists = (state: State) => state.dentists;
export const selectCurrent = (state: State) => state.currentDentist;
export const selectGusDentist = (state: State) => state.gusDentist;
export const selectLink = (state: State) => state.link;
export const selectDentistBySeller = (state: State) => state.dentistBySeller;
