import {createAction, props} from '@ngrx/store';
import {Credentials} from '../models/auth.model';

export const login = createAction(
    '[Login Page] Login',
    props<Credentials>()
);

export const changePassword = createAction(
    '[Login Page] Change Password',
    props<{credentials: Credentials, resetPassword: boolean}>()
);