import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { combineLatest, of } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";


import * as fromDentists from "../reducers";
import { OrdersService } from "../services";
import { ContaractorService } from "../../contractor/services/contractor.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { OrdersApiActions, OrdersPageActions } from "../actions";
import { IOrders } from "../models/orders.model";

@Injectable()
export class OrdersEffects {

  getOrders$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrdersPageActions.getOrders),
      switchMap(({ status }) => {
        return this.apiService.getOrdes(status).pipe(
          map((res: IOrders[]) => {
            let t = res.map(product => {
              // debugger
              return {
                ...product,
                data: new Date(product.date),
                person: product.name + " " + product.surname,
                complatePerson: product.complete ? product.complete.name + ' ' + product.complete.surname : "",
                complateDate: product.complete ? product.complete.date : ''
              }
            })


            if (status === "active") {
              return OrdersApiActions.getOrdersSuccess({ orders: t });
            } else {
              t.sort((a, b) => {
                return new Date(b.complete.date).getTime() - new Date(a.complete.date).getTime()
              })
              return OrdersApiActions.getCompleteOrdersSuccess({ orders: t });
            }

          }),
          catchError((error: any) => of(OrdersApiActions.getOrdersFailure({ error })))
        );
      })
    )
  );



  sendOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrdersPageActions.sendOrder),
      switchMap(({ id }) => {
        return this.apiService.sendOrder(id).pipe(
          map((res: any[]) => {
            return OrdersApiActions.sendOrderSuccess();
          }),
          catchError((error: any) => of(OrdersApiActions.sendOrderFailure({ error })))
        );
      })
    )
  );


  sendOrderSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrdersApiActions.sendOrderSuccess),
      map(() => {
        this.store.dispatch(OrdersPageActions.getOrders({ status: 'active' }));
      })
    ),
    { dispatch: false }
  );











  constructor(
    private modalService: NgbModal,
    private store: Store<fromDentists.State>,
    private actions$: Actions,
    private apiService: OrdersService,
    private router: Router,
    private apiServiceContractor: ContaractorService,
  ) {
  }
}
