import {Component} from '@angular/core';
import {select, Store} from '@ngrx/store';
import * as fromResetPassword from '../reducers';
import {ResetPasswordPageActions} from '../actions';
import {ResetPassword} from '../models/reset-password';

@Component({
    template: `
    <app-reset-password-form
      (submitted)="onSubmit($event)"
      [success]="success$ | async"
      [error]="error$ | async">
    </app-reset-password-form>`
})
export class ResetPasswordPageComponent {
    
    success$ = this.store.pipe(select(fromResetPassword.getChangePasswordSuccess));
    error$ = this.store.pipe(select(fromResetPassword.getResetPasswordPageError));

    constructor(private store: Store<fromResetPassword.State>) {}

    onSubmit(resetPassword: ResetPassword) {
        this.store.dispatch(ResetPasswordPageActions.reset({resetPassword}));
    }
}
